import React, { useState } from 'react';
import { Form, FormGroup, Label, Input, FormText, Button,Spinner } from 'reactstrap';
import server from '../../../../../apis/server';
import Loader from "../../../../../components/common/loader/loader";
import toast from '../../../../../utils/toast';

const SeoSettings = ({ pageId, onSave, initialData ,updateGrapeJs,setUpdateGrapeJs}) => {
    console.warn('initialData', initialData)
    const { seo_title, seo_keyword, seo_description } = initialData?.seo_setting ?? {};

    const [seoTitle, setSeoTitle] = useState(seo_title || '');
    const [seoKeyword, setSeoKeyword] = useState(seo_keyword || '');
    const [seoDescription, setSeoDescription] = useState(seo_description || '');
    const [seoSlug, setSeoSlug] = useState(initialData?.url || '');
    const [isLoading, setIsLoading] = useState(false);

    const handleSave = async () => {
        setUpdateGrapeJs(false);
        setIsLoading(true)

        const formData = {
            page_id: pageId,
            seo_title: seoTitle,
            seo_keyword: seoKeyword,
            seo_description: seoDescription,
            slug: seoSlug,
        }

        try {
            const {data} = await  server.post(`page/update_seo_setting`,
                formData
            );
            setUpdateGrapeJs(true);

            if (data?.status) {
                setIsLoading(false)
                toast(data?.message, 'success');
            }

            // spinner.style.display = 'none';
        } catch (e) {
            setIsLoading(false)

            console.log('cosakdljsald', e);

            if (e.response.data?.error === true) {
                if (e.response?.data?.errors.seo_title) {
                    toast(e.response?.data?.errors.seo_title[0], 'error');
                } else if (e.response?.data?.errors.seo_description) {
                    toast(e.response?.data?.errors.seo_description[0], 'error');
                } else if (e.response?.data?.errors.seo_keyword) {
                    toast(e.response?.data?.errors.seo_keyword[0], 'error');
                } else if (e.response?.data?.errors.slug) {
                    toast(e.response?.data?.errors.slug[0], 'error');
                }
                else
                {
                    toast(e.response?.data?.errors_array[0], 'error');

                }
            }
        } finally {
            // isApiCallInProgress = false; // Reset the flag to allow future API calls.
        }
    
    };

    return (
        <div className={'styleDetails'}>
            <Form>
                <FormGroup>
                    <Label for="pageTitle">Page Title</Label>
                    <Input
                        type={'text'}
                        value={seoTitle}
                        onChange={(e) => setSeoTitle(e.target.value)}
                        placeholder={'Enter SEO title'}
                    />
                    <FormText className={'text-white'}>Max 70 characters</FormText>
                </FormGroup>
                <FormGroup>
                    <Label for="keywords">Keywords (comma separated)</Label>
                    <Input
                        type={'text'}
                        value={seoKeyword}
                        onChange={(e) => setSeoKeyword(e.target.value)}
                        placeholder={'Marketing, Subscription, Email etc.'}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="Description">Description</Label>
                    <Input
                        type="textarea"
                        value={seoDescription}
                        onChange={(e) => setSeoDescription(e.target.value)}
                        placeholder={'Enter SEO description'}
                    />
                    <FormText className={'text-white'}>Max 200 words</FormText>
                </FormGroup>
                <FormGroup>
                    <Label for="pageSlug">Page Slug<sup>*</sup></Label>
                    <Input
                        type={'text'}
                        value={seoSlug}
                        onChange={(e) => setSeoSlug(e.target.value)}
                        placeholder={'Enter the page slug'}
                    />
                    <FormText className={'text-white'}>Max 70 characters</FormText>
                </FormGroup>
                {/* <Button color="primary" onClick={handleSave}>Save</Button> */}
                {/* <Button color="primary" className={'btn primary-btn-modal shadow-none'} onClick={handleSave} >
                    {isLoading ? <span className={''}> Saving  <Loader /> </span> : 'Save'}
                </Button> */}
                <Button className={'primary-btn-modal shadow-none'} onClick={handleSave}
                                           >
                                                {isLoading ?
                                        <span className={'spinnerContainer'}> Saving  <Spinner>
                                    Loading...
                                </Spinner></span> : 'Save'}</Button>{' '}
            </Form>
        </div>
    );
};

export default SeoSettings;
