import React from 'react';
import PaymentImg from '../../images/payment-method.png'
import InternalTitle from "../common/internalTittle/GenaricButton";

function PaymentMethod({billingInfo}) {
    return (
        <div className={'mt-4 mb-5'}>
            <InternalTitle title={'Payment Method'}/>
            <div className={'plan_subscription  mt-4'}>
                <div className={'row justify-content-center'}>
                <div className={'col-md-4'}>
                    <div className='payment_method'>
                        <div className="head">
                            <img src={PaymentImg} alt="" height={50}/>
                        </div>
                        <div className="body">
                            <p className="card-no-space"> •••• •••• •••• { billingInfo?.card_last_4 ? billingInfo?.card_last_4 :'••••'} </p>
                            <p> Added: {billingInfo?.card_last_updated ? billingInfo?.card_last_updated : '---' } </p>
                        </div>
                        <div className='footer'>
                            <button 
                            disabled={!billingInfo?.update_cc_url}
                            onClick={()=>{
                                location.href = billingInfo?.update_cc_url

                            }}
                            block className="btn btn-primary block"> Edit Payment Information
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    );

}

export default PaymentMethod;