import React, {useState} from 'react';
import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Nav, Form, Row, Input, Col,
    Navbar,
    NavItem, Spinner, NavLink
} from 'reactstrap';
import {BiEditAlt, BiPencil} from "react-icons/bi";
// import Image from 'next/image';
import styles from './header.module.css';
import ProfilePic from '../../images/testName.jpg';

import {BsBell} from "react-icons/bs";
import {IoSettingsOutline, IoTrashOutline} from "react-icons/io5";
import {Link, useNavigate} from "react-router-dom";

import server from '../../apis/server'
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import toast from '../../utils/toast'
import { useStoreState, useStoreActions } from "easy-peasy"


function Header({...props}) {
    const AccountAction = useStoreActions((actions) => actions?.accountState?.setAccount)

    const router = useNavigate()
    const accountsString =   localStorage.getItem('accounts')
    console.log(accountsString)
    const accounts =    accountsString ? JSON.parse(accountsString) : ''
    console.log('accounts',accounts)
    const {account_title}  =   accounts

   

    // alert(props.page)

    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [title, setTitle] = useState();

    // const [name, setName] = useState('John Smith')
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [showEdit, setShowEdit] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);
    const [modal, setModal] = useState(false);
    const toggleDelete = () => {
        setModal(!modal);

    }
    const toogleEdit = () => {
        setShowEdit(!showEdit);

    }

    const showModal = () => {

        toggleDelete()


    }
    const characterLimit=event=>{
        event.preventDefault();

        let result=event.target.value.replace(/\s+/g, ' ').trim();

        // if (event.target.value.includes(" ")) {
        //     event.target.value = event.target.value.replace(/\s/g, "");
        // }
        // let result=event.target.value;

        // setTitle(e.target.value)
        // let isValid = result.replace(/\s+/g, '');
        setTitle(result)
      const charCount = result.length;
     if(charCount >= 3 &&  charCount < 255 ){

    //    setDisable(false);

     }else {
         console.log(charCount);
        //  setDisable(true);
     }
    }

    const deletePage=async()=>{
        setLoading(true)
        
          try {
            const { data } = await server.delete(`/page/delete/${props.detail}`);
            if(data?.status){
                // alert('cc')
                toast(data?.message,'success')

                setLoading(false)

                router(`/pages`)

            }
           console.log(data)

            // setProduct(data);
            // setLoading(false);
          } catch (e) {
              setLoading(false)
                 if(e.response?.data?.status===false){


                    toast(e.response?.data?.message,'error')
                 }
                 else{
                    //  setError(e.message)
                    //  setDisable(false)
                    //  setIsLoading(false)

                 }
            //console.log(e.response);

        }
    }

    

    const editPage = async()=>{

        // setDisable(true)
        // setIsLoading(true)
        // setError('')
        let formData={
            page_id:props?.detail,
           page_title: title ? title : props?.pageDetail?.title
         }

          try {
            const { data } = await server.post(`page/update_title`,
                formData,
            );
            setShowEdit(false)

            if(data?.status){
              props?.handleClick(true)
              toast(data?.message,'success')
                // isLoading(true)
                // router(`/pages/editor/${data?.data?.page_id}`)
            }
           console.log(data)
            // setProduct(data);
            // setLoading(false);
          } catch (e) {
            setShowEdit(false)

                 if(e.response?.data?.status===false){
                   toast(e.response?.data.errors?.page_title[0],'error')
                    //  setDisable(false)
                    //  setIsLoading(false)
                 }
                 else{
                    toast(e.message)
                    //  setDisable(false)
                    //  setIsLoading(false)

                 }
            //console.log(e.response);

        }
    }

    return (
        <header className={styles.header}>
            {/* <ToastContainer /> */}
            <Navbar className="pt-1">
                    <span href="/" className={styles.pageName}>
                        
                    {props.page !=='detail' &&(
                     <span
                        className="mx-2"> {props.page ? props.page.replace(/%20/g, " ")   : 'Marketing Funnels'} </span>
                        
                    )}
                    { (props.page =='detail' && !showEdit) &&(
                     <span
                        className="mx-2"> { props?.pageDetail?.title} </span>
                        
                    )}

<span>

                    {/* {props.page === 'editor' &&

                    (
                        <BiPencil className="mb-1"/>
                    )} */}

{props.page === 'detail' &&

(
    <>
                       { !showEdit && ( <BiPencil className="mb-1" onClick={toogleEdit}/>)}
{ showEdit && (
 <Row className="row-cols-lg-auto g-3 align-items-center">
 <Col>
   <Input
       id="exampleEmail"
       name="email"
    //    placeholder="something@idk.cool"
    //    type="email"
    defaultValue={props?.pageDetail?.title}
    // value={props?.pageDetail?.title}
       onChange={characterLimit}
   />
 </Col>
 <Col>
   <Button className={"btn-primary"}
   onClick={editPage}
   >
     Save
   </Button>
 </Col>
</Row>
)}
 


  </>
)}


{/* </Form> */}


                         </span></span>
                {/* {props.page==='editor' &&  <span className={`${styles.pbBadge} rounded-pill`} >Published</span>
                            )} */}
                <Nav className="ms-auto pt-1" navbar>
                    {props.page === 'detail' && (
                        <>
                            <NavItem>
                                <Button

                                    

                                    onClick={() => {
                                        const targetUrl = props?.pageDetail?.grape_js_version 
                                            ? `/pages/editor/${props?.pageDetail?.id}`
                                            : `/pages/editor_v0/${props?.pageDetail?.id}`;
                                
                                        router(targetUrl, {
                                            state: {
                                                is_from_template: props?.pageDetail?.is_from_template
                                            },
                                        });
                                
                                        // Reload the page
                                        window.location.href = targetUrl;
                                    }}

                                    className={`${styles.editBtn} shadow-none`}><BiEditAlt/><span>Edit Page</span></Button>
                            </NavItem>
                            <NavItem
                                onClick={() => showModal()}
                            >
                                <span className={styles.trash}><IoTrashOutline size={18}/></span>
                            </NavItem>
                            {/* <NavItem>
                                <Button className={`${styles.pbBtn}  btn primary-btn shadow-none`}>Unpublish</Button>
                            </NavItem>
                            <NavItem>
                                <span className={styles.trash}><IoTrashOutline size={18}/></span>
                            </NavItem> */}
                        </>
                    )
                    }

                    {/*<NavItem>*/}
                    {/*            <span>*/}
                    {/*                <Button className={`${styles.notificationBtn} position-relative shadow-none`}>*/}
                    {/*                   <BsBell size={25}/>*/}
                    {/*                  <span*/}
                    {/*                      className={`${styles.pillNoti} p-1 position-absolute  start-100 translate-middle badge rounded-pill bg-danger`}>*/}
                    {/*                    99+*/}
                    {/*                    <span className="visually-hidden">unread messages</span>*/}
                    {/*                  </span>*/}
                    {/*                </Button>*/}

                    {/*            </span>*/}
                    {/*</NavItem>*/}

                </Nav>

                <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={'down'}>
                    <DropdownToggle className={`${styles.profileDropdown} shadow-none`} caret>
                        <span className={styles.profilePic}><img src={ProfilePic} alt={'profileImage'} width={'36'}
                                                                   height={'36'}/></span>
                        <span>{account_title}</span>
                    </DropdownToggle>    
                    <DropdownMenu className={`${styles.listUser}`}>
                        {/*<DropdownItem onClick={()=>{*/}
                        {/*    router('/pages/setting/')*/}
                        {/*}}>*/}

                        {/*       My Profile*/}

                        {/*  </DropdownItem>*/}
                        {/*<DropdownItem>Notification Setting</DropdownItem>*/}
                        {/*<DropdownItem>Security</DropdownItem>*/}
                        {/*<DropdownItem divider/>*/}
                        <DropdownItem onClick={()=>{
                            localStorage.clear()

                            AccountAction(null)
                            router('/login')
                    //    window.location.reload()


                            
                        }}>Log out</DropdownItem>
                    </DropdownMenu>
                </Dropdown>

            </Navbar>


            <Modal isOpen={modal} toggle={toggleDelete} className={'creatPageModal'} centered backdrop={'false'}>
                <ModalHeader toggle={toggleDelete}>Delete Page </ModalHeader>
                <ModalBody>
                    <p>Are you sure you want to delete this page? </p>

                </ModalBody>
                <ModalFooter>
                    <Button className={'btn-danger shadow-none d-flex align-items-center justify-content-center '} onClick={deletePage}> <IoTrashOutline size={15}/>{isLoading ?
                        <span className={'spinnerContainer '}>Deleting<Spinner >
                                    Loading...
                                </Spinner></span> :'Delete'} </Button>
                    <Button className={'secondary-btn-modal mx-2 shadow-none'} onClick={toggleDelete}> Cancel</Button>

                </ModalFooter>
            </Modal>



        </header>

    );
}

export default Header;