import { useEffect } from 'react';
import server from "../../../../apis/server"

import { toast } from 'react-toastify'; // Import toast if you're using it for notifications

const usePageDetails = ({ getPage, setDraftData, setGoogleCoversionId, setLoading }) => {
    useEffect(() => {
        if (getPage) {
            getPageDetail();
        }
    }, [getPage]);

    const getPageDetail = async () => {
        try {
            const { data } = await server.get(getPage);
            setLoading(false);

            if (data?.status) {
                setDraftData(data?.data?.html_body);
                setGoogleCoversionId(data?.data?.script_setting?.google_conversion_id);
            }
        } catch (e) {
            setLoading(false);
            if (e.response?.data?.status === false) {
                const errorMessage = e.response?.data.errors_array?.[0] || e.response?.data?.message;
                toast(errorMessage, 'error');
            }
        }
    };
};

export default usePageDetails;
