import React,{useState,useEffect} from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
function Audience({data,xaxis}) {

    const [audienceData, setAudienceData] = useState([]);

    const [options, setChartOptions] = useState(
    {
        chart: {
            type: 'spline',
            height: 230
        },
        title: {
            text: '',
            style: {
                display: 'none'
            }
        },
        credits: {
            enabled: false
        },
        
        xAxis: {
            categories: [2023-1-1]
        },
        yAxis: {
            min: 0,
            title: {
                text: ''
            },
            stackLabels: {
                enabled: true,

            }
        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y}'
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false
                }
            }
        },

        series: [{
            name: 'User',
             data:  [0,0,0,0,0,0,0,0,0,0,0]

        },

         {
            name: 'Session',
            data:  [0,0,0,0,0,0,0,0,0,0,0]
            
        },
        {
            name: 'Conversion Rate',
            data:  [0,0,0,0,0,0,0]

        }
        ]
    }
    )

    useEffect(() => {
        console.log('xaxis',xaxis)
        setChartOptions({ 
            xAxis: {
                categories: xaxis?.users_x_axis
            },
            series: [
                {data:xaxis?.users_session_x_val},
                {data:xaxis?.conversion_x_val},
                { data: data},
               
                
              ]
          });   

    }, [data,xaxis])
    

    return (

        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>

    )
}
export default Audience;