import React,{useState,useEffect} from "react";








function DayandTime() {
    const current = new Date();
    const date = `${current.getDate()}`;

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const d = new Date();

    const dayName =['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const day = dayName[new Date().getDay()];

    return (

         <>
             <h6> {day} , {monthNames[d.getMonth()]}  {date}   </h6>
         </>

    )
}

export default DayandTime;