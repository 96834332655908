import React, { useEffect, useState } from 'react'
import {
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    List,
    Nav,
    NavItem,
    NavLink,
    Row,
    Spinner,
    TabContent,
    Table,
    Badge,
    TabPane
} from "reactstrap";

import {AiOutlineEye, AiOutlineEyeInvisible} from "react-icons/ai";
import {BsCheck2} from "react-icons/bs";
import { BiLinkExternal }  from "react-icons/bi";
import { FaDownload }  from "react-icons/fa";

import InternalTitle from "../../common/internalTittle/GenaricButton"
import { Formik } from "formik";
import * as yup from "yup";
import server from "../../../apis/server"
import Loader from "../../../components/common/loader/loader";
import toast from '../../../utils/toast'
import { useNavigate, useParams } from "react-router-dom";
import { FiLink } from "react-icons/fi";
import PlanSub from "../../../components/billing/subscriptionPlan"
import Invoice from "../../../components/billing/invoices"
import PaymentMethod from "../../billing/paymentMethod";
import { useStoreState, useStoreActions } from "easy-peasy"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { GiGlassCelebration } from "react-icons/gi";



function ProfileSettings() {
    const [activeTab, setActiveTab] = useState('1');
    const [workspacemodal, setworkspaceModal] = useState(false);
    const [profileData, setProfileData] = useState(null);
    const [billingInfo, setBillingInfo] = useState(null);
    const [subscriptionPlan, setSubscriptionPlan] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [updatePlan, setUpdatePlan] = useState(false);

    const [isLoadingWorkspace, setIsLoadingWorkspace] = useState(false);
    const [isLoadingSecurity, setIsLoadingSecurity] = useState(false);
    const [pagesList, setPageslist] = useState([]);
    const [selectedPageOption, setSelectedPageOption] = useState("");
    const [gdprStatus, setGdprStatus] = useState(false);
    const [updateUi, setUpdateUi] = useState(false);
    const [currentPasswordIcon, setCurrentPasswordIcon] = useState(true);
    const [passwordIcon, setPasswordIcon] = useState(true);
    const [cPasswordIcon, setCpasswordIcon] = useState(true);
    const apiConnecttoggle = () => setworkspaceModal(!workspacemodal);
    const accountsString = localStorage.getItem('accounts')
    const accounts = accountsString ? JSON.parse(accountsString) : ''
    console.log('accounts', accounts)
    const { user_id, public_url, account_url, is_gdp,user_type } = accounts
    const router = useNavigate()
    let { setting,pp} = useParams();
    // state?.funnelState?.funnel?.payload

    const LoadingState = useStoreState((state) => state?.LoadingState?.LoadingState?.payload)
    const LoadingAction = useStoreActions((actions) => actions?.LoadingState?.setLoader)

    const AccountAction = useStoreActions((actions) => actions?.accountState?.setAccount)


    const floatHostingUrl = process.env.REACT_APP_FLOAT_HOSTING
    let validationSchema = yup.object({

        first_name: yup.string().required('This field is required.').min(3).max(250),
        email: yup.string().email('Invalid email').required('This field is required.'),
        // city: yup.string().min(3).max(250),
        // country: yup.string().min(3).max(250),
        // address: yup.string().min(3).max(140),
        // address2: yup.string().min(3).max(140),

        // billing_first_name: yup.string().required('This field is required.').min(3).max(250),

        // billing_last_name: yup.string().min(3).max(250),
        // billing_city: yup.string().min(3).max(250),
        // billing_country: yup.string().min(3).max(250),
        // billing_address: yup.string().min(3).max(140),
        // billing_address2: yup.string().min(3).max(140),


    });
    let validationSecuritySchema = yup.object({

        current_password: yup.string().required('Current Password is required.'),

        confirm_password: yup
            .string()
            .oneOf([yup.ref('password'), null], 'Passwords must match')

    });
    useEffect(() => {
        getProfileData()
    }, [updateUi])

    useEffect(() => {
        getBillingInfo()
        getPages()
        getGdprStatus()
        getSubscriptionPlan()


    }, []) 

    useEffect(() => {

        if (setting == 5 || pp == 'pp') {
            setActiveTab('5')
            getBillingInfo()
            getSubscriptionPlan()
  
        }
        else {
            setActiveTab('1')
        }
    }, [setting])


 useEffect(() => {
     if(updatePlan){
        getBillingInfo()
        getSubscriptionPlan()
     }
        

    }, [updatePlan])


    const cancelPlanUpdate = (plan) => {
        // alert(plan)
        setUpdatePlan(plan)
        LoadingAction(true)

      };
    


    const getBillingInfo = async () => {

        try {
            setIsLoading(true)
            let formData = {
                user_id
            }

            const { data } = await server.post(`billing/info`, formData);
            if (data?.status) {
                setBillingInfo(data?.data)
                setIsLoading(false);
            }
            console.log(data)
            // setProduct(data);
            // setLoading(false);

        } catch (e) {
            if (e.response?.data?.status === false) {
                if (e.response?.data.errors_array?.[0]) {
                    toast(e.response?.data.errors_array?.[0], 'error')
                }
                else {
                    toast(e.response?.data?.message, 'error')

                }
                //  setError(e.response?.data.errors?.title[0])
                setIsLoading(false)
            }
            else {
                setIsLoading(false)

            }


        }
    }

    const getSubscriptionPlan = async () => {

        try {
            setIsLoading(true)
            let formData = {
                user_id
            }

            const { data } = await server.post(`billing/info`, formData);
            if (data?.status) {

                // console.warn('local', accounts)


                const {

                    card_last_4,
                    card_expiry,
                    card_last_updated,
                    update_cc_url,
                    subscription_type,
                    account_status,
                    subscription_status,
                    subscription_package_name,
                    subscription_start_date,
                    subscription_end_date,
                    subscription_next_billing_date,
                    subscription_plan_price,
                    subscription_plan_price_paid,
                    subscription_coupon,
                    subscription_currency,
                    subscription_remaining_days,
                    subscription_package_type,
                    pp_url,
                    is_pause_plan,
                    pause_plan_schedule_date,
                    is_grace_period,
                    is_cancelled,
                    remaining_days_date,
                    acc_close_date,
                    is_active_pp,
                    change_plan_schedule_date,
                    change_plan_name,
                    package_type,
                    user_type

                } = data?.data
   
                    accounts['card_last_4'] = card_last_4;
                    accounts['card_expiry'] = card_expiry;
                    accounts['card_last_updated'] = card_last_updated;
                    accounts['update_cc_url'] = update_cc_url;
                    accounts['subscription_type'] = subscription_type;
                    accounts['account_status'] = account_status;
                    accounts['subscription_status'] = subscription_status;
                    accounts['subscription_package_name'] = subscription_package_name;
                    accounts['subscription_start_date'] = subscription_start_date;
                    accounts['subscription_end_date'] = subscription_end_date;
                    accounts['subscription_next_billing_date'] = subscription_next_billing_date;
                    accounts['subscription_plan_price'] = subscription_plan_price;
                    accounts['subscription_plan_price_paid'] = subscription_plan_price_paid;
                    accounts['subscription_coupon'] = subscription_coupon;
                    accounts['subscription_currency'] = subscription_currency;
                    accounts['subscription_remaining_days'] = subscription_remaining_days;
                    accounts['subscription_package_type'] = subscription_package_type;
                    accounts['pp_url'] = pp_url;
                    accounts['is_pause_plan'] = is_pause_plan;
                    accounts['pause_plan_schedule_date'] = pause_plan_schedule_date;
                    accounts['is_grace_period'] = is_grace_period;
                    accounts['is_cancelled'] = is_cancelled;
                    accounts['remaining_days_date'] = remaining_days_date;
                    accounts['acc_close_date'] = acc_close_date;
                    accounts['is_active_pp'] = is_active_pp;   
                    accounts['change_plan_schedule_date'] = change_plan_schedule_date;
                    accounts['change_plan_name'] = change_plan_name; 
                    accounts['package_type'] = package_type; 
                    accounts['user_type'] = user_type; 
                      
                  
                // }
            // })
            console.warn('local', accounts)

            await localStorage.setItem('accounts',JSON.stringify(accounts))


                      AccountAction(accounts)

                setSubscriptionPlan(data?.data)
                setIsLoading(false);

            }
            console.log(data)
            // setProduct(data);
            // setLoading(false);

        } catch (e) {
            if (e.response?.data?.status === false) {
                if (e.response?.data.errors_array?.[0]) {
                    toast(e.response?.data.errors_array?.[0], 'error')
                }
                else {
                    toast(e.response?.data?.message, 'error')

                }
                //  setError(e.response?.data.errors?.title[0])
                setIsLoading(false)
            }
            else {
                setIsLoading(false)

            }


        }
    }


    const getProfileData = async () => {

        try {
            setIsLoading(true)


            const { data } = await server.get(`profile/get?user_id=${user_id}`);
            if (data?.status) {
                setProfileData(data?.data)
                setIsLoading(false);
            }
            console.log(data)
            // setProduct(data);
            // setLoading(false);

        } catch (e) {
            if (e.response?.data?.status === false) {
                console.log(e.response?.data.errors?.title[0])
                // setError(e.response?.data.errors?.title[0])
                setIsLoading(true);
            } else {
                // setError(e.message)
            }


        }
    }


    const getPages = async () => {

        try {
            setIsLoading(true)

            const { data } = await server.get('page/getPublishedPages');
            if (data?.status) {
                setPageslist(data?.data)
                let page = data?.data
                let defaultPage = page?.filter((x) => x?.is_default == 1)
                setSelectedPageOption(defaultPage[0]?.id)
                setIsLoading(false);
            }
            console.log(data)
            // setProduct(data);
            // setLoading(false);

        } catch (e) {
            if (e.response?.data?.status === false) {
                console.log(e.response?.data.errors?.title[0])
                // setError(e.response?.data.errors?.title[0])
                setIsLoading(true);
            } else {
                // setError(e.message)
            }


        }
    }
    const getGdprStatus = async () => {

        try {
            let formData = {
                domain: account_url,

            }
            setIsLoading(true)

            const { data } = await server.post('domain/checkGdpStatus', formData);
            if (data?.status) {
                let isGdp = data?.data?.is_gdp == 1 ? true : false
                setGdprStatus(isGdp)
            }
            setIsLoading(false);

            // setProduct(data);
            // setLoading(false);

        } catch (e) {
            if (e.response?.data?.status === false) {
                console.log(e.response?.data.errors?.title[0])
                // setError(e.response?.data.errors?.title[0])
                setIsLoading(true);
            } else {
                // setError(e.message)
            }


        }
    }

    async function handleProfileUpdate(values, resetForm) {
        setIsLoading(true)

        let formData = {
            user_id: user_id,
            email: values?.email,
            first_name: values?.first_name,
            // last_name: values?.last_name,

            billing_first_name: values?.billing_first_name,
            billing_last_name: values?.billing_last_name,
            billing_city: values?.billing_city,
            billing_country: values?.billing_country,
            billing_bio: values?.billing_bio,
            billing_address: values?.billing_address,
            billing_address2: values?.billing_address2
        }
        try {
            const { data } = await server.post(`profile/update`,
                formData,
            );

            if (data?.status) {
                setUpdateUi(!updateUi)
                // isLoading(true)
            }
            console.log(data)
            // setProduct(data);
            setIsLoading(false);
        } catch (e) {
            if (e.response?.data?.status === false) {
                if (e.response?.data.errors_array?.[0]) {
                    toast(e.response?.data.errors_array?.[0], 'error')
                } else {
                    toast(e.response?.data?.message, 'error')

                }
                //  setError(e.response?.data.errors?.title[0])
                setIsLoading(false)
            } else {
                setIsLoading(false)

            }

        }
    }

    const validatePassFormat = (values) => {
        return (
            !/[a-z]/.test(values?.password) ||
            !/[A-Z]/.test(values?.password) ||
            values?.password?.length < 8 ||
            !/\d/.test(values?.password)
            //    ||
            //   !/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(values?.password)
        );
    };

    async function handleChangePassword(values, resetForm) {
        setIsLoadingSecurity(true)

        let formData = {
            user_id,
            current_password: values?.current_password,
            new_password: values?.password,
        }
        try {
            const { data } = await server.post(`profile/change_password`,
                formData,
            );

            if (data?.status) {

                toast(data?.message, 'success')
                router(`/`)

            }
            console.log(data)
            // setProduct(data);
            setIsLoadingSecurity(false);
        } catch (e) {
            if (e.response?.data?.status === false) {
                console.log(e.response?.data)
                toast(e.response?.data.errors_array?.[0], 'error')

                //  setError(e.response?.data.errors?.title[0])
                setIsLoadingSecurity(false)
            } else {
                setIsLoadingSecurity(false)
                toast(e.message, 'error')


            }
            //console.log(e.response);

        }
    }

    async function handleChangeWorkSpace(values, resetForm) {
        setIsLoadingWorkspace(true)

        let formData = {
            page_id: selectedPageOption,
            is_default: true

        }
        try {
            const { data } = await server.post(`page/set_default`,
                formData,
            );

            if (data?.status) {

                toast(data?.message, 'success')

            }
            console.log(data)
            // setProduct(data);
            setIsLoadingWorkspace(false)
        } catch (e) {
            setIsLoadingWorkspace(false)

            if (e.response?.data?.status === false) {
                console.log(e.response?.data)
                toast(e.response?.data.errors_array?.[0], 'error')

                //  setError(e.response?.data.errors?.title[0])

            } else {
                toast(e.message, 'error')


            }
            //console.log(e.response);

        }
    }

    const onChangePageSelection = (e) => {
        setSelectedPageOption(e.target.value);
    };
    const enableGdpr = async (domain) => {
        setGdprStatus(!gdprStatus)
        setIsLoading(true)
        let formData = {
            domain: account_url,
            enable_gdp: !gdprStatus ? 1 : 0,
            is_custom: 0
        }


        try {
            const { data } = await server.post(`domain/setGdpStatus`,
                formData,
            );
            console.log(data);
            setIsLoading(false)
            if (data?.status) {
                toast(data?.message, 'success')
                // setUpdateFloatHostingDomain(!updateFloatHostingDomain)
                // setupdatUi(!updatUi);

            }

        } catch (e) {
            setIsLoading(false)

            if (e.response?.data?.status === false) {
                if (e.response?.data.errors_array?.[0]) {
                    toast(e.response?.data.errors_array?.[0], 'error')
                    // setUpdateFloatHostingDomain(!updateFloatHostingDomain)
                    //  setActiveTab(2)
                } else {
                    toast(e.response?.data?.message, 'error')

                }
                //  setError(e.response?.data.errors?.title[0])
            } else {

            }
        }


    }



    return (

        <div className={'tabsLayout'}>
            <div className={`tabsNav`}>
                <div className="container">

                    <h4 className={'main-heading mt-5'}> My Settings </h4>

                    <Nav>
                        <NavItem>
                            <NavLink className={activeTab == '1' ? 'active' : ''}
                                onClick={() => setActiveTab('1')}>
                                Profile
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={activeTab == '2' ? 'active' : ''}
                                onClick={() => setActiveTab('2')}>
                                Security
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={activeTab == '4' ? 'active' : ''}
                                onClick={() => setActiveTab('4')}>
                                Workspaces
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={activeTab == '3' ? 'active' : ''}
                                onClick={() => setActiveTab('3')}>
                                Float Hosting
                            </NavLink>

                        </NavItem>

                        { (accounts?.subscription_type==='paid' || accounts?.subscription_type==='trial') &&
   accounts?.is_pause_plan=== true &&
    accounts?.account_status === 'active'  
    && accounts?.subscription_status ==='pause' &&   accounts?.is_active_pp == true ? (
                             
                            <></>
    )
     :                   
                      ( <> <NavItem>
                            
                            <NavLink className={activeTab == '6' ? 'active' : ''}
                                     onClick={() => setActiveTab('6')}>
                                Downloads
                            </NavLink>
                        </NavItem>



                        </>


    )



}
<NavItem>
                             <NavLink className={activeTab == '5' ? 'active' : ''}
                                onClick={() => setActiveTab('5')}>
                                Billing
                            </NavLink>
                        </NavItem>
           
                    </Nav>
                </div>
            </div>
            <div className="container pb-5 mb-5">
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <div className={'row'}>
                            <div className={'col-md-12'}>
                                <div className={'ProfileInfo'}>
                                    <div className={'username'}>
                                        {profileData?.firstname?.substring(0, 1)}
                                    </div>
                                    <div className={'text-details'}>
                                        <h5>{profileData?.firstname} {profileData?.lastname}</h5>
                                        <h6>{profileData?.email}</h6>
                                    </div>
                                </div>
                                <hr />

                                {isLoading &&
                                    (


                                        <div className={'loaderPage'}>
                                            <Loader />
                                        </div>

                                    )}


                                {profileData && (
                                    <Formik
                                        initialValues={{
                                            first_name: profileData?.firstname ? profileData?.firstname : '',
                                            // last_name: profileData?.last_name ? profileData?.last_name : '',
                                            email: profileData?.email ? profileData?.email : '',


                                            billing_first_name: profileData?.billing_first_name ? profileData?.billing_first_name : '',
                                            billing_last_name: profileData?.billing_last_name ? profileData?.billing_last_name : '',

                                            billing_city: profileData?.billing_city ? profileData?.billing_city : '',
                                            billing_country: profileData?.billing_country ? profileData?.billing_country : '',
                                            billing_bio: profileData?.billing_bio ? profileData?.billing_bio : '',
                                            billing_address: profileData?.billing_address ? profileData?.billing_address : '',
                                            billing_address2: profileData?.billing_address2 ? profileData?.billing_address2 : ''
                                        }}
                                        validationSchema={validationSchema}
                                        onSubmit={(values, { resetForm }) => {
                                            handleProfileUpdate(values, resetForm);
                                        }}
                                    >
                                        {(formikProps) => (
                                            <>
                                                <div className={'mt-4'}>
                                                    <InternalTitle title={'Account info'} />
                                                    <Row className={'mt-3'}>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label for="fname">
                                                                    First name
                                                                </Label>


                                                                <Input

                                                                    placeholder="Enter your First Name"
                                                                    type="text"
                                                                    name="first_name"
                                                                    id="first_name"
                                                                    value={formikProps.values.first_name}
                                                                    onChange={formikProps.handleChange("first_name")}
                                                                    onBlur={formikProps.handleBlur("first_name")}
                                                                    error={
                                                                        formikProps.errors.first_name && formikProps.touched.first_name
                                                                            ? true
                                                                            : false
                                                                    }
                                                                //   isDisabled
                                                                />
                                                                <div>
                                                                    {formikProps.errors.first_name && formikProps.touched.first_name && (
                                                                        //    <Alert status='error' color={'#DD2922'} pl={0} fontWeight={'400'} fontSize={'14px'} lineheight={'20px'}>

                                                                        <p className={'errorMessage'}>
                                                                            {formikProps.errors.first_name}
                                                                        </p>
                                                                    )}
                                                                </div>


                                                            </FormGroup>
                                                        </Col>
                                                        {/* <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="lname">
                                                                        Last name
                                                                    </Label>
                                                                    <Input

                                                                        placeholder="Enter your Last Name"
                                                                        type="text"
                                                                        name="last_name"
                                                                        id="last_name"
                                                                        value={formikProps.values.last_name}
                                                                        onChange={formikProps.handleChange("last_name")}
                                                                        onBlur={formikProps.handleBlur("last_name")}
                                                                        error={
                                                                            formikProps.errors.last_name && formikProps.touched.last_name
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        //   isDisabled
                                                                    />
                                                                    <div>
                                                                        {formikProps.errors.last_name && formikProps.touched.last_name && (
                                                                            //    <Alert status='error' color={'#DD2922'} pl={0} fontWeight={'400'} fontSize={'14px'} lineheight={'20px'}>

                                                                            <p className={'errorMessage'}>
                                                                                {formikProps.errors.last_name}
                                                                            </p>
                                                                        )}
                                                                    </div>
                                                                </FormGroup>
                                                            </Col> */}
                                                    </Row>
                                                    <FormGroup className={'mb-4'}>
                                                        <Label for="email">
                                                            Email
                                                        </Label>
                                                        <Input
                                                            disabled
                                                            placeholder="Enter your Email"
                                                            type="email"
                                                            name="email"
                                                            id="email"
                                                            value={formikProps.values.email}
                                                            //   onChange={formikProps.handleChange("email")}
                                                            onBlur={formikProps.handleBlur("email")}
                                                            error={
                                                                formikProps.errors.email && formikProps.touched.email
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        <div>
                                                            {formikProps.errors.email && formikProps.touched.email && (
                                                                //    <Alert status='error' color={'#DD2922'} pl={0} fontWeight={'400'} fontSize={'14px'} lineheight={'20px'}>

                                                                <p className={'errorMessage'}>
                                                                    {formikProps.errors.email}
                                                                </p>
                                                            )}
                                                        </div>
                                                    </FormGroup>

                                                    <InternalTitle title={'Billing Info'} />

                                                    <Row className={'mt-3'}>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label for="fname">
                                                                    First name
                                                                </Label>


                                                                <Input

                                                                    placeholder="Enter your First Name"
                                                                    type="text"
                                                                    name="billing_first_name"
                                                                    id="billing_first_name"
                                                                    value={formikProps.values.billing_first_name}
                                                                    onChange={formikProps.handleChange("billing_first_name")}
                                                                    onBlur={formikProps.handleBlur("billing_first_name")}
                                                                    error={
                                                                        formikProps.errors.billing_first_name && formikProps.touched.billing_first_name
                                                                            ? true
                                                                            : false
                                                                    }
                                                                //   isDisabled
                                                                />
                                                                <div>
                                                                    {formikProps.errors.billing_first_name && formikProps.touched.billing_first_name && (
                                                                        //    <Alert status='error' color={'#DD2922'} pl={0} fontWeight={'400'} fontSize={'14px'} lineheight={'20px'}>

                                                                        <p className={'errorMessage'}>
                                                                            {formikProps.errors.billing_first_name}
                                                                        </p>
                                                                    )}
                                                                </div>


                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label for="lname">
                                                                    Last name
                                                                </Label>
                                                                <Input

                                                                    placeholder="Enter your Last Name"
                                                                    type="text"
                                                                    name="billing_last_name"
                                                                    id="billing_last_name"
                                                                    value={formikProps.values.billing_last_name}
                                                                    onChange={formikProps.handleChange("billing_last_name")}
                                                                    onBlur={formikProps.handleBlur("billing_last_name")}
                                                                    error={
                                                                        formikProps.errors.billing_last_name && formikProps.touched.billing_last_name
                                                                            ? true
                                                                            : false
                                                                    }
                                                                //   isDisabled
                                                                />
                                                                <div>
                                                                    {formikProps.errors.billing_last_name && formikProps.touched.billing_last_name && (
                                                                        //    <Alert status='error' color={'#DD2922'} pl={0} fontWeight={'400'} fontSize={'14px'} lineheight={'20px'}>

                                                                        <p className={'errorMessage'}>
                                                                            {formikProps.errors.billing_last_name}
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>

                                                    <Row className={'mt-3'}>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label for="billing_city">
                                                                    City
                                                                </Label>
                                                                <Input

                                                                    placeholder="Enter your City"
                                                                    type="text"
                                                                    name="billing_city"
                                                                    id="billing_city"
                                                                    value={formikProps.values.billing_city}
                                                                    onChange={formikProps.handleChange("billing_city")}
                                                                    onBlur={formikProps.handleBlur("billing_city")}
                                                                    error={
                                                                        formikProps.errors.billing_city && formikProps.touched.billing_city
                                                                            ? true
                                                                            : false
                                                                    }
                                                                //   isDisabled
                                                                />
                                                                <div>
                                                                    {formikProps.errors.billing_city && formikProps.touched.billing_city && (
                                                                        //    <Alert status='error' color={'#DD2922'} pl={0} fontWeight={'400'} fontSize={'14px'} lineheight={'20px'}>

                                                                        <p className={'errorMessage'}>
                                                                            {formikProps.errors.billing_city}
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label for="billing_country">
                                                                    Country
                                                                </Label>
                                                                <Input

                                                                    placeholder="Enter your Country"
                                                                    type="text"
                                                                    name="billing_country"
                                                                    id="billing_country"
                                                                    value={formikProps.values.billing_country}
                                                                    onChange={formikProps.handleChange("billing_country")}
                                                                    onBlur={formikProps.handleBlur("billing_country")}
                                                                    error={
                                                                        formikProps.errors.billing_country && formikProps.touched.billing_country
                                                                            ? true
                                                                            : false
                                                                    }
                                                                //   isDisabled
                                                                />
                                                                <div>
                                                                    {formikProps.errors.billing_country && formikProps.touched.billing_country && (
                                                                        //    <Alert status='error' color={'#DD2922'} pl={0} fontWeight={'400'} fontSize={'14px'} lineheight={'20px'}>

                                                                        <p className={'errorMessage'}>
                                                                            {formikProps.errors.billing_country}
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    {/*<FormGroup>*/}
                                                    {/*    <Label for="billing_bio">*/}
                                                    {/*        billing_bio*/}
                                                    {/*    </Label>*/}
                                                    {/*    <Input*/}

                                                    {/*        placeholder="Please Enter your billing_bio"*/}
                                                    {/*        type="textarea"*/}
                                                    {/*        name="billing_bio"*/}
                                                    {/*        id="billing_bio"*/}
                                                    {/*        value={formikProps.values.billing_bio}*/}
                                                    {/*        onChange={formikProps.handleChange("billing_bio")}*/}
                                                    {/*        onBlur={formikProps.handleBlur("billing_bio")}*/}
                                                    {/*        error={*/}
                                                    {/*            formikProps.errors.billing_bio && formikProps.touched.billing_bio*/}
                                                    {/*                ? true*/}
                                                    {/*                : false*/}
                                                    {/*        }*/}
                                                    {/*        //   isDisabled*/}
                                                    {/*    />*/}
                                                    {/*    <div>*/}
                                                    {/*        {formikProps.errors.bio && formikProps.touched.bio && (*/}
                                                    {/*            //    <Alert status='error' color={'#DD2922'} pl={0} fontWeight={'400'} fontSize={'14px'} lineheight={'20px'}>*/}

                                                    {/*            <p className={'errorMessage'}>*/}
                                                    {/*                {formikProps.errors.bio}*/}
                                                    {/*            </p>*/}
                                                    {/*        )}*/}
                                                    {/*    </div>*/}
                                                    {/*    /!*<small> Max 140 Characters</small>*!/*/}
                                                    {/*</FormGroup>*/}
                                                    <Row className={'mt-3'}>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label for="billing_address">
                                                                    Address 1
                                                                </Label>
                                                                <Input

                                                                    placeholder="Address"
                                                                    type="textarea"
                                                                    name="billing_address"
                                                                    id="billing_address"
                                                                    value={formikProps.values.billing_address}
                                                                    onChange={formikProps.handleChange("billing_address")}
                                                                    onBlur={formikProps.handleBlur("billing_address")}
                                                                    error={
                                                                        formikProps.errors.billing_address && formikProps.touched.billing_address
                                                                            ? true
                                                                            : false
                                                                    }
                                                                //   isDisabled
                                                                />
                                                                <div>
                                                                    {formikProps.errors.billing_address && formikProps.touched.billing_address && (
                                                                        //    <Alert status='error' color={'#DD2922'} pl={0} fontWeight={'400'} fontSize={'14px'} lineheight={'20px'}>

                                                                        <p className={'errorMessage'}>
                                                                            {formikProps.errors.billing_address}
                                                                        </p>
                                                                    )}
                                                                </div>
                                                                {/*<small> Max 140 Characters</small>*/}
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label for="billing_address2">
                                                                    Address 2
                                                                </Label>
                                                                <Input

                                                                    placeholder="Enter your Address 2"
                                                                    type="textarea"
                                                                    name="billing_address2"
                                                                    id="billing_address2"
                                                                    value={formikProps.values.billing_address2}
                                                                    onChange={formikProps.handleChange("billing_address2")}
                                                                    onBlur={formikProps.handleBlur("billing_address2")}
                                                                    error={
                                                                        formikProps.errors.billing_address2 && formikProps.touched.v
                                                                            ? true
                                                                            : false
                                                                    }
                                                                //   isDisabled
                                                                />
                                                                <div>
                                                                    {formikProps.errors.billing_address2 && formikProps.touched.billing_address2 && (
                                                                        //    <Alert status='error' color={'#DD2922'} pl={0} fontWeight={'400'} fontSize={'14px'} lineheight={'20px'}>

                                                                        <p className={'errorMessage'}>
                                                                            {formikProps.errors.billing_address2}
                                                                        </p>
                                                                    )}
                                                                </div>
                                                                {/*<small> Max 140 Characters</small>*/}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>


                                                    <Button className={'primary-btn-modal shadow-none '}
                                                        onClick={formikProps.handleSubmit}
                                                    >
                                                        Save
                                                    </Button>
                                                    {/*<Button className={'secondary-btn-modal mx-2 shadow-none'}>*/}
                                                    {/*    Cancel*/}
                                                    {/*</Button>*/}

                                                </div>
                                            </>
                                        )}
                                    </Formik>
                                )}


                            </div>
                        </div>

                    </TabPane>
                    <TabPane tabId="2">
                        <div className={'row pass-tab'}>

                            <Formik
                                initialValues={{
                                    current_password: '',
                                    password: '',
                                    confirm_password: ''
                                }}
                                validationSchema={validationSecuritySchema}
                                onSubmit={(values, { resetForm }) => {
                                    handleChangePassword(values, resetForm);
                                }}
                            >
                                {(formikProps) => (
                                    <>
                                        <div className={'col-md-12 mt-5'}>
                                            <InternalTitle title={'Update Password'} />
                                            <Row className={'mt-3'}>
                                                <Col md={4}>
                                                    <FormGroup>
                                                        <Label for="currentPassword">
                                                            Current Password
                                                        </Label>
                                                        <div className="flexContainer">


                                                            <Input
                                                                className={'mb-0'}
                                                                type={currentPasswordIcon ? "password" : "text"}

                                                                name="current_password"
                                                                id="current_password"
                                                                value={formikProps.values.current_password}
                                                                onChange={formikProps.handleChange("current_password")}
                                                                onBlur={formikProps.handleBlur("current_password")}
                                                                error={
                                                                    formikProps.errors.current_password && formikProps.touched.current_password
                                                                        ? true
                                                                        : false
                                                                }
                                                            //   isDisabled
                                                            />
                                                            <button onClick={() => {
                                                                setCurrentPasswordIcon(!currentPasswordIcon)
                                                            }}>
                                                                {!currentPasswordIcon && (
                                                                    <AiOutlineEye />
                                                                )}

                                                                {currentPasswordIcon && (
                                                                    <AiOutlineEyeInvisible />
                                                                )}
                                                            </button>


                                                        </div>
                                                        {formikProps.errors.current_password && formikProps.touched.current_password && (
                                                            //    <Alert status='error' color={'#DD2922'} pl={0} fontWeight={'400'} fontSize={'14px'} lineheight={'20px'}>

                                                            <p className={'errorMessage'}>
                                                                {formikProps.errors.current_password}
                                                            </p>
                                                        )}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row className={'mt-1'}>
                                                <Col md={4}>
                                                    <FormGroup>
                                                        <Label for="Password">
                                                            New Password
                                                        </Label>
                                                        <div className="flexContainer">


                                                            <Input
                                                                className={'mb-0'}
                                                                type={passwordIcon ? "password" : "text"}
                                                                name="password"

                                                                id="password"
                                                                value={formikProps.values.password}
                                                                onChange={formikProps.handleChange("password")}
                                                                onBlur={formikProps.handleBlur("password")}
                                                                error={
                                                                    formikProps.errors.password && formikProps.touched.password
                                                                        ? true
                                                                        : false
                                                                }
                                                            //   isDisabled
                                                            />
                                                            <button onClick={() => {
                                                                setPasswordIcon(!passwordIcon)
                                                            }}>
                                                                {!passwordIcon && (
                                                                    <AiOutlineEye />
                                                                )}
                                                                {passwordIcon && (
                                                                    <AiOutlineEyeInvisible />
                                                                )}
                                                            </button>


                                                        </div>

                                                        <List type="unstyled">
                                                            <li>
                                                                <stong className={'internalTitle d-block'}>Your password
                                                                    must contain:
                                                                </stong>
                                                            </li>
                                                            {/* {formikProps.errors.password && formikProps.touched.password && ( */}


                                                            <li>

                                                                {formikProps?.values?.password?.length >= 8 ?
                                                                    <BsCheck2 className={'text-success'} /> :
                                                                    <BsCheck2 className={'text-danger'} />}
                                                                Minimum 8 characters


                                                            </li>

                                                            <li>
                                                                {/[A-Z]/.test(formikProps?.values?.password) ?
                                                                    <BsCheck2 className={'text-success'} /> :
                                                                    <BsCheck2 className={'text-danger'} />}

                                                                At least 1 upper case
                                                            </li>
                                                            <li>
                                                                {/[a-z]/.test(formikProps?.values?.password) ?
                                                                    <BsCheck2 className={'text-success'} /> :
                                                                    <BsCheck2 className={'text-danger'} />}

                                                                At least 1 lower case
                                                            </li>
                                                            <li>
                                                                {/\d/.test(formikProps?.values?.password) ?
                                                                    <BsCheck2 className={'text-success'} /> :
                                                                    <BsCheck2 className={'text-danger'} />}

                                                                At least 1 number
                                                            </li>
                                                            <li>

                                                                {/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(formikProps?.values?.password) ?
                                                                    <BsCheck2 className={'text-success'} /> :
                                                                    <BsCheck2 className={'text-danger'} />}

                                                                At least 1 special character
                                                            </li>
                                                        </List>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row className={'mt-1'}>
                                                <Col md={4}>
                                                    <FormGroup>
                                                        <Label for="confirmPassword">
                                                            Confirm Password
                                                        </Label>
                                                        <div className="flexContainer">


                                                            <Input
                                                                className={'mb-0'}
                                                                type={cPasswordIcon ? "password" : "text"}

                                                                name="confirm_password"
                                                                id="confirm_password"
                                                                value={formikProps.values.confirm_password}
                                                                onChange={formikProps.handleChange("confirm_password")}
                                                                onBlur={formikProps.handleBlur("confirm_password")}
                                                                error={
                                                                    formikProps.errors.confirm_password && formikProps.touched.confirm_password
                                                                        ? true
                                                                        : false
                                                                }
                                                            //   isDisabled
                                                            />
                                                            <button onClick={() => {
                                                                setCpasswordIcon(!cPasswordIcon)
                                                            }}>
                                                                {!cPasswordIcon && (
                                                                    <AiOutlineEye />
                                                                )}

                                                                {cPasswordIcon && (
                                                                    <AiOutlineEyeInvisible />
                                                                )}
                                                            </button>


                                                        </div>
                                                        {formikProps.errors.confirm_password && formikProps.touched.confirm_password && (
                                                            //    <Alert status='error' color={'#DD2922'} pl={0} fontWeight={'400'} fontSize={'14px'} lineheight={'20px'}>

                                                            <p className={'errorMessage'}>
                                                                {formikProps.errors.confirm_password}
                                                            </p>
                                                        )}
                                                    </FormGroup>


                                                    <Button className={'primary-btn-modal shadow-none mt-2'}
                                                        disabled={
                                                            !formikProps?.values.password ||
                                                            !formikProps?.values.current_password ||
                                                            !formikProps?.values.confirm_password ||

                                                            // !values.confirm_pass ||
                                                            validatePassFormat(formikProps.values)
                                                        }
                                                        onClick={formikProps.handleSubmit}>
                                                        {isLoadingSecurity ?
                                                            <span className={'spinnerContainer'}>

                                                                <Spinner>
                                                                    Loading...
                                                                </Spinner></span> : 'Save'}

                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </>
                                )}
                            </Formik>
                        </div>

                    </TabPane>
                    <TabPane tabId="3">

                        <div className={'row'}>
                            <div className={'col-md-12 mt-5'}>
                                <InternalTitle title={'Connect to Float Hosting'} />
                                <div className={'fh-wrapper text-center pt-5'}>
                                    <BiLinkExternal size={80} className={'mt-4 d-block m-auto'} />
                                    <a href={floatHostingUrl} target={'_blank'}
                                        className="primary-btn-modal shadow-none my-5 btn btn-secondary">Float Hosting
                                    </a>
                                    <p className={'mt-2'}><strong> Note: </strong> You can use your Float Funnels credentials to log into your Float Hosting <br/> account and start using its free services.
                                    </p>
                                    {/*<p>*/}
                                    {/*    If you were already a FH user, You can use the same FH credendials that you had set while creating FH Account.*/}
                                    {/*</p>*/}

                                </div>
                            </div>
                        </div>


                    </TabPane>
                    <TabPane tabId="4">

                        <div className={'row'}>
                            <div className={'col-md-12 mt-5'}>
                                <InternalTitle title={'Workspaces'} />
                                <Table className={'workspacemodal'}
                                    responsive
                                >
                                   
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span>    {public_url} </span>
                                                <button className={'border-0 background-none ms-3'}
                                                    onClick={() => window.open(`${public_url}`, "_blank")}>
                                                    <FiLink />
                                                </button>
                                            </td>
                                            <td>
                                                <Form className={'pt-0'}>
                                                    <FormGroup switch
                                                        className={'d-flex justify-content-center flex-column'}>
                                                        <Label check> GDPR</Label>


                                                        < Input
                                                            className={'ms-0'}
                                                            type="switch"
                                                            checked={gdprStatus}
                                                            onClick={() => enableGdpr()}
                                                        />

                                                    </FormGroup>
                                                </Form>
                                            </td>

                                            <td>
                                                <div className={'mb-0'}>
                                                    <Input
                                                        className=' shadow-none'

                                                        name="select"
                                                        type="select"
                                                        value={selectedPageOption}
                                                        onChange={onChangePageSelection}
                                                    >
                                                        {/*{*/}
                                                        {/*    (()=>{console.log(funnelList)})()*/}
                                                        {/*}*/}
                                                        <option value={""}>
                                                            Select Page
                                                        </option>
                                                        {pagesList?.map((x, i) => {
                                                            return (

                                                                <option value={x?.id}>
                                                                    {x?.title}
                                                                </option>
                                                            );
                                                        })}


                                                    </Input>

                                                </div>
                                            </td>
                                            <td>


                                                <Button className={'primary-btn-modal shadow-none save-button'}
                                                    onClick={handleChangeWorkSpace}
                                                    disabled={!selectedPageOption}
                                                > {isLoadingWorkspace ? <span className={'spinnerContainer'}>   <Spinner>
                                                    Loading...
                                                </Spinner></span> : 'Save'}
                                                </Button>
                                            </td>
                                        </tr>

                                    </tbody>
                                </Table>
                            </div>
                        </div>


                    </TabPane>

                    <TabPane tabId="6">

                        <div className={'row'}>
                            <div className={'col-md-12 mt-5'}>
                                <InternalTitle title={'Downloads'}/>
                                <Table className={'workspacemodal'}
                                       responsive
                                >
                                    <tbody>
                                    <tr>
                                        <td>
                                            Commission Theme
                                        </td>
                                        <td>



                                                <a href={'https://dl.dropboxusercontent.com/s/me3ie6ejja2n0ie/Commission%20Theme.zip?dl=0'} target={'_blank'}>
                                                    <Button className={'primary-btn-modal shadow-none save-button'}>
                                                    <FaDownload />
                                                    </Button>
                                                </a>


                                        </td>
                                    </tr>

                                    </tbody>
                                </Table>

                            </div>
                        </div>
                    </TabPane>


                    <TabPane tabId="5">
                        {isLoading &&(
                            <Skeleton style={{marginTop:40}} count={15} /> // Five-line loading skeleton

                        )}
                        
                        
                        {/*{isLoading && (user_type == 'bpa')  ?(*/}
                        {/*    <>*/}
                        {/*        <div className="d-flex justify-content-center flex-column mt-5 pt-5 text-center">*/}
                        {/*            <GiGlassCelebration className="d-block m-auto mb-4 text-primary" size={90} />*/}
                        {/*            <InternalTitle title={ 'You are a BPA Member.'} />*/}

                        {/*            <p className="my-3"> It's a lifetime free for you.</p>*/}
                        {/*        </div>*/}

                        {/*</>*/}
                        {/*    )*/}
                        {/*:*/}
                        {/*    (*/}
                        {/*        <>*/}
                        {/*            <PlanSub subscriptionPlan={subscriptionPlan} updatePlan={cancelPlanUpdate} isLoadingPlan={isLoading}/>*/}
                        {/*            <PaymentMethod billingInfo={billingInfo} />*/}
                        {/*            <Invoice />*/}
                        {/*        </>*/}
                        {/*    )*/}






                        {/*}*/}



                        {!(accounts?.user_type === 'bpa') ? (
                            <>
                                <PlanSub subscriptionPlan={subscriptionPlan} updatePlan={cancelPlanUpdate} isLoadingPlan={isLoading} />
                                <PaymentMethod billingInfo={billingInfo} />
                                 <Invoice />
                            </>
                        ) : (
                            <div className="d-flex justify-content-center flex-column mt-5 pt-5 text-center">
                                <GiGlassCelebration className="d-block m-auto mb-4 text-primary" size={90} />
                                <InternalTitle title={'You are a BPA Member.'} />
                                <p className="my-3">It's a lifetime free for you.</p>
                            </div>
                        )}



                    </TabPane>

                </TabContent>
            </div>

        </div>

    )
}

export default ProfileSettings;