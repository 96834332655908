import React, { useState, useEffect } from "react";
import {
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import SeoSettings from "./seo";
import Scripts from "./scripts";
import Favicon from "./setting/favicon";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import BackgroundImageUpload from "./setting/backgroundImage";
import server from "../../../../apis/server";
import { toast } from "react-toastify";

const Settingsidebar = ({
  editor,
  pageId,
  initialData,
  updateGrapejs,
  setUpdateGrapeJs,
  setSettingSidebarOpen,
}) => {
  const [activeTab, setActiveTab] = useState("1");
  const [deleteModal, setDeleteModal] = useState(false);
  const [settingsData, setSettingsData] = useState("");
  const [loading, setLoading] = useState(true);
  const getPage = `${process.env.REACT_APP_API_URL}/page/edit/${pageId}`;

  useEffect(() => {
    if (pageId) {
      getPageDetail();
    }
  }, [pageId]);

  const getPageDetail = async () => {
    try {
      setLoading(true);
      const { data } = await server.get(getPage);
      setLoading(false);

      if (data?.status) {
        console.warn(data?.data);
        setSettingsData(data?.data);
      }
    } catch (e) {
      setLoading(false);
      if (e.response?.data?.status === false) {
        const errorMessage =
          e.response?.data.errors_array?.[0] || e.response?.data?.message;
        toast(errorMessage, "error");
      }
    }
  };
  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };
  // const handleDeleteCanvas = () => {
  //     toggleDeleteModal();
  // };
  //
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleImportCode = () => {
    editor.runCommand("gjs-open-import-webpage");
    closeSetting();

    // Add your logic here
  };

  const handleFullScreen = () => {
    // editor.runCommand('set-background-image', {
    //     imageUrl: 'https://fastly.picsum.photos/id/170/200/300.jpg?hmac=8MNcDgapCZN2mHu0jnLxPWkxuwz9rq4TMQfQsYTINCk'
    //   });
    editor.runCommand("core:fullscreen");
    closeSetting();

    // Add your logic here
  };

  const handleExportCode = () => {
    editor.runCommand("export-template");
    closeSetting();
  };

  const closeSetting = () => {
    setSettingSidebarOpen(false);
    buttonClickHandler();
  };

  function buttonClickHandler() {
    // Check for .gjs-editor in the main document
    const editorElementMain = document.querySelector(".gjs-editor");

    if (editorElementMain) {
      console.warn(
        "Found .gjs-editor in the main document:",
        editorElementMain
      );
      editorElementMain.classList.add("short-sidebar");

      setTimeout(() => window.dispatchEvent(new Event("resize")), 400);
    } else {
      console.warn(".gjs-editor not found in the main document.");

      // Check for .gjs-editor in the editor's iframe document
      const editorElementIframe =
        editor.Canvas.getDocument().querySelector(".gjs-editor");

      if (editorElementIframe) {
        console.warn("Found .gjs-editor in the iframe:", editorElementIframe);
        editorElementIframe.classList.add("short-sidebar");
        setTimeout(() => window.dispatchEvent(new Event("resize")), 400);
      } else {
        console.warn(".gjs-editor not found in the iframe.");
      }
    }
  }

  const triggerViewComponentsButton = () => {
    const viewComponentsButton = document.querySelector(
      ".gjs-pn-btn.fa.fa-square-o"
    );
    if (viewComponentsButton) {
      viewComponentsButton.click();
    }
  };

  const handleViewComponents = () => {
    triggerViewComponentsButton();
    // closeSetting();
  };

  return (
    <div>
      <div className={"seoBar"}>
        <div className={"heading_button"}>
          <h3 className={"panel-title"}>Advanced </h3>
          <div className={"c2a"}>
            <button
              onClick={closeSetting}
              className={"closeButton fa fa-close"}
            ></button>
          </div>
        </div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab === "1" ? "active" : ""}
              onClick={() => {
                toggle("1");
              }}
            >
              Seo
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "2" ? "active" : ""}
              onClick={() => {
                toggle("2");
              }}
            >
              Scripts
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "3" ? "active" : ""}
              onClick={() => {
                toggle("3");
              }}
            >
              Styles
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "4" ? "active" : ""}
              onClick={() => {
                toggle("4");
              }}
            >
              Others
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <SeoSettings
              pageId={pageId}
              initialData={initialData}
              updateGrapejs={updateGrapejs}
              setUpdateGrapeJs={setUpdateGrapeJs}
            />
          </TabPane>
          <TabPane tabId="2">
            <Scripts pageId={pageId} initialData={initialData} />
          </TabPane>
          <TabPane tabId="3">
            <Favicon
              pageId={pageId}
              faviconUrl={settingsData?.favicon}
              setUpdateGrapeJs={setUpdateGrapeJs}
            />
            <BackgroundImageUpload
              editor={editor}
              pageId={pageId}
              backgroundImageUrl={settingsData?.background_image}
              setUpdateGrapeJs={setUpdateGrapeJs}
            />
          </TabPane>
          <TabPane tabId="4">
            <div className={"styleDetailsGeneral"}>
              <button onClick={handleImportCode} className={"btn btn-primary"}>
                {" "}
                <i className="fa fa-download"></i> <span> Import HTML </span>{" "}
              </button>
              <button onClick={handleExportCode} className={"btn btn-primary"}>
                {" "}
                <i className="fa fa-upload"></i> <span> Export HTML </span>{" "}
              </button>
              <button onClick={handleFullScreen} className={"btn btn-primary"}>
                {" "}
                <i className="fa fa-arrows-alt">
                  {" "}
                  <span> Full Screen </span>{" "}
                </i>
              </button>
              {/*<button onClick={handleDeleteCanvas} className={'btn btn-primary'}> <i className="fa fa-trash"> </i>  <span> Delete Canvas  </span></button>*/}
              <button
                onClick={handleViewComponents}
                className={"btn btn-primary"}
              >
                <i className="fa fa-eye"></i> <span> View Components </span>
              </button>
            </div>
          </TabPane>
        </TabContent>
      </div>
      <Modal isOpen={deleteModal} toggle={toggleDeleteModal} centered>
        <ModalHeader toggle={toggleDeleteModal}>Confirm Deletion</ModalHeader>
        <ModalBody>Are you sure you want to clear the canvas?</ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => {
              editor.runCommand("core:canvas-clear");
              toggleDeleteModal();
              closeSetting();
            }}
          >
            Clear
          </Button>{" "}
          <Button color="secondary" onClick={toggleDeleteModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Settingsidebar;
