import { createStore, persist } from "easy-peasy";
import accountState from "./accountState";
import funnel from "./funnel";
import loading from "./LoadingState";


const modal = persist(
  {
    accountState: accountState,
    funnelState : funnel,
    LoadingState : loading

  },
  {
    whitelist: ["profile"],
    storage: "localStorage",
  }
);

const middleware = [];

const config = {
  middleware,
};

const store = createStore(modal, config);

export default store;