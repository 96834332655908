import React from 'react'
import {BsArrowDown, BsArrowUp} from "react-icons/bs";

function stats({data}) {


    return (
        <div className="row">
            <div className="col-md-3 col-12">
                <h5> Users</h5>
                <h2> {data?.total_users} </h2>
                {data?.change_in_users_prc >0 && (
                <h4 className="text-success"><BsArrowUp/> {data?.change_in_users_prc} % </h4>
                )
}
{data?.change_in_users_prc <0 && (
                <h4 className="text-danger"><BsArrowDown/> {data?.change_in_users_prc} % </h4>
                )
}               
            </div>
            <div className="col-md-3 col-12">
                <h5> Session</h5>
                <h2> {data?.total_sessions} </h2>
                

                {data?.change_in_sessions_prc >0 && (
                <h4 className="text-success"><BsArrowUp/> {data?.change_in_sessions_prc} % </h4>
                )
}
{data?.change_in_sessions_prc <0 && (
                <h4 className="text-danger"><BsArrowDown/> {data?.change_in_sessions_prc} % </h4>
                )
}               
                {/*<h4 className="text-danger"><BsArrowDown/> 9.5%</h4>*/}
            </div>
            <div className="col-md-3 col-12">
                <h5> Conversion Rate</h5>
                <h2> {data?.conversion_rate} %</h2>
                {/* <h4 className="text-success"><BsArrowUp/> 1.8% </h4> */}
            </div>
        </div>
    )
}
export default stats;