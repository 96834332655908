import React,{useState,useEffect} from "react";
import Logo from "../../../images/logoinverse.png"
import {Form, FormGroup, Label, Input, Button, List, Alert, Row, Col,Spinner} from "reactstrap";
import {BsCheck2, BsX} from "react-icons/bs";
import { AiOutlineEyeInvisible,AiOutlineEye } from "react-icons/ai";
import { IoIosRefresh } from "react-icons/io";
import { RiErrorWarningLine } from "react-icons/ri";
import IconCheckMark from "../../../images/icon-check-success.png";
import { Formik } from "formik"; 
import * as yup from "yup";
import server from "../../../apis/server"
import Loader from "../../../components/common/loader/loader";
import toast from '../../../utils/toast' 
import { useNavigate,Link } from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy"


function LogIn() {

    const [isLoading, setIsLoading] = useState(false);
    const [passwordIcon, setPasswordIcon] = useState(true);
    const [cPasswordIcon, setCpasswordIcon] = useState(true);
    const AccountAction = useStoreActions((actions) => actions?.accountState?.setAccount)


    const router = useNavigate()

    let validationSchema = yup.object({ 
        
        email: yup.string().email('Invalid email').required('Email is required.'),


       });

       async function handleLogin(values,resetForm)
       {
           setIsLoading(true)
   
           let formData={
               email: values?.email,
               password: values?.password,
           }
             try {
               const { data } = await server.post(`user/login`,
                   formData,
               );
   
               if(data?.status){
                   
                toast(data?.message, 'success')


                AccountAction(data?.data)
              
                await localStorage.setItem('accounts',JSON.stringify(data?.data))

               

            if(data?.data?.is_subscribed==false){
         router(`/billing`)

            }
         
           else if(data?.data?.is_subscribed && data?.data?.account_id == null){
                router(`/accounts`)

            }

           else if(data?.data?.is_subscribed && data?.data?.account_id){
            router(`/`)

        }

                   // isLoading(true)
               }
              console.log(data)
               // setProduct(data);
               setIsLoading(false);
             } catch (e) {
                   
                if(e.response?.data?.status===false){
                    if(e.response?.data.errors_array?.[0]){
                    toast(e.response?.data.errors_array?.[0], 'error')
                  }
                  else{
                      toast(e.response?.data?.message, 'error')
                      
                  }
                     //  setError(e.response?.data.errors?.title[0])
                      setIsLoading(false)
                  }
                  else{
                      setIsLoading(false)
 
                  }
                //console.log(e.response);
   
           }
       }

    return (

        <>
            <div className={'login-flow'}>
            <Formik
            initialValues={{ 
                
                email:'',
                password:'',
             }}
             validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              handleLogin(values, resetForm);
            }} 
            > 
			   {(formikProps) => ( 
        <>
                <img src={Logo} alt={"LOGO"} />
                <h1> Welcome back!</h1>
                <p> Please enter the details below to sign in.</p>
                {/* <Form> */}
                <FormGroup >
                                        <Label for="email">
                                            Email
                                        </Label>
                                        <Input
                                    //   disabled
                                 placeholder="Enter your Email"
                                 type="email" 
                                //  autoFocus={'true'}
                                        name="email"
                                        id ="email"
                                        value={formikProps.values.email}
                   onChange={formikProps.handleChange("email")}
                  onBlur={formikProps.handleBlur("email")}
                  error={
                    formikProps.errors.email && formikProps.touched.email
                      ? true
                      : false
                  }
                                       />
                                        <div>
          {formikProps.errors.email && formikProps.touched.email && (
            //    <Alert status='error' color={'#DD2922'} pl={0} fontWeight={'400'} fontSize={'14px'} lineheight={'20px'}>

            <p className={'errorMessage'}>
               {formikProps.errors.email}
            </p>
         )}
            </div>
                                    </FormGroup>

                                   

                    <FormGroup>
                                                <Label for="password">
                                                    Password
                                                </Label>
                                                <div className="flexContainer">
       

                                                <Input
                            className={'mb-0'}
                            type= {passwordIcon ? "password":"text"}

                            name="password"
                                        id ="password"
                                        value={formikProps.values.password}
                                        

                  onChange={formikProps.handleChange("password")}
                  onBlur={formikProps.handleBlur("password")}
                  error={
                    formikProps.errors.password && formikProps.touched.password
                      ? true
                      : false
                  }
                  
                  
                  onKeyPress={event => {
                    if (formikProps.values.password && event.key === 'Enter') {
                        formikProps.handleSubmit()
                       }
                  }}
                //   isDisabled
                                       />
 <button onClick={()=>{
                                                                        setPasswordIcon(!passwordIcon)
                                                                    }}> 
                                                                    {! passwordIcon && ( 
                                                                    <AiOutlineEye /> 
                                                                    )}
                                                                    { passwordIcon && ( 
                                                                    <AiOutlineEyeInvisible /> 
                                                                    )}
                                                                    </button>

                                       </div>
                                        <div>
          {formikProps.errors.password && formikProps.touched.password && (
            //    <Alert status='error' color={'#DD2922'} pl={0} fontWeight={'400'} fontSize={'14px'} lineheight={'20px'}>

            <p className={'errorMessage'}>
               {formikProps.errors.password}
            </p>
         )}
            </div>
                                            </FormGroup>
                    <Row>
                        {/* <Col md={6}>
                                <FormGroup check>
                                    <Input type="checkbox" id={'rememberMe'}/>
                                    {' '}
                                    <Label check for={'rememberMe'}>
                                        Remember me
                                    </Label>
                                </FormGroup>
                        </Col> */}
                        <Col md={12} className={'d-flex justify-content-end'}>
                            <Link to="/forgot" className={'text-primary'}> Forgot password?</Link>
                        </Col>
                    </Row>

                    <Button  block className={'mt-4'}
                             color="primary"

                             disabled={
                                !formikProps?.values.email ||
                                !formikProps?.values.password
                           
                                // !values.confirm_pass ||
                                }
                             onClick={formikProps.handleSubmit}

                             >
                         {isLoading ?
                         <span className={'spinnerContainer'}>
                         <Spinner>
                                    Loading...
                                </Spinner></span> : 'Login'}
                    </Button>
                {/* </Form> */}
                <h5>Don’t have an account?</h5>
                {/* <a href="#" className={'actionIn text-primary'}> Register now</a> */}
                <Link to="/signup"  className={'actionIn text-primary'}> Register now</Link>


                </>
               )}
               </Formik>
               </div>

            <div className={'forgot-flow d-none'}>
                <img src={Logo} alt={"LOGO"} className={'mb-5 pb-5'} />
                <h1 className={'mt-5 pt-5'}>Forgot Password</h1>
                <Form>
                    <FormGroup>
                        <Label for="Email">
                            Email
                        </Label>
                        <Input
                            id="Email"
                            name="email"
                            placeholder="Enter your email"
                            type="email"
                        />
                    </FormGroup>
                    <Button  block className={'mt-4'}
                             color="primary">
                        Recover Password
                    </Button>
                </Form>
               <h6 className={'text-center'}> Go back to  <a href="#" className={'text-primary bold '}> Sign in</a></h6>
            </div>

            <div className={'reset-flow d-none'}>
                <img src={Logo} alt={"LOGO"} className={'mb-5'} />
                <h1 className={'mt-5'}>Forgot Password</h1>
                <Form>
                    <FormGroup>
                        <Label for="Password">
                            New Password
                        </Label>
                        <div className="flexContainer">
                            <Input
                                id="Password"
                                name="password"
                                // placeholder="Please Enter your placeholder"
                                type="password"
                            />
                            <button> <AiOutlineEyeInvisible /> </button>
                        </div>

                        <List type="unstyled">
                            <li>
                                <stong>Your password must contain:</stong>
                            </li>
                            <li>
                                <BsCheck2 className={'text-success'}/> Minimum 8 characters
                            </li>
                            <li>
                                <BsCheck2 className={'text-success'}/> At least 1 upper case
                            </li>
                            <li>
                                <BsX className={'text-danger'}/> At least 1 lower case
                            </li>
                            <li>
                                <BsCheck2 className={'text-success'}/> At least 1 number
                            </li>
                            <li>
                                <BsX className={'text-danger'}/>   At least 1 special character
                            </li>
                        </List>
                    </FormGroup>
                    <FormGroup>
                        <Label for="confirmPassword">
                            Confirm Password
                        </Label>
                        <div className="flexContainer">
                            <Input
                                id="confirmPassword"
                                name="confirmPassword"
                                // placeholder="Confirm password"
                                type="password"
                            />
                            <button> <AiOutlineEyeInvisible /> </button>
                        </div>
                    </FormGroup>
                    <Button  block className={''}
                             color="primary">
                        Reset Password
                    </Button>
                </Form>
                <h6 className={'text-center mt-4'}> Go back to  <a href="#" className={'text-primary bold '}> Sign in</a></h6>
            </div>

            <div className={'changed-succesfull-flow d-none'}>
                <img src={Logo} alt={"LOGO"} className={'mb-5 '}/>
                <img src={IconCheckMark} alt={'Check Payment'} className={'text-center mt-5 pt-5 d-block m-auto'}/>
                <h1 className={'text-center'}> Your password has <br/> been changed.</h1>
                <p className={'text-center'}>  You can now use your new password to log in <br/> to your account.</p>
                <Button  className={'mt-5 text-center d-block m-auto px-4'}
                         color="primary">
                    Sign In
                </Button>
            </div>

        </>

    )
}

export default LogIn;