import React,{useState,useEffect}  from "react";
import server from "../../../apis/server"
import Loader from "../../../components/common/loader/loader";
import toast from '../../../utils/toast' 
import { useNavigate ,useParams} from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy"


export default function Detail() {
    const [isLoading, setIsLoading] = useState(false);
    const [packages, setpackages] = useState(null);
    const router = useNavigate()
    let { active } = useParams();

    const accountsString = localStorage.getItem('accounts')
    const accounts = accountsString ? JSON.parse(accountsString) : ''
    console.log('accounts', accounts)
    const { user_id} = accounts
    const AccountAction = useStoreActions((actions) => actions?.accountState?.setAccount)


    useEffect(() => {
        if(active ==1){
         getSubscriptionPlan()
        }
        else{
            router('/suspended')
        }
     }, [])
 
      
        const getSubscriptionPlan = async () => {
 
         try {
             setIsLoading(true)
             let formData = {
                 user_id
             }
 
             const { data } = await server.post(`billing/info`, formData);
             if (data?.status) {
 
                 const {
 
                     card_last_4,
                     card_expiry,
                     card_last_updated,
                     update_cc_url,
                     subscription_type,
                     account_status,
                     subscription_status,
                     subscription_package_name,
                     subscription_start_date,
                     subscription_end_date,
                     subscription_next_billing_date,
                     subscription_plan_price,
                     subscription_plan_price_paid,
                     subscription_coupon,
                     subscription_currency,
                     subscription_remaining_days,
                     subscription_package_type,
                     pp_url,
                     is_pause_plan,
                     pause_plan_schedule_date,
                     is_grace_period,
                     is_cancelled,
                     remaining_days_date,
                     acc_close_date,
                     is_active_pp,
                     change_plan_schedule_date,
                     change_plan_name,
                     package_type
 
                 } = data?.data
    
                     accounts['card_last_4'] = card_last_4;
                     accounts['card_expiry'] = card_expiry;
                     accounts['card_last_updated'] = card_last_updated;
                     accounts['update_cc_url'] = update_cc_url;
                     accounts['subscription_type'] = subscription_type;
                     accounts['account_status'] = account_status;
                     accounts['subscription_status'] = subscription_status;
                     accounts['subscription_package_name'] = subscription_package_name;
                     accounts['subscription_start_date'] = subscription_start_date;
                     accounts['subscription_end_date'] = subscription_end_date;
                     accounts['subscription_next_billing_date'] = subscription_next_billing_date;
                     accounts['subscription_plan_price'] = subscription_plan_price;
                     accounts['subscription_plan_price_paid'] = subscription_plan_price_paid;
                     accounts['subscription_coupon'] = subscription_coupon;
                     accounts['subscription_currency'] = subscription_currency;
                     accounts['subscription_remaining_days'] = subscription_remaining_days;
                     accounts['subscription_package_type'] = subscription_package_type;
                     accounts['pp_url'] = pp_url;
                     accounts['is_pause_plan'] = is_pause_plan;
                     accounts['pause_plan_schedule_date'] = pause_plan_schedule_date;
                     accounts['is_grace_period'] = is_grace_period;
                     accounts['is_cancelled'] = is_cancelled;
                     accounts['remaining_days_date'] = remaining_days_date;
                     accounts['acc_close_date'] = acc_close_date;
                     accounts['is_active_pp'] = is_active_pp;   
                     accounts['change_plan_schedule_date'] = change_plan_schedule_date;
                     accounts['change_plan_name'] = change_plan_name; 
                     accounts['package_type'] = package_type; 
                       
                   
                 // }
             // })
             console.warn('local', accounts)
 
             await localStorage.setItem('accounts',JSON.stringify(accounts))
                     
 
                       AccountAction(accounts)
 
                 // setSubscriptionPlan(data?.data)
                 setIsLoading(false);
                 if ((subscription_type==='paid' || subscription_type==='trial') &&
                 
                  accounts?.account_status === 'active'  
                  && accounts?.subscription_status ==='active' ){
                 router(`/`)
                  }
                  else{
                    router(`/suspended`)

                  }

                   


 
             // location.reload()
 
             }
             console.log(data)
             // setProduct(data);
             // setLoading(false);
 
         } catch (e) {
             alert(e)
             if (e.response?.data?.status === false) {
                 if (e.response?.data.errors_array?.[0]) {
                     toast(e.response?.data.errors_array?.[0], 'error')
                 }
                 else {
                     toast(e.response?.data?.message, 'error')
 
                 }
                 //  setError(e.response?.data.errors?.title[0])
                 setIsLoading(false)
             }
             else {
                 setIsLoading(false)
 
             }
 
 
         }
     }
 
     
 

    

    return (
        <div>
            <div className="accounts-wrapper">
            {/* <ToastContainer/> */}

            {isLoading &&
       (


            <div className={'loaderPage'}>
                <Loader/>
            </div>
            
       )}



            </div>

        </div>
    )
}