import Header from "../../components/header/header";
import React from 'react';
import Sidebar from "../../components/sidebar/sidebar";
import List from "../../components/pages/list/list";
import { Outlet, useLocation } from "react-router-dom";


export default function Pages() {

    const { search } = useLocation();
     const headerTitle =  search.substring(search.indexOf('=') + 1);


    return (
        <div>

            <main className={'mainContainer'}>
                <Sidebar/>
                <section className={'internalContainer'}>
                    <Header page={headerTitle?headerTitle : `Pages`}/>
                    <div className={'allPages'}>
                        <div className="container">
                            <div className="row">

                                <div className="col-12 mb-3 py-5">
                                    <List/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        
        
       </div>
    )
}
