import React,{useState,useEffect}  from "react";
import server from "../../../apis/server"
import Loader from "../../../components/common/loader/loader";
import toast from '../../../utils/toast' 
import { useNavigate ,useParams} from "react-router-dom";


export default function VerifyPayment() {
    const [isLoading, setIsLoading] = useState(true);
    const [packages, setpackages] = useState(null);
    const router = useNavigate()
    let { hash } = useParams();

    const accountsString =   localStorage.getItem('accounts')
    const user =    accountsString ? JSON.parse(accountsString) : ''
    const {user_id,is_subscribed,account_id}  =   user



    useEffect(() => {
       if(hash){
        VerifyPayment()
       }
    }, [])


    const VerifyPayment = async () => {

        try {
            
        

            const {data} = await server.get(`account/verify/access/${hash}`);
            if (data?.status) {
                setIsLoading(false)

                // setpackages(data?.data)
                toast(data?.message, 'success')

                if(!user){
                    router(`/login`)
                    return
                }
                Object.keys(user)?.forEach(function(key) {
                    if(user['is_subscribed'] === false) {
       
                        user['is_subscribed'] = true;
                        
                      
                    }
                })
                console.log('newUser',user)
       
                  await localStorage.setItem('accounts',JSON.stringify(user))
                  router(`/accounts`)
    

                            //    router(`/login`)

                
            }
            else{
                toast(data?.message, 'error')
                router(`/login`)


            }
            console.log(data)
            // setProduct(data);
            // setIsLoading(false);

        } catch (e) {
            if (e.response?.data?.status === false) {
                console.log(e.response?.data.errors?.title[0])
                toast(e.response?.data?.message, 'error')
                router(`/signup`)

                // setError(e.response?.data.errors?.title[0])
                setIsLoading(false);
            } else {
                router(`/login`)

                // setError(e.response?.data.errors?.title[0])
                setIsLoading(false);
                // setError(e.message)
            }


        }
    }


    return (
        <div>
            <div className="accounts-wrapper">
            {/* <ToastContainer/> */}

            {isLoading &&
       (


            <div className={'loaderPage'}>
                <Loader/>
            </div>
            
       )}



            </div>

        </div>
    )
}