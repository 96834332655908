import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from 'reactstrap';
import Loader from "../../../../components/common/loader/loader";
import server from '../../../../apis/server'

const PageModal = ({ isOpen, toggle, createPage, title: defaultTitle = '', pageId }) => {
    const [title, setTitle] = useState(defaultTitle);
    const [error, setError] = useState(null);
    const [disable, setDisable] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const characterLimit = (event) => {
        event.preventDefault();
        setTitle(event.target.value);
    
        const charCount = event.target.value.length;
        if (charCount >= 3 && charCount < 255) {
            setDisable(false);
        } else {
            setDisable(true);
        }
    };
    

    const handleCreatePage = async () => {
        setDisable(true);
        setIsLoading(true);
        setError(null);


        let formData={
            page_id:pageId,
           page_title: title
         }

          try {
            const { data } = await server.post(`page/update_title`,
                formData,
            );
            // setShowEdit(false)

            if(data?.status){
                setIsLoading(true)
                window.location.reload()
                // isLoading(true)
                // router(`/pages/editor/${data?.data?.page_id}`)
            }
           console.log(data)
            // setProduct(data);
            // setLoading(false);
          } catch (e) {

                 if(e.response?.data?.status===false){
                   setError(e.response?.data.errors?.page_title[0],'error')
                    //  setDisable(false)
                    //  setIsLoading(false)
                 }
                 else{
                    setError(e.message)
                    //  setDisable(false)
                    //  setIsLoading(false)

                 }
            //console.log(e.response);

        }
          finally {
            setDisable(false);
            setIsLoading(false);
        }
    };

    return (
        <Modal autoFocus={false} isOpen={isOpen} toggle={toggle} className={'creatPageModal'} centered backdrop={true}>
            <ModalHeader toggle={toggle}>Edit page name</ModalHeader>
            <ModalBody>
                <p>Page name</p>
                <Input
                    autoFocus
                    className='' type={'text'} placeholder={'Enter page name '}
                    value={title}
                    onChange={characterLimit}
                    onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                            handleCreatePage();
                        }
                    }}
                />
                {error && (<p className={'errorMessage'}> {error} </p>)}
            </ModalBody>
            <ModalFooter>
                <Button className={'btn primary-btn-modal shadow-none'} onClick={handleCreatePage} disabled={disable}>
                    {isLoading ? <span className={''}> Rename  <Loader /> </span> : 'Renaming'}
                </Button>
                <Button className={'secondary-btn-modal shadow-none'} onClick={toggle}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};

export default PageModal;
