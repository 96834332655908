    import React from 'react';


const Manual = () => {


    return (
        <div className={`row justify-content-center my-4 pt-2`}>
            <div className={`col-md-9 col-12`}>
                <div className={`manualAddons`}>
                    <h4> DNS Settings </h4>
                    <p> For further information on manually changing your DNS settings at your domain provider, please use the following links.</p>
                    <div className={`row`}>
                        <div className={`col-md-3 col-sm-12`}>
                            <ul>
                                <li><a target="_blank" href="https://www.ionos.com/help/domains/glossary-important-terms-and-topics-explained/dns-settings/" >1&amp;1
                                    IONOS </a></li>
                                <li><a target="_blank" href="https://www.123-reg.co.uk/support/domains/how-do-i-set-up-a-cname-record-on-my-domain-name/" >123
                                    Reg </a>    </li>
                                <li><a target="_blank" href="https://help.blacknight.com/hc/en-us/articles/212512209-DNS-records-in-cp-blacknight-com" >Blacknight
                                    Solutions </a></li>
                                <li><a target="_blank" href="https://www.bluehost.com/help/article/dns-management-add-edit-or-delete-dns-entries" >Bluehost </a></li>
                            </ul>
                         </div>
                        <div className={`col-md-3 col-sm-12`}>
                            <ul>
                                <li>
                                    <a target="_blank" href="https://www.domain.com/help/article/dns-management-how-to-update-dns-records" >Domain.com </a>
                                </li>
                                <li><a target="_blank" href="https://help.enom.com/hc/en-us/articles/115000474012-How-to-Change-DNS-Host-Records" >Enom </a></li>
                                <li><a target="_blank" href="https://ca.godaddy.com/help/manage-dns-records-680" >GoDaddy </a></li>
                                <li><a target="_blank" href="https://support.google.com/domains/answer/3290309?hl=en&amp;ref_topic=9018335" >Google
                                    Domains </a></li>
                            </ul>
                        </div>
                        <div className={`col-md-3 col-sm-12`}>
                            <ul>
                                <li><a target="_blank" href="https://www.hostgator.com/help/article/changing-dns-records" >HostGator </a></li>
                                <li><a target="_blank" href="https://www.namecheap.com/support/knowledgebase/article.aspx/9837/46/how-to-connect-a-domain-to-a-server-or-hosting/" >Namecheap </a></li>
                                <li><a target="_blank" href="https://customerservice.networksolutions.com/prweb/PRAuth/app/WebKM_/JfLhd8LVz0a16-h3GqsHOCqqFky5N_vd*/!autoThread0?pzuiactionzzz=CXtycX1jZTJkNDU3ODdkMmVlNjhiOTMyMTM3YzVlNmRjNTM4ZDI5YzY1NWUwY2M4ZTBjZWI1MmMxNzgzODUzMTVkMGVjMGI1NjJkYjA5MzkyNjAwZGMwYWZmZmNmZTFjMmRhMjg3ODIwMTc3YjQ0ZDI3OTY1MzZmZTc2MmNhYjAxMjhjZjAwY2E0NjFmMmM2NzYyMjlkZWI4NGE4NGIxZDczMjY4NWRkYzEzNWJhNDExN2VjYTcxYTJmMjYxMDhkZDQwODM%3D*" >Network
                                    Solutions </a></li>
                                <li><a target="_blank" href="https://docs.ovh.com/ca/en/domains/web_hosting_general_information_about_dns_servers/" >OVHcloud </a></li>
                            </ul>
                        </div>
                        <div className={`col-md-3 col-sm-12`}>
                            <ul>
                                <li>
                                    <a target="_blank" href="https://customerservice.register.com/prweb/PRAuth/app/WebKM_/JfLhd8LVz0a16-h3GqsHOCqqFky5N_vd*/!autoThread0?pzuiactionzzz=CXtycX03NTdkNzNlNzhiMzIzNmEwYWQ1MWFhMDZjNmIwMDc2ZjE3ZmI1ZTYxZTc3NDdlMDFhMDBkNWUxMzVkZWE3MTQ2MjQwZTY4MDVjODlhMDczMGJkMWMwMjg5NTQ1OTVjMTkyNjY3MDYxMmIyZTlmZjVlOTkyNGNkMzZhYjdmMGYwMTBhOWMxYmM1OGZlODlmMTI3MWQzZDNhMzI4ZGI3ZDI2YjExOWExODFkZGE5ZGY2NzFkNTgxY2MwNmY0NjY3NmM%3D*" >Register </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://support.wix.com/en/article/connecting-a-wix-domain-to-an-external-site" >Wix </a>
                                </li>
                             </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Manual;