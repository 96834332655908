import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "grapesjs/dist/grapes.min.js";
import "grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.js";
import server from "../../../apis/server.js";
import { toast } from "react-toastify";
import Loader from "../../../components/common/loader/loader.js";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import isLoading from "../../../store/LoadingState.js";

import EditPageModal from "./editpage/editModal.jsx";
import useHtmlComparison from "./hooks/useHtmlComparison.js";
import useEditorEffects from "./hooks/useEditorEffects.js";
import usePageDetails from "./hooks/usePageDetails.js";
import useGrapesJsButtonTooltips from "./hooks/useCustomTooltips.js";
import CustomAlignment from "./customstyles/alignment.js";
import CustomBackgroundImage from "./customstyles/backgroundImageDefault.js";
import { Initialize } from "./config/config.js";
import { updateBlocks } from "./blocks/updateblocks.js";
import { newBlocks } from "./blocks/newblocks.js";
import { addRemoteStorage } from "./storage/config.js";
import { navbarPanel } from "./panels/navbarpanel.js";
import { sidebarPannel } from "./panels/sidebarpanel.js";
import { seoPannel } from "./panels/seopanel.js";
import { grapeJsEvents } from "./events/grapeJsEvents.js";
import { utils } from "./utils/index.js";
import Settingsidebar from "./panels/settingsidebar.js";
function Editor({ direction, ...args }) {
  const navigate = useNavigate();
  const { state } = useLocation();
  let { editorpage } = useParams();

  // Retrieve accounts data from localStorage
  const accountsString = localStorage.getItem("accounts");
  console.log(accountsString);
  const accounts = accountsString ? JSON.parse(accountsString) : "";
  console.log("accounts", accounts);
  const { public_url } = accounts;

  // Set page ID in localStorage if available
  if (editorpage) {
    localStorage.setItem("pageId", editorpage);
  }

  // API endpoints
  const updatePage = `${process.env.REACT_APP_API_URL}/page/update`;
  const getPage = `${process.env.REACT_APP_API_URL}/page/edit/${editorpage}`;
  const uploadImage = `${process.env.REACT_APP_API_URL}/upload/assets/${editorpage}`;

  // State management
  const [draft, setDraft] = useState();
  const [edit, setEdit] = useState(false);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [draftLoading, setDraftLoading] = useState(false);
  const [publishOpen, setPublishOpen] = useState(false);
  const [settingSidebarOpen, setSettingSidebarOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [html, setHtml] = useState(null);
  const [seoPgeTitle, setseoPgeTitle] = useState("");
  const [seoPgeKeyword, setseoPgeKeyword] = useState("");
  const [seoPgeDescription, setseoPgeDescription] = useState("");
  const [pageDetail, setPageDetail] = useState("");
  const [domainId, setDomainId] = useState();
  const [updateUi, setUpdateUi] = useState(false);
  const [updateGrapeJs, setUpdateGrapeJs] = useState(false);
  const [editor, setEditor] = useState(null);
  const [draftData, setDraftData] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [googleCoversionId, setGoogleCoversionId] = useState("");
  const [isPublished, setIsPublished] = useState(
    localStorage.getItem("isPublished") === "true"
  );
  const [publishedUrl, setPublishedUrl] = useState(
    localStorage.getItem("publishedUrl")
  );
  let data;

  // Toggle functions
  const toggle = () => setPublishOpen((prevState) => !prevState);
  const toogleEditModal = () => setIsEditModalOpen(!isEditModalOpen);

  function storeHtml(editor) {
    const htmlObj = {
      html: editor.getHtml(),
      css: editor.getCss(),
    };

    setHtml(htmlObj);
  }
  //    Custom Hooks
  useHtmlComparison({
    html,
    editor,
    draftData,
    setIsDisabled,
    updateUi,
    draftLoading,
  });
  useEditorEffects({
    draftLoading,
    editor,
    isDisabled,
    pageDetail,
    setPublishOpen,
    isEditModalOpen,
  });
  usePageDetails({ getPage, setDraftData, setGoogleCoversionId, setLoading });

  useEffect(() => {
    // Conditionally import SCSS file based on your condition
    const importScss = async () => {
      if (true) { // Replace with your actual condition
        const scssModule = await import("../editor_v1/grapejs_v1.scss");
        return scssModule.default;
      } else {
        // const scssModule = await import("../path/to/grapejs2.scss");
        // return scssModule.default;
      }
    };
  
    let linkTag;
  
    importScss().then((scssHref) => {
      // Create a new <link> tag
      linkTag = document.createElement("link");
      linkTag.rel = "stylesheet";
      linkTag.href = scssHref;
      document.head.appendChild(linkTag);
    });
  
    // Cleanup function to unload CSS when the component unmounts
    return () => {
      if (linkTag) {
        document.head.removeChild(linkTag);
      }
    };
  }, []);
  
  useEffect(() => {
    if (editorpage && draftLoading) {
      return;
    }

    if (editorpage && public_url) {
      setLoading(true);

      function init() {
        const editor = Initialize(state, setLoading, editorpage);

        CustomAlignment(editor);
        CustomBackgroundImage(editor);
        editor.on("load", () => {
          updateBlocks(editor);
          storeHtml(editor);
        });
        newBlocks(editor);

        addRemoteStorage({
          editor,
          server,
          setLoading,
          setStatus,
          setPageDetail, 
          setseoPgeTitle,
          setseoPgeKeyword,
          setseoPgeDescription,
          setDraftLoading,
          setHtml,
          updatePage,
          getPage,
          editorpage,
        });
        navbarPanel({
          editor,
          navigate,
          setPublishOpen,
          draftLoading,
          setHtml,
          toogleEditModal,
        });
        sidebarPannel({ editor, settingSidebarOpen, setSettingSidebarOpen });
        seoPannel({
          editor,
          pageDetail,
          editorpage,
          server,
          toast,
          setUpdateGrapeJs,
          updateGrapeJs,
        });
        grapeJsEvents(editor);

        return editor;
      }

      let editor = init();
      setEditor(editor);
      utils(editor);
    }

    setDraft("");
  }, [editorpage, updateUi, updateGrapeJs, isLoading]);

  useEffect(() => {
    if (isPublished) {
      showToast(); // Show toast if isPublished is true and the toast hasn't been shown yet
    }
  }, [isPublished]);

  useGrapesJsButtonTooltips();
  useEffect(() => {
    const updateConversionId = () => {
      // Get the input element
      var inputElement = document.querySelector(
        ".custom-seo-input.google_conversion_id"
      );

      // Get the span element
      var spanElement = document.querySelector(".gjs-sm-icon");

      // Check if the elements are found
      if (inputElement && spanElement) {
        // Get the updated value of the input element
        var conversionId = inputElement.value;

        // Check if there is a value in the input
        if (conversionId.trim() !== "") {
          // Change the text content of the span element to the conversion ID
          spanElement.textContent = conversionId;
        } else {
          console.warn("wroks");
          // If the input is empty, set it back to the default text
          spanElement.textContent = "Google Conversion ";
        }
      }
    };

    // Initial update
    updateConversionId();

    // Add an event listener to the input for changes
    document.addEventListener("input", updateConversionId);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("input", updateConversionId);
    };
  }, []); // Empty dependency array means this effect runs once, similar to componentDidMount

  const Publish = async () => {
    let vr = html?.html?.length > 56;
    if (!vr) {
      toast(
        "Please add text to Publish Landing Page, Page cannot be Empty",
        "error"
      );
      //   alert('please fill')
    } else {
      setLoading(true);
      // const html = {
      //     html: editor.getHtml(),
      //     css: editor.getCss()
      // }
      const obj = {
        page_id: editorpage,
        html_body: JSON.stringify(html),
        domain_id: domainId,
        status: 1,
      };
      try {
        const { data } = await server.post(`${updatePage}`, obj);
        setPublishOpen(!publishOpen);
        setLoading(false);
        if (data?.status) {
          setDomainId();
          console.log("data publishh", data.data);

          setUpdateUi(!updateUi);
          let Url = data.data?.published_url;
          //   setFrontUrl(Url)
          localStorage.setItem("publishedUrl", Url);

          // window.location.href = `${fronUrl}?is_published=true`;

          // window.open(`${fronUrl}?is_published=true`, "_blank")
          window.location.reload();

          // localStorage.setItem('isPublished', 'true');
          localStorage.setItem("isPublished", "true");

          // setLoading(false)
          // isLoading(true)
          // router.push(`/pages/editor/${data?.data?.page_id}`)
        }
        console.log(data);
        // setProduct(data);
        // setLoading(false);
      } catch (e) {
        setLoading(false);
        if (e.response?.data?.status === false) {
          if (e.response?.data.errors_array?.[0]) {
            toast(e.response?.data.errors_array?.[0], "error");
          } else {
            toast(e.response?.data?.message, "error");
          }
          //  setError(e.response?.data.errors?.title[0])
        } else {
          setPublishOpen(!publishOpen);

          toast("Publishing failed. Please try again.", "error");
        }
      }
    }
  };
  const showToast = () => {
    toast.success(
      <div>
        <span>
          Publishing Successful.{" "}
          <a
            style={{ color: "blue", textDecoration: "underline" }}
            href={`${publishedUrl}?is_published=true`}
            target="_blank"
          >
            View Page
          </a>
        </span>
      </div>,
      {
        position: toast.POSITION.TOP_RIGHT,
        closeButton: true, // Show close button
        autoClose: false, // Set auto-close timeout to 5 seconds (5000 milliseconds)
      }
    );

    setTimeout(() => {
      // setToastShown(false);
      localStorage.setItem("isPublished", "false");
    }, 5000); // 5 seconds
  };

  const   handleViewClick = () => {
    const publishedUrl = pageDetail?.published_url
    if (publishedUrl) {
      window.open(`${publishedUrl}?is_published=true`, "_blank");
    } else {
      toast.error("Published URL not found");
    }
  };
  
  return (
    <>
      {/* <ToastContainer /> */}
      {/* <LoadingOverlay
          style={{ width: 200, height: 200, backgroundColor: 'papayawhip' }}
  active={loading}

  text='Loading your content...'
  > */}


      {publishOpen && !isDisabled && (
        <div className={"publishDropDown"}>
          <div className="d-flex p-5">
            <div className={`publish_dropdown`}>
              <Dropdown
                isOpen={publishOpen}
                toggle={toggle}
                direction={direction}
              >
                {/* <DropdownToggle caret>  */}
                {/* <IoRocketSharp size={22}/>  <span className="px-2 fw-semibold">Publish</span>   */}
                {/* </DropdownToggle> */}

                <DropdownMenu {...args} dark>
                  <DropdownItem header>SELECT YOUR DESTINATION</DropdownItem>
                  <div className={"domain-list-drop "}>
                    {!pageDetail?.is_domain && (
                      <FormGroup
                        check
                        className={"radio-check-custom opacity-dim"}
                      >
                        <Input type="radio" checked />
                        <Label>
                          {pageDetail?.public_url} &nbsp; {pageDetail?.title}
                        </Label>
                      </FormGroup>
                    )}
                    {/* <DropdownItem> */}

                    {pageDetail?.domains?.length > 0 &&
                      pageDetail?.domains?.map((x) => {
                        return (
                          <>
                            <FormGroup check className={"radio-check-custom"}>
                              {pageDetail?.is_domain && (
                                <Input
                                  disabled={pageDetail?.is_domain}
                                  checked={pageDetail?.is_domain}
                                  name="radio1"
                                  type="radio"
                                  value={x?.id}
                                  onChange={(e) => setDomainId(e.target.value)}
                                />
                              )}
                              {!pageDetail?.is_domain && (
                                <Input
                                  disabled={pageDetail?.is_domain}
                                  name="radio1"
                                  type="radio"
                                  value={x?.id}
                                  onChange={(e) => setDomainId(e.target.value)}
                                />
                              )}{" "}
                              <Label check>{x?.domain_url}</Label>
                            </FormGroup>
                          </>
                        );
                      })}
                  </div>

                  {pageDetail?.domains?.length == 0 && (
                    <FormGroup check className={"radio-check-custom"}>
                      {/* <Input type="checkbox"/> */}
                      <Label>Custom Domain</Label>
                      <small>
                        {" "}
                        <a
                          onClick={() => {
                            navigate(`/pages/domain`);

                            // let fronUrl = `${public_url}/${data.data.url}`;
                            // window.open("/pages/domain", "_blank")
                          }}
                        >
                          {" "}
                          Click here
                        </a>{" "}
                        to add custom domain{" "}
                      </small>
                    </FormGroup>
                  )}

                  <div className={"footer-action"}>
                    <div className="d-flex justify-content-between">
                      <a
                        // disabled={isDisabled}

                        onClick={Publish}
                        className={`primary-btn-modal`}
                      >
                        {" "}
                        {draftLoading
                          ? "Loading"
                          : `Publish on selected domain`}{" "}
                      </a>

                      <a
                        className={`btn-grey-outline`}
                        onClick={() => {
                          setPublishOpen(!publishOpen);
                        }}
                      >
                        Cancel
                      </a>
                    </div>
                  </div>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>

          {/* <div id="myDropdown" class="dropdown-content"  >
                                       <a href="#home">Home</a>
                                       <a href="#about">About</a>
                                       <a href="#contact">Contact</a>
                                     </div>`; */}
        </div>
      )}

      {/* Modal */}
      {isEditModalOpen && (
        <EditPageModal
          isOpen={isEditModalOpen}
          toggle={toogleEditModal}
          //  createPage={handleCreatePage}
          title={pageDetail ? pageDetail?.title : ""}
          pageId={editorpage}
        />
      )}
      {loading && (
        <div className={"loaderPageEditor"}>
          <Loader />
        </div>
      )}
      {settingSidebarOpen && (
        <Settingsidebar
          editor={editor}
          setSettingSidebarOpen={setSettingSidebarOpen}
          pageId={editorpage}
          initialData={pageDetail}
          updateGrapeJs={updateGrapeJs}
          setUpdateGrapeJs={setUpdateGrapeJs}
        />
      )}

      {}
{/*      <a*/}
{/*  className={`btn-grey-outline`}*/}
{/*  onClick={handleViewClick}*/}
{/*>*/}
{/*  View*/}
{/*</a>*/}

      {/* </LoadingOverlay> */}
      <div id="gjs"></div>
    </>
  );
}

export default Editor;
