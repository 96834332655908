import Header from "../components/header/header";
import React ,{useState,useEffect} from 'react';
import Sidebar from "../components/sidebar/sidebar";
import Audience from "../components/analytics/audience";
import {Button, DropdownItem, DropdownMenu, DropdownToggle, TabPane, UncontrolledButtonDropdown} from "reactstrap";
import Demograhics from "../components/analytics/demographics";
import StatAnalytics from "../components/analytics/stats";
import Traffic from "../components/analytics/traffic";
import InternalTitle from "../components/common/internalTittle/GenaricButton";
import LabelStats from "../components/analytics/labelStatButton";



import List from "../components/pages/list/list"
import ButtonSet from "../components/analytics/labelStatButton"
import DayandTime from "../components/common/dateAndTime/dayAndTime";
import Greetings from "../components/common/dateAndTime/greetings"
import {useStoreActions} from "easy-peasy";
import {useNavigate} from "react-router-dom";
import server from '../apis/server'

export default function Home() {
    
    const [audienceData, setAudienceData] = useState([]);
    const [trafficData, setTrafficData] = useState([]);


    // /analytics/all/get


    useEffect(() => {

        getTemplateData()
    }, [])


    const getTemplateData = async () => {

        try {


            const {data} = await server.get(`analytics/all/get`);
            if (data?.status) {
                console.log('ccone',data?.data)
                setAudienceData(data?.data?.users_x_axis_val)
                setTrafficData(data?.data)
                // setGeoData(data?.data)
                // setIsLoading(false);

            }
            console.log(data)
            // setProduct(data);
            // setLoading(false);

        } catch (e) {
            if (e.response?.data?.status === false) {
                console.log(e.response?.data.errors?.title[0])
                // setError(e.response?.data.errors?.title[0])
                // setIsLoading(true);
            } else {
                // setError(e.message)
            }


        }
    }
    return (
    <div>
      
      <main className={'mainContainer'}>
          <Sidebar/>
          <section className={'internalContainer'}>
              <Header/>
              <div className={'container mt-5  py-5'}>
                  <div className={'headingTitle'}>
                      <DayandTime />
                      <h1> <Greetings /> </h1>
                  </div>
                  <div className={'row'}>
                      <div className="col-md-6 col-12 ">
                          <InternalTitle title={'AUDIENCE'}/>
                          <div className="widget-analytics">
                              <div className="widget-header">
                                  <StatAnalytics data ={trafficData}/>
                              </div>
                              <div className="widget-graph">
                                  <Audience data={audienceData} xaxis ={trafficData}/>
                              </div>
                          </div>
                      </div>
                      <div className="col-md-6 col-12 ">
                                <InternalTitle title={'TRAFFIC'}/>
                                <div className="widget-analytics">
                                    <div className="widget-header">
                                        <LabelStats/>
                                    </div>
                                    <div className="widget-graph">
                                        <Traffic data={trafficData}/>
                                    </div>
                                </div>
                            </div>
                  </div>
                  <div className={'row'}>
                      <div className="col-md-12 col-12 ">
                          <div className="widget-analytics pb-0 ">
                                <List list="main" />
                          </div>
                      </div>
                  </div>
              </div>
          </section>
      </main>
    </div>
  )
}
