import React,{useState,useEffect} from "react";
import {Label, Input, Button, Spinner, ModalBody} from "reactstrap";
import AccountIllustration  from "../../../images/accountIllustration.png";
import Logoff  from "../../../images/logoinverse.png";
import {CgNotes} from "react-icons/cg";
import ReactDOM from "react-dom";
import auth from "../../../apis/auth";
import { useNavigate } from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy"






function AccountsWorkspace() {

    const accountsString =   localStorage.getItem('accounts')
    const user =    accountsString ? JSON.parse(accountsString) : ''
    const {user_id,is_subscribed,account_id,secure_key}  =   user


   
    const router = useNavigate()
    const NetworkState = useStoreState((state) => state?.accountState?.state?.payload)
    const AccountAction = useStoreActions((actions) => actions?.accountState?.setAccount)

    

    const accountUrl=process.env.REACT_APP_ACCOUNT_NAME_URL
    const [account_name,setAccountName] = useState("")
    const [account_name_url,setAccountNameUrl] = useState("")
    const [error,setError] = useState(false);
    const [disable,setDisable]=useState(true);
    const [selectedOption, setSelectedOption] = useState("");
    const [isLoading, setIsLoading] = useState(false)



    useEffect(() => {

        validateUser()
    }, [user_id])

    const validateUser = async () => {
            if(!user ){
                 router('/login')
            }
           else if(!is_subscribed){
                router('/login')
           }
           else if(account_id){
            // router('/')

           }


    }

    const characterLimit=event=>{
        event.preventDefault();

        let result=event.target.value.replace(/\s+/g, '-').trim().toLowerCase();
        let res = result.replace(/^-+/, '').replace(/-(\s+)?$/, '')
        console.log('res',res )
        

        setAccountName(event.target.value)
        setAccountNameUrl(res)
        const charCount = result.length;

        if(charCount >= 3 &&  charCount < 16 ){
            setDisable(false);
        }else {
            console.log(charCount);
            setDisable(true);
        }
    }

    
    const createWorkspace=async()=>{
        setIsLoading(true)

        setDisable(true)
        setError('')
        let formData={
            account_name,
            user_id,
            secure_key
        }
        try {

          

            const { data } = await auth.post(`register/space`,
               formData,
            );

            if(data?.status){
               console.log('data',data)
             

               AccountAction(data?.data)

               console.log('user',user)

                const {account_id,account_name,account_title,account_url,public_url}  = data?.data
               Object.keys(user)?.forEach(function(key) {
                if(user[key] === null || user[key] === "") {
   
                    user['account_id'] = account_id;
                    user['account_name'] = account_name;
                    user['account_title'] = account_title;
                    user['account_url'] = account_url;
                    user['public_url'] = public_url;
                    
                  
                }
            })
            console.log('newUser',user)
   
              await localStorage.setItem('accounts',JSON.stringify(user))
              router(`/`)

            //   alert('cc')
            }
            console.log(data)
            // setProduct(data);
            setIsLoading(false);
        }catch (e) {
            if(e.response?.data?.status===false){

                   if(e.response?.data.errors){
                setError(e.response?.data.errors_array?.[0])
            setDisable(false)
            setIsLoading(false)
                   }
                   else{
                    setError(e.response?.data?.message)
                    setDisable(false)
                    setIsLoading(false)
                   }

            }
            else{

                alert(e)
                setError(e.message)
                setDisable(false)
                setIsLoading(false)

            }
            //console.log(e.response);


    }
    }

    return (

        <div className={'Connectionaccount'}>
            <div className="row">
                <div className="col-md-6 col-12">
                    <div className={'Imageholder'}>
                    <img src={AccountIllustration} alt="illustartion" />
                    </div>
                </div>
                <div className="col-md-6 col-12">
                    <div className="formSide">
                        <img src={Logoff} alt="Logo" />
                        <h1> Create a Workspace </h1>

                        <div>

                            {/*<Input type={'text'}  />*/}
                            <Input

                                  maxLength={15}
                                autoFocus
                                className='mb-4' type={'text'} placeholder={'' +'Digital Station'}
                                onChange={characterLimit}
                                onKeyPress={event => {
                                    if (event.key === 'Enter') {
                                        createWorkspace()
                                    }
                                  }}
                                />
                            {error && (<p className={'errorMessage'}> {error} </p>)}
                            <small> The workspace name will be used as your default subdomain. <br/> For example,
                                if your workspace name is "Digital Station",
                                your subdomain will be https://digital-station.floatfunnels.com.  </small>
                            <br/>
                            {/*<small className="accountNameWidth"> <strong className="text-dark"> {account_name_url}</strong>.{accountUrl} </small>*/}
                            <br/>
                            <Button className={'primary-btn-modal'}
                            
                           

                            onClick={createWorkspace}
                                    disabled={disable ? true : false}><CgNotes/>
                                    
                                    {isLoading ?   <span className={'spinnerContainer accountContainerSpinner'}> Creating  <Spinner >
                                    Loading...
                                </Spinner></span>:' Create Workspace'}</Button>{' '}
                                       
                    </div>

                    </div>
                </div>
            </div>
        </div>

    )
}

export default AccountsWorkspace;