import Header from "../../../components/header/header";
import React from 'react';
import Sidebar from "../../../components/sidebar/sidebar";
import IntegrationParties from "../../../components/pages/integration/integrationParties";
import IntegratedApp from "../../../components/pages/integration/integratedApp";

export default function IntegrationPage() {


    return (
        <div>
            <main className={'mainContainer'}>
                <Sidebar/>

                <section className={'internalContainer'}>
                    <Header/>
                    <div className={'allPages pb-5'}>
                        <div className="container">
                            <IntegratedApp />
                            <IntegrationParties />

                        </div>
                    </div>

                </section>


            </main>
        </div>
    )
}
