import axios from 'axios';

// we get accounts detail from storage


 
         
// Next we make an 'instance' of it

const server =  axios.create({
// .. where we make our configurations
    baseURL: process.env.REACT_APP_API_URL,
});




// Where you would set stuff like your 'Authorization' header, etc ...


// Also editor/ configure interceptors && all the other cool stuff





    server.interceptors.request.use(function (config) {

    const accountsString =   localStorage.getItem('accounts')
    console.log(accountsString)
    const accounts =    accountsString ? JSON.parse(accountsString) : ''
    const {access_token,account_id,account_name}  =   accounts
   


    config.headers['Authorization'] = `Bearer ${access_token}`;
    config.headers['account-id'] = account_id;
    config.headers['account-name'] = account_name;
    // Do something before request is sent
    
    return config;
  });

  server.interceptors.response.use((response) => {
    return response;
}, (error) => { // Anything except 2XX goes to here

  console.log('err',error.response)
  if(error.response?.data?.auth == false){
             localStorage.clear()
             window.location.reload()
  }
   return Promise.reject(error);
    // const status = error.response?.status || 500;
    // if (status === 401) {
    //      window.location = window.location.protocol + "//" + window.location.host + "/sign-in"
    // } else {
    //     return Promise.reject(error); // Delegate error to calling side
    // }
});


  

export default server;
   

