import React, { useState } from 'react';
import {List, ListInlineItem, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input} from "reactstrap";

import ActiveCampaign from "../../../images/app-active-campaign.png"
import Aweber from "../../../images/app-AWeber.png"
import CampaignMonitor from "../../../images/app-campaign-monitor.png"
import Encharge from "../../../images/app-encharge.png"
import GetResponse from "../../../images/app-get-response.png"
import Hubspot from "../../../images/app-hubspot.png"
import Mailchimp from "../../../images/app-mailchimp.png"
import Moosend from "../../../images/app-moosend.png"
import Sendfox from "../../../images/app-sendfox.png"
import Zoho from "../../../images/app-zoho.png"

function IntegrationParties() {
    const [integrationmodal, setintegrationModal] = useState(false);

    const apiConnecttoggle = () => setintegrationModal(!integrationmodal);

    return (
        <>

            <div className={'row mt-5 instList'}>
                <div className={'col-md-12'}>
                    <h3>    Connect with one of these services directly</h3>
                    <List type="inline">
                        <ListInlineItem className={'active'}  onClick={apiConnecttoggle}>
                            <img
                                 src= {Mailchimp}
                                 alt="Mailchimp"
                            />
                        </ListInlineItem>
                        <ListInlineItem>
                            <img
                                src= {ActiveCampaign}
                                alt="Active Campaign"
                            />
                        </ListInlineItem>
                        <ListInlineItem>
                            <img
                                src= {GetResponse}
                                alt="Get Response"
                            />
                        </ListInlineItem>
                        <ListInlineItem>
                            <img
                                src= {Aweber}
                                alt="Aweber"
                            />
                        </ListInlineItem>
                        <ListInlineItem>
                            <img
                                src= {Hubspot}
                                alt="Hubspot"
                            />
                        </ListInlineItem>
                        <ListInlineItem>
                            <img
                                src= {Zoho}
                                alt="Zoho"
                            />
                        </ListInlineItem>
                        <ListInlineItem>
                            <img
                                src= {CampaignMonitor}
                                alt="Campaign Monitor "
                            />
                        </ListInlineItem>
                        <ListInlineItem>
                            <img
                                src= {Sendfox}
                                alt="Send Fox"
                            />
                        </ListInlineItem>
                        <ListInlineItem>
                            <img
                                src= {Encharge}
                                alt="Encharge"
                            />
                        </ListInlineItem>
                        <ListInlineItem>
                            <img
                                src= {Moosend}
                                alt="Moo send"
                            />
                        </ListInlineItem>
                    </List>
                </div>
            </div>



            <Modal isOpen={integrationmodal} toggle={apiConnecttoggle} className={'modal-lg'} centered backdrop={'false'}>
                <ModalHeader toggle={apiConnecttoggle}>API Integration</ModalHeader>
                <ModalBody>
                    <p className={'mb-3'}>API URL</p>
                    <Input
                        autoFocus
                        className='' type={'text'}
                        placeholder={'e.g. https://tpm798.api-us1.com'}
                        maxlength="15"/>

                    <p className={'mt-3  mb-3'}>API Key</p>
                    <Input
                        autoFocus
                        className='' type={'text'}
                        placeholder={'Get the API Key from your ESP/Autoresponder and enter it here'}
                        maxlength="15"/>
                </ModalBody>
                <ModalFooter>
                    <Button className={'primary-btn-modal shadow-none '} onClick={apiConnecttoggle}>
                        Make Connect
                    </Button>{' '}
                    <Button className={'secondary-btn-modal mx-2 shadow-none'} onClick={apiConnecttoggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>

        </>
    );
}

export default IntegrationParties;