import { N } from "ts-toolbelt";

export function grapeJsEvents(editor) {

  
        editor.on('load', () => {
            editor.runCommand('open-tm');
            setTimeout(() => {
                editor.stopCommand('open-tm');

                editor.runCommand('section');
            }, 0); // You can adjust the timeout as needed
            
            const sectors = document.querySelector('.gjs-sm-sectors');
            if (!sectors) {
                console.error('Sectors container not found!');
                return;
            }
    
            // Create a new sector for Settings
            const traitsSector = document.createElement('div');
            traitsSector.className = 'gjs-sm-sector no-select';
            traitsSector.innerHTML = `
                <div class="gjs-sm-sector-title">
                    <span class="icon-settings fa fa-cog"></span>
                    <span class="gjs-sm-sector-label">Settings</span>
                </div>
                <div class="gjs-sm-properties" style="display: none;"></div>
            `;
            sectors.parentNode.insertBefore(traitsSector, sectors);
    
            const traitsProps = traitsSector.querySelector('.gjs-sm-properties');
            const sectorTitle = traitsSector.querySelector('.gjs-sm-sector-title');
            sectorTitle.addEventListener('click', () => {
                traitsProps.style.display = traitsProps.style.display === 'none' ? 'block' : 'none';
            });
    
            editor.on('component:selected', (component) => {
                // Force opening the Trait Manager to make the elements accessible
                // editor.runCommand('open-tm');
    
                // Wait for the Trait Manager to be fully loaded
                setTimeout(() => {
                    const traitsManager = editor.TraitManager.getTraitsViewer()?.el;
                    if (traitsManager && traitsProps) {
                        traitsProps.innerHTML = ''; // Clear previous traits
                        traitsProps.appendChild(traitsManager); // Move the existing traits manager
                        traitsProps.style.display = 'block'; // Ensure properties are visible
                    }
                }, 100); // Adjust the timeout if necessary
            });
    
            editor.on('component:deselected', () => {
                editor.stopCommand('open-tm');
                const originalContainer = document.querySelector('.original-traits-container');
                if (originalContainer && traitsManager) {
                    originalContainer.appendChild(traitsManager);
                }
            });
    
            editor.on('all', (eventName) => {
                console.log('Event:', eventName);
            });
    
            // Additional event handlers can be added here if needed
        });
    


                            
      
    editor.on('load', () => {
        // Function to check if the 'gjs-four-color' class is removed
        function checkIfClassRemoved(prevClassState, element) {
            const nowHasClass = element.classList.contains('gjs-four-color');
            if(!nowHasClass) {
// Check for .gjs-editor in the main document
const editorElementMain = document.querySelector('.gjs-editor');

if (editorElementMain) {
    console.warn('Found .gjs-editor in the main document:', editorElementMain);
    editorElementMain.classList.add('short-sidebar');
   
    setTimeout(() => window.dispatchEvent(new Event('resize')), 400);

} else {
    console.warn('.gjs-editor not found in the main document.');
    // Check for .gjs-editor in the editor's iframe document
    const editorElementIframe = editor.Canvas.getDocument().querySelector('.gjs-editor');

    if (editorElementIframe) {
        console.warn('Found .gjs-editor in the iframe:', editorElementIframe);
        editorElementIframe.classList.add('short-sidebar');
        setTimeout(() => window.dispatchEvent(new Event('resize')), 400);

    } else {
        console.warn('.gjs-editor not found in the iframe.');
    }
}
            }
            // alert(nowHasClass)
            // Check if the class was present before and now it's removed
            if (prevClassState && !nowHasClass) {
            }
        }
    
        // Attach listeners to panel buttons
        const panelButtons =  document.querySelectorAll('.gjs-pn-btn.fa.fa-bars, .gjs-pn-btn.fa.fa-paint-brush, .gjs-pn-btn.fa.fa-cog, .gjs-pn-btn.fa.fa-folder,.gjs-pn-btn.fa.fa-th-large.block-btn,.gjs-pn-btn.fa.fa-sliders');
        console.warn('pannelButton', document.querySelectorAll('.gjs-pn-btn'))
        panelButtons.forEach(button => {
            button.addEventListener('click', function() {

                // Store previous class presence
                const hadClassBefore = button.classList.contains('gjs-four-color');
                // Delay the check slightly to ensure GrapesJS has updated the class list
                setTimeout(() => checkIfClassRemoved(hadClassBefore, button), 50);
            });
        });
    });
    
    editor.on('run:open-tm run:open-layers run:open-sm', () => {
      
        
        
        const editorElementMain = document.querySelector('.gjs-editor');
        
        if (editorElementMain) {
            console.warn('Found .gjs-editor in the main document:', editorElementMain);
            editorElementMain.classList.remove('short-sidebar');
            setTimeout(() => window.dispatchEvent(new Event('resize')), 400);
    
        } else {
            console.warn('.gjs-editor not found in the main document.');
    
            // Check for .gjs-editor in the editor's iframe document
            const editorElementIframe = editor.Canvas.getDocument().querySelector('.gjs-editor');
    
            if (editorElementIframe) {
                console.warn('Found .gjs-editor in the iframe:', editorElementIframe);
                editorElementIframe.classList.remove('short-sidebar');
                setTimeout(() => window.dispatchEvent(new Event('resize')), 400);
    
            } else {
                console.warn('.gjs-editor not found in the iframe.');
            }
        }
        console.warn('calls')
        if (editor.Commands.isActive('section')) {
            editor.stopCommand('section');
        }
    });

    editor.on('all', (eventName) => {
        console.log('Event:', eventName);
    });

    editor.on('load', function() {
 

// Define the function you want to attach to the button
function buttonClickHandler() {
     // Check for .gjs-editor in the main document
     const editorElementMain = document.querySelector('.gjs-editor');

     if (editorElementMain) {
         console.warn('Found .gjs-editor in the main document:', editorElementMain);
         editorElementMain.classList.add('short-sidebar');
        
         setTimeout(() => window.dispatchEvent(new Event('resize')), 400);

     } else {
         console.warn('.gjs-editor not found in the main document.');

         // Check for .gjs-editor in the editor's iframe document
         const editorElementIframe = editor.Canvas.getDocument().querySelector('.gjs-editor');

         if (editorElementIframe) {
             console.warn('Found .gjs-editor in the iframe:', editorElementIframe);
             editorElementIframe.classList.add('short-sidebar');
             setTimeout(() => window.dispatchEvent(new Event('resize')), 400);

         } else {
             console.warn('.gjs-editor not found in the iframe.');
         }
     }
}

// Get all panels
var panels = document.querySelectorAll('.gjs-pn-panel.gjs-pn-views-container.gjs-one-bg.gjs-two-color');

// Add button to all panels
panels.forEach(function(panel) {
    var panelContentArea = panel;
    if (panelContentArea) {
        var text = document.createElement('span');
        text.className = 'panel-title';
        text.textContent = 'Panel Title'; // Default title, you can update this later

        var button = document.createElement('button');
        // button.textContent = ' &times; ';

        button.className = 'closeButton fa fa-close';
        button.addEventListener('click', buttonClickHandler);
        
        panelContentArea.appendChild(button);
        panelContentArea.appendChild(text)
    } else {
        console.error('Content area not found in panel:', panel);
    }
});
// editor.runCommand('open-tm');

editor.runCommand('section');

});


editor.on('component:unselected', (selectedComponent) => {
    const editorElementIframe = editor.Canvas.getDocument()
    const iframeDocument = editorElementIframe;

    if (iframeDocument) {
        const container = iframeDocument.querySelector(".gjs-higlight-backdrop-container")

        container.classList.remove("active")
        setTimeout(() => {
            if (container) {
                container.remove()
            }
        }, 300)

    }
    console.warn(selectedComponent.getEl())
    console.warn("test", selectedComponent)
})

editor.on('run:open-layers', () => {
    const panelTitle = document.querySelector('.panel-title');

    if (panelTitle) {
            panelTitle.textContent = 'Layout'; // Default title
        }
})

editor.on('run:open-sm', () => {
    const panelTitle = document.querySelector('.panel-title');

    if (panelTitle) {
            panelTitle.textContent = 'Styles'; // Default title
        }
})
editor.on('run:open-tm', () => {
    const panelTitle = document.querySelector('.panel-title');

    if (panelTitle) {
            panelTitle.textContent = 'Settings'; // Default title
        }
})


}