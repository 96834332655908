import React  from "react";


export default function serverCheck() {
  

    

    return 'ok'
       
   
}  