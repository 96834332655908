import { action, computed, thunk } from "easy-peasy"

export const funnels = {
    funnel: {payload : null },
    setFunnel: action((state, payload) => {
    state.funnel = { payload }
  }),

}

export default funnels;
