import React from "react";
import {List, ListInlineItem} from "reactstrap";
import Mailchimp from "../../../images/integ-mailchimp.png"
function IntegratedApp() {


    return (
        <>

            <div className={'row mt-5 instedList'}>
                <div className={'col-md-12'}>
                    <h3>    Integrated Apps</h3>
                    <List type="inline">
                        <ListInlineItem>
                            <div className={'integratedApp'}>
                                <img
                                    src= {Mailchimp}
                                    alt="Mailchimp"
                                />
                                <div className={'text-details'}>
                                    <h5>Mailchimp</h5>
                                    <h6>1 connected account</h6>
                                </div>
                            </div>

                        </ListInlineItem>
                    </List>
                </div>
            </div>

        </>
    );
}

export default IntegratedApp;