import React from 'react'
import {Button} from "reactstrap";


function stats() {


    return (
    <>
        <Button className="active"
                outline
        >
            Traffic Channels
        </Button>

        {/*<Button outline>*/}
        {/*    Gender*/}
        {/*</Button>*/}
    </>
    )
}
export default stats;