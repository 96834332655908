import React  from "react";
import AccountsWorkspace from "../../components/common/accounts/account"
import { GrFormClose } from "react-icons/gr";
export default function Detail() {

    return (
        <div>
            <div className="account-wrapper">
                <button className="secondary-btn-modal"> <GrFormClose size={20}/> </button>
                <div className="container">
                    <AccountsWorkspace/>
                </div>
            </div>

        </div>
    )
}