import React ,{useState,useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
function Traffic({data}) {

    const [options, setChartOptions] = useState(
        {
  
        chart: {
            type: 'column',
            height: 230
        },
        title: {
            text: '',
            style: {
                display: 'none'
            }
        },
        credits: {
            enabled: false
        },
        xAxis: {
            categories: ['0' ]
        },
        yAxis: {
            // min: 0,
            title: {
                text: ''
            },
            stackLabels: {
                enabled: true,

            }
        },

        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false
                }
            }
        },
        series: [
            {
            name: 'Organic Search',
            data: [0,0,0,0,0,0],
        }, {
            name: 'Direct',
            data: [0,0,0,0,0,0],
        }, {
            name: 'Social',
            data: [0,0,0,0,0,0],
        }, {
            name: 'Other',
            data: [0,0,0,0,0,0],
        }
        ]
    }
    )


    useEffect(() => {

        console.log('dataa',data)
        setChartOptions({ 
            xAxis: {
                categories: data?.referer_x_axis
            },
            series: [{
                name: 'Organic Search',
                data: data?.referer_organic_val,
            }, {
                name: 'Direct',
                data: data?.referer_direct_val,
            }, {
                name: 'Social',
                data: data?.referer_social_val,
            }, {
                name: 'Other',
                data: data?.referer_other_val,
            }]
          });   



          

    }, [data])
  
    return (

        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>

    )
}
export default Traffic;