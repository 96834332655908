import React, { useState } from 'react';
import {
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
    Button,
    Accordion,
    AccordionItem,
    AccordionHeader,
    AccordionBody,
    Spinner
} from 'reactstrap';
import server from '../../../../../apis/server'; // Adjust the import path according to your project structure
import { toast } from 'react-toastify';

const Scripts = ({ pageId, initialData }) => {
    const { 
        google_conversion_id, 
        google_conversion_label, 
        google_verification_tag, 
        google_analytics_id, 
        ms_verification_tag, 
        ms_uet_tag_id, 
        page_header_script, 
        page_body_script, 
        page_footer_script 
    } = initialData?.script_setting || {};

   // Decode the initial data
   const [googleConversionId, setGoogleConversionId] = useState(google_conversion_id || '');
   const [conversionLabel, setConversionLabel] = useState(google_conversion_label || '');
   const [googleMetaTag, setGoogleMetaTag] = useState(decodeURIComponent(window.atob(google_verification_tag || '')));
   const [googleAnalyticsId, setGoogleAnalyticsId] = useState(google_analytics_id || '');
   const [msSiteVerificationTag, setMsSiteVerificationTag] = useState(decodeURIComponent(window.atob(ms_verification_tag || '')));
   const [msUetTagId, setMsUetTagId] = useState(ms_uet_tag_id || '');
   const [headScript, setHeadScript] = useState(decodeURIComponent(window.atob(page_header_script || '')));
   const [bodyScript, setBodyScript] = useState(decodeURIComponent(window.atob(page_body_script || '')));
   const [footerScript, setFooterScript] = useState(decodeURIComponent(window.atob(page_footer_script || '')));

    const [open, setOpen] = useState('1');
    const [isLoading, setIsLoading] = useState(false);

    const toggle = (id) => {
        if (open === id) {
            setOpen('');
        } else {
            setOpen(id);
        }
    };

    const handleSave = async () => {
        setIsLoading(true);
        const formData = {
            page_id: pageId,
            google_conversion_id: googleConversionId,
            google_conversion_label: conversionLabel,
            google_verification_tag: window.btoa(encodeURIComponent(googleMetaTag)),
            google_analytics_id: googleAnalyticsId,
            ms_verification_tag: window.btoa(encodeURIComponent(msSiteVerificationTag)),
            ms_uet_tag_id: msUetTagId,
            page_header_script: window.btoa(encodeURIComponent(headScript)),
            page_body_script: window.btoa(encodeURIComponent(bodyScript)),
            page_footer_script: window.btoa(encodeURIComponent(footerScript)),
        };
        try {
            const { data } = await server.post(`page/update_script_setting`, formData);
            setIsLoading(false);
            if (data?.status) {
                toast(data?.message, 'success');
            }
        } catch (e) {
            setIsLoading(false);
            console.error('Error saving script settings:', e);
            if (e.response?.data?.error) {
                toast(e.response.data.errors_array, 'error');
            }
        }
    };

    return (
        <div className={'styleDetails'}>
            <Accordion open={open} toggle={toggle} className={'mb-2'}>
                <AccordionItem>
                    <AccordionHeader targetId="1">
                        Google Scripts
                    </AccordionHeader>
                    <AccordionBody accordionId="1">
                        <Form>
                            <FormGroup>
                                <Label for="GoogleConversionID">Google Conversion ID</Label>
                                <Input
                                    type={'text'}
                                    value={googleConversionId}
                                    onChange={(e) => setGoogleConversionId(e.target.value)}
                                    placeholder={'Enter Google Conversion ID'}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="conversionLabel">Conversion Label</Label>
                                <Input
                                    type={'text'}
                                    value={conversionLabel}
                                    onChange={(e) => setConversionLabel(e.target.value)}
                                    placeholder={'Enter Conversion Label'}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="googleMetaTag">Google Meta Tag</Label>
                                <Input
                                    type={'text'}
                                    value={googleMetaTag}
                                    onChange={(e) => setGoogleMetaTag(e.target.value)}
                                    placeholder={'Enter Google Meta Tag'}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="GoogleAnalyticsID">Google Analytics ID</Label>
                                <Input
                                    type={'text'}
                                    value={googleAnalyticsId}
                                    onChange={(e) => setGoogleAnalyticsId(e.target.value)}
                                    placeholder={'UA-0000000-02'}
                                />
                            </FormGroup>
                        </Form>
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="2">
                       Microsoft Scripts
                    </AccordionHeader>
                    <AccordionBody accordionId="2">
                        <Form>
                            <FormGroup>
                                <Label for="MSSiteVerificationTag">MS Site Verification Tag</Label>
                                <Input
                                    type={'text'}
                                    value={msSiteVerificationTag}
                                    onChange={(e) => setMsSiteVerificationTag(e.target.value)}
                                    placeholder={'Enter MS Site Verification Tag'}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="MSUetTagId">MS UET Tag ID</Label>
                                <Input
                                    type={'text'}
                                    value={msUetTagId}
                                    onChange={(e) => setMsUetTagId(e.target.value)}
                                    placeholder={'Enter MS UET Tag ID'}
                                />
                            </FormGroup>
                        </Form>
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="3">
                        General Scripts
                    </AccordionHeader>
                    <AccordionBody accordionId="3">
                        <Form>
                            <FormGroup>
                                <Label for="headScript">Head</Label>
                                <Input
                                    type={'textarea'}
                                    value={headScript}
                                    onChange={(e) => setHeadScript(e.target.value)}
                                    placeholder={'Paste your Code here'}
                                />
                                <FormText className={'text-white'}>Enter JavaScript or CSS code into the HTML document header.</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label for="bodyScript">Body</Label>
                                <Input
                                    type={'textarea'}
                                    value={bodyScript}
                                    onChange={(e) => setBodyScript(e.target.value)}
                                    placeholder={'Paste your Code here'}
                                />
                                <FormText className={'text-white'}>Enter HTML, JavaScript, or CSS right after the opening body tag.</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label for="footerScript">Footer</Label>
                                <Input
                                    type={'textarea'}
                                    value={footerScript}
                                    onChange={(e) => setFooterScript(e.target.value)}
                                    placeholder={'Paste your Code here'}
                                />
                                <FormText className={'text-white'}>Enter HTML, JavaScript, or CSS right before the closing body tag.</FormText>
                            </FormGroup>
                        </Form>
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
            <Button className={'primary-btn-modal shadow-none'} onClick={handleSave}
                                           >
                                                {isLoading ?
                                        <span className={'spinnerContainer'}> Saving  <Spinner>
                                    Loading...
                                </Spinner></span> : 'Save'}</Button>{' '}
        </div>
    );
};

export default Scripts;
