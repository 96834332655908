import React, {useEffect, useState} from "react";
import HeadTitle from "../common/internalTittle/GenaricButton";
import {
    Button,
    Badge,
    Table,
    List,
    ListInlineItem,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
    NavItem, FormGroup, Label, Input, Form
} from "reactstrap";
import {BiUnlink} from "react-icons/bi";
import {HiOutlineArrowSmLeft} from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import CheckMark from "../../images/domain_icons/check.png";
import server from "../../apis/server";
import ContinueProcess from "../../images/domain_icons/continue-process.png";
import StopWarning from "../../images/domain_icons/stop-warning.png";
import Loader from "../common/loader/loader";
import {IoTrashOutline} from "react-icons/io5";
import {
    useParams
  } from "react-router-dom";
import {FiLink} from "react-icons/fi";




function DetailList() {
    let { domainName } = useParams();

    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingLink, setIsLoadingLink] = useState(false)

    const [isLoadingDomain, setIsLoadingDomain] = useState(false)
    const [update, setUpdate] = useState(false)
    const [updateLinkUi, setUpdateLinkUi] = useState(false)

    const [disable,setDisable]=useState(true);

    const [domain, setDomain] = useState({})
    const [selectedPage, setSelectedPage] = useState()
    const [selectedDomainId, setSElectedDomainId] = useState()
    const [isRootPage, setIsRootPage] = React.useState(false);
    const [pages, setPages] = useState([]);
    const [selectedPageOption, setSelectedPageOption] = useState("");
    const router = useNavigate()
    const [error,setError]=useState(false)
    useEffect(() => {
// alert('cc')

        getData()
        getDataPages()


        // return
    }, [update,updateLinkUi]);
    const getData = async () => {
        try {
            // setIsLoading(true)


            const {data} = await server.get(`domain/get/${domainName}`);
            console.log('this is domain',data?.data);
            setIsLoading(false);

            if (data?.status) {
                setDomain(data?.data)


                setIsLoading(false);
            }


        } catch (e) {

        }
    }

    const getDataPages = async () => {
        try {
            // setIsLoading(true)


            const {data} = await server.get(`page/all_unattached`);
            if (data?.status) {


                setPages(data?.data)
                setIsLoading(false);
            }
            console.log(data)
            // setProduct(data);
            // setLoading(false);

        } catch (e) {
            if (e.response?.data?.status === false && e.response?.data?.auth === false) {
                localStorage.clear()
                // router("/accounts")

                // console.log(e.response?.data.errors?.title[0])
                // setError(e.response?.data.errors?.title[0])
                setIsLoading(false);
            }



            else if (e.response?.data?.status === false) {
                console.log(e.response?.data.errors?.title[0])
                setError(e.response?.data.errors?.title[0])
                setIsLoading(false);
            } else {
                setIsLoading(false);

                setError(e.message)
            }

        }
    }
    var url=window.location.href.split("/").pop();
    console.log(url)
    const [modal, setModal] = useState(false);
    const [modalLink, setModalLink] = useState(false);

    const toggleDelete = () => {
        setModal(!modal);

    }
    const toggleLink = () => {

        setSelectedPage()
        setSelectedPageOption()
        setIsRootPage()
        setModalLink(!modalLink);

    }
    // const [modal, setModal] = useState(false);
    const linkPage = () => {
        setModal(!modal);

    }

    const showModal = (id,d) => {

        console.log('idd',id)
        console.log('dddd',d)

        toggleDelete()
        setSelectedPage(d)
          setSElectedDomainId(id)




    }

    const onChangePageSelection = (e) => {
        setSelectedPageOption(e.target.value);
    };

    const unlink = async () => {
        try {
            setIsLoadingLink(true)


            const {data} = 
            await server.get('domain/page_unlink', {
                params: {
                    domain_id: selectedDomainId,
                    page_id: selectedPage?.page_id
                }
              });
            console.log('this is domain',data?.data);
            setIsLoadingLink(false);

            if (data?.status) {
                setUpdate(!update)
                // setDomain(data?.data)

               
                setIsLoadingLink(false);

                toggleDelete()
                // router(`/pages/editor/${data?.data?.page_id}`)
                     
            }


        } catch (e) {
            setIsLoadingLink(false);


        }


    }

    const link=async()=>{
        setDisable(true)
        setIsLoadingDomain(true)

        setError('')
    
       
          try {
            const { data } = await server.get('domain/page_link',
            {
                params: {
                    domain_id: domain?.id,
                    page_id:selectedPageOption ? selectedPageOption : 0,
                    is_root_page: isRootPage ? 1 : 0
                }
              });
              toggleLink()

            if(data?.status){
                setUpdateLinkUi(!updateLinkUi)
               
                // setCurrent(current + 1);
                  setIsLoadingDomain(false)

                // isLoading(true)
                // router(`/pages/editor/${data?.data?.page_id}`)
            }
           console.log(data)
            // setProduct(data);
            setIsLoadingDomain(false)

            // setLoading(false);
          } catch (e) {
            setIsLoadingDomain(false)

            setDisable(false)
                 if(e.response?.data?.status===false){
                //    console.log(e.response?.data.errors?.title[0])
                if(e.response?.data.errors){
                    setError(e.response?.data.errors?.page_id[0])
                    return
                }
                 setError(e.response?.data?.message)



                 }
                 else{
                     setError(e.message)
                     setDisable(false)
                     setIsLoading(false)

                 }
            //console.log(e.response);

        }
    }
    return (
        <>
            {isLoading &&
            (
                <div className={'loaderPage'}>
                    <Loader />
                </div>

            )}
                <div className={`row`}>
                    <div className={`col-md-12 col-12`}>
                        <div className={`row`}>
                            <div className={`col-md-8 col-12 domain-info`}>
                              
                                <List type="inline">
                                    <ListInlineItem>
                                        <button className={'border-0 background-none p-0 text-grey'} onClick={() => {
                                            router(`/pages/domain`)
                                        }}>
                                            <HiOutlineArrowSmLeft size={20}/>
                                        </button>
                                    </ListInlineItem>
                                    <ListInlineItem>
                                        <HeadTitle title= {domain?.domain}/>
                                    </ListInlineItem>
                                    <ListInlineItem>
                                        { domain?.a_record?.status == 'completed' && (   <img src={CheckMark} /> )}
                                        { domain?.a_record?.status == 'pending' && (   <img src={ContinueProcess} /> )}
                                        { domain?.a_record?.status == 'failed' && (   <img src={StopWarning} /> )}

                                        A Name
                                    </ListInlineItem>
                                    <ListInlineItem>
                                        { domain?.setup_domain_config?.status == 'completed' && (   <img src={CheckMark} /> )}
                                        { domain?.setup_domain_config?.status == 'pending' && (   <img src={ContinueProcess} /> )}
                                        { domain?.setup_domain_config?.status == 'failed' && (   <img src={StopWarning} /> )}

                                        Default Page
                                    </ListInlineItem>
                                    <ListInlineItem>
                                        { domain?.ssl_verification?.status == 'completed' && (   <img src={CheckMark} /> )}
                                        { domain?.ssl_verification?.status == 'pending' && (   <img src={ContinueProcess} /> )}
                                        { domain?.ssl_verification?.status == 'failed' && (   <img src={StopWarning} /> )}

                                        SSL Certificate
                                    </ListInlineItem>
                                </List>
                 
                 
                 
                            </div>
                            <div className={`col-md-4 col-12 d-flex justify-content-end`}>
                                <button type="button" className="btn primary-btn-modal"
                                        onClick={() => {
                                            toggleLink()
                                            // router(`/pages/domain/setup`)
                                        }}>
                                    Link Page
                                </button>
                            </div>
                        </div>
                        <Modal isOpen={modalLink} toggle={toggleLink} className={'creatPageModal'} centered backdrop={'false'}>

                        <ModalHeader toggle={toggleLink}>Are you sure you want to link page?</ModalHeader>
                            <ModalBody>
                                <p>You are about to link this page to {domain?.domain} </p>
                                <FormGroup>


                                        <>
                                            <Label for="attach">
                                                Select page
                                            </Label>
                                            <Input
                                                className=' shadow-none'

                                                name="select"
                                                type="select"
                                                value={selectedPageOption}
                                                onChange={onChangePageSelection}
                                            >
                                                {/*{*/}
                                                {/*    (()=>{console.log(funnelList)})()*/}
                                                {/*}*/}
                                                <option value={""}>
                                                    Select Page ...
                                                </option>
                                                {pages?.map((x,i)=>{
                                                    return(
                                                        <option value={x?.id}>
                                                            {x?.title}
                                                        </option>
                                                    );
                                                })}



                                            </Input>

                                            {selectedPageOption && (
                                                <FormGroup className={`checkboxClass input-check`}>
                                                    <p className={`mt-3`}>Do you want to publish this page on your main domain?</p>

                                                    <Input type="checkbox" id={`publishPage`}
                                                           defaultChecked={isRootPage}

                                                           onChange={() => setIsRootPage(!isRootPage)}
                                                    />
                                                    <Label for={`publishPage`}>
                                                        Yes, publish on Main Domain
                                                    </Label>

                                                </FormGroup>
                                            )}



                                        </>







                                </FormGroup>
                                {error && (<p className={'errorMessage'}> {error} </p>)}

                            </ModalBody>

                            <ModalFooter>
                                <Button
                                    onClick={() => link()}
                                    className={'primary-btn-modal shadow-none d-flex align-items-center justify-content-center '}
                                    disabled={!selectedPageOption}
                                    >{isLoadingDomain ?
                                    <span className={'spinnerContainer '}>Link<Spinner >
                                    Loading...
                                </Spinner></span> :'Link'} </Button>
                                <Button className={'secondary-btn-modal mx-2 shadow-none'} onClick={toggleLink}> Cancel</Button>

                            </ModalFooter>
                        </Modal>

                        <div className={`tableSet my-5`}>

                          
                       {/* { domain?.pages?.length>=1 && ( */}
                            <Table className={'domainList'}
                                   responsive
                                   size=""
                            >
                                <thead>
                                <tr>
                                    <th>
                                        Page
                                    </th>
                                    <th>
                                        URL
                                    </th>
                                    <th  colSpan={2}>
                                        Status
                                    </th>

                                </tr>
                                </thead>
                                <tbody>

                                {domain?.pages?.length ==0 &&(
                                  <h6 className={'text-center mt-2 mx-3 p-2'}> No pages has been attached to this domain.</h6>
                              )}


                                {
                                    domain?.pages?.map((d,index)=>{
                                        return(
                                   <tr>
                                                <td>
                                                    {d?.title}
                                                </td>
                                                <td>
                                                    <button className={'border-0 background-none'}
                                                            onClick={()=> window.open(`${d?.page_public_url}`, "_blank")}>
                                                        {d?.page_public_url}
                                                    </button>
                                                </td>
                                                <td>
                                                    
                                                   { d?.status == 1 && (
                                                    <Badge  className={'mx-0'}
                                                             color="success"
                                                             outline
                                                    >
                                                        Active
                                                    </Badge>
                                                   )}
                                                   
                                                   { d?.status == 0 && (
                                                    <Badge  className={'mx-0'}
                                                             color="text-dark"
                                                             outline
                                                    >
                                                       InActive
                                                    </Badge>
                                                   )}
                                                   



                                                </td>
                                                <td>

                                                        <button     onClick={() => showModal(domain?.id,d)}
                                                            className={'border-0 background-none'}
                                                        >
                                                            <BiUnlink/>
                                                        </button>



                                                </td>

                                            </tr>

   )})}
                                <tr>
                                   
                                </tr>
                                </tbody>
                            </Table>


                             

                               <Modal isOpen={modal} toggle={toggleDelete} className={'creatPageModal'} centered backdrop={'false'}>
                               <ModalHeader toggle={toggleDelete}>Are you sure you want to unlink this page? </ModalHeader>
                          
                               <ModalBody>


                                   <p>You are about to unlink this page from {domain?.domain}, please note that you and your visitors will not access this page again. </p>
                               </ModalBody>


                               <ModalFooter>

                                    <Button
                                    onClick={() => unlink()}
                                    className={'btn-danger shadow-none d-flex align-items-center justify-content-center '}>{isLoadingLink ?
                                       <span className={'spinnerContainer '}>Unlink<Spinner >
                                   Loading...
                                </Spinner></span> :'Unlink'} </Button>
                                  <Button className={'secondary-btn-modal mx-2 shadow-none'} onClick={toggleDelete}> Cancel</Button>

                               </ModalFooter>
                            </Modal>


                        </div>
                    </div>
                </div>
        </>
    );
}

export default DetailList;