import React, { useState,useEffect } from 'react';
import { Form, FormGroup, Label, Input, Card, CardImg, CardBody, CardText, Spinner } from 'reactstrap';
import server from '../../../../../apis/server'; // Adjust the import path according to your project structure
import { CiImageOn } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

import { toast } from 'react-toastify';

const BackgroundImageUpload = ({ editor, pageId, backgroundImageUrl,setUpdateGrapeJs }) => {
    const [loading, setLoading] = useState(false);
    const [backgroundImagePreview, setBackgroundImagePreview] = useState(null);

    const [deleteModal, setDeleteModal] = useState(false);

    const toggleDeleteModal = (event) => {
        event.stopPropagation(); // Prevent the card onClick from triggering

        setDeleteModal(!deleteModal);
    };

    useEffect(() => {
        setBackgroundImagePreview(backgroundImageUrl || null);
    }, [backgroundImageUrl]);
    const uploadFile = async (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }

        // Generate background image preview immediately
        const imageUrl = URL.createObjectURL(file);
        setBackgroundImagePreview(imageUrl);

        setLoading(true);

        let fileSizeInBytes = file.size;
        let fileSizeInMB = fileSizeInBytes / (1024 * 1024); // Convert bytes to megabytes

        if (fileSizeInMB > 2) {
            toast("Please upload a file smaller than 2 MB.");
            setLoading(false);
            return;
        }

        var formData = new FormData();
        formData.append('file', file);
        formData.append('page_id', pageId);

        try {
            const { data } = await server.post(`media/upload/page_background`, formData);
            setLoading(false);

            if (data?.status) {
                toast('Background image uploaded successfully', 'success');
                editor.runCommand('set-background-image', {
                    imageUrl: data?.data?.upload_url
                }, () => {
                    setBackgroundImagePreview(data?.data?.upload_url); // Update the preview with the new image URL
                    editor.refresh();
                });
            }
        } catch (e) {
            setLoading(false);
            if (e.response?.data?.status === false) {
                if (e.response?.data?.errors_array?.[0]) {
                    toast(e.response?.data?.errors_array?.[0], 'error');
                } else {
                    toast(e.response?.data?.message, 'error');
                }
            }
        }
    };

   

    const handleDeleteImage = async (event) => {
        toggleDeleteModal(event)

        setLoading(true);
    
        try {
            // Concatenate the page ID directly in the URL
            const response = await server.delete(`media/delete/page_background/${pageId}`);
    
            if (response.data.status) {
                setBackgroundImagePreview(null);
            
                editor.runCommand('set-background-image', { imageUrl: '' }, () => {
                    // Clears out the background styles when deleting the image
                    const wrapper = editor.DomComponents.getWrapper();
                    wrapper.setStyle({
                        'background-image': '',
                        'background-size': '',
                        'background-repeat': ''
                    });
                    editor.refresh(); // Refresh to apply the changes
                });
                

                toast('Background image removed successfully', 'success');
    
                // Reset the file input
                document.getElementById('background-image-upload').value = "";
            } else {
                toast('Failed to remove the background image', 'error');
            }
        } catch (error) {
            if (error.response?.data?.status === false) {
                if (error.response?.data?.errors_array?.[0]) {
                    toast(error.response?.data?.errors_array?.[0], 'error');
                } else {
                    toast(error.response?.data?.message, 'error');
                }
            } else {
                toast('Error removing the background image', 'error');
            }
        }
        setLoading(false);
    };
    
    return (
        <div className={'styleDetails'}>
            <Form>
                <FormGroup>
                    <Label for="BackgroundImage">Background Image</Label>
                    <Input type={'file'} placeholder={'Upload an image'} onChange={uploadFile} style={{ display: 'none' }} id="background-image-upload" accept='image/*' />
                    <Card onClick={() => document.getElementById('background-image-upload').click()} style={{ cursor: 'pointer', position: 'relative' }}>
    {loading && (
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 2 }}>
            <Spinner color="primary" />
        </div>
    )}
    {backgroundImagePreview ? (
       <>
       <CardImg top width="100%" src={backgroundImagePreview} alt="Background image preview" style={{ opacity: loading ? 0.5 : 1 }} />
       <MdDelete size={30} style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer', color: 'red', zIndex: 3 }} onClick={toggleDeleteModal} />
   </>
) : (
        <CardBody>
            <CiImageOn size={50} />
            <CardText>Upload Background Image</CardText>
        </CardBody>
    )}
</Card>

                </FormGroup>
            </Form>

            <Modal isOpen={deleteModal} toggle={toggleDeleteModal} centered>
    <ModalHeader toggle={toggleDeleteModal}>Confirm Deletion</ModalHeader>
    <ModalBody>
        Are you sure you want to delete the Background Image?
    </ModalBody>
    <ModalFooter>
        <Button color="danger" 
        onClick={handleDeleteImage}
        >Clear</Button>
        {' '}
        <Button color="secondary" onClick={toggleDeleteModal}>Cancel</Button>
    </ModalFooter>
</Modal>
        </div>
    );
};

export default BackgroundImageUpload;