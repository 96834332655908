import React, { useEffect, useState } from "react";
import HeadTitle from "../common/internalTittle/GenaricButton";
import {
    Button,
    Col,
    Nav,
    NavItem,
    NavLink,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Badge,
    Spinner,
    TabContent,
    Table,
    TabPane, Form, FormGroup, Label, Input
} from "reactstrap";
import { BiRefresh } from "react-icons/bi";
import { FiLink } from "react-icons/fi";
import { BsFillTrashFill } from "react-icons/bs";
import { VscTrash } from "react-icons/vsc";
import { AiOutlineEye, AiOutlineEyeInvisible, AiOutlineRight } from "react-icons/ai";
//import { IoMdRemoveCircleOutline } from "react-icons/io";
import { useNavigate, generatePath } from "react-router-dom";
import server from '../../apis/server'
import EmptyImg from '../../images/empty-domain.svg'

import CheckMark from "../../../src/images/domain_icons/check.png";
import ContinueProcess from "../../../src/images/domain_icons/continue-process.png";
import Processing from "../../../src/images/domain_icons/processing.png";
import StopWarning from "../../../src/images/domain_icons/stop-warning.png";
import Loader from "../common/loader/loader";
import { Formik } from "formik";
import InternalTitle from "../common/internalTittle/GenaricButton";
import { BsCheck2 } from "react-icons/bs";
import toast from '../../utils/toast'
import { Tooltip, UncontrolledTooltip } from 'reactstrap';
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";



function List() {
    const [activeTab, setActiveTab] = useState('1');
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingTicket, setIsLoadingTicket] = useState(false)
    const [updateFloatHostingDomain, setUpdateFloatHostingDomain] = useState(false)
    const [state, setState] = useState(true);
    const [domain, setDomain] = useState([])
    const [floatHostingDomain, setFloatHostingDomain] = useState([])
    const [isemptyModal, setIsemptyModal] = useState(true);
    const [selectedDomain, setSelectedDomain] = useState('');
    const [selectedDdomain, setSelectedDdomain] = useState('');

    const [error, setError] = useState("")
    const [ticketNote, setticketNote] = useState("Please Update A Record against my Domain")
    const [disable, setDisable] = useState(true);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [tooltipDisOpen, setTooltipDisOpen] = useState(false);
    const [tooltipTGOpen, setTooltipTGOpen] = useState(false);
    const [tooltipGTOpen, setTooltipGTOpen] = useState(false);
    const [updateUi, setUpdateUi] = useState(false);
    const [updateCustomDomain, setUpdateCustomDomain] = useState(false);



    const toggleTol = () => setTooltipOpen(!tooltipOpen);
    const toggleTolDis = () => setTooltipDisOpen(!tooltipDisOpen);
    const toggleTolTg = () => setTooltipTGOpen(!tooltipTGOpen);
    const toggleTolGt = () => setTooltipGTOpen(!tooltipGTOpen);

    const [gdprStatus, setGdprStatus] = useState(false);

    const [workspacemodal, setworkspaceModal] = useState(false);
    const [ddmodal, setddModal] = useState(false);
    const [nestedModal, setNestedModal] = useState(false);
    const [closeAll, setCloseAll] = useState(false);


    const router = useNavigate()

    const useNavigateParams = () => {
        const navigate = useNavigate();

        return (url, params) => {
            const path = generatePath(":url?:queryString", {
                url,
                queryString: params
            });
            navigate(path);
        };
    };

    const navigateParam = useNavigateParams();


    useEffect(() => {


        getData()
        // getFloatHostingData()


        // return
    }, [updateUi, updateCustomDomain]);

    useEffect(() => {


        getFloatHostingData()


        // return
    }, [updateFloatHostingDomain, updateUi]);

    const getData = async () => {
        try {
            setIsLoading(true)


            const { data } = await server.get(`domain/all`);
            setIsLoading(false);

            if (data?.status) {
                setDomain(data?.data)

                console.log('this is domain', data?.data);
                setIsLoading(false);
            }
            console.log(data)
            // setProduct(data);

        } catch (e) {
            if (e.response?.data?.status === false && e.response?.data?.auth === false) {
                localStorage.clear()
                // router("/accounts")

                // console.log(e.response?.data.errors?.title[0])
                // setError(e.response?.data.errors?.title[0])
                setIsLoading(false);
            }



            else if (e.response?.data?.status === false) {
                console.log(e.response?.data.errors?.title[0])
                // setError(e.response?.data.errors?.title[0])
                setIsLoading(false);
            } else {
                setIsLoading(false);

                // setError(e.message)
            }

        }
    }
    const getFloatHostingData = async () => {
        try {
            setIsLoading(true)

            let formData = {
                // fetch_data: true,



            }
            const { data } = await server.post(`domain/get_fh_domains`, formData);
            setIsLoading(false);

            if (data?.status) {
                setFloatHostingDomain(data?.data ? data?.data : [])

                console.log('this is domain', data?.data);
                setIsLoading(false);
            }
            console.log(data)
            // setProduct(data);

        } catch (e) {
            if (e.response?.data?.status === false && e.response?.data?.auth === false) {
                localStorage.clear()
                // router("/accounts")

                // console.log(e.response?.data.errors?.title[0])
                // setError(e.response?.data.errors?.title[0])
                setIsLoading(false);
            }



            else if (e.response?.data?.status === false) {
                console.log(e.response?.data.errors?.title[0])
                // setError(e.response?.data.errors?.title[0])
                setIsLoading(false);
            } else {
                setIsLoading(false);

                // setError(e.message)
            }

        }
    }

    const getFloatHostingApiData = async () => {
        try {
            setIsLoading(true)

            let formData = {
                fetch_data: true,



            }
            const { data } = await server.post(`domain/get_fh_domains`, formData);
            setIsLoading(false);

            if (data?.status) {
                setFloatHostingDomain(data?.data ? data?.data : [])

                console.log('this is domain', data?.data);
                setIsLoading(false);
            }
            console.log(data)
            // setProduct(data);

        } catch (e) {
            if (e.response?.data?.status === false && e.response?.data?.auth === false) {
                localStorage.clear()
                // router("/accounts")

                // console.log(e.response?.data.errors?.title[0])
                // setError(e.response?.data.errors?.title[0])
                setIsLoading(false);
            }



            else if (e.response?.data?.status === false) {
                console.log(e.response?.data.errors?.title[0])
                // setError(e.response?.data.errors?.title[0])
                setIsLoading(false);
            } else {
                setIsLoading(false);

                // setError(e.message)
            }

        }
    }


    const toggle = () => {
        setworkspaceModal(!workspacemodal);

    }
    const showDomainDisconnectModal = (payload) => {
        setSelectedDdomain(payload)
        setddModal(!ddmodal);

    }
    const showDdModal = (payload) => {
        setddModal(!ddmodal);

    }



    const showModal = (val, payload) => {
        setIsemptyModal(val);
        setSelectedDomain(payload)

        toggle()

    }

    const toggleNested = () => {
        setNestedModal(!nestedModal);
        setCloseAll(false);
    };
    const toggleAll = () => {
        setNestedModal(!nestedModal);
        setCloseAll(true);
    };

    const characterLimit = (event) => {
        event.preventDefault();
        let result = event.target.value;

        setticketNote(result);
        const charCount = result.length;
        if (charCount >= 1 && charCount < 255) {

            setDisable(false);

        } else {
            console.log(charCount);
            setDisable(true);
        }
    }




    const generateTicket = async () => {
        let formData = {
            domain_id: selectedDomain?.domain_id,
            ticket_note: ticketNote

        }


        // alert(JSON.stringify(formData))
        setIsLoadingTicket(true)
        try {
            const { data } = await server.post(`domain/generate_ticket`,
                formData,
            );
            console.log(data);

            if (data?.status) {
                toast(data?.message, 'success')
                setUpdateFloatHostingDomain(!updateFloatHostingDomain)
                toggle()
                // setupdatUi(!updatUi);

            }



        } catch (e) {
            setIsLoadingTicket(false)
            if (e.response?.data?.status === false) {
                if (e.response?.data.errors_array?.[0]) {
                    toast(e.response?.data.errors_array?.[0], 'error')
                    // setUpdateFloatHostingDomain(!updateFloatHostingDomain)
                    //  setActiveTab(2)
                }
                else {
                    toast(e.response?.data?.message, 'error')

                }
                //  setError(e.response?.data.errors?.title[0])
            }
            else {

            }
        }


    }


    const disconnectDomain = async () => {
        let formData = {
            domain: selectedDdomain,

        }


        // alert(JSON.stringify(formData))
        setIsLoadingTicket(true)
        try {
            const { data } = await server.post(`domain/remove`,
                formData,
            );
            console.log(data);
            setIsLoadingTicket(false)
            showDdModal()
            if (data?.status) {
                toast(data?.message, 'success')

                setUpdateCustomDomain(!updateCustomDomain)
                setUpdateFloatHostingDomain(!updateFloatHostingDomain)

                // toggle()
                // setupdatUi(!updatUi);

            }



        } catch (e) {
            showDdModal()
            setIsLoadingTicket(false)
            if (e.response?.data?.status === false) {
                if (e.response?.data.errors_array?.[0]) {
                    toast(e.response?.data.errors_array?.[0], 'error')
                    // setUpdateFloatHostingDomain(!updateFloatHostingDomain)
                    //  setActiveTab(2)
                }
                else {
                    toast(e.response?.data?.message, 'error')

                }
                //  setError(e.response?.data.errors?.title[0])
            }
            else {

            }
        }


    }




    const enableGdpr = async (domain, isGdp) => {
        setGdprStatus(!gdprStatus)
        setIsLoading(true)
        let formData = {
            domain: domain,
            enable_gdp: !isGdp,
            is_custom: 1
        }


        try {
            const { data } = await server.post(`domain/setGdpStatus`,
                formData,
            );
            console.log(data);
            setIsLoading(false)
            if (data?.status) {
                toast(data?.message, 'success')
                // setUpdateFloatHostingDomain(!updateFloatHostingDomain)
                setUpdateUi(!updateUi)
                // setupdatUi(!updatUi);

            }

        } catch (e) {
            setIsLoading(false)

            if (e.response?.data?.status === false) {
                if (e.response?.data.errors_array?.[0]) {
                    toast(e.response?.data.errors_array?.[0], 'error')
                    // setUpdateFloatHostingDomain(!updateFloatHostingDomain)
                    //  setActiveTab(2)
                }
                else {
                    toast(e.response?.data?.message, 'error')

                }
                //  setError(e.response?.data.errors?.title[0])
            }
            else {

            }
        }


    }


    return (
        <>
            {isLoading &&
                (
                    <div className={'loaderPageEditor domainloader'}>

                        <Loader />
                    </div>

                )}





            {/* {domain.length >= 1 && ( */}
            <div className={`row`}>
                <div className={`col-md-12 col-12`}>
                    <div className={`row`}>
                        <div className={`col-md-6 col-12 ps-5`}>
                            <HeadTitle title="ALL DOMAINS" />
                        </div>
                        {activeTab == '1' && domain.length >= 1 && (
                            <div className={`col-md-6 col-12 d-flex justify-content-end`}>
                                <button type="button" className="btn primary-btn-modal"
                                    onClick={() => {
                                        router(`/pages/domain/setup`)
                                    }}>
                                    Add Domain
                                </button>
                            </div>
                        )}



                        {activeTab == '2' && floatHostingDomain?.length >= 1 && (
                            <div className={`col-md-6 col-12 d-flex justify-content-end`}>
                                <div className={'d-flex justify-content-end'}>
                                    <button type="button" className="btn primary-btn-modal"
                                        id="TooltipInfo"
                                        onClick={getFloatHostingApiData}
                                    >
                                        <BiRefresh />

                                    </button>
                                    <Tooltip
                                        isOpen={tooltipOpen}
                                        target="TooltipInfo"
                                        toggle={toggleTol}
                                    >
                                        Refresh to get new FH domains
                                    </Tooltip>
                                </div>
                            </div>
                        )}




                    </div>
                    <div className={'tabsLayout mt-5'}>
                        <div className={`tabsNav`}>
                            <div className="container">
                                <Nav>
                                    <NavItem>
                                        <NavLink className={activeTab == '1' ? 'active' : ''}
                                            onClick={() => setActiveTab('1')}>
                                            Custom Domains
                                        </NavLink>
                                    </NavItem>

                                    <NavItem>
                                        <NavLink className={activeTab == '2' ? 'active' : ''}
                                            onClick={() => setActiveTab('2')}>
                                            Float Hosting Domains
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </div>
                        </div>
                        <div className="container pb-5 mb-5 domainsinfo">
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <div className={`tableSet my-5`}>
                                        {domain.length >= 1 && (
                                            <Table className={'domainList'}
                                                responsive
                                                size=""
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            Domain
                                                        </th>
                                                        <th>
                                                            GDPR
                                                        </th>
                                                        <th colSpan={6}>
                                                            Status
                                                        </th>

                                                    </tr>
                                                </thead>
                                                <tbody>


                                                    {

                                                        domain?.map((d, index) => {

                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        {d?.domain}
                                                                    </td>
                                                                    <td>
                                                                        <Form className={'pt-3'}>
                                                                            <FormGroup switch>

                                                                                < Input
                                                                                    //   disabled={d?.domain_status==0}
                                                                                    disabled={d?.domain_status == 0}

                                                                                    type="switch"
                                                                                    checked={d?.is_gdp}
                                                                                    onClick={() => enableGdpr(d?.domain, d?.is_gdp)}
                                                                                />
                                                                                <Label check></Label>
                                                                            </FormGroup>
                                                                        </Form>
                                                                    </td>




                                                                    <td>
                                                                        {d?.a_record?.status == 'completed' && (<img src={CheckMark} />)}
                                                                        {d?.a_record?.status == 'pending' && (<img src={ContinueProcess} />)}
                                                                        {d?.a_record?.status == 'failed' && (<img src={StopWarning} />)}


                                                                        A Name

                                                                    </td>
                                                                    <td>

                                                                    </td>
                                                                    <td>
                                                                        {d?.setup_domain_config?.status == 'completed' && (<img src={CheckMark} />)}
                                                                        {d?.setup_domain_config?.status == 'pending' && (<img src={ContinueProcess} />)}
                                                                        {d?.setup_domain_config?.status == 'failed' && (<img src={StopWarning} />)}

                                                                        Default Page

                                                                    </td>
                                                                    <td >
                                                                        {d?.ssl_verification?.status == 'completed' && (<img src={CheckMark} />)}
                                                                        {d?.ssl_verification?.status == 'pending' && (<img src={ContinueProcess} />)}
                                                                        {d?.ssl_verification?.status == 'failed' && (<img src={StopWarning} />)}

                                                                        SSL Certificate



                                                                    </td>

                                                                    <td>



                                                                        {d?.domain_status == 1 && (
                                                                            <button className={'border-0 background-none'}
                                                                                onClick={() => window.open(`https://${d?.domain}`, "_blank")}>
                                                                                <FiLink />
                                                                            </button>
                                                                        )}


                                                                        <button className={'border-0 background-none'} onClick={() => showDomainDisconnectModal(d?.domain)}
                                                                            id={`btn-${d?.domain}`}

                                                                        >
                                                                            <BsFillTrashFill className={'text-danger'} />
                                                                        </button>
                                                                        {/* <Tooltip
                                                                        // id={d?.domain}
                                                                        // itemID
                                                                            isOpen={tooltipDisOpen}
                                                                            target={`btn-${d?.id}`}

                                                                            // target="Tooltipdis"
                                                                            toggle={toggleTolDis}
                                                                        >
                                                                            This will remove your domain permanently.
                                                                        </Tooltip> */}


                                                                        <ReactTooltip
                                                                            anchorId={`btn-${d?.domain}`}
                                                                            place="top"
                                                                            content="This will remove your domain permanently."
                                                                        />

                                                                        <button className={'border-0 background-none'}
                                                                            onClick={() => {

                                                                                if (d?.retry?.status == "failed") {

                                                                                    router(`/pages/domain/setup/step2`,
                                                                                        {
                                                                                            state: {
                                                                                                selectedDomain: d,
                                                                                                is_retry: true,
                                                                                                domain_type: 0

                                                                                            },
                                                                                        })
                                                                                }
                                                                                else if (d?.domain_status == 1) {

                                                                                    router(`/pages/domain/${d?.domain}`)
                                                                                }


                                                                                else {
                                                                                    router(`/pages/domain/setup/${d?.domain}`)
                                                                                    // navigateParam(`/pages/domain/setup/${d?.domain}`, `step=1`);

                                                                                }
                                                                            }}
                                                                        >
                                                                            <AiOutlineRight />
                                                                        </button>
                                                                    </td>

                                                                </tr>

                                                            )
                                                        })}

                                                </tbody>
                                            </Table>


                                        )}


                                        {!domain.length == true && (
                                            <>
                                                <div className="mt-3 mb-5 emptyState d-block flex-column mx-auto text-center">
                                                    <img
                                                        alt="Empty! no page found"
                                                        src={EmptyImg}
                                                        width="250"
                                                    />
                                                    <h2 className="text-center mt-4">

                                                        You have not configured any domains yet

                                                    </h2>
                                                    <p className="mx-auto">Please click the button below to add a domain. Don't have a domain? You can use our subdomain.</p>
                                                    <button type="button" className="btn primary-btn-modal"
                                                        onClick={() => {
                                                            router(`/pages/domain/setup`)
                                                        }}>
                                                        Add Domain

                                                    </button>

                                                </div>
                                            </>
                                        )}



                                    </div>
                                </TabPane>
                                <TabPane tabId="2">




                                    <div className={`tableSet my-5`}>


                                        {floatHostingDomain.length >= 1 && (
                                            <Table className={'domainList'}
                                                responsive
                                                size=""
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            Domain
                                                        </th>

                                                        <th>
                                                            GDPR
                                                        </th>

                                                        {/* <th> Status</th> */}
                                                        <th colSpan={3}>
                                                            Status
                                                        </th>
                                                        <th >
                                                            Support Request Status
                                                        </th>

                                                        <th className={'text-right'}>
                                                            Action
                                                        </th>

                                                    </tr>
                                                </thead>


                                                <tbody>
                                                    {

                                                        floatHostingDomain?.map((d, index) => {
                                                            return (
                                                                <tr>
                                                                    <td> {d?.domain_name} </td>
                                                                    <td>
                                                                        <Form className={'pt-3'}>
                                                                            <FormGroup switch>

                                                                                < Input
                                                                                    disabled={d?.domain_status == 0}
                                                                                    type="switch"
                                                                                    checked={d?.is_gdp}
                                                                                    onClick={() => enableGdpr(d?.domain_name, d?.is_gdp)}
                                                                                />
                                                                                <Label check></Label>
                                                                            </FormGroup>
                                                                        </Form>
                                                                    </td>


                                                                    {/* <td>
                                                    <Badge color="primary">
                                                        Pending
                                                    </Badge> </td> */}

                                                                    <td>
                                                                        {d?.a_record?.status == 'completed' && (<img src={CheckMark} />)}
                                                                        {d?.a_record?.status == 'pending' && (<img src={ContinueProcess} />)}
                                                                        {d?.a_record?.status == 'failed' && (<img src={StopWarning} />)}


                                                                        A Name

                                                                    </td>

                                                                    <td>
                                                                        {d?.setup_domain_config?.status == 'completed' && (<img src={CheckMark} />)}
                                                                        {d?.setup_domain_config?.status == 'pending' && (<img src={ContinueProcess} />)}
                                                                        {d?.setup_domain_config?.status == 'failed' && (<img src={StopWarning} />)}

                                                                        Default Page

                                                                    </td>

                                                                    <td >
                                                                        {d?.ssl_verification?.status == 'completed' && (<img src={CheckMark} />)}
                                                                        {d?.ssl_verification?.status == 'pending' && (<img src={ContinueProcess} />)}
                                                                        {d?.ssl_verification?.status == 'failed' && (<img src={StopWarning} />)}

                                                                        SSL Certificate



                                                                    </td>


                                                                    <td> {d?.support_ticket_status}</td>
                                                                    <td>

                                                                        {(d?.domain_status == 1 || d?.domain_status == 2) && d?.is_a_record == true && (
                                                                            <>
                                                                                <button className={'border-0 background-none'} onClick={() => showDomainDisconnectModal(d?.domain_name)}
                                                                                    id={`btn-${d?.domain_name}`}>
                                                                                    {/*<IoMdRemoveCircleOutline size={80} className={'text-danger'} />*/}
                                                                                    <BsFillTrashFill className={'text-danger'} />
                                                                                </button>
                                                                                {/* <Tooltip
                                                                                    isOpen={tooltipDisOpen}
                                                                                    target="Tooltipdis"
                                                                                    toggle={toggleTolDis}
                                                                                >
                                                                                    This will remove your domain permanently.
                                                                                </Tooltip> */}


                                                                                <ReactTooltip
                                                                                    anchorId={`btn-${d?.domain_name}`}
                                                                                    place="top"
                                                                                    content="This will remove your domain permanently."
                                                                                />

                                                                            </>
                                                                        )}



                                                                        {d?.is_a_record == false && d?.is_support_ticket == 0 && (
                                                                            <>
                                                                                <button
                                                                                    id="generateTicket"
                                                                                    onClick={() => {


                                                                                        showModal(true, d)
                                                                                    }}

                                                                                    className={'btn btn-table-connect'}>  Generate Ticket </button>
                                                                                <Tooltip
                                                                                    isOpen={tooltipGTOpen}
                                                                                    target="generateTicket"
                                                                                    toggle={toggleTolGt}
                                                                                >
                                                                                    Generate a ticket to update A Records on FH

                                                                                </Tooltip>
                                                                            </>

                                                                        )}


                                                                        {d?.is_a_record == false && d?.is_support_ticket == 1 && (
                                                                            <>
                                                                                <button
                                                                                    id="ticketGenerated"
                                                                                    className={'btn btn-table-connect'}>  Ticket Generated
                                                                                </button>
                                                                                <Tooltip
                                                                                    isOpen={tooltipTGOpen}
                                                                                    target="ticketGenerated"
                                                                                    toggle={toggleTolTg}
                                                                                >
                                                                                    ok
                                                                                </Tooltip>

                                                                            </>

                                                                        )}

                                                                        {d?.is_a_record == true && d?.domain_status == 0 && (

                                                                            <button
                                                                                onClick={() => {

                                                                                    router(`/pages/domain/setup/step2`,
                                                                                        {
                                                                                            state: {
                                                                                                selectedDomain: d,
                                                                                                domain_type: 1
                                                                                            },
                                                                                        })
                                                                                }}

                                                                                className={'btn btn-table-connect'}> Connect </button>
                                                                        )}

                                                                        {(d?.domain_status == 1 || d?.domain_status == 2) && d?.is_a_record == true && (

                                                                            <button
                                                                                // onClick={() => {
                                                                                //         router(`/pages/domain/${d?.domain_name}`)
                                                                                // }}
                                                                                onClick={() => {

                                                                                    if (d?.retry?.status == "failed") {

                                                                                        router(`/pages/domain/setup/step2`,
                                                                                            {
                                                                                                state: {
                                                                                                    selectedDomain: d,
                                                                                                    is_retry: true,
                                                                                                    domain_type: 1

                                                                                                },
                                                                                            })
                                                                                    }
                                                                                    else if (d?.domain_status == 1) {

                                                                                        router(`/pages/domain/${d?.domain_name}`)
                                                                                    }


                                                                                    else {
                                                                                        router(`/pages/domain/setup/${d?.domain_name}`)
                                                                                        // navigateParam(`/pages/domain/setup/${d?.domain}`, `step=1`);

                                                                                    }


                                                                                }}

                                                                                className={'btn btn-table-connect'}>
                                                                                <AiOutlineRight />

                                                                            </button>
                                                                        )}



                                                                    </td>
                                                                </tr>

                                                            )
                                                        })}
                                                </tbody>
                                            </Table>
                                        )}
                                        {!floatHostingDomain.length == true && (

                                            <div className="emptyState d-block flex-column mx-auto text-center">
                                                <img
                                                    alt="Empty! no page found"
                                                    src={EmptyImg}
                                                    width="250"
                                                />
                                                <h2 className="text-center mt-5 mb-0"> You can check all available Float Hosting domains here</h2>

                                                <div className={`col-md-12 col-12 d-flex justify-content-center`}>
                                                    <div className={'d-flex justify-content-end'}>
                                                        <button type="button" className="btn primary-btn-modal my-5"
                                                            id="TooltipInfo"
                                                            onClick={getFloatHostingApiData}
                                                        >
                                                            <BiRefresh className={'me-2'} /> Fetch Domains

                                                        </button>
                                                        <Tooltip
                                                            isOpen={tooltipOpen}
                                                            target="TooltipInfo"
                                                            toggle={toggleTol}
                                                        >
                                                            Refresh to get new Float Hosting domains
                                                        </Tooltip>
                                                    </div>
                                                </div>


                                                {/* <p className="mx-auto">Please click the button below to connect your Float Hosting Domain in your Account </p> */}
                                                {/* <button type="button" className="btn primary-btn-modal"
                                                onClick={() => {
                                                    router(`/pages/domain/setup`, { state: '2' })
                                                }}>
                                                Float Hosting Domains

                                            </button> */}

                                            </div>
                                        )}


                                    </div>




                                    <Modal isOpen={workspacemodal} toggle={toggle} centered backdrop={'false'}>
                                        <ModalHeader toggle={toggle}>Request to update A Records on FH</ModalHeader>
                                        <ModalBody>
                                            <p className={'mb-3'}> {selectedDomain?.domain_name}	</p>

                                            <Input
                                                autoFocus
                                                className='' type={'textarea'}
                                                placeholder={'Enter your query'}
                                                onChange={characterLimit}
                                                value={ticketNote}
                                                onKeyPress={event => {
                                                    if (event.key === 'Enter') {
                                                        toggleNested
                                                    }
                                                }}
                                            //   maxlength="15"
                                            />
                                            {error && (<p className={'errorMessage'}> {error} </p>)}

                                            {/* <p className={'errorMessage'}> cccccccccccc </p> */}

                                            {/* <small className={'helpingtext'}> The workspace will be used as a subdomain for your organization or a team.
                                            <strong> acmestore</strong>.floatfunnel.com</small> */}





                                            <Modal
                                                isOpen={nestedModal}
                                                toggle={toggleNested}
                                                onClosed={closeAll ? toggle : undefined}
                                            >
                                                <ModalHeader>Are you sure you want to update A Record</ModalHeader>
                                                <ModalBody>Please Note: Once request generated this will remove your previous A Record settings</ModalBody>
                                                <ModalFooter>
                                                    <Button className={'primary-btn-modal shadow-none'} onClick={generateTicket} >
                                                        {isLoadingTicket ?
                                                            <span className={'spinnerContainer'}>
                                                                <Spinner>
                                                                    Loading...
                                                                </Spinner></span> : 'OK'}
                                                    </Button>

                                                    <Button color="secondary" onClick={toggleAll}>
                                                        Cancel
                                                    </Button>
                                                </ModalFooter>
                                            </Modal>


                                        </ModalBody>
                                        <ModalFooter>


                                            <Button className={'primary-btn-modal shadow-none'} onClick={toggleNested} >
                                                {/* {isLoadingTicket ? */}
                                                {/* <span className={'spinnerContainer'}> */}
                                                {/* <Spinner> */}
                                                Generate
                                                {/* </Spinner></span> : 'Generate'} */}
                                            </Button>

                                            <Button className={'secondary-btn-modal mx-2 shadow-none'} onClick={toggle}>
                                                Cancel
                                            </Button>
                                        </ModalFooter>
                                    </Modal>

                                    <Modal isOpen={ddmodal} toggle={showDdModal} centered backdrop={'false'}>
                                        <ModalHeader toggle={showDdModal}>Are you sure you want to remove this domain? </ModalHeader>
                                        <ModalBody>
                                            {/* <p className={'mb-2'}> You want to remove  this domain from Float Funnels. </p> */}
                                            <p>  <strong> Note: </strong> All pages published with this domain will no longer be accessible. </p>

                                        </ModalBody>
                                        <ModalFooter>


                                            <Button className={'btn-danger shadow-none spinner-btn'} onClick={disconnectDomain} >
                                                {isLoadingTicket ?
                                                    <span className={'spinnerContainer'}>
                                                        <Spinner>

                                                        </Spinner></span> : 'Confirm'}
                                            </Button>


                                            {/* <Button className={'primary-btn-modal shadow-none'}
                                            onClick={disconnectDomain}
                                            >

                                                Confirm

                                            </Button> */}

                                            <Button className={'secondary-btn-modal mx-2 shadow-none'} onClick={showDdModal}>
                                                Cancel
                                            </Button>
                                        </ModalFooter>
                                    </Modal>

                                </TabPane>
                            </TabContent>
                        </div>

                    </div>








                </div>
            </div>
            {/* )} */}


            {/* {
                !domain.length == true && (
                    <>
                        <div className="mt-3 mb-5 emptyState d-block flex-column mx-auto text-center">
                            <img
                                alt="Empty! no page found"
                                src={EmptyImg}
                                width="250"
                            />
                            <h2 className="text-center mt-4"> You have not configured any domain yet  </h2>
                            <p className="mx-auto">Please click the button below to configure your domain. You can use our subdomain if you don’t have any domain yet. </p>
                             <button type="button" className="btn primary-btn-modal"
                                onClick={() => {
                                    router(`/pages/domain/setup`)
                                }}>
                                Add Domain

                            </button>

                        </div>

                        <div className="or"> OR</div>

                        <div className="emptyState d-block flex-column mx-auto text-center">

                            <h2 className="text-center mt-5"> You can check all available Float Hosting Domains </h2>
                            <p className="mx-auto">Please click the button below to connect your Float Hosting Domain in your Account </p>
                            <button type="button" className="btn primary-btn-modal"
                                onClick={() => {
                                    router(`/pages/domain/setup`, { state: '2' } )
                                }}>
                                Float Hosting Domains

                            </button>

                        </div>



                    </>
                )} */}

        </>
    );
}

export default List;