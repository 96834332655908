import React, {useState} from 'react'
import InternalTitle from "../common/internalTittle/GenaricButton";
import {Badge, Button, List, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from 'reactstrap';
import server from "../../apis/server";
import toast from "../../utils/toast";
import {useNavigate} from "react-router-dom";


function SubscriptionPlan({subscriptionPlan, updatePlan, isLoadingPlan}) {
    const [isLoading, setIsLoading] = useState(false);
    const [pauseModal, setPauseModal] = useState(false);
    const [unPauseModal, setUnPauseModal] = useState(false);
    const [changePlanModal, setChangePlanModal] = useState(false);
    const [cancelPauseModal, setCancelPauseModal] = useState(false);


    const [cancelModal, setcancelModal] = useState(false);
    const [unCancelModal, setunCancelModal] = useState(false);

    const [pauseLinkButton, setPauseLinkButton] = useState("Pause Plan");
    const accountsString = localStorage.getItem('accounts')
    const accounts = accountsString ? JSON.parse(accountsString) : ''
    console.log('accounts', accounts)
    const {user_id} = accounts

    const router = useNavigate()

    const unPauseToggle = () => setUnPauseModal(!unPauseModal);
    const changePlanToogle = () => setChangePlanModal(!changePlanModal);

    const cancelPauseToogle = () => setCancelPauseModal(!cancelPauseModal);

    const pauseToggle = () => setPauseModal(!pauseModal);
    const cancelToggle = () => setcancelModal(!cancelModal);
    const unCancelToggle = () => setunCancelModal(!unCancelModal);

    const subscriptionTypeTrial = 'trial';


    const pausePlanLink = () => {
        if (subscriptionPlan?.is_pause_plan === false) {
            alert('hello World');
        } else {
            alert('pause plan is activated alredy');
        }
    }

    const pauseButtonToggle = () => {
        if (subscriptionPlan?.is_pause_plan === true) {
            alert('hello World');
            setPauseLinkButton('Pause Plan Now')
        } else {
            alert('pause plan is activated already');
            setPauseLinkButton('Pause Plan Scheduled')
        }
    }

    const getCancelSubscription = async () => {

        try {

            setIsLoading(true)
            let formData = {
                user_id
            }

            const {data} = await server.post(`billing/cancelSubscription`, formData);
            if (data?.status) {
                cancelToggle()

                toast(data?.message, 'success')
                updatePlan(true)

                // localStorage.clear()

                // alert('hello World')
                setIsLoading(false);
                // router(`/login`)

            }
            console.log(data)


        } catch (e) {
            cancelToggle()

            if (e.response?.data?.status === false) {
                if (e.response?.data.errors_array?.[0]) {
                    toast(e.response?.data.errors_array?.[0], 'error')
                } else {
                    toast(e.response?.data?.message, 'error')

                }
                setIsLoading(false)
            } else {
                setIsLoading(false)

            }


        }
    }


    const cancelPausePlan = async () => {

        try {

            setIsLoading(true)
            let formData = {
                user_id
            }

            const {data} = await server.post(`billing/cancelPausePlan`, formData);
            if (data?.status) {
                cancelPauseToogle()
                toast(data?.message, 'success')
                updatePlan(true)

                // localStorage.clear()

                // alert('hello World')
                setIsLoading(false);
                // router(`/login`)

            }
            console.log(data)


        } catch (e) {
            cancelPauseToogle()
            if (e.response?.data?.status === false) {
                if (e.response?.data.errors_array?.[0]) {
                    toast(e.response?.data.errors_array?.[0], 'error')
                } else {
                    toast(e.response?.data?.message, 'error')

                }
                setIsLoading(false)
            } else {
                setIsLoading(false)

            }


        }
    }


    return (

        <div>
            <div className={'row'}>
                <div className={'col-md-12 mt-5'}>
                    <InternalTitle title={'Subscription Details'}/>
                    <div className="plan_subscription mt-4">
                        <div className="d-flex justify-content-between">
                            <div className={'heading_name d-flex'}>
                                <h5 className={'text-capitalize'}> {subscriptionPlan?.subscription_type}</h5>


                                {subscriptionPlan?.is_cancelled && !subscriptionPlan?.is_pause_plan ? (

                                    <Badge pill
                                           outline
                                           className={'dangerColor'}
                                    >
                                        Cancelled
                                    </Badge>
                                ) : (subscriptionPlan?.is_pause_plan && subscriptionPlan?.pause_plan_schedule_date) || subscriptionPlan?.is_active_pp ? (

                                    <Badge pill
                                           outline
                                           className={'warningColor'}
                                    >
                                        Paused
                                    </Badge>
                                ) : (

                                    <Badge pill
                                           outline
                                    >
                                        {subscriptionPlan?.subscription_status}
                                    </Badge>
                                )}


                            </div>

                            <div className="action_btn">

                                {subscriptionPlan?.pp_url == null && subscriptionPlan?.is_pause_plan == false && subscriptionPlan?.subscription_package_type.period_short == 'yr'
                                    ? (
                                        <> </>
                                    ) : (
                                        !subscriptionPlan?.pause_plan_schedule_date && !subscriptionPlan?.change_plan_schedule_date
                                            ? (
                                                <button
                                                    disabled={!subscriptionPlan?.pp_url}
                                                    type="button"
                                                    className={`shadow-none btn ${!subscriptionPlan?.pp_url ? 'btn-light' : 'btn-warning'}`}
                                                    onClick={pauseToggle}
                                                >
                                                    Pause Plan
                                                </button>
                                            ) : null
                                    )
                                }


                                {subscriptionPlan?.pp_url == null && subscriptionPlan?.is_pause_plan == false && subscriptionPlan?.subscription_package_type.period_short == 'yr'
                                    ? (
                                        <> </>
                                    ) : (
                                        subscriptionPlan?.pause_plan_schedule_date
                                            ? (
                                                <button
                                                    // disabled={!subscriptionPlan?.pp_url}
                                                    type="button"
                                                    className="btn btn-warning shadow-none btn btn-secondary"
                                                    onClick={cancelPauseToogle}
                                                >
                                                    Cancel Pause Schedule
                                                </button>
                                            ) : null
                                    )
                                }


                                <Modal isOpen={cancelPauseModal} toggle={cancelPauseToogle} size={'lg'} centered>
                                    <ModalHeader toggle={cancelPauseToogle}> Cancel Pause Schedule </ModalHeader>
                                    <ModalBody>

                                        <h6><strong> Attention: </strong></h6>
                                        <h6> You are moving back to your previous plan.</h6>


                                    </ModalBody>
                                    <ModalFooter>
                                        <Button className={'primary-btn-modal shadow-none btn btn-secondary'}
                                                onClick={cancelPausePlan}
                                        >

                                            {isLoading ?
                                                <span className={'spinnerContainer'}>
                                                    <Spinner>
                                                        Loading...
                                                    </Spinner></span> : 'Confirm'}

                                        </Button>
                                        <Button className={'secondary-btn-modal shadow-none btn btn-secondary'}
                                                onClick={cancelPauseToogle}>
                                            Cancel
                                        </Button>
                                    </ModalFooter>
                                </Modal>


                                <Modal isOpen={pauseModal} toggle={pauseToggle} size={'lg'} centered>
                                    <ModalHeader toggle={pauseToggle}>Pause Plan: Terms and Condition </ModalHeader>
                                    <ModalBody>

                                        <List className={'m-0 ps-3 pb-4'}>
                                            <li>
                                                While your plan subscription is paused, you can't use page(s). However,
                                                you will be still able to access the My Settings module.
                                            </li>
                                            <li>
                                                During the pause of your plan, you will be charged <span
                                                className={'text-success'}>  $11 </span> per month.
                                            </li>
                                            <li>
                                                Pause Plan will be applicable from the next billing date, which is <span
                                                className={'text-success'}> {subscriptionPlan?.subscription_next_billing_date} </span>.
                                                {/* and you can cancel your scheduled pause before <span className={'text-success'}> {subscriptionPlan?.subscription_next_billing_date}</span> */}
                                            </li>
                                            <li>
                                                After the successful payment for the Pause Plan you can resume your plan
                                                at any time.
                                            </li>
                                            <li>
                                                Upon resuming the plan no proration will be credited to your account.
                                            </li>
                                        </List>
                                        To pause your plan, click the continue button below and complete the steps.
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button className={'primary-btn-modal shadow-none btn btn-secondary'}
                                                onClick={async () => {
                                                    //   await  localStorage.clear()
                                                    location.href = subscriptionPlan?.pp_url

                                                }}
                                        >
                                            Continue
                                        </Button>
                                        <Button className={'secondary-btn-modal shadow-none btn btn-secondary'}
                                                onClick={pauseToggle}>
                                            Cancel
                                        </Button>
                                    </ModalFooter>
                                </Modal>
                                {/* <button type="button"
                                        className="primary-btn-modal shadow-none btn btn-secondary ms-3">Change
                                    Plan
                                </button> */}


                                {subscriptionPlan?.is_active_pp && (
                                    <Button className={"btn btn-primary shadow-none ms-3"} onClick={unPauseToggle}>
                                        Resume Plan
                                    </Button>
                                )}

                                {subscriptionPlan?.account_status == "active" && subscriptionPlan?.subscription_status == "active" && subscriptionPlan?.is_active_pp == false && subscriptionPlan?.change_plan_schedule_date == null && subscriptionPlan?.pause_plan_schedule_date == null && (
                                    <Button className={"btn btn-primary shadow-none ms-3"} onClick={changePlanToogle}>
                                        Change Plan
                                    </Button>
                                )}

                                <Modal isOpen={changePlanModal} toggle={changePlanToogle} size={'lg'} centered>
                                    <ModalHeader toggle={changePlanToogle}>Change Plan </ModalHeader>
                                    <ModalBody>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <h6><strong> Attention: </strong> <br/> Are you sure you want to move
                                                    from your current  <strong
                                                        className="text-primary"> {subscriptionPlan?.subscription_package_name}</strong> ?
                                                </h6>
                                            </div>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button
                                            onClick={async () => {
                                                router(`/billing`,
                                                    {
                                                        state: {
                                                            re_active_plan: 'change_plan',


                                                        },
                                                    }
                                                )
                                            }}

                                            className={'primary-btn-modal shadow-none btn btn-secondary'}
                                        >
                                            Continue
                                        </Button>
                                        <Button className={'secondary-btn-modal shadow-none btn btn-secondary'}
                                                onClick={changePlanToogle}>
                                            Cancel
                                        </Button>
                                    </ModalFooter>
                                </Modal>


                                <Modal isOpen={unPauseModal} toggle={unPauseToggle} size={'lg'} centered>
                                    <ModalHeader toggle={unPauseToggle}>Resume Plan </ModalHeader>
                                    <ModalBody>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <h6 className='fw-bold mt-2'> Unpause Subscription: Terms and
                                                    Conditions</h6>
                                                <ul>
                                                    <li> Your subscription will be reactivated, granting your account
                                                        full access to all Float Funnel features.
                                                    </li>
                                                    <li> Your account plan will be returned to its original state.</li>
                                                    <li> To reactivate your subscription and revert back to the plan
                                                        before it was paused, please click the "Continue" button below
                                                        and follow the provided steps.
                                                    </li>
                                                    <li> Kindly be aware that upon resuming the plan, there will be no
                                                        pro-ration credited to your account.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button
                                            onClick={async () => {
                                                router(`/billing`,
                                                    {
                                                        state: {
                                                            re_active_plan: 'pause',


                                                        },
                                                    }
                                                )
                                            }}

                                            className={'primary-btn-modal shadow-none btn btn-secondary'}
                                        >
                                            Continue
                                        </Button>
                                        <Button className={'secondary-btn-modal shadow-none btn btn-secondary'}
                                                onClick={unPauseToggle}>
                                            Cancel
                                        </Button>
                                    </ModalFooter>
                                </Modal>


                                <button type="button"
                                        disabled={subscriptionPlan?.is_cancelled}
                                        className="btn shadow-none btn btn-danger float-end ms-3"
                                        onClick={cancelToggle}> Cancel
                                    Subscription
                                </button>


                                {/* {subscriptionPlan?.account_status == "active" && subscriptionPlan?.subscription_status == "cancel" && (
                                <button type="button"
                                    disabled={subscriptionPlan?.is_cancelled}
                                    className="secondary-btn-modal shadow-none btn btn-secondary float-end ms-3"
                                    onClick={cancelToggle}> 
                                              
                                </button>
                               )} */}


                                <Modal isOpen={cancelModal} toggle={cancelToggle} size={'lg'} centered>
                                    <ModalHeader toggle={cancelToggle}>Cancel Subscription </ModalHeader>
                                    <ModalBody>
                                        <p className={'mb-3'}>Before you proceed, please review your pages to make sure you
                                            don't lose any important details
                                            or receive unexpected changes.</p>
                                        <p className={'mb-3 bold'}> Terms and Conditions:</p>

                                        <List className={'m-0 ps-3 pb-4'}>

                                            <li>
                                                Float Funnels doesn't provide refunds once you cancel your subscription.
                                                You will be able
                                                to access your store till the end of this billing cycle.
                                            </li>
                                            <li>
                                                Upon re-activations Float Funnels will charge full amount for the
                                                selected plan and no
                                                adjustments from the previous billing cycle will be done.
                                            </li>
                                            <li>
                                                Once the subscription is cancelled, the user will be provided with a
                                                time period of 30 days to re-activate the
                                                subscription and once this time period passes, the user subscription
                                                along with his pages will be deleted permanently.
                                            </li>
                                        </List>

                                    </ModalBody>
                                    <ModalFooter>
                                        <Button className={'shadow-none btn btn-danger'}
                                                onClick={getCancelSubscription}
                                        >
                                            {isLoading ?
                                                <span className={'spinnerContainer'}>
                                                    <Spinner>
                                                        Loading...
                                                    </Spinner></span> : 'Confirm'}

                                        </Button>
                                        <Button className={'secondary-btn-modal shadow-none btn btn-secondary'}
                                                onClick={cancelToggle}>
                                            Cancel
                                        </Button>
                                    </ModalFooter>
                                </Modal>
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col-md-2 mt-5'}>
                                <h6> Plan</h6>
                                <p className={'text-capitalize'}>{subscriptionPlan?.subscription_package_name} </p>
                            </div>
                            <div className={'col-md-2 mt-5'}>
                                <h6> COST</h6>
                                {/*<p className={'bold'}> $ {subscriptionPlan?.subscription_plan_price_paid}/{subscriptionPlan?.subscription_package_type?.period_short} </p>*/}
                                <p className={'bold'}> $ {subscriptionPlan?.subscription_plan_price_paid} </p>
                            </div>
                            <div className={'col-md-2 mt-5'}>
                                <h6> ACTIVATED ON</h6>
                                <p>{subscriptionPlan?.subscription_start_date}</p>
                            </div>
                            <div className={'col-md-2 mt-5'}>
                                <h6> Next Billing Date</h6>
                                <p>   {subscriptionPlan?.subscription_next_billing_date ? subscriptionPlan?.subscription_next_billing_date : '------'}  </p>

                            </div>


                            {/* active compaign  */}
                            <div className={'col-md-2 mt-5'}>
                                <h6> Subscription Type</h6>
                                {/*<p>Aug 29, 2022 </p>*/}
                                <p className={'text-capitalize'}>   {subscriptionPlan?.subscription_type}  </p>

                            </div>


                            {/*/!* active compaign  *!/*/}
                            {/*<div className={'col-md-2 mt-5'}>*/}
                            {/*    <h6> Remaining Days</h6>*/}
                            {/*    /!*<p>Aug 29, 2022 </p>*!/*/}
                            {/*    <p className={'text-capitalize'}>   {subscriptionPlan?.subscription_remaining_days}  </p>*/}

                            {/*</div>*/}


                            {subscriptionPlan?.subscription_type === 'trial' && (
                                <div className={'col-md-2 mt-5'}>
                                    <h6>Remaining Days</h6>
                                    <p className={'text-capitalize'}>{subscriptionPlan?.subscription_remaining_days}</p>
                                </div>
                            ) }


                        </div>
                    </div>

                </div>
            </div>

        </div>
    );

}


export default SubscriptionPlan;