import React,{useState,useEffect,useMemo} from "react";
import Logo from "../../../images/logoinverse.png"
import { Button, List, Badge,Spinner} from "reactstrap";
import { BsCheckCircle } from "react-icons/bs";
import server from "../../../apis/server"
import Loader from "../../../components/common/loader/loader";
import { useStoreState, useStoreActions } from "easy-peasy"


    const PricingModule = React.memo(function PricingModule({ validCoupon ,couponData,state}) {

        const AccountState = useStoreState((state) => state?.accountState?.account?.payload)

        const [accounts, setAccounts] = useState({
    
            package_type :''
        });
    

    const [isLoading, setIsLoading] = useState(false);
    const [packages, setpackages] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState('');
    const [isLoadingPackage, setisLoadingPackage] = useState(new Set());
    const [isLoadingYearlyPackages, setIsLoadingYearlyPackages] = useState(false);

    

    // const isValid = (validCoupon) => {
        
    //     return validCoupon;
    //   };
      

    // const IsValidCoupon = useMemo(() => isValid(validCoupon), [validCoupon]);
       

//alert(validCoupon)
    useEffect(() => {

        getPackages()
    }, [])

    useEffect(() => {

        setAccounts(AccountState)

        //  console.warn('accounts cancelled',account)
        //  alert('cc')
    }, [AccountState])


    useEffect(() => {

        if(validCoupon){
        setpackages(couponData?.package_plans)
        }
    }, [couponData,validCoupon])


    const getPackages = async () => {

        try {
            setIsLoading(true)
            
            const formdata = {
                re_activate_plan : state?.re_active_plan

            }
                    const {data} = await server.post(`getPackages`, state ? formdata : null

                );
            if (data?.status) {
                setpackages(data?.data)
                setIsLoading(false); 
            }
            console.log(data)
            // setProduct(data);
            // setLoading(false);

        } catch (e) {
            if (e.response?.data?.status === false) {
                console.log(e.response?.data.errors?.title[0])
                // setError(e.response?.data.errors?.title[0])
                setIsLoading(true);
            } else {
                // setError(e.message)
            }


        }
    }


    async function selectPackages(productId,selectedIndex)
    {
        const accountsString =   localStorage.getItem('accounts')
        const accounts =    accountsString ? JSON.parse(accountsString) : ''
        console.log('accounts',accounts)
        const {user_id,secure_key}  =   accounts

        setisLoadingPackage((prev) => new Set([...prev, selectedIndex]));

          
        // alert(productId)
          
        let formData={
            user_id: user_id,
            product_id: productId,
            coupon : couponData?.coupon,
            secure_key,
            re_activate_plan : state?.re_active_plan
        } 
          try {
            const { data } = await server.post(`choosePlan`,
                formData,
             );

            if(data?.status){
            window.location.replace(data?.data?.redirect_url);


            }
           console.log(data)
           setisLoadingPackage((prev) => {
            const updated = new Set(prev);
            updated.delete(selectedIndex);
            return updated;
          });
            // setProduct(data);
        } catch (e) {
            
                 if(e.response?.data?.status===false){
                    //  toast(e.response?.data?.message, 'error')

                    //  setError(e.response?.data.errors?.title[0])
                    setisLoadingPackage((prev) => {
                        const updated = new Set(prev);
                        updated.delete(selectedIndex);
                        return updated;
                      });
                }
                 else{
                    setisLoadingPackage((prev) => {
                        const updated = new Set(prev);
                        updated.delete(selectedIndex);
                        return updated;
                      });
            
                 }
            //console.log(e.response);

        }
    }

    function percentage(num, per)
      {
         return num * (100 - per) / 100;
       }
    const calculateDiscount = (amount) =>{
        const {discount,discount_type} = couponData
        if(!validCoupon){
            return amount
        }

        if(couponData){
            if(discount_type==='prc'){
              let discountedAmount =  percentage(amount, discount) 
               return discountedAmount?.toFixed(2)
            }
           else if(discount_type==='amt'){
                let discountedAmount =  amount - discount 
                 return discountedAmount?.toFixed(2)
              }
        }

    //    return amount
    }

    
    return (

        <>
            <div className={'feature-wrapper'}>

            {packages?.map((x, index) => {
                                return (
                <div className={`pricing-feature ${selectedPlan =='monthly' ? 'user-select' : ''}`}>
                    <div className={'head-price'}>
                        <h3>{x?.package_name}</h3>
                         <h1 className={validCoupon ? 'old-price':''}>
                             {/*${x?.second_price}*/}


                             {!x?.is_bpa ? (
                                 <h1> {validCoupon && calculateDiscount(x?.second_price)== x?.second_price ?'':   ` ${ x?.second_price}`} </h1>

) : (
                                 <h1>$ 0.00</h1>
                             )}
                             </h1>


                            
                            { !x?.is_bpa  && validCoupon && (
                             <h1>
                                    $ {   calculateDiscount(x?.second_price)}
                             </h1>
                            )}
                        <List type="unstyled">
                            <li>
                                <BsCheckCircle/> {x?.first_period}
                            </li>
                             <li>
                                <BsCheckCircle/> Unlimited Pages
                            </li>
                            <li>
                                <BsCheckCircle/> Unlimited Domains
                            </li>
                            <li>
                                <BsCheckCircle/> Battleship Plan <Badge
                                color="primary"
                                pill
                            >
                                Free
                            </Badge>
                            </li>
                            <li className={'text-muted'}>
                                Float Hosting battleship plan included. <a href={'https://floathosting.com/'} target={'_blankx'} className={'text-primary'}>View details </a>
                            </li>

                        </List>
                    </div>
                    <hr/>
                    <Button block   color="dark"
                            outline
                            disabled = {(x?.package_type  && x?.package_type == accounts?.package_type) && state?.re_active_plan == 'change_plan'}

                            onClick={()=>{ 
                                // setSelectedPlan('monthly')
                                selectPackages(x?.product_id,index)

                            }}
                            > 
                             { isLoadingPackage.has(index) ?
                         <span className={'spinnerContainer'}>
                          <Spinner>
                                    
                                </Spinner></span> : 'Select'}
                             </Button>

                </div>
                                )})}



                {/* <div className={`pricing-feature ${selectedPlan =='yearly' ? 'user-select' : ''}`}>
                    <div className={'head-price'}>
                        <h3>Yearly</h3>
                        <h1>$799.95</h1>
                        <Badge
                            color="info"
                            pill
                        >
                            Save $159.45
                        </Badge>
                        <List type="unstyled">
                            <li>
                                <BsCheckCircle/> 100 Days Free Trial
                            </li>
                            <li>
                                <BsCheckCircle/> Unlimited Pages
                            </li>
                            <li>
                                <BsCheckCircle/> Unlimited Domains
                            </li>
                            <li>
                                <BsCheckCircle/> Battleship Plan <Badge
                                color="primary"
                                pill
                            >
                                Free
                            </Badge>
                            </li>
                            <li className={'text-muted'}>
                                Float Hosting battleship plan included <a href={'#'} className={'text-primary'}>view details </a>
                            </li>

                        </List>
                    </div>
                    <hr/>
                    <Button block   color="dark"
                            outline
                            disabled = {!validCoupon}
                            onClick={()=>{

                                setSelectedPlan('yearly')
                                selectPackages(packages?.["trial-yearly"]?.product_id,'yearly')


                            }}
                            > 
                             {isLoadingYearlyPackages ?
                         <span className={'spinnerContainer'}>
                         <Spinner>
                                    
                                </Spinner></span> : 'Select'}
                             </Button>

                </div> */}
            </div>

        </>

    )
})

export default PricingModule;