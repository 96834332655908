import { action, computed, thunk } from "easy-peasy"

export const isLoading = {
    LoadingState: false,

  setLoader: action((state, payload) => {
    state.LoadingState = { payload }
  }),

}

export default isLoading;
