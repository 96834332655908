import React, {useEffect, useState} from "react";
import server from '../../../apis/server'
import {
    Button,
    Card,
    CardBody,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Spinner,
    UncontrolledButtonDropdown
} from 'reactstrap';
import {BiDotsVerticalRounded} from "react-icons/bi";
import {HiDocumentDuplicate} from "react-icons/hi";
import {FaRegFolder} from "react-icons/fa";
import {generatePath, Link, useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {CgNotes} from "react-icons/cg";
import Loader from "../../common/loader/loader";
import toast from '../../../utils/toast'
import InternalTitle from "../../common/internalTittle/GenaricButton";
import {FiFolderPlus} from "react-icons/fi";
import {BsEyeFill} from "react-icons/bs";
import EmptyImg from '../../../images/emptyPage.svg'
import dummyImg from '../../../images/tile0.png';
import {useStoreActions, useStoreState} from "easy-peasy"
import {IoTrashOutline} from "react-icons/io5";
import TimeAgo from "../../../utils/timeStamp/timeAgo";


function List({list}) {
    const funnelState = useStoreState((state) => state?.funnelState?.funnel?.payload)
    const FunnelAction = useStoreActions((actions) => actions?.funnelState?.setFunnel)
    let location = useLocation();
    const {search} = useLocation();
    let {funnelName} = useParams();
    //    alert(funnelName)
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingDelete, setIsLoadingDelete] = useState(false)

    const [pageUI, setInitialPageUi] = useState(false)
    const [movFunnel, setmovFunnel] = useState(false)
    const [creatFunnelLoader, setcreatFunnelLoader] = useState(false)
    const [editcreatFunnelLoader, seteditcreatFunnelLoader] = useState(false)
    const [duplicatepageLoader, setDuplicatePageLoader] = useState(false)

    const [param, setParam] = useState('')
    const [error, setError] = useState("")
    const [updatUi, setupdatUi] = useState(false)
    const [funnel, setFunnel] = useState([]);
    const [funnelList, setFunnelList] = useState([]);
    const [selectedOption, setSelectedOption] = useState("");
    const [selectedFunnelOption, setSelectedFunnelOption] = useState("");
    const accountsString = localStorage.getItem('accounts')
    const accounts = accountsString ? JSON.parse(accountsString) : ''
    console.log('acc', accounts)
    const {public_url} = accounts

    const router = useNavigate()
    const frontUrl = process.env.REACT_APP_FRONT_URL

    const useNavigateParams = () => {
        const navigate = useNavigate();

        return (url, params) => {
            const path = generatePath(":url?:queryString", {
                url,
                queryString: params
            });
            navigate(path);
        };
    };

    useEffect(() => {
        //    aler('CC')
        getFunnel()
        if (!funnelName) {
            setParam('')
            FunnelAction(null)

            if (!list) {
                getData()

            } else {
                getMainPageData()
            }

        }


        // return
    }, [selectedOption, updatUi, location, param]);

    useEffect(() => {
        if (error) {
            let timerFunc = setTimeout(() => {
                setError((false))
            }, 3000);

            return () => clearTimeout(timerFunc);
        }
    }, [error])

    useEffect(() => {
        // alert(funnelName)

        if ((!funnelState && funnelName)) {

            router(`/pages`)

            setParam('')
            FunnelAction(null)


        }


        if ((funnelState && funnelName)) {

            filterFunnelPages()


        }

        // return
    }, [selectedOption, param, funnelState, location, updatUi]);

    const onChangeSelection = (e) => {
        setSelectedOption(e.target.value);
    };

    const onChangeFunnelSelection = (e) => {
        setSelectedFunnelOption(e.target.value);
    };

    const filterFunnelPages = async () => {
        try {
            setIsLoading(true)
            const params = {status: selectedOption};


            const {data} = await server.get(`page`, {params});
            if (data?.status) {
                setIsLoading(false);

                const filteredFunnel = data?.data?.funnels?.filter((x) => x?.id == funnelState?.id)

                const filteredPages = selectedOption ? filteredFunnel[0]?.pages?.filter((x) => x?.status == selectedOption) : filteredFunnel[0]?.pages
                console.log('filterrrrrrrr selectedddd', selectedOption)

                console.log('filterrrrrrrr', filteredPages)

                setPages(filteredPages ? filteredPages : [])

                setIsLoading(false);

            }
            // console.log(data)
            // setProduct(data);
            // setLoading(false);

        } catch (e) {
            if (e.response?.data?.status === false && e.response?.data?.auth === false) {
                localStorage.clear()
                router("/accounts")

                // console.log(e.response?.data.errors?.title[0])
                // setError(e.response?.data.errors?.title[0])
                setIsLoading(false);
            } else if (e.response?.data?.status === false) {
                console.log(e.response?.data.errors?.title[0])
                setError(e.response?.data.errors?.title[0])
                setIsLoading(false);
            } else {
                setIsLoading(false);

                setError(e.message)
            }

        }
    }

    const getData = async () => {
        try {
            setIsLoading(true)
            const params = {status: selectedOption};


            const {data} = await server.get(`page`, {params});
            if (data?.status) {


                setPages(data?.data?.pages)
                setFunnel(data?.data?.funnels);
                console.log('this is funnel',);
                setIsLoading(false);
                setInitialPageUi(true)
            }
            console.log(data)
            // setProduct(data);
            // setLoading(false);

        } catch (e) {
            if (e.response?.data?.status === false && e.response?.data?.auth === false) {
                localStorage.clear()
                router("/accounts")

                // console.log(e.response?.data.errors?.title[0])
                // setError(e.response?.data.errors?.title[0])
                setIsLoading(false);
            } else if (e.response?.data?.status === false) {
                console.log(e.response?.data.errors?.title[0])
                setError(e.response?.data.errors?.title[0])
                setIsLoading(false);
            } else {
                setIsLoading(false);

                setError(e.message)
            }

        }
    }

    const getMainPageData = async () => {
        try {
            setIsLoading(true)
            const params = {status: selectedOption};


            const {data} = await server.get(`page/getLatest`);
            if (data?.status) {


                setPages(data?.data)
                // setFunnel(data?.data?.funnels);
                console.log('this is funnel',);
                setIsLoading(false);
            }
            console.log(data)
            // setProduct(data);
            // setLoading(false);

        } catch (e) {
            if (e.response?.data?.status === false && e.response?.data?.auth === false) {
                localStorage.clear()
                router("/accounts")

                // console.log(e.response?.data.errors?.title[0])
                // setError(e.response?.data.errors?.title[0])
                setIsLoading(false);
            } else if (e.response?.data?.status === false) {
                console.log(e.response?.data.errors?.title[0])
                setError(e.response?.data.errors?.title[0])
                setIsLoading(false);
            } else {
                setIsLoading(false);

                setError(e.message)
            }

        }
    }

    const getFunnel = async () => {
        try {
            setIsLoading(true)
            //    const searchParams = new URLSearchParams(location.search);
            //    const type = searchParams.get("type");
            //    const objParam =   JSON.stringify(searchParams)
            //    Object.keys(obj).length === 0
            console.log('jjjj')
            // const type = searchParams.get("funnel");
            const type = funnelName

            // alert(type)


            const {data} = await server.get(`funnel/getAll`);
            if (data?.status) {
// alert(type)
                if (type) {
                    // alert('cc')
                    const filter = data?.data?.filter((x) => x?.id != funnelState?.id)
                    console.log('dataaa', filter)
                    const filterPage = [{id: 0, name: "Move To Main"}, ...filter]
                    console.log('filterss pagesssssss', filterPage)


                    setFunnelList(filterPage)

                    setSelectedFunnelOption(filterPage[0]?.id)
                } else {
                    setFunnelList(data?.data)
                    setSelectedFunnelOption(data?.data[0]?.id)


                }

                setIsLoading(false);
            }
            console.log(data, "darraar")
            // setProduct(data);
            // setLoading(false);

        } catch (e) {
            if (e.response?.data?.status === false && e.response?.data?.auth === false) {
                localStorage.clear()
                router("/accounts")

                // console.log(e.response?.data.errors?.title[0])
                // setError(e.response?.data.errors?.title[0])
                setIsLoading(false);
            } else if (e.response?.data?.status === false) {
                console.log(e.response?.data.errors?.title[0])
                setError(e.response?.data.errors?.title[0])
                setIsLoading(false);
            } else {
                setIsLoading(false);

                setError(e.message)
            }

        }
    }


    const [emptyScreen, setEmptyScreen] = useState(true)
    const [pages, setPages] = useState([]);
    const [modal, setModal] = useState(false);
    const [createFunnelmodal, setcreateFunnelmodal] = useState(false);
    const [moveFunnelmodal, setmoveFunnelmodal] = useState(false);
    const [currentId, setCurrentId] = useState();
    const [currentPageId, setCurrentPageId] = useState();
    const [currentFunnelId, setCurrentFunnelId] = useState();


    const [currentFunnel, setCurrentFunnel] = useState();

    const [disable, setDisable] = useState(true);
    const [funnelTitle, setfunnelTitle] = useState("")
    const [editFunnelTitle, setEditfunnelTitle] = useState("")
    const [duplicatePageTitle, setDuplicatePageTitle] = useState("")


    const [createDeletePagemodal, setDeletePageModal] = useState(false);
    const [createDeleteFunnelmodal, setDeleteFunnelModal] = useState(false);

    const [createFunnelName, setEditFunnelModal] = useState(false);

    const [createDuplicatePage, setDuplicatePage] = useState(false);

    const toggleDuplicatePage = () => {
        setDuplicatePage(!createDuplicatePage);

    }
    const showDuplicatePageModal = (id) => {
        let duplicatePageName = `${id?.title}-Duplicate`

        //    alert(JSON.stringify(funnelState))

        setDuplicatePageTitle(duplicatePageName)
        setCurrentPageId(id?.id)
        toggleDuplicatePage()

    }
    const toggleEditFunnelModal = () => {
        setEditFunnelModal(!createFunnelName);

    }
    const showEditModalFunnel = (id) => {
        setEditfunnelTitle(id?.name)
        setCurrentFunnelId(id)
        toggleEditFunnelModal()

    }

    const toggleDeleteFunnelModal = () => {
        setDeleteFunnelModal(!createDeleteFunnelmodal);

    }
    const showDeleteModalFunnel = (id) => {
        setCurrentFunnelId(id)
        toggleDeleteFunnelModal()

    }


    const toggleDeletempageModal = () => {
        setDeletePageModal(!createDeletePagemodal);

    }
    const showDeleteModalPage = (id) => {
        setCurrentPageId(id)
        toggleDeletempageModal()

    }

    const toggleDelete = () => {
        setModal(!modal);

    }
    const toggleCreateFunnelmodal = () => {
        setcreateFunnelmodal(!createFunnelmodal);

    }
    const toggleMoveFunnelmodal = () => {
        setmoveFunnelmodal(!moveFunnelmodal);

    }

    const showModal = (id) => {
        setCurrentId(id)
        toggleDelete(id)

    }


    const showCreateFunnelmodalModal = () => {
        toggleCreateFunnelmodal()
    }

    const showMoveFunnelmodal = (id) => {
        setCurrentFunnel(id)
        toggleMoveFunnelmodal()
    }

    const unpublish = async () => {

        try {
            const formData = {

                page_id: currentId,
                status: 2
            }
            const {data} = await server.post(`page/changeStatus`, formData);
            if (data?.status) {
                // alert('cc')
                getData()
                toast(data?.message, 'success')
                toggleDelete()
                // isLoading(true)

                router(`/pages`)

            }
            console.log(data)

            // setProduct(data);
            // setLoading(false);
        } catch (e) {
            if (e.response?.data?.status === false) {

                toast(e.response?.data?.message, 'error')
            } else {
                //  setError(e.message)
                //  setDisable(false)
                //  setIsLoading(false)

            }
            //console.log(e.response);

        }
    }

    const characterLimit = (event) => {
        event.preventDefault();
        let result = event.target.value.replace(/\s+/g, ' ').trim();

        setfunnelTitle(result);
        const charCount = result.length;
        if (charCount >= 3 && charCount < 255) {

            setDisable(false);

        } else {
            console.log(charCount);
            setDisable(true);
        }
    }

    const editFunnelname = (event) => {
        event.preventDefault();
        let result = event.target.value.replace(/\s+/g, ' ').trim();

        setEditfunnelTitle(result);
        const charCount = result.length;
        if (charCount >= 3 && charCount < 255) {

            setDisable(false);

        } else {
            console.log(charCount);
            setDisable(true);
        }
    }


    const duplicatePage = (event) => {
        event.preventDefault();
        let result = event.target.value.replace(/\s+/g, ' ').trim();

        setDuplicatePageTitle(result);
        const charCount = result.length;
        if (charCount >= 3 && charCount < 255) {

            setDisable(false);

        } else {
            console.log(charCount);
            setDisable(true);
        }
    }

    const createFunnel = async () => {
        let formData = {
            funnel_name: funnelTitle
        }
        setcreatFunnelLoader(true);

        try {
            const {data} = await server.post(`funnel/create`,
                formData,
            );
            console.log(data);
            setupdatUi(!updatUi);
            toggleCreateFunnelmodal()
            setcreatFunnelLoader(false);


            // setProduct(data);
            // setLoading(false);
        } catch (e) {
            setcreatFunnelLoader(false);
            if (e.response?.data?.status === false) {
                setError(e.response?.data?.errors_array);

            } else {

            }
            //console.log(e.response);

        }

    }


    const updateFunnel = async () => {
        // alert('cc')
        let formData = {
            funnel_id: currentFunnelId?.id,
            funnel_name: editFunnelTitle
        }
        seteditcreatFunnelLoader(true);

        try {
            const {data} = await server.post(`funnel/update_name`,
                formData,
            );
            console.log(data);
            setupdatUi(!updatUi);
            toggleEditFunnelModal()
            seteditcreatFunnelLoader(false);
            toast(data?.message, 'success')


            // setProduct(data);
            // setLoading(false);
        } catch (e) {
            seteditcreatFunnelLoader(false);
            if (e.response?.data?.status === false) {
                if (e.response?.data.errors_array?.[0]) {
                    toast(e.response?.data.errors_array?.[0], 'error')
                } else {
                    toast(e.response?.data?.message, 'error')

                }
                //  setError(e.response?.data.errors?.title[0])
                setIsLoading(false)
            } else {
                setIsLoading(false)

            }
            //console.log(e.response);

        }

    }

    const handleDuplicatePage = async () => {
        // alert('cc')
        let formData = {
            page_id: currentPageId,
            title: duplicatePageTitle,
            funnel_id: funnelState?.id
        }
        setDuplicatePageLoader(true);

        try {
            const {data} = await server.post(`page/duplicate`,
                formData,
            );
            console.log(data);
            setupdatUi(!updatUi);
            toggleDuplicatePage()
            setDuplicatePageLoader(false);
            toast(data?.message, 'success')


            // setProduct(data);
            // setLoading(false);
        } catch (e) {
            setDuplicatePageLoader(false);
            if (e.response?.data?.status === false) {
                if (e.response?.data.errors_array?.[0]) {
                    toast(e.response?.data.errors_array?.[0], 'error')
                } else {
                    toast(e.response?.data?.message, 'error')

                }
                //  setError(e.response?.data.errors?.title[0])
                setIsLoading(false)
            } else {
                setIsLoading(false)

            }
            //console.log(e.response);

        }

    }

    const navigateParam = useNavigateParams();


    const moveFunnel = async () => {
        let formData = {
            page_id: currentFunnel,
            funnel_id: selectedFunnelOption

        }
        setmovFunnel(true);


        try {
            const {data} = await server.post(`page/move_to_funnel`,
                formData,
            );
            console.log(data);
            toast(data?.message, 'success')

            setupdatUi(!updatUi);
            toggleMoveFunnelmodal()
            router(`/pages`)
            setmovFunnel(false);


            // setProduct(data);
            // setLoading(false);

        } catch (e) {
            setmovFunnel(false);
            if (e.response?.data?.status === false) {
                setError(e.response?.data?.errors_array);

            } else {

            }
            //console.log(e.response);

        }


    }

    const deletePage = async () => {
        setIsLoadingDelete(true)

        try {
            const {data} = await server.delete(`/page/delete/${currentPageId}`);
            if (data?.status) {
                // alert('cc')
                toast(data?.message, 'success')


                router(`/pages`)
                toggleDeletempageModal()
            }
            console.log(data)
            setIsLoadingDelete(false)

            // setProduct(data);
            // setIsLoadingDelete(false);
        } catch (e) {
            toggleDeletempageModal()

            setIsLoadingDelete(false)
            if (e.response?.data?.status === false) {


                toast(e.response?.data?.message, 'error')
            } else {
                //  setError(e.message)
                //  setDisable(false)
                //  setIsLoading(false)

            }
            //console.log(e.response);

        }
    }
    const deleteFunnel = async () => {

        setIsLoadingDelete(true)
        console.log('Hello World');
        try {
            const formData = {
                funnel_id: currentFunnelId
            }
            const {data} = await server.delete(`/funnel/delete/${currentFunnelId?.id}`);
            if (data?.status) {
                // alert('cc')
                toast(data?.message, 'success')
                toggleDeleteFunnelModal()
                router(`/pages`)
            }
            console.log(data)
            setIsLoadingDelete(false)

        } catch (e) {

            setIsLoadingDelete(false)
            if (e.response?.data?.status === false) {


                toast(e.response?.data?.message, 'error')
            } else {
                //  setError(e.message)
                //  setDisable(false)
                //  setIsLoading(false)

            }
        }
    }
    return (
        <>

            {/* <ToastContainer/> */}

            {/*  templates section with set name modal*/}
            {
                isLoading ?

                    <div className={'loaderPage'}>
                        <Loader/>
                    </div>


                    :
                    <>


                        {

                            (!pages.length == 0 || !funnel.length == 0 || selectedOption) && (
                                <>
                                    <div className="col-12">
                                        {list !== "main" && (
                                            <div className="row">

                                                <div className="col-md-6 col-12 py-5 ">
                                                    <InternalTitle title={'ALL PAGES'}/>
                                                </div>
                                                <div className="col-md-6 col-12 py-5 pageActionsRight">
                                                    <>
                                                        <Input
                                                            className='select-filter shadow-none me-2'
                                                            id="filterSelectPage"
                                                            name="select"
                                                            type="select"
                                                            value={selectedOption}
                                                            onChange={onChangeSelection}
                                                        >
                                                            <option value={""}>
                                                                All Pages
                                                            </option>
                                                            <option value={2}>
                                                                Draft
                                                            </option>
                                                            <option value={1}>
                                                                Published
                                                            </option>

                                                        </Input>

                                                        {!funnelName && (
                                                            <Button className={`secondary-btn-modal me-2`}
                                                                    onClick={showCreateFunnelmodalModal}>
                                                                <FiFolderPlus/>
                                                            </Button>
                                                        )
                                                        }
                                                        <Button
                                                            onClick={() => {

                                                                router(
                                                                    `/pages/templates`,
                                                                    {state: param}
                                                                )
                                                            }}
                                                            className={`primary-btn-modal`}>
                                                            Create Page
                                                        </Button>
                                                    </>
                                                </div>
                                                {/*<div className="col-12 mb-3">*/}
                                                {/*    <Radio/>*/}
                                                {/*</div>*/}

                                            </div>
                                        )}

                                        {list == "main" && (
                                            <div className="row">
                                                <div className="col-md-6 col-12 pb-2 ">
                                                    <InternalTitle title={'PAGES'}/>
                                                </div>
                                                <div className="col-md-6 col-12 pb-2 ">
                                                    <Link to="/pages"
                                                          className="link-primary d-flex justify-content-end">
                                                        View all pages
                                                    </Link>
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                </>
                            )}


                        {
                            <div className="row listPage">
                                {

                                    funnel?.map((f, index) => {
                                        const card = `${f?.pages?.length == 2 ? "card-2" : f?.pages?.length == 3 ? "card-3" : f?.pages.length >= 4 ? "card-4" : ""}`;
                                        const card2 = "card-4";

                                        return (
                                            <div className="col-20" key={index}>
                                                <div className="row">
                                                    <div className="col-12 d-flex justify-content-between actionMenu">
                                                        <p className={'mb-0 cursor-pointer ps-3'} onClick={() => {
                                                            //       router(
                                                            //       `/pages/funnel/${f?.id}`,
                                                            //       { state: f }
                                                            //   )


                                                            //with query string

                                                            setPages(f?.pages)
                                                            setFunnel([])
                                                            FunnelAction(f)
                                                            setParam(f)
                                                            router(`/pages/funnels/${f?.name}`)


                                                        }

                                                        }>
                                                                    <span className={`pageTitleName`}>
                                                                        {f?.name}
                                                                    </span>
                                                        </p>
                                                        <UncontrolledButtonDropdown
                                                            className={'shadow-none btn btn-secondary'}
                                                            direction="end">
                                                            <DropdownToggle>
                                                                <BiDotsVerticalRounded
                                                                    size={29}
                                                                    color={'#717C8D'}/>
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                <DropdownItem
                                                                    onClick={() => showEditModalFunnel(f)}
                                                                >Rename </DropdownItem>
                                                                <DropdownItem
                                                                    onClick={() => showDeleteModalFunnel(f)}
                                                                >Delete </DropdownItem>

                                                            </DropdownMenu>
                                                        </UncontrolledButtonDropdown>
                                                    </div>
                                                </div>

                                                <Card
                                                    className={card}
                                                >
                                                    <span className={'cardImage'}>
                                                        {!f.pages.length ? <img src={dummyImg}

                                                                                onClick={() => {
                                                                                    setPages(f?.pages)
                                                                                    setFunnel([])
                                                                                    FunnelAction(f)
                                                                                    setParam(f)
                                                                                    router(`/pages/funnels/${f?.name}`)

                                                                                    // navigateParam("/pages", `funnel=${f?.name}`);


                                                                                }}
                                                                                width="100%" height="100%"
                                                                                layout="responsive" objectfit="cover"/>
                                                            :

                                                            <>
                                                                {
                                                                    f.pages.slice(0, 4).map((i) => {
                                                                        return (
                                                                            <>
                                                                                <img src={i.thumbnail}
                                                                                     width="100%" height="100%"
                                                                                     layout="responsive"
                                                                                     objectfit="cover"
                                                                                     onClick={() => {
                                                                                         setPages(f?.pages)
                                                                                         setFunnel([])
                                                                                         FunnelAction(f)
                                                                                         setParam(f)

                                                                                         router(`/pages/funnels/${f?.name}`)


                                                                                     }}

                                                                                />

                                                                            </>
                                                                        )

                                                                    })

                                                                }
                                                            </>

                                                        }


                                                    </span>
                                                    <CardBody className={'px-0'}
                                                        //           onClick={() => {
                                                        //     //       router(
                                                        //     //       `/pages/funnel/${f?.id}`,
                                                        //     //       { state: f }
                                                        //     //   )
                                                        //
                                                        //
                                                        //     //with query string
                                                        //     setPages(f?.pages)
                                                        //     setFunnel([])
                                                        //     FunnelAction(f)
                                                        //     setParam(f)
                                                        //     navigateParam("/pages", `funnel=${f?.name}`);
                                                        //
                                                        //
                                                        // }
                                                        //
                                                        // }
                                                    >

                                                        <div className="row" onClick={() => {
                                                            //       router(
                                                            //       `/pages/funnel/${f?.id}`,
                                                            //       { state: f }
                                                            //   )


                                                            //with query string

                                                            setPages(f?.pages)
                                                            setFunnel([])
                                                            FunnelAction(f)
                                                            setParam(f)
                                                            router(`/pages/funnels/${f?.name}`)


                                                            // navigateParam("/pages", `funnel=${f?.name}`);


                                                        }

                                                        }>
                                                            <div className="col-12">
                                                                <span className={`subTitle`}>
                                                                    {f.page_count} page(s) in funnel
                                                                </span>
                                                                <span className={`px-2`}>
                                                                    <FaRegFolder size={18}/>
                                                                 </span>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>

                                            </div>
                                        );

                                    })


                                }


                                {
                                    (pages) && (
                                        <>

                                            {
                                                pages.map((x, index) => {
                                                    return (
                                                        <>
                                                            <div className="col-20">

                                                                <Card
                                                                    key={index}
                                                                >
                                                                    <div className="row actionMenu">
                                                                        <div className="col-12 d-flex justify-content-between">
                                                                            <p className={'mb-0 cursor-pointer'}>
                                                                                    <span className={`pageTitleName`}
                                                                                          onClick={() => {
                                                                                              router(`/pages/detail/${x?.id}`)

                                                                                          }}>
                                                                                        {x?.title} </span>

                                                                            </p>


                                                                            {!list && (
                                                                                <UncontrolledButtonDropdown
                                                                                    className={'shadow-none btn btn-secondary'}
                                                                                    direction="start">
                                                                                    <DropdownToggle>
                                                                                        <BiDotsVerticalRounded
                                                                                            size={29}
                                                                                            color={'#717C8D'}/>
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu>

                                                                                    <DropdownItem
                                                                                            className={`cursor-pointer`}
                                                                                            onClick={() => {
                                                                                                const targetUrl = x?.grape_js_version 
                                                                                                    ? `/pages/editor/${x?.id}`
                                                                                                    : `/pages/editor_v0/${x?.id}`;
                                                                                        
                                                                                                router(targetUrl, {
                                                                                                    state: {
                                                                                                        is_from_template: x?.is_from_template
                                                                                                    },
                                                                                                });
                                                                                        
                                                                                                // Reload the page
                                                                                                window.location.href = targetUrl;
                                                                                            }}
                                                                                            
                                                                                            > Edit
                                                                                            Page</DropdownItem>

                                                                                        {x?.status == 1 &&
                                                                                        <DropdownItem
                                                                                            className={`cursor-pointer ps-0`}
                                                                                        >

                                                                                            <a href={`${x?.published_url}`}
                                                                                               target={'_blank'}>

                                                                                                View Page
                                                                                            </a>


                                                                                        </DropdownItem>
                                                                                        }


                                                                                        {
                                                                                            x.status == 1 && (
                                                                                                <DropdownItem
                                                                                                    onClick={() => showModal(x?.id)}
                                                                                                >Unpublish</DropdownItem>
                                                                                            )
                                                                                        }

                                                                                        {
                                                                                            (funnel.length > 0 || (funnelState || funnelName)) && (
                                                                                                <DropdownItem
                                                                                                    onClick={() => showMoveFunnelmodal(x?.id)}
                                                                                                >Move to
                                                                                                    Funnel </DropdownItem>
                                                                                            )
                                                                                        }
                                                                                        <DropdownItem
                                                                                            className={`cursor-pointer`}
                                                                                            onClick={() => showDuplicatePageModal(x)}
                                                                                        > Duplicate
                                                                                        </DropdownItem>


                                                                                        {
                                                                                            x?.status == 2 && (
                                                                                                <DropdownItem
                                                                                                    onClick={() => showDeleteModalPage(x?.id)}
                                                                                                >Delete
                                                                                                </DropdownItem>
                                                                                            )

                                                                                        }

                                                                                    </DropdownMenu>

                                                                                </UncontrolledButtonDropdown>

                                                                            )}

                                                                        </div>

                                                                    </div>
                                                                    <span className={'cardImage'}>
                                                                        <img
                                                                            alt={x?.title}
                                                                            src={x?.thumbnail}
                                                                            width="100%" height="100%"
                                                                            layout="responsive"
                                                                            objectFit="cover"
                                                                            onClick={() => router(`/pages/detail/${x?.id}`)}

                                                                        />
                                                                    </span>
                                                                    <CardBody className={'px-0'}>

                                                                        <div className="row">
                                                                            <div className="col-8">
                                                                                <span className={`subTitle`}>
                                                                                    {/*{*/}
                                                                                    {/*    x?.status == 1 && (*/}
                                                                                    {/*        <a href={`${x?.published_url}`}*/}
                                                                                    {/*           target={'_blank'}>*/}
                                                                                    {/*            <span className="pe-1"> <BsEyeFill/>  </span>*/}
                                                                                    {/*            View Page*/}
                                                                                    {/*        </a>*/}
                                                                                    {/*    )*/}
                                                                                    {/*}*/}
                                                                                    <span>
                                                                                         <TimeAgo
                                                                                             timestamp={x?.updated_at}/>

                                                                                        </span>
                                                                                </span>

                                                                            </div>
                                                                            <div className="col-4">
                                                                                  <span className={"float-end"}>
                                                                                    {
                                                                                        x?.status === 1 ? (
                                                                                            <div
                                                                                                className={'published text-success'}>
                                                                                                Published
                                                                                            </div>
                                                                                        ) : (
                                                                                            <div
                                                                                                className={'draft text-warning'}>
                                                                                                Draft
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </div>

                                                        </>


                                                    )
                                                })
                                            }


                                            <Modal isOpen={modal} toggle={toggleDelete} className={'creatPageModal'}
                                                   centered backdrop={'false'}>
                                                <ModalHeader toggle={toggleDelete}>Are you sure ? </ModalHeader>
                                                <ModalBody>
                                                    <p>You want to Unpublish this page.</p>

                                                </ModalBody>
                                                <ModalFooter>
                                                    <Button onClick={unpublish}
                                                            className={'primary-btn-modal shadow-none '}>Confirm</Button>
                                                    <Button className={'secondary-btn-modal mx-2 shadow-none'}
                                                            onClick={toggleDelete}>Cancel</Button>

                                                </ModalFooter>
                                            </Modal>


                                        </>
                                    )


                                }
                            </div>
                        }


                        {/*  empty scections*/}


                        {
                            (pages.length == 0 && funnel.length == 0 && !selectedOption == true) && pageUI && (
                                <div className="row">
                                    <div className="col-12">
                                        <div
                                            className="mt-5 emptyState d-block mx-auto align-content-center text-center">
                                            <img
                                                alt="Empty! no page found"
                                                src={EmptyImg}
                                            />
                                            <h2 className={'mb-5'}> You don’t have any pages yet. </h2>
                                            {/*<p className="mx-auto align-content-center"> Start building your landing*/}
                                            {/*    page*/}
                                            {/*    with the <strong> Starter </strong> site plan. You can connect a custom domain or use our*/}
                                            {/*    sub-domain to publish.</p>*/}
                                            <button onClick={() => {

                                                router(
                                                    `/pages/templates`,
                                                    {state: param}
                                                )


                                            }} className="primary-btn-modal shadow-none mb-5">
                                                <CgNotes size="18"/> <span> Create a new page </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}


                        {
                            (pages.length == 0 && funnel.length == 0 && selectedOption) && (
                                <>
                                    <div
                                        className="mt-5 emptyState d-block mx-auto align-content-center mt-5 text-center">
                                        <img
                                            alt="Empty! no page found"
                                            src={EmptyImg}
                                            width="395"
                                        />
                                        <h2> No results found </h2>


                                    </div>
                                </>
                            )}


                    </>
            }


            <Modal isOpen={createFunnelmodal} toggle={toggleCreateFunnelmodal} className={'creatPageModal'} centered
                   backdrop={'false'}>
                <ModalHeader toggle={toggleCreateFunnelmodal}>Create Funnel </ModalHeader>
                <ModalBody>
                    <p>Funnel name</p>
                    <Input
                        autoFocus
                        className='' type={'text'}
                        placeholder={'Enter funnel name '}
                        onChange={characterLimit}
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                                createFunnel()
                            }
                        }}
                        maxlength="15"/>
                    {error && (<p className={'errorMessage'}> {error} </p>)}
                </ModalBody>
                <ModalFooter>
                    <Button className={'primary-btn-modal shadow-none'} onClick={createFunnel}>
                        {creatFunnelLoader ? <span className={'spinnerContainer'}> Creating  <Spinner>
                            Loading...
                        </Spinner></span> : 'Create'}
                    </Button>
                    <Button className={'secondary-btn-modal shadow-none'}
                            onClick={toggleCreateFunnelmodal}>Cancel</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={moveFunnelmodal} toggle={toggleMoveFunnelmodal} className={'creatPageModal'} centered
                   backdrop={'false'}>
                <ModalHeader toggle={toggleMoveFunnelmodal}>Move to Funnel </ModalHeader>
                <ModalBody>
                    <p>Select</p>
                    <Input
                        className=' shadow-none'

                        name="select"
                        type="select"
                        value={selectedFunnelOption}
                        onChange={onChangeFunnelSelection}
                    >
                        {/*{*/}
                        {/*    (()=>{console.log(funnelList)})()*/}
                        {/*}*/}

                        {funnelList?.map((x, i) => {
                            return (
                                <option value={x?.id}>
                                    {x?.name}
                                </option>
                            );
                        })}


                    </Input>
                    {error && (<p className={'errorMessage'}> {error} </p>)}
                </ModalBody>
                <ModalFooter>
                    <Button className={'primary-btn-modal shadow-none movebutton'} onClick={moveFunnel}> {movFunnel ?
                        <span className={'spinnerContainer'}> Moving  <Spinner>
                        Loading...
                    </Spinner></span> : 'Move'}
                    </Button>
                    <Button className={'secondary-btn-modal shadow-none'}
                            onClick={toggleMoveFunnelmodal}>Cancel</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={createDeletePagemodal} toggle={toggleDeletempageModal} className={'creatPageModal'} centered
                   backdrop={'false'}>
                <ModalHeader toggle={toggleDeletempageModal}>Are you sure? </ModalHeader>
                <ModalBody>
                    <p>You want to Delete this page. </p>

                </ModalBody>
                <ModalFooter>
                    <Button className={'btn-danger shadow-none d-flex align-items-center justify-content-center '}
                            onClick={deletePage}> <IoTrashOutline size={15}/>{isLoadingDelete ?
                        <span className={'spinnerContainer '}>Deleting<Spinner>
                                Loading...
                            </Spinner></span> : 'Delete'} </Button>
                    <Button className={'secondary-btn-modal mx-2 shadow-none'}
                            onClick={toggleDeletempageModal}> Cancel</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={createDeleteFunnelmodal} toggle={toggleDeleteFunnelModal} className={'creatPageModal'}
                   centered
                   backdrop={'false'}>
                <ModalHeader toggle={toggleDeleteFunnelModal}>Delete Funnel </ModalHeader>
                <ModalBody>
                    <p><strong>Note:</strong> All the pages will be moved to the main page. Are you sure you want to delete this funnel? </p>
                </ModalBody>
                <ModalFooter>
                    <Button className={'btn-danger shadow-none d-flex align-items-center justify-content-center '}
                            onClick={deleteFunnel}> <IoTrashOutline size={15}/>{isLoadingDelete ?
                        <span className={'spinnerContainer '}>Deleting<Spinner>
                                Loading...
                            </Spinner></span> : 'Delete'} </Button>


                    <Button className={'secondary-btn-modal mx-2 shadow-none'}
                            onClick={toggleDeleteFunnelModal}> Cancel</Button>

                </ModalFooter>
            </Modal>

            <Modal isOpen={createFunnelName} toggle={toggleEditFunnelModal} className={'creatPageModal'} centered
                   backdrop={'false'}>
                <ModalHeader toggle={toggleEditFunnelModal}>Edit funnel name </ModalHeader>
                <ModalBody>
                    {/*<p>Rename Funnel Name</p>*/}
                    <Input
                        autoFocus
                        className='' type={'text'}
                        placeholder={'Enter new funnel name '}
                        onChange={editFunnelname}
                        value={editFunnelTitle}
                        maxlength="15"/>
                    {error && (<p className={'errorMessage'}> {error} </p>)}
                </ModalBody>
                <ModalFooter>
                    <Button className={'primary-btn-modal shadow-none'}
                            onClick={
                                updateFunnel
                            }
                    >
                        {editcreatFunnelLoader ? <span className={'spinnerContainer'}>   <Spinner>
                            Loading...
                        </Spinner></span> : 'Change'}
                    </Button>
                    <Button className={'secondary-btn-modal shadow-none'}
                            onClick={toggleEditFunnelModal}>Cancel</Button>
                </ModalFooter>
            </Modal>


            <Modal isOpen={createDuplicatePage} toggle={toggleDuplicatePage} className={'creatPageModal'} centered
                   backdrop={'false'}>
                <ModalHeader toggle={toggleDuplicatePage}>You want to Duplicate this page. </ModalHeader>
                <ModalBody>
                    <label> Page Name</label>
                    <Input
                        autoFocus
                        className='' type={'text'}
                        placeholder={'Enter new funnel name '}
                        onChange={duplicatePage}
                        value={duplicatePageTitle}
                        maxlength="15"/>

                    {error && (<p className={'errorMessage'}> {error} </p>)}

                </ModalBody>
                <ModalFooter>
                    <Button
                        disabled={!duplicatePageTitle}

                        onClick={
                            handleDuplicatePage
                        }
                        className={'primary-btn-modal shadow-none d-flex align-items-center justify-content-center '}
                    > <HiDocumentDuplicate className={'me-1'} size={15}/>{duplicatepageLoader ?
                        <span className={'spinnerContainer'}>Duplicating<Spinner>
                                Loading...
                            </Spinner></span> : 'Duplicate'} </Button>


                    <Button className={'secondary-btn-modal mx-2 shadow-none'}
                            onClick={toggleDuplicatePage}> Cancel</Button>

                </ModalFooter>
            </Modal>


        </>
    );
}

export default List;