import React, {useEffect, useState} from "react";
import server from '../../../apis/server'
import {
    Button,
    Card,
    CardBody,
    DropdownItem,
    DropdownMenu,
    DropdownToggle, Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner,
    UncontrolledButtonDropdown
} from 'reactstrap';
import {BiDotsVerticalRounded} from "react-icons/bi";
import {FaRegFolder} from "react-icons/fa";
import {useNavigate, useParams,useLocation} from "react-router-dom";
import {CgNotes } from "react-icons/cg";
import Loader from "../../common/loader/loader";
import toast from '../../../utils/toast'
import { ToastContainer } from "react-toastify";
import InternalTitle from "../../common/internalTittle/GenaricButton";
import {FiFolderPlus} from "react-icons/fi";
import { BsEyeFill } from "react-icons/bs";
import EmptyImg from '../../../images/emptyPage.svg'
import dummyImg from '../../../images/tile0.png';
function FunnelList() {


    let {state} = useLocation();
    console.log('this is test funnel data',state);


    const [isLoading, setIsLoading] = useState(true)
    const [updatUi, setupdatUi] = useState(false)
    const [funnel, setFunnel] = useState([]);



    const [selectedOption, setSelectedOption] = useState("");
    const accountsString =   localStorage.getItem('accounts')
    const accounts =    accountsString ? JSON.parse(accountsString) : ''
    console.log('acc',accounts)
    const {public_url}  =   accounts


    const router = useNavigate()
    const frontUrl=process.env.REACT_APP_FRONT_URL
    useEffect(() => {
        getData()
    }, [selectedOption,updatUi]);





    const onChangeSelection = (e) => {
        setSelectedOption(e.target.value);
    };



    const getData = async () => {
        try {
            const params = { status: selectedOption };


            const {data} = await server.get(`page`,{params});
            if (data?.status) {


                setPages(data?.data?.pages)
                setFunnel(data?.data?.funnels);
                console.log('this is funnel',);
                setIsLoading(false);
            }
            console.log(data)
            // setProduct(data);
            // setLoading(false);

        } catch (e) {
            if (e.response?.data?.status === false && e.response?.data?.auth === false) {
                localStorage.clear()
                router("/accounts")

                // console.log(e.response?.data.errors?.title[0])
                // setError(e.response?.data.errors?.title[0])
                // setIsLoading(true);
            }
            else if (e.response?.data?.status === false) {
                console.log(e.response?.data.errors?.title[0])
                setError(e.response?.data.errors?.title[0])
                setIsLoading(false);
            } else {
                setError(e.message)
            }

        }
    }
    const [emptyScreen, setEmptyScreen] = useState(true)
    const [error, setError] = useState("")
    const [pages, setPages] = useState([]);
    const [modal, setModal] = useState(false);
    const [createFunnelmodal, setcreateFunnelmodal] = useState(false);
    const [currentId, setCurrentId] = useState();
    const [disable,setDisable]=useState(true);


    const [funnelTitle,setfunnelTitle] = useState("")



    const toggleDelete = () => {
        setModal(!modal);

    }
    const toggleCreateFunnelmodal = () => {
        setcreateFunnelmodal(!createFunnelmodal);

    }

    const showModal = (id) => {
        setCurrentId(id)
        toggleDelete(id)

    }
    const showCreateFunnelmodalModal = ()=> {
        toggleCreateFunnelmodal()

    }

    const unpublish=async()=>{

        try {
            const formData = {

                page_id:currentId,
                status:2
            }
            const { data } = await server.post(`page/changeStatus`,formData);
            if(data?.status){
                // alert('cc')
                getData()
                toast(data?.message,'success')
                toggleDelete()
                // isLoading(true)

                router(`/pages`)

            }
            console.log(data)

            // setProduct(data);
            // setLoading(false);
        } catch (e) {
            if(e.response?.data?.status===false){

                toast(e.response?.data?.message,'error')
            }
            else{
                //  setError(e.message)
                //  setDisable(false)
                //  setIsLoading(false)

            }
            //console.log(e.response);

        }
    }

    const characterLimit=(event)=>{
        event.preventDefault();
        let result=event.target.value.replace(/\s+/g, ' ').trim();

        setfunnelTitle(result);
        const charCount = result.length;
        if(charCount >= 3 &&  charCount < 255 ){

            setDisable(false);

        }else {
            console.log(charCount);
            setDisable(true);
        }
    }

    const createFunnel=async ()=>{
        let formData={
            funnel_name:funnelTitle
        }

        try {
            const  {data}  = await server.post(`funnel/create`,
                formData,
            );
            console.log(data);
            setupdatUi(!updatUi);
            toggleCreateFunnelmodal()


            // setProduct(data);
            // setLoading(false);
        } catch (e) {
            if(e.response?.data?.status===false){
                setError(e.response?.data?.errors_array);
            }
            else{

            }
            //console.log(e.response);

        }

    }

    return (
        <>

            {/* <ToastContainer /> */}

            {/*  templates section with set name modal*/}
            {
                isLoading ?

                    <div className={'loaderPage'}>
                        <Loader/>
                    </div>
                    :
                    <>
                        {

                            (!pages.length==0  || selectedOption) &&  (
                                <>
                                    <div className="col-12">
                                        {state!=="main" && (
                                            <div className="row">

                                                <div className="col-md-6 col-12 py-5 ">
                                                    <InternalTitle title={'ALL PAGES'}/>
                                                </div>
                                                <div className="col-md-6 col-12 py-5 pageActionsRight">
                                                    <>
                                                        <Input
                                                            className='select-filter shadow-none mx-2'
                                                            id="filterSelectPage"
                                                            name="select"
                                                            type="select"
                                                            value={selectedOption}
                                                            onChange={onChangeSelection}
                                                        >
                                                            <option value={""}>
                                                                All Pages
                                                            </option>
                                                            <option value={2}>
                                                                Draft
                                                            </option>
                                                            <option value={1}>
                                                                Published
                                                            </option>
                                                        </Input>
                                                        {/*<Button className={`secondary-btn-modal mx-2`} onClick={showCreateFunnelmodalModal}>*/}
                                                        {/*    <FiFolderPlus/>*/}
                                                        {/*</Button>*/}

                                                        <Button
                                                            onClick={()=>{ router(`/pages/templates`,{state:state}) }}
                                                            className={`primary-btn-modal`}>
                                                            Create Page
                                                        </Button>
                                                    </>
                                                </div>
                                                {/*<div className="col-12 mb-3">*/}
                                                {/*    <Radio/>*/}
                                                {/*</div>*/}

                                            </div>
                                        )}

                                        {state=="main" && (
                                            <div className="row">
                                                <div className="col-md-6 col-12 pb-2 ">
                                                    <InternalTitle title={'PAGES'}/>
                                                </div>
                                                <div className="col-md-6 col-12 pb-2 ">
                                                    <a href={`/pages`} className="link-primary d-flex justify-content-end">
                                                        View all pages
                                                    </a>
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                </>
                            )}


                        {
                            <div className="row listPage">


                                {
                                    (state?.pages) && (
                                        <>

                                            {
                                                state?.pages.map((x, index) => {
                                                    return (
                                                        <>
                                                            <div className="col-20">

                                                                <Card
                                                                    key={index}
                                                                    // onClick={()=>showModal(index)}
                                                                >
                                                        <span className={'cardImage'}>
                                                        <img
                                                            alt={x?.title}
                                                            src={x?.thumbnail}
                                                            width="100%" height="100%" layout="responsive"
                                                            objectFit="cover"
                                                        />
                                                        </span>
                                                                    <CardBody className={'px-0'}>

                                                                        <div className="row">
                                                                            <div
                                                                                className="col-12 d-flex justify-content-between">
                                                                                <p className={'mb-0 cursor-pointer'}>
                                                   <span className={`pageTitleName`} onClick={() => {
                                                       router(`/pages/detail/${x?.id}`)

                                                   }}>
                                                   {x?.title} </span>
                                                                                </p>
                                                                                <UncontrolledButtonDropdown
                                                                                    className={'shadow-none'}
                                                                                    direction="start">
                                                                                    <DropdownToggle>
                                                                                        <BiDotsVerticalRounded
                                                                                            size={29}
                                                                                            color={'#717C8D'}/>
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu>
                                                                                        <DropdownItem
                                                                                            className={`cursor-pointer`}
                                                                                            onClick={() => {
                                                                                                router(`/pages/editor/${x?.id}`)

                                                                                            }}> Edit
                                                                                            Page</DropdownItem>

                                                                                        {
                                                                                             x.status == 1 && (
                                                                                                <DropdownItem
                                                                                                    onClick={() => showModal(x?.id)}
                                                                                                >Unpublish</DropdownItem>
                                                                                            )
                                                                                        }

                                                                                    </DropdownMenu>
                                                                                </UncontrolledButtonDropdown>
                                                                            </div>
                                                                            <div className="col-12">
                                               <span className={`subTitle`}>
                                                    {
                                                        x?.status == 1 && (
                                                            <a href={`${public_url}/${x?.url}`} target={'_blank'}>
                                                                <span className="pe-1"> <BsEyeFill/>  </span>
                                                                View Page
                                                            </a>
                                                        )
                                                    }
                                               </span>
                                                                                {/*                   <span className={`px-2`}>*/}
                                                                                {/*    <FaRegFolder size={18}/>*/}
                                                                                {/*</span>*/}
                                                                            </div>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </div>

                                                        </>


                                                    )
                                                })
                                            }





                                            <Modal isOpen={modal} toggle={toggleDelete} className={'creatPageModal'}
                                                   centered backdrop={'false'}>
                                                <ModalHeader toggle={toggleDelete}>Are you sure ? </ModalHeader>
                                                <ModalBody>
                                                    <p>You want to Unpublish this page.</p>

                                                </ModalBody>
                                                <ModalFooter>
                                                    <Button onClick={unpublish}
                                                            className={'primary-btn-modal shadow-none '}>Confirm</Button>
                                                    <Button className={'secondary-btn-modal mx-2 shadow-none'}
                                                            onClick={toggleDelete}>Cancel</Button>

                                                </ModalFooter>
                                            </Modal>


                                        </>
                                    )


                                }
                            </div>
                        }




                        {/*  empty scections*/}

                        {
                            !pages.length && !selectedOption == true && (
                                <div className="row">
                                    <div className="col-12">
                                        <div className="mt-5 emptyState d-block mx-auto align-content-center text-center">
                                            <img
                                                alt="Empty! no page found"
                                                src= {EmptyImg}
                                            />
                                            <h2> You don’t have any pages yet </h2>


                                            <button onClick={() => {
                                                router(`templates`)

                                            }} className="primary-btn-modal shadow-none mb-5">
                                                <CgNotes size="18"/> <span> Create a new page </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}


                        {
                            !pages.length == true  &&selectedOption  && (
                                <>
                                    <div className="mt-5 emptyState d-block mx-auto align-content-center mt-5 text-center">
                                        <img
                                            alt="Empty! no page found"
                                            src= {EmptyImg}
                                            width="395"
                                        />
                                        <h2> No results found </h2>


                                    </div>
                                </>
                            )}
                    </>
            }
            <Modal isOpen={createFunnelmodal} toggle={toggleCreateFunnelmodal} className={'creatPageModal'} centered backdrop={'false'}>
                <ModalHeader toggle={toggleCreateFunnelmodal}>Are you sure ? </ModalHeader>
                <ModalBody>
                    <p>Page name</p>
                    <Input
                        autoFocus
                        className='' type={'text'} placeholder={'Enter page name '}
                        onChange={characterLimit}/>
                    {error && (<p className={'errorMessage'}> {error} </p>)}
                </ModalBody>
                <ModalFooter>
                    <Button className={'primary-btn-modal shadow-none'} onClick={createFunnel}>
                        Create</Button>
                    <Button className={'secondary-btn-modal shadow-none'} onClick={toggleCreateFunnelmodal}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default FunnelList;