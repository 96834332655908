const Radio = ({ categories, selectedCategory, onCategoryChange }) => {
    console.warn('categories', categories);

    return (
        <div className={'radio-toolbar'}>
            {categories && categories.length > 0 && categories
                .filter(category => category !== null && category !== '')
                .map((category, index) => (
                    <div key={index} className={selectedCategory === category ? 'selected' : 'not-selected'}>
                        <input
                            type="radio"
                            id={category}
                            name="category"
                            value={category}
                            checked={selectedCategory === category}
                            onChange={() => onCategoryChange(category)}
                        />
                        <label htmlFor={category}>{category}</label>
                    </div>
                ))}
        </div>
    );
}

export default Radio;
