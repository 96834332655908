import React from 'react'
import { Button, InputGroup, Input, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const InternalTitle = ( {...props} ) => {
    return (<>
    <h5 className={'internalTitle'}>
        {props.title}
    </h5>
    </>)
}

export default InternalTitle