import React,{useState,useEffect} from "react";
import Logo from "../../../images/logoinverse.png"
import {Form, FormGroup, Label, Input, Button, List, Alert,Spinner} from "reactstrap";
import {BsCheck2, BsX} from "react-icons/bs";
import { AiOutlineEyeInvisible,AiOutlineEye } from "react-icons/ai";
import { IoIosRefresh } from "react-icons/io";
import { RiErrorWarningLine } from "react-icons/ri";
import { Formik } from "formik"; 
import * as yup from "yup";
import server from "../../../apis/server"
import Loader from "../../../components/common/loader/loader";
import { useNavigate,Link } from "react-router-dom";
import toast from '../../../utils/toast' 




function Registration() {

    const [isLoading, setIsLoading] = useState(false);
    const [emailsended, setEmailSended] = useState(false);
    const [userData, setUserData] = useState(null);
    const [passwordIcon, setPasswordIcon] = useState(true);
    const [cPasswordIcon, setCpasswordIcon] = useState(true);
    const [email, setEmail] = useState('');

    const router = useNavigate()


    let validationSchema = yup.object({ 
        
        first_name: yup.string().required('This field is required.').min(3).max(250),

        email: yup.string().email('Invalid email').required('This field is required.'),
        confirm_password: yup
  .string()
  .oneOf([yup.ref('password'), null], 'Passwords must match')
    //     password:   yup.string().
    //         yup.object({
    //           lengthCount: yup.string().required('Minimum 8 characters'),
    //           upperCase: yup.string().required('Minimum 8 characters'),
    //         //   specialLetter : yup.string().matches(
    //         //     /^.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?].**$/,
    //         //    'Need one special character',
    //         // ),
    //         })   
        
        });
       async function handleSignUp(values,resetForm)
       {
           setIsLoading(true)
   
           let formData={
               email: values?.email,
               first_name :values?.first_name,
            //    last_name : values?.last_name,
            //    user_name : values?.user_name,
               password: values?.password,
           }
             try {
               const { data } = await server.post(`register/user`,
                   formData,
               );
   
               if(data?.status){

                // router(`/billing`)
                // AccountAction(data?.data)
                setEmailSended(true)
                setUserData(data?.data)
                setEmail(data?.data?.email)
               await localStorage.setItem('userData',JSON.stringify(data?.data))
               toast(data?.message, 'success')

                   // isLoading(true)
               }
              console.log(data)
               // setProduct(data);
               setIsLoading(false);
             } catch (e) {
                    if(e.response?.data?.status===false){
                      if(e.response?.data.errors_array?.[0]){
                      toast(e.response?.data.errors_array?.[0], 'error')
                    }
                    else{
                        toast(e.response?.data?.message, 'error')
                        
                    }
                       //  setError(e.response?.data.errors?.title[0])
                        setIsLoading(false)
                    }
                    else{
                        setIsLoading(false)
   
                    }
               //console.log(e.response);
   
           }
       }

       const validatePassFormat = (values) => {
        return (
          !/[a-z]/.test(values?.password) ||
          !/[A-Z]/.test(values?.password) ||
          values?.password?.length < 8 ||
          !/\d/.test(values?.password)
        //    ||
        //   !/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(values?.password)
        );
      };


      async function resendEmail()
      {
        //   setIsLoading(true)
  
          let formData={
              email,
           
          }
            try {
              const { data } = await server.post(`user/resend_verification_email`,
                  formData,
              );
  
              if(data?.status){

               // router(`/billing`)
               // AccountAction(data?.data)
               
               toast(data?.message, 'success')

                  // isLoading(true)
              }
             console.log(data)
              // setProduct(data);
            //   setIsLoading(false);
            } catch (e) {
                if(e.response?.data?.status===false){
                    if(e.response?.data.errors_array?.[0]){
                    toast(e.response?.data.errors_array?.[0], 'error')
                  }
                  else{
                      toast(e.response?.data?.message, 'error')
                      
                  }
                     //  setError(e.response?.data.errors?.title[0])
                      setIsLoading(false)
                  }
                  else{
                      setIsLoading(false)
 
                  }
  
          }
      }

    return (

            <>

            {!emailsended  &&(
          <div className={'signup-flow'}>
                 
                 <Formik
             initialValues={{ 
                 first_name: '' ,
                //  last_name:'',
                 email:'',
                //  user_name:'',
                 password:'',
                 confirm_password:''
              }}
              validationSchema={validationSchema}
             onSubmit={(values, { resetForm }) => {
               handleSignUp(values, resetForm);
             }} 
             > 
                {(formikProps) => ( 
         <>
                     <img src={Logo} alt={"LOGO"} />
                     <h1> Sign up</h1>
                     {/* <Form> */}
 
 
                                              <FormGroup>
                                                 <Label for="fname">
                                                      Name
                                                 </Label>
                                                 
 
                                         <Input
 
                                  placeholder="Enter your Name"
                                  type="text"
                                         name="first_name"
                                         id ="first_name"
                                         value={formikProps.values.first_name}
                   onChange={formikProps.handleChange("first_name")}
                   onBlur={formikProps.handleBlur("first_name")}
                   error={
                     formikProps.errors.first_name && formikProps.touched.first_name
                       ? true
                       : false
                   }
                 //   isDisabled
                                        />
                                         <div>
           {formikProps.errors.first_name && formikProps.touched.first_name && (
             //    <Alert status='error' color={'#DD2922'} pl={0} fontWeight={'400'} fontSize={'14px'} lineheight={'20px'}>
 
             <p className={'errorMessage'}>
                {formikProps.errors.first_name}
             </p>
          )}
             </div>
 
 
                                             </FormGroup>
 
                                            
 
 
                                                                                           
                     <FormGroup >
                                         <Label for="email">
                                             Email
                                         </Label>
                                         <Input
                                     //   disabled
                                  placeholder="Enter your Email"
                                  type="email"
                                         name="email"
                                         id ="email"
                                         value={formikProps.values.email}
                   onChange={formikProps.handleChange("email")}
                   onBlur={formikProps.handleBlur("email")}
                   error={
                     formikProps.errors.email && formikProps.touched.email
                       ? true
                       : false
                   }
                                        />
                                         <div>
           {formikProps.errors.email && formikProps.touched.email && (
             //    <Alert status='error' color={'#DD2922'} pl={0} fontWeight={'400'} fontSize={'14px'} lineheight={'20px'}>
 
             <p className={'errorMessage'}>
                {formikProps.errors.email}
             </p>
          )}
             </div>
                                     </FormGroup>
 
                                     
                         <FormGroup>
                             <Label for="Password">
                                 Password
                             </Label>
                             <div className="flexContainer">
        
 
                                                 <Input
                             className={'mb-0'}
                                  type= {passwordIcon ? "password":"text"}
                                         name="password"
                                         
                                         id ="password"
                                         value={formikProps.values.password}
                   onChange={formikProps.handleChange("password")}
                   onBlur={formikProps.handleBlur("password")}
                   error={
                     formikProps.errors.password && formikProps.touched.password
                       ? true
                       : false
                   }
                 //   isDisabled 
                                        />
                                                                    <button onClick={()=>{
                                                                        setPasswordIcon(!passwordIcon)
                                                                    }}> 
                                                                    {! passwordIcon && ( 
                                                                    <AiOutlineEye /> 
                                                                    )}
                                                                    { passwordIcon && ( 
                                                                    <AiOutlineEyeInvisible /> 
                                                                    )}
                                                                    </button>

 
                                        </div>
 
                             <List type="unstyled">
                                 <li>
                                     <stong>Your password must contain:</stong>
                                 </li>
                                 {/* {formikProps.errors.password && formikProps.touched.password && ( */}
 
                                 
 
                                 
                                 <li>
 
                           {formikProps?.values?.password?.length >= 8 ? <BsCheck2 className={'text-success'}/> : <BsCheck2 className={'text-danger'}/>}
                                 Minimum 8 characters
 
 
                                     
 
                                 </li>
 
                                 <li>
                                 {/[A-Z]/.test(formikProps?.values?.password) ? <BsCheck2 className={'text-success'}/> : <BsCheck2 className={'text-danger'}/>}
 
                                     At least 1 upper case
                                 </li>
                                 <li>
                                 {/[a-z]/.test(formikProps?.values?.password) ? <BsCheck2 className={'text-success'}/> : <BsCheck2 className={'text-danger'}/>}
 
                                     At least 1 lower case
                                 </li>
                                 <li>
                                 {/\d/.test(formikProps?.values?.password) ?  <BsCheck2 className={'text-success'}/> : <BsCheck2 className={'text-danger'}/>}
 
                                      At least 1 number
                                 </li>
                                 <li>
 
                                 {/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(formikProps?.values?.password) ? <BsCheck2 className={'text-success'}/> : <BsCheck2 className={'text-danger'}/>}
 
                                       At least 1 special character
                                 </li>
                             </List>
                         </FormGroup>
                         <FormGroup>
                             <Label for="confirmPassword">
                                 Confirm Password
                             </Label>
                             <div className="flexContainer">
        
 
                                 <Input
                             className={'mb-0'}
                             type= {cPasswordIcon ? "password":"text"}

                             name="confirm_password"
                             id ="confirm_password"
                             value={formikProps.values.confirm_password}
                             onChange={formikProps.handleChange("confirm_password")}
                             onBlur={formikProps.handleBlur("confirm_password")}
                             error={
                             formikProps.errors.confirm_password && formikProps.touched.confirm_password
                             ? true
                             : false
                             }
                             //   isDisabled
                             />
  <button onClick={()=>{
                                                                        setCpasswordIcon(!cPasswordIcon)
                                                                    }}> 
                                                                    {! cPasswordIcon && ( 
                                                                    <AiOutlineEye /> 
                                                                    )}

                                                                    { cPasswordIcon && ( 
                                                                    <AiOutlineEyeInvisible /> 
                                                                    )}
                                                                    </button>
                           
 
 </div>
 {formikProps.errors.confirm_password && formikProps.touched.confirm_password && (
             //    <Alert status='error' color={'#DD2922'} pl={0} fontWeight={'400'} fontSize={'14px'} lineheight={'20px'}>
 
             <p className={'errorMessage'}>
                {formikProps.errors.confirm_password}
             </p>
          )}
                         </FormGroup>
                         <p> By creating an account, you agree to our <a href="https://floatfunnels.com/terms-conditions/" target={'_blank'} className={'text-primary'}> Terms & Conditions </a>  and have read and
                             acknowledge the <a href="https://floatfunnels.com/privacy/" target={'_blank'} className={'text-primary'}> Privacy Statement</a> .</p>
                         <Button
     disabled={
     !formikProps?.values.email ||
     !formikProps?.values.password ||
     !formikProps?.values.first_name ||
     !formikProps?.values.confirm_password ||

     // !values.confirm_pass ||
     validatePassFormat(formikProps.values)
     }
                     onClick={formikProps.handleSubmit}
 
                         block
                                  color="primary">
          {isLoading ?
                 <span className={'spinnerContainer'}>
                      
                       <Spinner>
                                    Loading...
                                </Spinner></span> : 'Register'}
                             
                         </Button>
                     <h5>Already have an account?</h5>
                     <Link to="/login"  className={'actionIn text-primary'}> Sign in</Link>
                 
     </>
      )}
 </Formik>
                 </div>

            )}
                
          {emailsended && (
                <div className={'createdCheckEmail'}>
                <img src={Logo} alt={"LOGO"} />
                <h1> All Done! <br/>  Check your email.</h1>
                <Alert>
                    <RiErrorWarningLine />
                        <span>We’ve sent a message to <span> {userData?.email} </span> with a link to activate your account. </span>
                </Alert>

                <p> 
                    {/* <a  className={'text-primary'}> Open your mail </a>   and activate your account. */}
                    
                    Didn’t get an email? Check your spam folder!    </p>

                <a onClick={resendEmail} className={'text-primary resendLink'}> <IoIosRefresh size={20} /> Resend Link</a>

                {/* <h5>Already have an account?</h5> */}
                {/*     <Link to="/login"  className={'actionIn text-primary'}> Sign in</Link>*/}
                 
            </div>
          )}


            </>

    )
}

export default Registration;