import React, {useState,useEffect} from 'react';
import Sidebar from "../../../components/sidebar/sidebar";
import Header from "../../../components/header/header";
import ProfileTabs from "../../../components/pages/setting/profileTabs";
import {BsArrowRight } from "react-icons/bs";
import LogoInverse from "../../../images/logoinverse.png"
import { useNavigate,Link,useParams } from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy"
import server from "../../../apis/server"
import toast from '../../../utils/toast' 



const SuspendedComponent = () => {
    const router = useNavigate()
    let { renew} = useParams();

    const [isLoading, setIsLoading] = useState(false);

    const accountsString = localStorage.getItem('accounts')
    const accounts = accountsString ? JSON.parse(accountsString) : ''
    console.log('accounts', accounts)
    const { user_id} = accounts
    const AccountAction = useStoreActions((actions) => actions?.accountState?.setAccount)


    const [mode, setMode] = useState('trialEnd')
    const toggleMode = ()=>{
        if(mode === 'trialEnd'){
            document.getElementsByClassName('trialEnd')
        }
        else{

        }
    }
    const choosePlan = (plan) => {
        router(`/billing`,
            {
                state: {
                    re_active_plan: plan,


                },
            }
        )
    }
   
    


    return (
        <div>
            <main className={'mainContainer'}>
                <Sidebar mode={mode}  toggleMode={toggleMode}/>

                <section className={'internalContainer'}>
                    <Header page={'Suspended Subscription'}/>
                    <div className={'allPages'}>
                        <div className="container">
                            <div className="row align-items-center justify-content-center mt-5">
                                <div className="col-md-8 col-sm-12">
                                    <div className="center-content-closed-store mt-5 py-5">
                                        <img src={LogoInverse} className={'py-3'}/>
                                            <h4 className="text-center">Your Account Has Been Closed</h4>
                                            <p>Thank you for using Float Funnels.</p>
                                            <div className="row justify-content-center mt-5">
                                                <div className="col-md-5 mb-5">
                                                    <div 
                                                    // onClick={choosePlan}
                                                    className="reopen-store">
                                                        <h3 className="mb-2"> Reactivate your Account </h3>
                                                        <h5> 
                                                            {/* To start again, please contact support Thanks. */}
                                                            To reactivate your account please choose a plan by clicking on re-activate button bellow.

                                                             </h5>
                                                        <button onClick={()=>choosePlan('re_activate')} type="button" className="btn primary-btn-modal mt-3 shadow-none ">Re-activate Now</button>
                                                        {/* <a className="move-action-link"> <BsArrowRight /> </a> */}
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>


            </main>
        </div>
    );
};

export default SuspendedComponent;
