import { action, computed, thunk } from "easy-peasy"

export const accounts = {
    account: {payload : null },
    setAccount: action((state, payload) => {
    state.account = { payload }
  }),

}

export default accounts;
