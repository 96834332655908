import { useEffect } from "react";

function useGrapesJsButtonTooltips() {
  useEffect(() => {
    const setButtonTitle = (selector, title) => {
      const button = document.querySelector(selector);
      if (button) {
        button.setAttribute("title", title);
      }
    };

    setButtonTitle(".gjs-pn-btn.fa.fa-undo", "Undo");
    setButtonTitle(".gjs-pn-btn.fa.fa-repeat", "Redo");
    setButtonTitle(".gjs-pn-btn.fa.fa-download", "Import");
    setButtonTitle(".gjs-pn-btn.fa.fa-trash", "Delete");
    setButtonTitle(".gjs-pn-btn.fa.fa-desktop", "Desktop View");
    setButtonTitle(".gjs-pn-btn.fa.fa-square-o", "View widgets");
    setButtonTitle(".gjs-pn-btn.fa.fa-code", "View HTML Code");
    setButtonTitle(".gjs-pn-btn.fa.fa-paint-brush", "Open Widgets");
    setButtonTitle(".gjs-pn-btn.fa.fa-arrow-left", "Back Arrow");
  }, []);
}

export default useGrapesJsButtonTooltips;
