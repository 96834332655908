import React  from "react";
import AccountsWorkspace from "../../../components/common/accounts/account"
import { GrFormClose } from "react-icons/gr";
import Reset from "../../../components/common/accounts/resetpassword";
import RightSide from "../../../components/common/accounts/right";
export default function Detail() {

    return (
        <div>
            <div className="accounts-wrapper"> 

                <div className={'left-side-wrapper'}>
                    <Reset />
                </div>
                <div className={'right-side-wrapper'}>
                    <RightSide/>
                </div>



            </div>

        </div>
    )
}