import 'bootstrap/dist/css/bootstrap.min.css';
import LoaderImg from "../../../images/preview.gif"  
const loader = () => {
    return (
        <>
            <div className='componentLoader'>
                <img src={LoaderImg} alt="Laoding" />
            </div>        </>
    );
};

export default loader;
