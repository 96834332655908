
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter,HashRouter } from "react-router-dom";
import App from "./app";
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/globals.scss'
import store from "./store/index"
import { StoreProvider } from "easy-peasy"
import {ToastContainer} from "react-toastify";

if (process.env.REACT_APP_CONSOLE === 'true') {
        console.log = () => {}
        console.error = () => {}
        console.debug = () => {}
        // console.warn = () => {}
      }
      function stoperror() {
              return true;
        };
        
        window.onerror = stoperror;


//       function stoperror() {
//               return true; 
//         };
//         window.onerror = stoperror;
        console.log = console.error = () => {};

ReactDOM.render(
        <BrowserRouter>

<StoreProvider store={store}>
        <ToastContainer
        closeButton ={false}

        />
        <App />
         

               
        
</StoreProvider>

</BrowserRouter>,
  document.getElementById("root")
);



