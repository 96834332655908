/** 
  All of the routes  are added here,
  You can add a new route, customize the routes and delete the routes here.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

//  layouts

import Home from './pages/index'
import PagesList from "./pages/pages/index"
import TemplateList from "./pages/pages/templates/index"
import FunnelList from "./pages/pages/funnelPages/index"
import PageDetail from "./pages/pages/detail/detail"
import PageCheck from "./pages/pages/versionCheck"

import PageEditorV0 from "./pages/pages/editor_v0/editor_v0"
import PageEditorV1 from "./pages/pages/editor_v1/editor_v1"

import DomainList from "./pages/pages/domain/index"
import DomainDetail from "./pages/pages/domain/detail/detail"
import DomainSetup from "./pages/pages/domain/setup"
import IntegrationList from "./pages/pages/integration/index"
import UserSettings from "./pages/pages/setting/index"
import Accounts from "./pages/accounts/index"
import Login from "./pages/accounts/login"
import SignUp from "./pages/accounts/registration/index"
import Billing from "./pages/accounts/billing/index"
import Verify from "./pages/accounts/verify/index"
import VerifyPayment from './pages/accounts/verifypayment'
import Auth from "./pages/accounts/auth/index"
import Ghost from "./pages/accounts/ghostauth/index"
import ServerCheck from "./pages/serverCheck/index"
import SuspendedComponent from "./pages/pages/suspendedSubscription";
import VerifySuspendedComponent from "./pages/accounts/verifysuspended/index";



const routes = [
  {
    // type: "collapse",
    name: "Home",
    key: "Home",
    route: "/",
    component: <Home />,
  },
  {
    // type: "collapse",
    name: "accounts",
    key: "accounts",
    route: "/accounts",
    component: <Accounts />,
  },
  {
    // type: "collapse",
    name: "auth",
    key: "auth",
    route: "auth/user/:token",
    component: <Auth />,
  },


  {
    // type: "collapse",
    name: "ghost",
    key: "ghost",
    route: "ghost/user/:token",
    component: <Ghost />,
  },




  // <Route  path="auth/user/:token" element= {<Auth />} key="auth" />;

  // <Route exact path="/billing" element= {<Billing />} key="SignUp" />;
  {
    // type: "collapse",
    name: "billing",
    key: "billing",
    route: "/billing",
    component: <Billing />,
  },
  {
    // type: "collapse",
    name: "verifyPayment",
    key: "verifyPayment",
    route: "/confirm/:hash",
    component: <VerifyPayment />,
  },

  {
    // type: "collapse",
    name: "domain",
    key: "domain",
    route: "/pages/domain",
    component: <DomainList />,
  },
  {
    // type: "collapse",
    name: "templates",
    key: "templates",
    route: "pages/templates",
    component: <TemplateList />,
  },
  {
    // type: "collapse",
    name: "integration",
    key: "integration",
    route: "/pages/integration/",
    component: <IntegrationList />,
  },
  {
    // type: "collapse",
    name: "setting",
    key: "setting",
    route: "/pages/setting/",
    component: <UserSettings />,
  },
  {
    // type: "collapse",
    name: "billingsetting",
    key: "billingsetting",
    route: "/pages/setting/:setting",
    component: <UserSettings />,
  },
  {
    // type: "collapse",
    name: "billingsettingpp",
    key: "billingsettingpp",
    route: "/pages/setting/:setting/:pp",
    component: <UserSettings />,
  },
  {
    // type: "collapse",
    name: "detail",
    key: "detail",
    route: "/pages/detail/:detail",
    component: <PageDetail />,
  },
  {
    // type: "collapse",
    name: "editor",
    key: "editor",
    route: "/pages/editor/:editorpage",
    component: <PageEditorV1 />,
  },
  {
    // type: "collapse",
    name: "editorv2",
    key: "editorv2",
    route: "/pages/editor_v0/:editorpage",
    component: <PageEditorV0 />,
  },
  // {
  //   // type: "collapse",
  //   name: "editornew",
  //   key: "editornew",
  //   route: "/pages/editornew/:editorpage",
  //   component: <PageEditorNew />,
  // },
  
  {
    // type: "collapse",
    name: "domaindetail",
    key: "domaindetail",
    route: "/pages/domain/:domainName",
    component: <DomainDetail />,
  },
  {
    // type: "collapse",
    name: "domain",
    key: "domain",
    route: "/pages/domain/setup",
    component: <DomainSetup />,
  },
  {
    // type: "collapse",
    name: "domainStatus",
    key: "domainStatus",
    route: "/pages/domain/setup/:domainName",
    component: <DomainSetup />,
  },
  {
    // type: "collapse",
    name: "funnel",
    key: "funnel",
    route: "/pages/funnel/:funnel",
    component: <FunnelList />,
  },
  {
    // type: "collapse",
    name: "pages",
    key: "pages",
    route: "/pages",
    component: <PagesList />,
  },

  {
    // type: "collapse",
    name: "pagesFunnel",
    key: "pagesFunnel",
    route: "/pages/funnels/:funnelName",
    component: <PagesList />,
  },
  {
    // type: "collapse",
    name: "login",
    key: "login",
    route: "/login",
    component: <Login />,
  },
  {
    // type: "collapse",
    name: "SignUp",
    key: "SignUp",
    route: "/SignUp",
    component: <SignUp />,
  },
  {
    // type: "collapse",
    name: "Billing",
    key: "Billing",
    route: "/billing",
    component: <Billing /> ,
  },
  {
    // type: "collapse",
    name: "Verify",
    key: "Verify",
    route: "/Verify/:token",
    component: <Verify /> ,
  },
  {
    // type: "collapse",
    name: "Ok",
    key: "ok",
    route: "/ok.html",
    component: <ServerCheck /> ,
  },
  {
    // type: "collapse",
    name: "SuspendedComponent",
    key: "SuspendedComponent",
    route: "/suspended",
    component: <SuspendedComponent /> ,
  },

  {
    // type: "collapse",
    name: "checkSuspended",
    key: "CheckSuspendedComponent",
    route: "/verify/suspended/:active",
    component: <VerifySuspendedComponent /> ,
  },
  
  
];

export default routes;  
