import React, {useState,useEffect} from 'react'
import {Button, ButtonGroup, Card, CardBody, CardTitle, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import InternalTitle from "../internalTittle/GenaricButton";
import {BsArrowDown, BsArrowUp} from "react-icons/bs";
import Audience from "../../analytics/audience";
import Behavior from "../../analytics/behavior";
import Events from "../../analytics/events";
import Demograhics from "../../analytics/demographics";
import Geo from "../../analytics/geo";
import Browser from "../../analytics/browser";
import Device from "../../analytics/device";
import Filters from "../../analytics/filtersButton";

import Traffic from "../../analytics/traffic";
import StatAnalytics from "../../analytics/stats";
import LabelStats from "../../analytics/labelStatButton";
import server from "../../../apis/server"
import moment from 'moment'
import Loader from "../../common/loader/loader";

// import toast from '../../../utils/toast'
// import { ToastContainer } from "react-toastify";

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";


function NavTabView({detail}) {
    const [activeTab, setActiveTab] = useState('1');
    const [isLoading, setIsLoading] = useState(false);

    const [audienceData, setAudienceData] = useState([]);
    const [trafficData, setTrafficData] = useState([]);
    const [geoData, setGeoData] = useState([]);
    const [dateFilterSelected, setdateFilterSelected] = useState(2);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    useEffect(() => {

        getTemplateData()
    }, [dateFilterSelected,dateRange])



    const getTemplateData = async () => {

        try {

         let filter =    dateFilterSelected ==1  ? 'today' : dateFilterSelected ==2 ? 'week' :dateFilterSelected==3 ? 'month' : 'custom'
         let date_from = moment(startDate).format("YYYY-MM-DD")
         let date_to = moment(endDate).format("YYYY-MM-DD")
         if(dateFilterSelected==4 && endDate==null){
            //  alert('cc')
              return

         }


            // let filter = 
            // dateFilterSelected == 1 
            // ? 'today'
            // : dateFilterSelected == 2
            //     ? 'week'
            //       ? dateFilterSelected == 3
            //       : 'month'
            //     : null
            setIsLoading(true)

     const {data} = await server.get(`analytics/get?page_id=${detail}&filter=${filter} ${dateFilterSelected==4 ? `&date_from=${date_from}&date_to=${date_to}`:''} `);
            if (data?.status) {
                console.log('cc',data?.data?.users_x_axis_val)
                setAudienceData(data?.data?.users_x_axis_val)
                setTrafficData(data?.data)
                setGeoData(data?.data)

            }
            console.log(data)
            // setProduct(data);
            setIsLoading(false);

        } catch (e) {
            setIsLoading(false);

            if (e.response?.data?.status === false) {
                console.log(e.response?.data.errors?.title[0])
                // setError(e.response?.data.errors?.title[0])
            } else {
                // setError(e.message)
            }


        }
    }

    return (

        <div className={'tabsLayout'}>
              {isLoading &&
                (
                    <div className={'loaderPageEditor domainloader'}>

                        <Loader />
                    </div>

                )}

            <div className={`tabsNav`}>
                <div className="container">
                    <Nav>
                        <NavItem>
                            <NavLink className={activeTab == '1' ? 'active' : ''}
                                     onClick={() => setActiveTab('1')}>
                                Analytics
                            </NavLink>
                        </NavItem>

                        {/*<NavItem>*/}
                        {/*    <NavLink className={activeTab == '2' ? 'active' : ''}*/}
                        {/*             onClick={() => setActiveTab('2')}>*/}
                        {/*        Integrations*/}
                        {/*    </NavLink>*/}
                        {/*</NavItem>*/}
                        {/*<NavItem>*/}
                        {/*    <NavLink className={activeTab == '3' ? 'active' : ''}*/}
                        {/*             onClick={() => setActiveTab('3')}>*/}
                        {/*        Settings*/}
                        {/*    </NavLink>*/}
                        {/*</NavItem>*/}
                    </Nav>
                </div>
            </div>
            <div className="container mb-5">
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <div className="row pt-5 justify-content-end">
                            <div className="col-md-6 col-12 ">
                                <div className="float-end">
                                    <div className={'row'}>
                                        <div className={'col-md-12'}>
                                            <ButtonGroup className={'mt-1 mb-2 float-end'}>
                                                <Button
                                                    color="secondary"
                                                    outline
                                                    onClick={() => {setdateFilterSelected(1)
                                                    setDateRange([null, null])
                                                    }}
                                                    active={dateFilterSelected === 1}
                                                >
                                                    Today
                                                </Button>
                                                <Button
                                                    color="secondary"
                                                    outline
                                                    onClick={() => {
                                                        setdateFilterSelected(2) 
                                                        setDateRange([null, null])

                                                    }}
                                                    active={dateFilterSelected === 2}
                                                >
                                                    Week
                                                </Button>
                                                <Button
                                                    color="secondary"
                                                    outline
                                                    onClick={() => 
                                                        {
                                                            setdateFilterSelected(3)
                                                            setDateRange([null, null])
   
                                                    }}
                                                    active={dateFilterSelected === 3}
                                                >
                                                    Month
                                                </Button>
                                                <DatePicker
                                                    selectsRange={true}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                     maxDate={new Date()}

                                                    onChange={(update) => {
                                                        setDateRange(update);

                                                        // alert(endDate)

                                                            setdateFilterSelected(4)

                                                        // alert('ok')
                                                        console.log('range',update)
                                                    }}
                                                    //isClearable={true}
                                                    placeholderText="Please select custom range"
                                                />
                                            </ButtonGroup>
                                        </div>
                                    </div>
                                    {/* <div className={'row'}>
                                        <div className={'col-md-12'}>
                                            <p> <strong>  Selected: </strong> {dateFilterSelected} </p>
                                        </div>
                                    </div> */}
                                </div>

                            </div>
                        </div>
                        <div className="row pb-5">
                            <div className="col-md-6 col-12 ">
                                <InternalTitle title={'AUDIENCE'}/>
                                <div className="widget-analytics">
                                    <div className="widget-header">
                                        <StatAnalytics data ={trafficData} />
                                    </div>
                                    <div className="widget-graph">
                                        <Audience data={audienceData} xaxis ={trafficData}/>
                                    </div>
                                </div>
                            </div>
                            {/*<div className="col-md-6 col-12 ">*/}
                            {/*    <InternalTitle title={'DEMOGRAPHICS'}/>*/}
                            {/*    <div className="widget-analytics">*/}
                            {/*        <div className="widget-header">*/}
                            {/*            <LabelStats/>*/}
                            {/*        </div>*/}
                            {/*        <div className="widget-graph">*/}
                            {/*            <Demograhics/>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="col-md-6 col-12 ">*/}
                            {/*    <InternalTitle title={'BEHAVIOR'}/>*/}
                            {/*    <div className="widget-analytics">*/}
                            {/*        <div className="widget-header">*/}
                            {/*            <LabelStats/>*/}
                            {/*        </div>*/}
                            {/*        <div className="widget-graph">*/}
                            {/*            <Behavior/>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="col-md-6 col-12 ">*/}
                            {/*    <InternalTitle title={'EVENTS'}/>*/}
                            {/*    <div className="widget-analytics">*/}
                            {/*        <div className="widget-header">*/}
                            {/*            <LabelStats/>*/}
                            {/*            <div className={'mt-3'}></div>*/}
                            {/*            <StatAnalytics />*/}
                            {/*        </div>*/}
                            {/*        <div className="widget-graph">*/}
                            {/*            <Events height='300px'/>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}

                            {/*</div>*/}
                            <div className="col-md-6 col-12 ">
                                <InternalTitle title={'TRAFFIC'}/>
                                <div className="widget-analytics">
                                    <div className="widget-header">
                                        <LabelStats/>
                                    </div>
                                    <div className="widget-graph">
                                        <Traffic data={trafficData}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 ">
                                <InternalTitle title={'GEO'}/>
                                <div className="widget-analytics">
                                    <div className="widget-header">
                                        <button className="active btn btn-outline-secondary">Country
                                        </button>
                                    </div>
                                    <div className="widget-graph">
                                        <Geo data={geoData}/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-12 ">
                                <InternalTitle title={'Browser'}/>
                                <div className="widget-analytics">
                                    <div className="widget-header">
                                        <button className="active btn btn-outline-secondary"> Browser
                                        </button>
                                    </div>
                                    <div className="widget-graph">
                                        <Browser data={geoData}/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-12 ">
                                <InternalTitle title={'Device'}/>
                                <div className="widget-analytics">
                                    <div className="widget-header">
                                        <button className="active btn btn-outline-secondary"> Device
                                        </button>
                                    </div>
                                    <div className="widget-graph">
                                        <Device data={geoData}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPane>
                    {/*<TabPane tabId="2">*/}
                    {/*    <div className="col-md-6 col-12 py-5 ">*/}
                    {/*        <InternalTitle title={'INTEGRATIONS'}/>*/}
                    {/*    </div>*/}
                    {/*</TabPane>*/}
                    {/*<TabPane tabId="3">*/}
                    {/*    <div className="col-md-7 col-12 py-5 ">*/}
                    {/*        <Card body>*/}
                    {/*            <CardTitle>*/}
                    {/*                Add Domain*/}
                    {/*            </CardTitle>*/}
                    {/*            <CardBody>*/}
                    {/*                With supporting text below as a natural lead-in to*/}
                    {/*                additional content.*/}
                    {/*            </CardBody>*/}
                    {/*            <Button>*/}
                    {/*                Go somewhere*/}
                    {/*            </Button>*/}
                    {/*        </Card>*/}

                    {/*    </div>*/}
                    {/*    <div className="col-md-5 col-12 py-5">*/}

                    {/*    </div>*/}

                    {/*</TabPane>*/}
                </TabContent>
            </div>

        </div>

    )
}

export default NavTabView;