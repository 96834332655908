import React,{useState,useEffect} from "react";
import Logo from "../../../images/logoinverse.png"
import {Form, FormGroup, Label, Input, Button, List, Alert, Row, Col,Spinner} from "reactstrap";
import {BsCheck2, BsX} from "react-icons/bs";
import { AiOutlineEyeInvisible,AiOutlineEye } from "react-icons/ai";
import { IoIosRefresh } from "react-icons/io";
import { RiErrorWarningLine } from "react-icons/ri";
import IconCheckMark from "../../../images/icon-check-success.png";
import { Formik } from "formik"; 
import * as yup from "yup";
import server from "../../../apis/server"
import Loader from "../loader/loader";
import toast from '../../../utils/toast' 
import { useNavigate,useParams,Link } from "react-router-dom";



function ResetPassword() {

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingVerify, setIsLoadingVerify] = useState(false);
    
    const [passwordIcon, setPasswordIcon] = useState(true);
    const [cPasswordIcon, setCpasswordIcon] = useState(true);
    const [resetVerified, setResetVerified] = useState(false);

    const [resetPassword, setResetPassword] = useState(false);
    const [error, setError] = useState('');

    let { token } = useParams();


    const router = useNavigate()

    let validationSchema = yup.object({ 
        

        confirm_password: yup
  .string().required()
  .oneOf([yup.ref('password'), null], 'Passwords must match')
        
        });
       async function handleReset(values,resetForm)
       {
           setIsLoading(true)
   
           let formData={
               token,
               password: values?.password,
           }
             try {
               const { data } = await server.post(`user/reset_password`,
                   formData,
               );
   
               if(data?.status){
                   
                toast(data?.message, 'success')
                  setResetPassword(true)

                // AccountAction(data?.data)
             
                   // isLoading(true)
               }
              console.log(data)
               // setProduct(data);
               setIsLoading(false);
             } catch (e) {
                if(e.response?.data?.status===false){
                    if(e.response?.data.errors_array?.[0]){
                    toast(e.response?.data.errors_array?.[0], 'error')
                  }
                  else{
                      toast(e.response?.data?.message, 'error')
                      
                  }
                     //  setError(e.response?.data.errors?.title[0])
                      setIsLoading(false)
                  }
                  else{
                      setIsLoading(false)
 
                  }

   
           }
       }


       useEffect(() => {
        if(token){
         verifyToken()
        }
     }, [])


     const verifyToken = async () => {

        try {
            setIsLoadingVerify(true)
            
        

            const {data} = await server.get(`account/verify/password/${token}`);
            if (data?.status) {
                
                // setpackages(data?.data)
                toast(data?.message, 'success')
                setResetVerified(true)
                            //    router(`/login`)

                
            }
            else{
                toast(data?.message, 'error')
                setError(data?.message)

                // router(`/login`)


            }
            console.log(data)
            // setProduct(data);
            setIsLoadingVerify(false);

        } catch (e) {
            if (e.response?.data?.status === false) {
                console.log(e.response?.data.errors?.title[0])
                toast(e.response?.data?.message, 'error')
                setError(e.response?.data?.message)

                // router(`/login`)

                // setError(e.response?.data.errors?.title[0])
                setIsLoadingVerify(false);
            } else {
                setIsLoadingVerify(false)
                setError(e.message)
            }


        }
    }

    const validatePassFormat = (values) => {
        return (
          !/[a-z]/.test(values?.password) ||
          !/[A-Z]/.test(values?.password) ||
          values?.password?.length < 8 ||
          !/\d/.test(values?.password)
        //    ||
        //   !/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(values?.password)
        );
      };

 
    return (

        <>
           {isLoadingVerify &&
       (


            <div className={'loaderPageEditor'}>
                <Loader/>
            </div>
            
       )}
           {!resetPassword  &&(
            <div className={'reset-flow '}>
                  
                       <Formik
                       initialValues={{ 
                           password:'',
                           confirm_password:''
                        }}
                        validationSchema={validationSchema}
                       onSubmit={(values, { resetForm }) => {
                         handleReset(values, resetForm);
                       }} 
                       > 
                          {(formikProps) => ( 
                  
                  <>
                          <img src={Logo} alt={"LOGO"} className={'mb-5'} />
                          <h1 className={'mt-5'}>Forgot Password</h1>
                          
          
          
                          {resetVerified &&(   
                              <>
                          <FormGroup>
                                       <Label for="Password">
                                         New Password
                                       </Label>
                                       <div className="flexContainer">
                  
           
                                                           <Input
                                       className={'mb-0'}
                                            type= {passwordIcon ? "password":"text"}
                                                   name="password"
                                                   
                                                   id ="password"
                                                   value={formikProps.values.password}
                             onChange={formikProps.handleChange("password")}
                             onBlur={formikProps.handleBlur("password")}
                             error={
                               formikProps.errors.password && formikProps.touched.password
                                 ? true
                                 : false
                             }
                           //   isDisabled 
                                                  />
                                                                              <button onClick={()=>{
                                                                                  setPasswordIcon(!passwordIcon)
                                                                              }}> 
                                                                              {! passwordIcon && ( 
                                                                              <AiOutlineEye /> 
                                                                              )}
                                                                              { passwordIcon && ( 
                                                                              <AiOutlineEyeInvisible /> 
                                                                              )}
                                                                              </button>
          
           
                                                  </div>
           
                                       <List type="unstyled">
                                           <li>
                                               <stong>Your password must contain:</stong>
                                           </li>
                                           {/* {formikProps.errors.password && formikProps.touched.password && ( */}
           
                                           
           
                                           
                                           <li>
           
                                     {formikProps?.values?.password?.length >= 8 ? <BsCheck2 className={'text-success'}/> : <BsCheck2 className={'text-danger'}/>}
                                           Minimum 8 characters
           
           
                                               
           
                                           </li>
           
                                           <li>
                                           {/[A-Z]/.test(formikProps?.values?.password) ? <BsCheck2 className={'text-success'}/> : <BsCheck2 className={'text-danger'}/>}
           
                                               At least 1 upper case
                                           </li>
                                           <li>
                                           {/[a-z]/.test(formikProps?.values?.password) ? <BsCheck2 className={'text-success'}/> : <BsCheck2 className={'text-danger'}/>}
           
                                               At least 1 lower case
                                           </li>
                                           <li>
                                           {/\d/.test(formikProps?.values?.password) ?  <BsCheck2 className={'text-success'}/> : <BsCheck2 className={'text-danger'}/>}
           
                                                At least 1 number
                                           </li>
                                           <li>
           
                                           {/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(formikProps?.values?.password) ? <BsCheck2 className={'text-success'}/> : <BsCheck2 className={'text-danger'}/>}
           
                                                 At least 1 special character
                                           </li>
                                       </List>
                                   </FormGroup>
          
                              
                                   <FormGroup>
                                       <Label for="confirmPassword">
                                           Confirm Password
                                       </Label>
                                       <div className="flexContainer">
                  
           
                                           <Input
                                       className={'mb-0'}
                                       type= {cPasswordIcon ? "password":"text"}
          
                                       name="confirm_password"
                                       id ="confirm_password"
                                       value={formikProps.values.confirm_password}
                                       onChange={formikProps.handleChange("confirm_password")}
                                       onBlur={formikProps.handleBlur("confirm_password")}
                                       error={
                                       formikProps.errors.confirm_password && formikProps.touched.confirm_password
                                       ? true
                                       : false
                                       }
                                       //   isDisabled
                                       />
            <button onClick={()=>{
                                                                                  setCpasswordIcon(!cPasswordIcon)
                                                                              }}> 
                                                                              {! cPasswordIcon && ( 
                                                                              <AiOutlineEye /> 
                                                                              )}
          
                                                                              { cPasswordIcon && ( 
                                                                              <AiOutlineEyeInvisible /> 
                                                                              )}
                                                                              </button>
                                     
           
           </div>
           {formikProps.errors.confirm_password && formikProps.touched.confirm_password && (
                       //    <Alert status='error' color={'#DD2922'} pl={0} fontWeight={'400'} fontSize={'14px'} lineheight={'20px'}>
           
                       <p className={'errorMessage'}>
                          {formikProps.errors.confirm_password}
                       </p>
                    )}
                                   </FormGroup>
                                   <Button
               disabled={
              //  !formikProps?.values.password &&
              //  !formikProps?.values.confirm_password &&
               validatePassFormat(formikProps.values)
               }
                               onClick={formikProps.handleSubmit}
           
                                   block
                                            color="primary">
                    {isLoading ?
                           <span className={'spinnerContainer'}>
                                
                                 <Spinner>
                                              Loading...
                                          </Spinner></span> : 'Reset Password'}
                                       
                                   </Button>
                                  </>
                          )}
                          {error &&(
                                <p className={'errorMessage'}>
                                {error}
                             </p>
                          )}
                          <h6 className={'text-center mt-4'}> Go back to  <Link to="/login" className={'text-primary bold '}> Sign in</Link></h6>
                     
                          </>
                )}
               </Formik>
                  

            
  </div>)}

  {  resetPassword  &&(
       <div className={'changed-succesfull-flow '}>
                <img src={Logo} alt={"LOGO"} className={'mb-5 '}/>
                <img src={IconCheckMark} alt={'Check Payment'} className={'text-center mt-5 pt-5 d-block m-auto'}/>
                <h1 className={'text-center'}> Your password has <br/> been changed.</h1>
                <p className={'text-center'}>  You can now use your new password to log in <br/> to your account.</p>
             <Link to = "/login">  <Button  className={'mt-5 text-center d-block m-auto px-4'}
                         color="primary">
                    Sign In
                </Button>
                </Link>
            </div>
  )}
   
        </>

    )
}

export default ResetPassword;