import Header from "../../../components/header/header";
import React from 'react';
import Sidebar from "../../../components/sidebar/sidebar";
import ProfileTabs from "../../../components/pages/setting/profileTabs";


export default function Setting() {


    return (
        <div>
            <main className={'mainContainer'}>
                <Sidebar/>

                <section className={'internalContainer'}>
                    <Header page={'Settings'}/>
                    <div className={'allPages'}>
                        <div className="container">
                                <ProfileTabs />

                        </div>
                    </div>

                </section>


            </main>
        </div>
    )
}
