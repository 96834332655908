import { useEffect } from 'react';
import isEqual from 'lodash/isEqual';

const useHtmlComparison = ({ html, editor, draftData, setIsDisabled, updateUi, draftLoading }) => {
    useEffect(() => {
        const isEqualWithoutIds = (obj1, obj2) => {
            // Remove the "id" attribute from the body element in both objects
            const removeIdFromBody = (obj) => {
                const newObj = { ...obj };

                const bodyElement = newObj.html.match(/<body.*?>/);
                if (bodyElement) {
                    newObj.html = newObj.html.replace(bodyElement[0], bodyElement[0].replace(/ id="[^"]*"/, ''));
                }
                return newObj;
            };

            const sanitizedObj1 = obj1;
            const sanitizedObj2 = obj2

            console.warn('publish html', sanitizedObj1)
            console.warn('draft html', sanitizedObj2)

            return isEqual(sanitizedObj1, sanitizedObj2);
        };

        //   console.warn('html',html)
        if (html && editor && draftData) {
            //  alert('cc')
            let obj1 = draftData ? JSON.parse(draftData) : ''
            let obj2 = html

            const areEqual = isEqualWithoutIds(obj1, obj2);

            setIsDisabled(areEqual)



            // alert(areEqual)


        }


    }, [html, updateUi, draftLoading, editor, draftData])


};

export default useHtmlComparison;
