import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import routes from "./routes";
import Accounts from "./pages/accounts/index"
import PagesList from "./pages/pages/index"
import TemplateList from "./pages/pages/templates/index"

import { useStoreState, useStoreActions } from "easy-peasy"
import LogIn from "./pages/accounts/login/index";
import SignUp from "./pages/accounts/registration/index";
import Billing from "./pages/accounts/billing/index"
import Verify from "./pages/accounts/verify/index"
import Forgot from "./pages/accounts/forgotpassword/index"
import Reset from "./pages/accounts/resetpassword/index"
import Auth from "./pages/accounts/auth/index"
import Ghost from "./pages/accounts/ghostauth/index"
import ServerCheck from "./pages/serverCheck/index"
import ExpirePlanStripe from "./components/billing/expirePlanStrip";
import SuspendedComponent from "./pages/pages/suspendedSubscription/index";
import VerifySuspendedComponent from "./pages/accounts/verifysuspended/index";

import VerifyPayment from "./pages/accounts/verifypayment";
import UserSettings from "./pages/pages/setting/index"





export default function App() {
    const { pathname } = useLocation();
    const [account,setAccount] = useState(false)
    
    const accountsString = localStorage.getItem('accounts')
    const accounts =    accountsString ? JSON.parse(accountsString) : ''
    // const accounts = useStoreState((state) => state?.accountState?.state?.payload)
    const LoadingState = useStoreState((state) => state?.LoadingState?.LoadingState)


    // alert(location.pathname.indexOf("/pages/editor/") == 0)

           



    // Setting the dir attribute for the body element

    // Setting page scroll to 0 when changing the route
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;


// alert(LoadingState)

    }, [pathname]);


    

    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            // if (accounts&& accounts?.subscription_status=='expired') {

            
            //     return   <Route  path="suspendedComponent" element={route.component} key={route.key} />;

            // }
         
            if (!accounts) {
                console.log('cc')
                return (
                    <Route>
                 <Route exact path="/login" element= {<LogIn />} key="login" />
                 <Route exact path="/forgot" element= {<Forgot />} key="forgot" />

                 <Route exact path="/SignUp" element= {<SignUp />} key="SignUp" />;


                 {/* <Route exact path="/billing" element= {<Billing />} key="SignUp" />; */}
                 <Route exact path="/Verify/:token" element= {<Verify />} key="Verify" />; 
                 <Route exact path="/Verify/:token" element= {<Verify />} key="Verify" />;
                 <Route  path="password/verify/:token" element= {<Reset />} key="reset" />;
                 <Route  path="auth/user/:token" element= {<Auth />} key="auth" />;
                 <Route  path="ghost/user/:token" element= {<Ghost />} key="ghost" />;
                 <Route  path="/ok.html" element= {<ServerCheck />} key="ok" />; 


                 
                 {/* <Route exact path="/accounts" element= {<Accounts />} key="accounts" />; */}

                 </Route>

                ) 

            }
           

        if ((accounts?.subscription_status=='expired' ||accounts?.subscription_status=='cancel') && accounts?.account_status === 'expired' && accounts?.is_grace_period === false) {
                
            if(route.route=='/billing' || location.pathname.indexOf("/confirm") == 0){
                // alert('cc')
                        return (
                        <Route>  
                             <Route exact path="/billing" element= {<Billing />} key="Billing" />
                             <Route exact path="/confirm/:hash" element= {<VerifyPayment />} key="verifyPayment" />


                             </Route>
                        )
                      
            }   
   
            
            else{
                return (
                    <Route>                  <Route  path="/verify/suspended/:active" element={<VerifySuspendedComponent />} key={route.key} />
                  <Route  path="/suspended" element={<SuspendedComponent />} key={route.key} />
                  </Route>

                
                
                  )

            }
            
 
        }
       

       if ((accounts?.subscription_type==='paid' || accounts?.subscription_type==='trial') &&
        accounts?.is_pause_plan=== true &&
         accounts?.account_status === 'active'  
         && accounts?.subscription_status ==='pause' &&   accounts?.is_active_pp == true)
         {
            return   (
            <Route >

            <Route  path="/pages/setting/" element={<UserSettings />}  key={route.key} />;
            <Route exact path="/billing" element= {<Billing />} key="Billing" />
                             <Route exact path="/confirm/:hash" element= {<VerifyPayment />} key="verifyPayment" />

            </Route>
            )
         } 

         


           
            if (accounts) {

            
                return   <Route  path={route.route} element={route.component} key={route.key} />;

            }
            


            if (route.collapse) {
                return getRoutes(route.collapse);
            }


            if (route.route) {
                return <Route  path={route.route} element={route.component} key={route.key} />;
            }




            return null;
        });


    return (
       <>


        <Routes>

        
            {getRoutes(routes)}

            {(accounts?.subscription_status=='expired' || accounts?.subscription_status=='cancel')   && (
                <Route path="*" element={<Navigate to="/verify/suspended/:active" />} />
            )} 
         


         {(accounts?.subscription_type==='paid' || accounts?.subscription_type==='trial') &&
   accounts?.is_pause_plan=== true &&
    accounts?.account_status === 'active'  
    && accounts?.subscription_status ==='pause' &&   accounts?.is_active_pp == true && (
                <Route path="*" element={<Navigate to="/pages/setting/" />} />
            )} 
            
           
            {accounts  && (
                <Route path="*" element={<Navigate to="/" />} />
            )}
              

            {!accounts && (
                <Route path="*" element={<Navigate to="/login" />} />
            )}


        </Routes>

     
         { !location.pathname.indexOf("/pages/editor/") == 0 && (

        <div className="embed-button right-embed-button">
            <a href={'https://help.floathosting.com/new-ticket/'} target={'_blank'}>
                {/*<a href={'https://support.123profit.com/'} target={'_blank'}>*/}
                <div className="embed-button-text"> Support</div>
                </a>
                </div>
       )
                  }

                  

              {accounts && !location.pathname.indexOf("/pages/editor/") == 0 && location.pathname!='/billing' && !location.pathname.indexOf("/confirm") == 0&& (
                  <ExpirePlanStripe updateBanner ={LoadingState} />

              )}

</>
    );
}
