import React, { useState, useEffect } from 'react';
import PaymentImg from '../../images/payment-method.png'
import InternalTitle from "../common/internalTittle/GenaricButton";
import { useNavigate, Link } from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy"


function ExpirePlanStripe({ updateBanner }) {

    const AccountState = useStoreState((state) => state?.accountState?.account?.payload)

    const [accounts, setAccounts] = useState({

        subscription_type: '',
        subscription_status: '',
        is_grace_period: '',
        account_status: '',
        is_pause_plan: '',
        is_active_pp: '',
        acc_close_date: '',
        remaining_days_date: '',
        subscription_package_name: '',
        pause_plan_schedule_date: '',
        subscription_end_date: '',
        is_cancelled: '',
        change_plan_schedule_date: '',
        change_plan_name:''
    });

    const router = useNavigate()

    const choosePlan = (plan) => {
        router(`/billing`,
            {
                state: {
                    re_active_plan: plan,


                },
            }
        )
    }



    useEffect(() => {

        setAccounts(AccountState)

        //  console.warn('accounts cancelled',account)
        //  alert('cc')
    }, [AccountState])
    // const {subscription_type,subscription_status,is_grace_period,account_status,is_pause_plan,is_active_pp,acc_close_date,remaining_days_date,subscription_package_name,pause_plan_schedule_date,subscription_end_date,is_cancelled} = accounts


    return (


        <>

            {accounts?.subscription_status != "active" && (

                <div>

                    {(accounts?.subscription_type === 'trial' && accounts?.subscription_status === 'expired' && accounts?.is_grace_period === true) &&
                        (

                            <div className="choose-plan choose-warning">
                                <div className="d-flex justify-content-center align-items-center">

                                    <p className="mb-0 mr-3">

                                        Your Float funnels trial period has expired. You are now on grace period until <strong> {accounts?.remaining_days_date}  </strong>
                                        due to non-payment. Your subscription will be ended if payment not succedded.


                                    </p>
                                </div>
                            </div>
                        )
                    }




                    {/* "accounts?.subscription_type": "trial" AND "accounts?.subscription_status": "expired" AND  "accounts?.is_grace_period": false = TRIAL Expired, Grace Period Ended. will go to choose plan */}


                    {accounts?.subscription_type === 'trial' && accounts?.subscription_status === 'expired' && accounts?.is_grace_period === false &&
                        (

                            <div className="choose-plan choose-warning">
                                <div className="d-flex justify-content-center align-items-center">

                                    <p className="mb-0 mr-3">

                                        Your subscription has been terminated. In order to continue using our service, please select a plan before



                                        <strong> {accounts?.acc_close_date} </strong>

                                        otherwise your account will be permanently closed and all of your data will be lost.

                                        <br />


                                        To Continue please select a plan for your account.
                                    </p>
                                    <button onClick={()=>choosePlan('re_activate')} type="button" className="secondary-btn-modal shadow-none btn btn-secondary ms-4">Activate</button>
                                </div>

                            </div>
                        )}



                    {/* For Paid */}

                    {/* "accounts?.subscription_type": "paid" AND "accounts?.subscription_status": "active" = Normal Paid plan. User will have full access */}





                    {/* "accounts?.subscription_type": "paid" AND "accounts?.subscription_status": "expired" AND  "accounts?.is_grace_period": true = Active But Expired Paid Plan. Grace period. Grace bar will be shown */}

                    {accounts?.subscription_type === 'paid' && accounts?.subscription_status === 'expired' && accounts?.is_grace_period === true && (

                        <div className="choose-plan choose-warning">
                            <div className="d-flex justify-content-center align-items-center">

                                <p className="mb-0 mr-3">

                                    Your <strong>{accounts?.subscription_package_name} </strong> has expired due to non payment.
                                    You are now on a grace period until {accounts?.remaining_days_date}. Your subscription will be ended if payment is not made.





                                </p>
                                {/* <button onClick={choosePlan} type="button" className="secondary-btn-modal shadow-none btn btn-secondary ms-4">Choose a Plan</button> */}
                            </div>
                        </div>

                    )}



                    {/* "accounts?.subscription_type": "paid" AND "accounts?.subscription_status": "expired" AND  "accounts?.is_grace_period": false = Paid Plan Expired, Grace Period Ended. will go to choose plan */}

                    {accounts?.subscription_type === 'paid' && accounts?.subscription_status === 'expired' && accounts?.is_grace_period === false && (

                        <div className="choose-plan choose-warning">
                            <div className="d-flex justify-content-center align-items-center">

                                <p className="mb-0 mr-3">
                                    Your <strong> {accounts?.subscription_package_name} </strong> has expired and account is restricted due to non payment.
                                    Please contact support before {accounts?.acc_close_date}. <br />  after that your account will be permanently closed and all data will be lost.

                                    To Continue, please activate your account.

                                </p>
                                <button onClick={()=>choosePlan('re_activate')} type="button" className="secondary-btn-modal shadow-none btn btn-secondary ms-4">Activate</button>
                            </div>
                        </div>

                    )}


                    {/* For Paid Or Trial */}


                    {/* "accounts?.subscription_type": "Paid / Trial " AND "accounts?.account_status" : active AND "accounts?.subscription_status": "pause" 
AND  "accounts?.is_pause_plan": true AND accounts?.pause_plan_schedule_date: 2023-05-26 = User has choosen pause plan option 
but its current package will work until package last date, here you will get pause plan start date as well. NO BAR will be shown */}

                    {(accounts?.subscription_type === 'paid' || accounts?.subscription_type === 'trial') &&
                        accounts?.is_pause_plan === true &&
                        accounts?.account_status === 'active'
                        && accounts?.subscription_status === 'pause' && accounts?.is_active_pp == false &&
                        (

                            <div className="choose-plan choose-warning">
                                <div className="d-flex justify-content-center align-items-center">

                                    <p className="mb-0 mr-3">
                                        You have paused your current <strong> {accounts?.subscription_package_name} </strong> which will be inactive from {accounts?.pause_plan_schedule_date}.




                                    </p>
                                    {/* <button onClick={choosePlan} type="button" className="secondary-btn-modal shadow-none btn btn-secondary ms-4">Choose a Plan</button> */}
                                </div>
                            </div>
                        )}



                    {(accounts?.subscription_type === 'paid' || accounts?.subscription_type === 'trial') &&
                        accounts?.is_pause_plan === true &&
                        accounts?.account_status === 'active'
                        && accounts?.subscription_status === 'pause' && accounts?.is_active_pp == true && (

                            <div className="choose-plan choose-warning">
                                <div className="d-flex justify-content-center align-items-center">

                                    <p className="mb-0 mr-3">
                                        You've paused your subscription. To Continue please activate your account.

                                    </p>
                                    <button onClick={()=>choosePlan('pause')} type="button" className="secondary-btn-modal shadow-none btn btn-secondary ms-4">Activate</button>
                                </div>
                            </div>

                        )}





                    {/* "accounts?.subscription_type": "Paid / Trial " AND "accounts?.account_status" : active AND "accounts?.subscription_status": "expired"
 AND  "accounts?.is_pause_plan": true AND  "accounts?.is_grace_period": true = Active But Expired Pause Plan. Grace period. 
 Grace bar will be shown. */}

                    {(accounts?.subscription_type === 'paid' || accounts?.subscription_type === 'trial') &&
                        accounts?.is_pause_plan === true &&
                        accounts?.account_status === 'active'
                        && accounts?.subscription_status === 'expired' && accounts?.is_grace_period === true && (


                            <div className="choose-plan choose-warning">
                                <div className="d-flex justify-content-center align-items-center">

                                    <p className="mb-0 mr-3">
                                        Your {accounts?.subscription_package_name} has expired due to non payment. You are now on grace period until {accounts?.remaining_days_date}. Your subscription will be ended if payment not made.


                                    </p>
                                    {/* <button onClick={choosePlan} type="button" className="secondary-btn-modal shadow-none btn btn-secondary ms-4">Choose a Plan</button> */}
                                </div>
                            </div>
                        )}




                    {/* "accounts?.subscription_type": "Paid / Trial " AND "accounts?.account_status" : expired AND
 "accounts?.subscription_status": "expired" AND  "accounts?.is_pause_plan": true AND  "accounts?.is_grace_period": false = Pause Plan Expired.
  Expired bar with choose plan will be shown */}

                    {(accounts?.subscription_type === 'paid' || accounts?.subscription_type === 'trial') &&
                        accounts?.is_pause_plan === true &&
                        accounts?.account_status === 'expired'
                        && accounts?.subscription_status === 'expired' && accounts?.is_grace_period === false && (

                            <div className="choose-plan choose-warning">
                                <div className="d-flex justify-content-center align-items-center">

                                    <p className="mb-0 mr-3">

                                        Your pause plan has expired and your account is restricted due to non-payment.
                                        it will be permanently closed after {accounts?.acc_close_date} and all data will be lost.
                                        You can reactivate your plan before it closes permanently.

                                    </p>
                                    <button onClick={()=>choosePlan('re_activate')} type="button" className="secondary-btn-modal shadow-none btn btn-secondary ms-4">Activate</button>
                                </div>
                            </div>
                        )}




                    {/* "accounts?.subscription_type": "Paid / Trial " AND "accounts?.account_status" : active AND 
"accounts?.subscription_status": "cancel" = Current package will work untill last date. 
after that user subscription will expire. */}

                    {(accounts?.subscription_type === 'paid' || accounts?.subscription_type === 'trial') &&
                        accounts?.account_status === 'active'
                        && accounts?.subscription_status === 'cancel' && (

                            <div className="choose-plan choose-danger">
                                <div className="d-flex justify-content-center align-items-center">

                                    <p className="mb-0 mr-3 ">
                                        You've cancelled your {accounts?.subscription_package_name} subscription. Your account will be suspended on {accounts?.subscription_end_date}.
                                        To Continue please select a plan for your account.





                                    </p>
                                    <button onClick={()=>choosePlan('cancel')} type="button" className="secondary-btn-modal shadow-none btn btn-secondary ms-4">Activate</button>
                                </div>
                            </div>
                        )}




                    {/* { (accounts?.subscription_type==='paid' || accounts?.subscription_type==='trial') &&
    accounts?.account_status === 'expired'  
    && accounts?.subscription_status ==='cancel' && accounts?.is_grace_period === false }
 */}


                    {(accounts?.subscription_type === 'paid' || accounts?.subscription_type === 'trial') &&
                        accounts?.account_status === 'expired'
                        && accounts?.subscription_status === 'cancel' && accounts?.is_cancelled === true && (
                            <div className="choose-plan choose-danger">
                                <div className="d-flex justify-content-center align-items-center">


                                    <p className="mb-0 mr-3">
                                        Your subscription has been canceled. To continue please contact support otherwise
                                        your account will be permanently closed  after {accounts?.acc_close_date} <br /> and all data will be lost.
                                        To Continue please active your account.


                                    </p>
                                    <button onClick={()=>choosePlan('cancel')} type="button" className="secondary-btn-modal shadow-none btn btn-secondary ms-4">Active</button>
                                </div>
                            </div>
                        )}




                      


                    {/* </div> */}
                </div>

            )}

            <div>
                     
            {accounts?.change_plan_schedule_date  && (
                            <div className="choose-plan choose-warning">
                                <div className="d-flex justify-content-center align-items-center">


                                    <p className="mb-0 mr-3">
                                    You've scheduled change plan to "{accounts?.change_plan_name}" which will start from {accounts?.change_plan_schedule_date}


                                    </p>
                                </div>
                            </div>
                        )}




            </div>
        </>


    );

}

export default ExpirePlanStripe;