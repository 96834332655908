import React,{useState,useEffect} from "react";
import Logo from "../../../images/logoinverse.png"
import {Form, FormGroup, Label, Input, Button, List, Alert, Row, Col,Spinner} from "reactstrap";
import {BsCheck2, BsX} from "react-icons/bs";
import { AiOutlineEyeInvisible,AiOutlineEye } from "react-icons/ai";
import { IoIosRefresh } from "react-icons/io";
import { RiErrorWarningLine } from "react-icons/ri";
import IconCheckMark from "../../../images/icon-check-success.png";
import { Formik } from "formik"; 
import * as yup from "yup";
import server from "../../../apis/server"
import Loader from "../loader/loader";
import toast from '../../../utils/toast' 
import { useNavigate,Link } from "react-router-dom";


function Forgot() {

    const [isLoading, setIsLoading] = useState(false);
    const [passwordIcon, setPasswordIcon] = useState(true);
    const [cPasswordIcon, setCpasswordIcon] = useState(true);


    const router = useNavigate()

    let validationSchema = yup.object({ 
        
        email: yup.string().email('Invalid email').required('Email is required.'),


       });

       async function handleForgot(values,resetForm)
       {
           setIsLoading(true)
   
           let formData={
               email: values?.email,
           }
             try {
               const { data } = await server.post(`user/forget_password_request`,
                   formData,
               );
   
               if(data?.status){
                   
                toast(data?.message, 'success')




               }
              console.log(data)
               // setProduct(data);
               setIsLoading(false);
             } catch (e) {
                if(e.response?.data?.status===false){
                    if(e.response?.data.errors_array?.[0]){
                    toast(e.response?.data.errors_array?.[0], 'error')
                  }
                  else{
                      toast(e.response?.data?.message, 'error')
                      
                  }
                     //  setError(e.response?.data.errors?.title[0])
                      setIsLoading(false)
                  }
                  else{
                      setIsLoading(false)
 
                  }
           }
       }

    return (

        <>
         
            <div className={'forgot-flow'}>
             
            <Formik
            initialValues={{ 
                
                email:'',
             }}
             validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              handleForgot(values, resetForm);
            }} 
            > 
			   {(formikProps) => ( 
        <>
                <img src={Logo} alt={"LOGO"} className={'mb-5 pb-5'} />
                <h1 className={'mt-5 pt-5'}>Forgot Password</h1>
                <Form>
                    <FormGroup>
                        <Label for="Email">
                            Email
                        </Label>
                        <Input
                            id="Email"
                            name="email"
                            placeholder="Enter your Email"
                            type="email"
                            value={formikProps.values.email}
                            onChange={formikProps.handleChange("email")}
                           onBlur={formikProps.handleBlur("email")}
                           error={
                             formikProps.errors.email && formikProps.touched.email
                               ? true
                               : false
                           }
                            
                        />
                        <div>
          {formikProps.errors.email && formikProps.touched.email && (
            //    <Alert status='error' color={'#DD2922'} pl={0} fontWeight={'400'} fontSize={'14px'} lineheight={'20px'}>

            <p className={'errorMessage'}>
               {formikProps.errors.email}
            </p>
         )}
            </div>
                    </FormGroup>
                    <Button  block className={'mt-4'}
                             color="primary"

                             disabled={
                                !formikProps?.values.email
                           
                                // !values.confirm_pass ||
                                }
                             onClick={formikProps.handleSubmit}

                             >
                         {isLoading ?
                         <span className={'spinnerContainer'}>
                         <Spinner>
                                    Loading...
                                </Spinner></span> : 'Recover Password'}
                    </Button>
                </Form>
               <h6 className={'text-center mt-4'}> Go back to  <Link to="/login" className={'text-primary bold '}> Sign in </Link></h6>
          
               </>
               )}
               </Formik>
                 </div>

           
        </>

    )
}

export default Forgot;