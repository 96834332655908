import LOGO  from "../../../../../src/images/short-logo.png";
export function navbarPanel({
    editor, 
    navigate, 
    setPublishOpen, 
    draftLoading, 
    setHtml, 
    toogleEditModal,
}) {

    const panelManager = editor.Panels;

    panelManager.addPanel({
        id: 'myNewPanel',
        className: 'MyNewpanle',
        visible: true,
        buttons: [
            {
                id: 'logo',
                className: 'logo-editor',
                label:`<img src="${LOGO}" alt="Logo" height="40">`,
            },
            {
                id: 'backbtn',
                command: {
                    run: () => {
                        navigate('/pages')
                    }
                },
                className: 'fa fa-arrow-left btn-back',

                label:'Back',
            },
            // {
            //     id: 'cmdDeviceDesktop',
            //     command: e => e.setDevice('Desktop'),
            //     className: 'fa fa-desktop',
            //     active: 1,
            // },
            // {
            //     id: 'cmdDeviceTablet',
            //     command: e => e.setDevice('Tablet'),
            //     className: 'fa fa-tablet',
            // },
            // {
            //     id: 'cmdDeviceMobile',
            //     command: e => e.setDevice('Mobile portrait'),
            //     className: 'fa fa-mobile',
            // },
           
            // {
            //     id: 'Draft',
            //     label: '',
            //     command: {
            //         run: () => {
            //
            //         }
            //     },
            // },
            {
                id: 'PageName',
                label: ``,
                active: 0,
                command: {
                    run: async () => {
                        toogleEditModal(true)
                    }
                },
            },

        ],
    });
    panelManager.addButton('options', {
        id: "cmdDevices", tagName: "span", className: "gjs-devices", label: `
        <select class="gjs-pn-btn gjs-devices">
        <option value="Desktop">Desktop</option>
        <option value="Tablet">Tablet</option>
        <option value="Mobile portrait">Mobile  </option>
        </select>
    `
    })
  // Add a custom text element to the right panel
  panelManager.addButton('options', {
    

        id: 'Publish',
        label: `<button  type="button"  class="ccc btn btn-secondary Mydropdowntoogle" > 
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="11" width="11" xmlns="http://www.w3.org/2000/svg">
                    <path d="M477.64 38.26a4.75 4.75 0 00-3.55-3.66c-58.57-14.32-193.9 36.71-267.22 110a317 317 0 00-35.63 42.1c-22.61-2-45.22-.33-64.49 8.07C52.38 218.7 36.55 281.14 32.14 308a9.64 9.64 0 0010.55 11.2l87.31-9.63a194.1 194.1 0 001.19 19.7 19.53 19.53 0 005.7 12L170.7 375a19.59 19.59 0 0012 5.7 193.53 193.53 0 0019.59 1.19l-9.58 87.2a9.65 9.65 0 0011.2 10.55c26.81-4.3 89.36-20.13 113.15-74.5 8.4-19.27 10.12-41.77 8.18-64.27a317.66 317.66 0 0042.21-35.64C441 232.05 491.74 99.74 477.64 38.26zM294.07 217.93a48 48 0 1167.86 0 47.95 47.95 0 01-67.86 0z"></path>
                    <path d="M168.4 399.43c-5.48 5.49-14.27 7.63-24.85 9.46-23.77 4.05-44.76-16.49-40.49-40.52 1.63-9.11 6.45-21.88 9.45-24.88a4.37 4.37 0 00-3.65-7.45 60 60 0 00-35.13 17.12C50.22 376.69 48 464 48 464s87.36-2.22 110.87-25.75A59.69 59.69 0 00176 403.09c.37-4.18-4.72-6.67-7.6-3.66z"></path>
                </svg>
           <span class="px-2"> ${draftLoading ? 'loading' : 'publish'}  <span>
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" 
            viewBox="0 0 24 24" height="1.5em" width="1.5em" xmlns="http://www.w3.org/2000/svg">
            <path fill="none" d="M0 0h24v24H0z"></path><path d="M7 10l5 5 5-5z"></path></svg>
            </span> </span>
             
        `,
        active: 0,
        command: {
            run: async () => {
                setPublishOpen(true);

                const components = editor.getComponents();
                const htmlObj = {
                    html: editor.getHtml(),
                    css: editor.getCss(),
                    components: components
                };
                setHtml(htmlObj);
            }
        },
    
});
    
}