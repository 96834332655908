import React, { useEffect, useState } from 'react';
import InternalTitle from "../common/internalTittle/GenaricButton";
import { Table } from "reactstrap";
import server from "../../apis/server";
import ReactPaginate from 'react-paginate';


function Invoices(props) {

    const [invoices, setInvoices] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const accountsString = localStorage.getItem('accounts')
    const accounts = accountsString ? JSON.parse(accountsString) : ''

    //pagination code
    const [currentItem, setCurrentItem] = useState(null);
    const [itemOffset, setItemOffset] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const itemsPerPage = 10;
    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItem(invoices.slice(itemOffset,endOffset));
        setPageCount(Math.ceil(invoices.length/itemsPerPage));
    }, [itemOffset, itemsPerPage, invoices]);


    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % invoices.length;
        setItemOffset(newOffset);
    };



    console.log('accounts', accounts)
    const { user_id } = accounts

    useEffect(() => {
        getInvoiceData();
    }, []);


    const getInvoiceData = async () => {
        try {
            setIsLoading(true);

            let formData = {
                user_id
            }
             const { data } = await server.post(`billing/getInvoices`, formData);
            console.warn("this", data)

            if (data?.status) {
            //    console.log()
              const { invoices } = data?.data;
                setInvoices(invoices);


            }

            setIsLoading(false);

        }catch (e) {
            console.log('rrrrrr',e)
            if (e.response?.data?.status === false) {
                setIsLoading(true);
            } else {
                setIsLoading(false);
             //   alert("not showing")
            }


        }
    };

    return (
        <div>
            <div className="row billing_table">
                <div className="col-md-12 mt-3">
                    <InternalTitle title="Invoices" />
                    <Table className="workspacemodal" responsive>
                        <thead>
                        <tr>
                            <th>Payment Method</th>
                            <th>Payment Date</th>
                            <th>Invoice No.</th>
                            <th>Description</th>
                            <th>Original Amount</th>
                            <th>Discount</th>
                            <th>Total Amount</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {invoices?.length === 0 ? (
                            <tr>

                             <td colSpan={8}>
                                 <p className="text-center">You don't have any invoice.</p>

                             </td>



                            </tr>
                        ) : (
                            currentItem?.map((invoice) => (
                                <tr key={invoice?.invoice_payment_id}>
                                    <td>
                                        <strong>Card</strong>
                                    </td>
                                    <td>{invoice?.payment_date}</td>
                                    <td>{invoice?.invoice_display_id}</td>
                                    <td>{invoice?.plan_name}</td>
                                    <td>$ {invoice?.sub_total}</td>
                                    <td>$ {invoice?.discount}</td>
                                    <td>$ {invoice?.invoice_total_amount}</td>
                                    <td></td>
                                </tr>
                            ))
                        )}
                        </tbody>

                    </Table>

                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        renderOnZeroPageCount={null}
                        containerClassName="pagination"
                        pageLinkClassName="page-num"
                        previousLinkClassName="page-num"
                        nextLinkClassName="page-num"
                        activeLinkClassName="active"

                    />
                </div>
            </div>
        </div>
    );
}

export default Invoices;
