import React,{useState,useEffect}  from "react";
import server from "../../../apis/server"
import Loader from "../../../components/common/loader/loader";
import toast from '../../../utils/toast' 
import { useNavigate ,useParams} from "react-router-dom";


export default function Detail() {
    const [isLoading, setIsLoading] = useState(false);
    const [packages, setpackages] = useState(null);
    const router = useNavigate()
    let { token } = useParams();



    useEffect(() => {
       if(token){
        //    alert('cc')
        verifyToken()
       }
    }, [])


    const verifyToken = async () => {

        try {


      await            localStorage.clear()
            setIsLoading(true)
            
        

            let formData={ 
                 req : token
            }
            const {data} = await server.post(`auth/user`,formData);
            if (data?.status) {
                if(data?.data?.user_id){

                    await localStorage.setItem('accounts',JSON.stringify(data?.data))

               

                    if(data?.data?.is_subscribed==false){
                 router(`/billing`)
        
                    }
                 
                   else if(data?.data?.is_subscribed && data?.data?.account_id == null){
                        router(`/accounts`)
        
                    }
        
                   else if(data?.data?.is_subscribed && data?.data?.account_id){
                    router(`/`)
        
                }


                }
               else if(data?.data?.is_register){
             router(`/signup`)

                }

                // setpackages(data?.data)
                toast(data?.message, 'success')
                            //    router(`/login`)

                
            }
            else{
                toast(data?.message, 'error')
                router(`/signup`)


            }
            console.log(data)
            // setProduct(data);
            setIsLoading(false);

        } catch (e) {
            router(`/signup`)

            if(e.response?.data?.status===false){

                if(e.response?.data.errors_array?.[0]){
                toast(e.response?.data.errors_array?.[0], 'error')
              }
              else{
                  toast(e.response?.data?.message, 'error')
                  
              }
                 //  setError(e.response?.data.errors?.title[0])
                  setIsLoading(false)
              }
              else{
                  setIsLoading(false)

              }

        }
    }


    return (
        <div>
            <div className="accounts-wrapper">
            {/* <ToastContainer/> */}

            {isLoading &&
       (


            <div className={'loaderPage'}>
                <Loader/>
            </div>
            
       )}



            </div>

        </div>
    )
}