import { Button, message, Steps } from 'antd';
import React, { useState ,useEffect} from 'react';
import {
    Form,
    FormGroup,
    Input,
    Label,
    Table,
    InputGroup,
    InputGroupText,
    Alert,
    Modal,
    ModalHeader,
    ModalBody,
    Img,
    Spinner
} from 'reactstrap'
import {FaRegCopy} from "react-icons/fa";
import {BsGlobe} from "react-icons/bs";
import CheckMark  from "../../../src/images/domain_icons/check.png";
import ContinueProcess  from "../../../src/images/domain_icons/continue-process.png";
import Processing  from "../../../src/images/domain_icons/processing.png";
import StopWarning  from "../../../src/images/domain_icons/stop-warning.png";
import Aimg from "../../images/1.gif"
import Cimg from "../../images/2.gif"
import server from '../../apis/server'
import {
    useParams
  } from "react-router-dom";
  import Loader from "../common/loader/loader";

  import { useNavigate,useLocation } from "react-router-dom";

const { Step } = Steps;

const Wizard = () => {
    
    let { domainName } = useParams();
    const router = useNavigate()
    const {state} = useLocation();

    const [amodal, setaModal] = useState(false);
    const [disable,setDisable]=useState(true);
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingDomain, setIsLoadingDomain] = useState(false)

    const [error,setError]=useState(false)
    const [domain,setDomain]=useState('')
    const [domainStatus,setDomainStatus]=useState('')
    const [isDomainCompleted,setIsDomainCompleted]=useState(false)

    const [stepOneChecked, setStepOneChecked] = React.useState(false);
    const [isRootPage, setIsRootPage] = React.useState(false);

    const [copySuccess, setCopySuccess] = useState('');
    const [pages, setPages] = useState([]);
    const [selectedPageOption, setSelectedPageOption] = useState("");
    const [visible, setVisible] = useState(true);

    const onDismiss = () => setVisible(false);


    const atoggle = () => setaModal(!amodal);

    const [cmodal, setcModal] = useState(false);
    const ctoggle = () => setcModal(!cmodal);

    const [current, setCurrent] = useState(0);

    


    
    useEffect(() => {
        if(error) {
            let timerFunc = setTimeout(() => {
           setError((false))
            }, 3000);

            return () => clearTimeout(timerFunc);
        }
    }, [error])

    useEffect(() => {
        if(domainName == 'step2' ){
            setCurrent(1);
            setStepOneChecked(true)
        return
        }

        if(domainName){
            setCurrent(2);
        }

        getData()
    }, [])



    useEffect(() => {
        
       
        
        if(current==2){
            getDomainStatus()
            }
            if(isDomainCompleted){
              return 
            }
            
            const timer = window.setInterval(() => {
                // function of api call 
              
                if(current==2 && !isDomainCompleted){
                getDomainStatus()
                }
                else{
                    window.clearInterval(timer);
                }
    
              }, 3000);
            
                     

        return () => { 
            window.clearInterval(timer);
          }
    }, [current,isDomainCompleted])
    


   
    const getData = async () => {
        try {
            setIsLoading(true)


            const {data} = await server.get(`page/all_unattached`);
            if (data?.status) {


                setPages(data?.data)
                setIsLoading(false);
            }
            console.log(data)
            // setProduct(data);
            // setLoading(false);

        } catch (e) {
            if (e.response?.data?.status === false && e.response?.data?.auth === false) {
                localStorage.clear()
                // router("/accounts")

                // console.log(e.response?.data.errors?.title[0])
                // setError(e.response?.data.errors?.title[0])
                setIsLoading(false);
            }



            else if (e.response?.data?.status === false) {
                console.log(e.response?.data.errors?.title[0])
                setError(e.response?.data.errors?.title[0])
                setIsLoading(false);
            } else {
                setIsLoading(false);

                setError(e.message)
            }

        }
    }

       const  checkStatusArecord = () =>{
              console.log('domain status',domainStatus)
              if(domainStatus?.a_record?.status=='pending'){
                  return ContinueProcess
              }
             else if(domainStatus?.a_record?.status=='completed'){
                return CheckMark
            }
           else if(domainStatus?.a_record?.status=='failed'){
                return StopWarning
            }
       }

       const  checkStatusSslVerification = () =>{
        console.log('domain status',domainStatus)
        if(domainStatus?.ssl_verification?.status=='pending'){
            return ContinueProcess
        }
       else if(domainStatus?.ssl_verification?.status=='completed'){
          return CheckMark
      }
     else if(domainStatus?.ssl_verification?.status=='failed'){
          return StopWarning
      }
 }

 const  checkStatusDomainConfig = () =>{
    console.log('domain status',domainStatus)
    if(domainStatus?.setup_domain_config?.status=='pending'){
        return ContinueProcess
    }
   else if(domainStatus?.setup_domain_config?.status=='completed'){
      return CheckMark
  }
 else if(domainStatus?.setup_domain_config?.status=='failed'){
      return StopWarning
  }
}

const  checkSetupCompleted = () =>{
    console.log('domain status',domainStatus)
    if(domainStatus?.is_completed?.status=='pending'){
        return ContinueProcess
    }
   else if(domainStatus?.is_completed?.status=='completed'){
      return CheckMark
  }
 else if(domainStatus?.is_completed?.status=='failed'){
      return StopWarning
  }
}

const  checkStatusCompleted = () =>{
    console.log('domain status',domainStatus)
    if(domainStatus?.is_completed?.status=='pending'){
        return ContinueProcess
    }
   else if(domainStatus?.is_completed?.status=='completed'){
      return CheckMark
  }
 else if(domainStatus?.is_completed?.status=='failed'){
      return StopWarning
  }
}


    const next = () => {
        
        if(current == 1){
        createDomain()
        }
        else
        setCurrent(current + 1);
    };



    const getDomainStatus = async () => {

        try {
                  
            setIsLoading(true)    
            const {data} = await server.get(`domain/get/${  state?.domain_type == 1 ? state?.selectedDomain?.domain_name : state?.domain_type == 0 ? state?.selectedDomain?.domain  :((domainName)?(domainName):(domain)) }`);
            if (data?.status) {

                console.log('dataaaa',data)
                setDomainStatus(data?.data)
                const {domain_status } = data?.data
                if(domain_status == 1){
                      setIsDomainCompleted(true)
                }
                 // setTemplate(data?.data)
                setIsLoading(false);

            }
            console.log(data)
            // setProduct(data);
            setIsLoading(false);

        } catch (e) {
            if (e.response?.data?.status === false) {
                console.log(e.response?.data.errors?.domain[0])
                setError(e.response?.data.errors?.domain[0])
                setIsLoading(true);
            } else {
                setError(e.message)
            }


        }
    }

    const prev = () => {
        setCurrent(current - 1);
        // alert(current)
    };


    const createDomain=async()=>{
// alert(current)
        setDisable(true)
        setIsLoadingDomain(true)

        setError('')
        let formData={
            domain : state?.domain_type == 1 ?  state?.selectedDomain?.domain_name : state?.domain_type == 0 ? state?.selectedDomain?.domain  : domain ,
            page_id:selectedPageOption ? selectedPageOption : 0,
            is_root_page: isRootPage ? 1 : 0,
            domain_type : state?.domain_type  ? state?.domain_type   : 0,
            is_retry  : state?.is_retry ? state?.is_retry : false
  
        }

        
          try {
            const { data } = await server.post(`domain/connect`,
                formData,
            );

            if(data?.status){
                setCurrent(2);
                  setIsLoadingDomain(false)
                // isLoading(true)
                // router(`/pages/editor/${data?.data?.page_id}`)
            }
           console.log(data)
            // setProduct(data);
            setIsLoadingDomain(false)

            // setLoading(false);
          } catch (e) {
            setIsLoadingDomain(false)
            setIsLoading(false)

            setDisable(false)
                 if(e.response?.data?.status===false){
                //    console.log(e.response?.data.errors?.title[0])
                if(e.response?.data.errors){
                    setError(e.response?.data.errors?.domain[0])
                    return
                }
                 setError(e.response?.data?.message)



                 }
                 else{
                     setError(e.message)
                     setDisable(false)
                     setIsLoading(false)

                 }
            //console.log(e.response);

        }
    }
    const characterLimit=event=>{
        // alert('cc')
        // event.preventDefault();

        // let result=event.target.value
        // .replace(/\s+/g, ' ').trim();

        
        setDomain(event.target.value)
    //   const charCount = result.length;
    //  if(charCount >= 3 &&  charCount < 255 ){

    //    setDisable(false);

    //  }else {
    //      console.log(charCount);
    //      setDisable(true);
    //  }
    }


    const copyToClipBoard = async copyMe => {
        try {
          await navigator.clipboard.writeText(copyMe);
          setCopySuccess('Copied!');
        } catch (err) {
          setCopySuccess('Failed to copy!');
        }
      };

      const onChangePageSelection = (e) => {
        setSelectedPageOption(e.target.value);
    };


    const Step1Form = () => {
        return (
            <div className="step-1">
            <h4>Change your DNS records in your third party domain provider </h4>
            <p>On your domain provider’s website, first log into your account, then locate the DNS settings or domain management area and change the following records. Once done, please check the "confirmed" box below.</p>
            <FormGroup className={`checkboxClass`}>
                    <Input type="checkbox" id={`step-1`} 
                            defaultChecked={stepOneChecked}
                              
                            onChange={() => setStepOneChecked(!stepOneChecked)}

                    />
                    <Label for={`step-1`}>
                     Confirmed
                    </Label>
                </FormGroup>
                <div className="row">

                    
                    <div className="col-md-9 col-12">

                        
                        <p>Update the A record in your third party domain provider OR create a new A record if there is no existing one with the following values.</p>
                    </div>
                    <div className="col-md-3 col-12 d-flex justify-content-end">
                        <Button className="text-primary border-0" onClick={atoggle} >
                            Show me how!
                        </Button>
                        <Modal size="xl" isOpen={amodal} toggle={atoggle}>
                            <ModalHeader toggle={atoggle}></ModalHeader>
                            <ModalBody className="mb-3">
                                <img src={Aimg} height={408}/>
                            </ModalBody>
                        </Modal>
                    </div>
                </div>
            <div className={`tableSet my-4`}>
                <Table className={`domainInfo`}
                    responsive

                >
                    <thead>
                    <tr>
                        <th>
                            Type
                        </th>
                        <th>
                            Name / Host Name
                        </th>
                        <th>
                            IP Address / Value
                        </th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr>
                        <td>
                            A
                        </td>
                        <td>
                            @
                        </td>
                        <td>
                            {process.env.REACT_APP_IP_ADDRESS}  <FaRegCopy onClick={() => copyToClipBoard(process.env.REACT_APP_IP_ADDRESS)} />
                            {copySuccess}

                        </td>

                    </tr>
                    </tbody>
                </Table>
            </div>

            {/* <FormGroup className={`checkboxClass`}>
            <Input type="checkbox" id={`step-2`}/>
            <Label for={`step-2`}>
                Step 2
            </Label>
            </FormGroup>
                <div className="row">
                    <div className="col-md-9 col-12">
                        <p>Update your www CNAME record in your third party domain OR create a new
                            www CNAME record if there is no existing one with the following values:</p>
                    </div>
                    <div className="col-md-3 col-12 d-flex justify-content-end">
                        <Button className="text-primary border-0" onClick={ctoggle}>
                            Show me how!
                        </Button>
                        <div>
                            <Modal size="xl"  isOpen={cmodal} toggle={ctoggle}>
                                <ModalHeader toggle={ctoggle}></ModalHeader>
                                <ModalBody className="mb-3">
                                    <img src={Cimg} height={492} />
                                </ModalBody>
                            </Modal>
                        </div>
                    </div>
                </div>

            <div className={`tableSet my-4`}>
                <Table className={`domainInfo`}
                       responsive

                >
                    <thead>
                    <tr>
                        <th>
                            Type
                        </th>
                        <th>
                            Name / Host Name
                        </th>
                        <th>
                            IP Address / Value
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            CNAME
                        </td>
                        <td>
                            www
                        </td>
                        <td>
                          acme.mycartzy.com <FaRegCopy />
                        </td>

                    </tr>
                    </tbody>
                </Table>
            </div> */}
        </div>
        );
      };

      const Step2Form = () => {
        return (
            <div className="step-2 ">
            <h4>Enter your domain </h4>
            {/* <Form> */}
                <FormGroup>
                    <Label for="Domain">
                        Domain
                    </Label>
                    <InputGroup>
                        <InputGroupText>
                            <BsGlobe/>
                        </InputGroupText>
                        <Input
                        autoFocus
                        className=''
                        type={'text'}
                        disabled ={state?.selectedDomain ? true : false}
                        value={state?.domain_type == 1 ?  state?.selectedDomain?.domain_name : state?.domain_type == 0 ? state?.selectedDomain?.domain  : domain}
                        placeholder="acme.com"  onChange={characterLimit}/>
                    </InputGroup>
                  
                  
                  { state?.selectedDomain && 
                  
                  <small> https://{state?.domain_type == 1 ?  state?.selectedDomain?.domain_name :  state?.selectedDomain?.domain }
                    {/* <span className={`text-success`}>{select} </span> */}
                     </small>
                       
                       }

                   {! state?.selectedDomain && 
                  
                  <small> https://{domain}
                    {/* <span className={`text-success`}>{select} </span> */}
                     </small>
                       
                       }


                </FormGroup>
                <FormGroup>
                   
                   {pages.length>=1 && (
                   <>
                    <Label for="attach">
                        Select your page to attach
                    </Label>
                    <Input
                          className=' shadow-none'

                          name="select"
                          type="select"
                          value={selectedPageOption}
                          onChange={onChangePageSelection}
                      >
                          {/*{*/}
                          {/*    (()=>{console.log(funnelList)})()*/}
                          {/*}*/}
                               <option value={""}>
                                  Select Page ...
                              </option>
                          {pages?.map((x,i)=>{
                              return(
                              <option value={x?.id}>
                                  {x?.title}
                              </option>
                              );
                          })}



                      </Input>

                      {selectedPageOption && (
                    <FormGroup className={`checkboxClass`}>
                    <p className={`mt-3`}>Do you want to publish this page on your main domain? </p>

                    <Input type="checkbox" id={`publishPage`}
                     defaultChecked={isRootPage}
                          
                     onChange={() => setIsRootPage(!isRootPage)}
                    />
                    <Label for={`publishPage`}>
                        Yes, publish on Main Domain
                    </Label>     
                    




                </FormGroup>
                      )}



                    </>
                   )}


{error && (<p className={'errorMessage'}> {error} </p>)}



                </FormGroup>

            {/* </Form> */}
        </div>
        );
      };


      const Step3Form = () => {
        return (
            <div className="step-3">
                 {isLoading && (
    <div className={'loaderPageEditor domainloader'}>
                        <Loader/>
                    </div>

)} 

 
    

            <Alert color="primary mt-3"  isOpen={visible} toggle={onDismiss} fade>
                <h4 className="alert-heading">
                    Heads up!
                </h4>
                <p>
                    It might take up to 24 hrs to connect your domain successfully.
                </p>

            </Alert>
            <h4>Verifying the domain and DNS setting </h4>
            <FormGroup>
                <Input
                    name="text"
                    value={state?.domain_type == 1 ? state?.selectedDomain?.domain_name : state?.domain_type == 0 ? state?.selectedDomain?.domain  :((domainName)?(domainName):(domain))}
                    type="text"
                    disabled
                />

            </FormGroup>
            {/*<p className="fst-italic">  Verifying DNS settings… </p>*/}
            <div className="row">
                <div className="col-md-12">
                    <div id="check-list">
                        <ul>
                            {/* <li className="steps_icons_li">
                                <div className="completed-domain">
                                    <img 
                                         src={CheckMark}
                                    />
                                </div>
                                Checking your domain status - Active
                            </li> */}
                            <li
                                className="steps_icons_li">
                                <div className="processing-domain">
                                    <img 
                                         src={checkStatusArecord()}
                                         />
                                </div>
                                Verifying: A records
                            </li>
                            <li className="steps_icons_li">
                                <div>
                                    <img 
                                         src={checkStatusDomainConfig()}
                                          />
                                </div>
                                Domain setup successful
                            </li>
                            {/* <li
                                className="steps_icons_li">
                                <div className="warning-domain">
                                    <img 
                                         src={StopWarning}
                                          />
                                </div>
                                Verifying: CNAME records
                            </li> */}
                            {/* <li className="steps_icons_li">
                                <div className="completed-processing-domain">
                                    <img 
                                         src={ContinueProcess}
                                          />
                                </div>
                                Verifying: AAAA record does not exist for connecting domain
                            </li> */}
                            <li className="steps_icons_li">
                                <div>
                                    <img 
                                         src={checkStatusDomainConfig()}
                                          />
                                </div>
                                Verifying: Float Funnels default page
                            </li>
                          
                            <li className="steps_icons_li">
                                <div>
                                    <img 
                                         src={checkStatusCompleted()}
                                          />
                                </div>
                                Installing SSL
                            </li>
                            <li className="steps_icons_li">
                                <div>
                                    <img 
                                         src={checkSetupCompleted()}
                                          />
                                </div>
                                Setup Completed
                            </li>
                        </ul>
                    </div>
                </div>
            </div>






        </div>
        );
      };

   
      const steps = [
        {
            title: 'DNS',
            content: <Step1Form/>,
        },
        {
            title: 'DOMAIN',
            content: <Step2Form />,
        },
        {
            title: 'VERIFY',
            content: <Step3Form />,
        },
    ];
    
  
    return (
        <>
 {/* {isLoading && (
    <div className={'loaderPage'}>
                        <Loader/>
                    </div>

)}  */}

          
          {/* {isLoading && (

<div className={'loaderPageEditor'}>
<Loader/>
</div>
    )} */}
          <div className={`row justify-content-center`}>
              <div className={`col-md-9 col-12`}>
                  <div className="wizardDomain">
                      <Steps current={current} labelPlacement="vertical">
                          {steps.map((item) => (
                              <Step key={item.title} title={item.title} />
                          ))}
                      </Steps>
                      <div className="steps-content">
                         
                      {steps[current].content}
                          

                      </div>
                      <div className="steps-action">
                          {current < steps.length - 1 && (
                            //   <Button disabled={!stepOneChecked} onClick={() => next()} className={`btn primary-btn-modal`}>
                            //       Next
                            //   </Button>

<Button className={'primary-btn-modal shadow-none'} 
disabled={!stepOneChecked} 
onClick={() => next()}
>
    {isLoadingDomain ?
<span className={'spinnerContainer'}> Creating
  <Spinner style={{height:'15px', width: '15px', margin:'0 15px '}}>
Loading...
</Spinner>

</span> : state?.is_retry ? 'Retry':'Next'}</Button>

                          )}
                          {current === steps.length - 1 && (
                              <Button onClick={() =>  router(`/pages/domain`) }>
                                  Done
                              </Button>
                          )}
                          {(current > 0 && current!==2) && (
                              <Button
                                  style={{
                                      margin: '0 8px',
                                  }}
                                  onClick={() => prev()}
                              >
                                  Previous
                              </Button>
                          )}
                      </div>
                  </div>
              </div>
          </div>
        </>
    );
};

export default Wizard;