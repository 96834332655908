import React from "react"
import ReactDOM from "react-dom"
import DayandTime from "./dayAndTime";

export const Greeting = () => {

    const accountsString =   localStorage.getItem('accounts')
    const accounts =    accountsString ? JSON.parse(accountsString) : ''
    const {account_title,first_name}  =   accounts

    var myDate = new Date();
    var hours= myDate.getHours();
    var greet;

    if (hours < 12)
        greet =  "morning";
    else if (hours >= 12 && hours <= 17)
        greet = "afternoon";
    else if (hours >= 17 && hours <= 20)
        greet = "evening";
    else if (hours >= 20 && hours <= 24)
        greet = "night";
    return(
            <h1> Good {greet},  <span>   {first_name} </span></h1>
        )

}

export default Greeting;