export function seoPannel({editor,pageDetail,editorpage,server,toast,setUpdateGrapeJs,updateGrapeJs}) {
  const pn = editor.Panels;
  let editPanel = null;
  let widgetPannel = null;
  let sectionPanel = null;

  let seoBtnTags = null; // Initialize outside the run function
  let googleBtnTags = null; // Initialize outside the run function
  let microsoftBtnTags = null; // Initialize outside the run function
  let googleIdBtnTags = null; // Initialize outside the run function
  let generalBtnTags = null; // Initialize outside the run function
  let isApiCallInProgress = false;




 
            // seo button
            pn.addButton('views',
                [
                    {
                        id: 'seo',
                        className: 'fa fa-cogs',
                        command: {
                            label: 'Custom Block',
                            category: 'Custom Category',
                            attributes: {
                                class: 'gjs-fonts gjs-f-b1',
                            },
                            run: function (editor) {
                                // editPanel = null
                                    const editorElementMain = document.querySelector('.gjs-editor');
            
                                    if (editorElementMain) {
                                        console.warn('Found .gjs-editor in the main document:', editorElementMain);
                                        editorElementMain.classList.remove('short-sidebar');
                                        window.dispatchEvent(new Event('resize'));

                                    } else {
                                        console.warn('.gjs-editor not found in the main document.');
                                
                                        // Check for .gjs-editor in the editor's iframe document
                                        const editorElementIframe = editor.Canvas.getDocument().querySelector('.gjs-editor');
                                
                                        if (editorElementIframe) {
                                            console.warn('Found .gjs-editor in the iframe:', editorElementIframe);
                                            editorElementIframe.classList.remove('short-sidebar');
                                            window.dispatchEvent(new Event('resize'));

                                        } else {
                                            console.warn('.gjs-editor not found in the iframe.');
                                        }
                                    }
                                    if (editor.Commands.isActive('section')) {
                                        editor.stopCommand('section');
                                    }
                                if (editPanel == null) {
                                    // alert('initial')
                                    const editMenuDiv = document.createElement('div')
                                    const btnn = editMenuDiv.getElementsByClassName('seo-tags-heading');
                                    // btnn[0].addEventListener('click',function (e){
                                    //
                                    // })
                                    editMenuDiv.innerHTML = `
                        <div class="gjs-block-category ">
                          <div class="gjs-title seo-tags-heading" >
                            <i class="gjs-caret-icon fa fa-caret-down"></i>
                            Seo Setting
                          </div>

                         
                          <div class="gjs-blocks-c seo-block">
                              <div class="gjs-sm-property gjs-sm-stack gjs-sm-property__transition gjs-sm-property--full">
                                  <div class="gjs-sm-label gjs-four-color" data-sm-label="">
                                      <span class="gjs-sm-icon " title="">
                                        Page Title 
                                      </span>
                                  </div>
                                  <div class="gjs-fields" data-sm-fields="">
                                    <input class="custom-seo-input custom-seo-input-title" placeholder="Enter SEO title" name="pageTitle"  />
                                </div>
                                <p class="custom-seo-label m-0">Max 70 characters</p>

                              </div>


                           <div class="gjs-sm-property gjs-sm-stack gjs-sm-property__transition gjs-sm-property--full">
                                  <div class="gjs-sm-label gjs-four-color" data-sm-label="">
                                      <span class="gjs-sm-icon " title="">
                                        Keywords (comma separated) 
                                      </span>
                                  </div>
                                  <div class="gjs-fields" data-sm-fields="">
                                    <input class="custom-seo-input custom-seo-input-keyword" placeholder="Marketing, Subscription, Email etc." name="Keywords" />
                                </div>
                              </div>  
                              <div class="gjs-sm-property gjs-sm-stack gjs-sm-property__transition gjs-sm-property--full">
                                  <div class="gjs-sm-label gjs-four-color" data-sm-label="">
                                      <span class="gjs-sm-icon " title="">
                                        Description 
                                      </span>
                                  </div>
                                  <div class="gjs-fields" data-sm-fields="">
                                    <textarea class="custom-seo-textarea custom-seo-input-description" placeholder="Enter SEO description" name="description"  maxlength="5000"></textarea>
                                </div>
                                 <p class="custom-seo-label m-0">Max 200 words</p>
                              </div>
                               <div class="gjs-sm-property gjs-sm-stack gjs-sm-property__transition gjs-sm-property--full">
                                  <div class="gjs-sm-label gjs-four-color" data-sm-label="">
                                      <span class="gjs-sm-icon " title="">
                                        Page slug *
                                      </span>
                                  </div>
                                  <div class="gjs-fields" data-sm-fields="">
                                    <input class="custom-seo-input custom-seo-input-slug" placeholder="Enter the page slug" />
                                </div>
                                 <button type="button" class="btn btn-primary savbtnnn d-flex fs-12 mb-3 shadow-none"  disabled>Save <div role="status" class="spinner-border save-spinner"><span class="visually-hidden">Loading...</span></div></button>
                               
                              </div>  
                          </div>
                        </div>

                        <div  class="gjs-block-category ">
                        <div class="gjs-title googleId-tags-heading" >
                                <i class="gjs-caret-icon fa fa-caret-down"></i>
                               Google Scripts
                          </div>
                          <div class="gjs-blocks-c googleId-block">
                              
                          <div class="gjs-sm-property gjs-sm-stack gjs-sm-property__transition gjs-sm-property--full">
                          <div class="gjs-sm-label gjs-four-color" data-sm-label="" ">
                              <span class="gjs-sm-icon" title="">
                                  Google Conversion ID ${pageDetail?.script_setting?.google_conversion_id ?  `<img src=${Checked} style="width:20px; height:20px;margin-left:5px" alt="Description" />`: ''}
                              </span>
                          </div>
                          <div class="gjs-fields" data-sm-fields="">
                              <input class="custom-seo-input google_conversion_id" placeholder="Enter Google Conversion ID" />
                          </div>
                      </div>
                      
                              <div class="gjs-sm-property gjs-sm-stack gjs-sm-property__transition gjs-sm-property--full">
                                
                                 <div class="gjs-sm-label gjs-four-color" data-sm-label="">
                                        <span class="gjs-sm-icon " title="">
                                        Conversion Label 
                                              </span>
                                         </div>                                  
                                      <div class="gjs-fields " data-sm-fields="">
                                        <input class="custom-seo-input google_label_id" placeholder="Enter Conversion Label                                        " />
                                    </div>



                                    <div class="gjs-sm-property gjs-sm-stack gjs-sm-property__transition gjs-sm-property--full">
                              <div class="gjs-sm-label gjs-four-color" data-sm-label="">
                                  <span class="gjs-sm-icon " title="">
                                    Google Meta Tag
                                  </span>
                              </div>
                              <div class="gjs-fields" data-sm-fields="">
                                <input class="custom-seo-input google-verification" placeholder="Enter Google Meta Tag" />
                            </div>
                          </div>   


                                    <div class="gjs-sm-property gjs-sm-stack gjs-sm-property__transition gjs-sm-property--full">
                                  <div class="gjs-sm-label gjs-four-color" data-sm-label="">

                                      <span class="gjs-sm-icon " title="">

                                        Google Analytics ID
                                        ${pageDetail?.script_setting?.google_analytics_id ?  `<img  src=${Checked} style="width:20px; height:20px;margin-left:5px" alt="Description" />`: ''}

                                        </span>

                                  </div>
                                  <div class="gjs-fields" data-sm-fields="">
                                    <input class="custom-seo-input google-analyticsId" placeholder="UA-0000000-02" />
                                </div>
                              </div>   


                              


                                     <button type="button" class="btn btn-primary google-save-btn d-flex fs-12 mb-3"  >
                                         Save
                                         <div role="status" class="spinner-border save-spinner save-spinner-googleId"><span class="visually-hidden">Loading...</span></div>
                                          
                                          </button>
                              </div>  
                              
                                           
                            </div>
                        </div>

                        <div class="gjs-block-category d-none">
                          <div class="gjs-title google-tags-heading" >
                            <i class="gjs-caret-icon fa fa-caret-down"></i>
                            Tracking Codes
                          </div>
                          <div class="gjs-blocks-c google-block">
                              <div class="gjs-sm-property gjs-sm-stack gjs-sm-property__transition gjs-sm-property--full d-none">
                                  <div class="gjs-sm-label gjs-four-color" data-sm-label="">
                                      <span class="gjs-sm-icon " title="">
                                        Google Maps API Key 
                                      </span>
                                  </div>
                                  <div class="gjs-fields" data-sm-fields="">
                                    <input class="custom-seo-input" placeholder="enter API Key "/>
                                </div>
                              </div>
                             
                                      
                                 <button type="button" class="btn btn-primary tracking-code-save-btn d-flex fs-12 mb-3"  >
                                 Save
                                 <div role="status" class="spinner-border save-spinner save-spinner-tracking-code"><span class="visually-hidden">Loading...</span></div>
                                  
                                  </button>
                              </div>  
                            </div>
                        </div>                      
                        <div class="gjs-block-category ">
                          <div class="gjs-title microsoft-tags-heading" >
                                <i class="gjs-caret-icon fa fa-caret-down"></i>
                               Microsoft Scripts
                          </div>
                          <div class="gjs-blocks-c microsoft-block">
                              <div class="gjs-sm-property gjs-sm-stack gjs-sm-property__transition gjs-sm-property--full">
                              <div class="gjs-sm-label gjs-four-color" data-sm-label="">
                                        <span class="gjs-sm-icon " title="">
                                          MS Site Verification Tag 
                                              </span>
                                         </div>                                  
                                <div class="gjs-fields " data-sm-fields="">
                                        <input class="custom-seo-input ms_verification_tag" placeholder="Enter MS Site Verification Tag " />
                               </div>
                              </div>
                              <div class="gjs-sm-property gjs-sm-stack gjs-sm-property__transition gjs-sm-property--full">
                                
                                 <div class="gjs-sm-label gjs-four-color" data-sm-label="">
                                        <span class="gjs-sm-icon " title="">
                                           MS UET Tag ID
                                              </span>
                                         </div>                                  
                                      <div class="gjs-fields " data-sm-fields="">
                                        <input class="custom-seo-input ms_uet_tag_id" placeholder="Enter MS Uet tag ID" />
                                    </div>
                                     <button type="button" class="btn btn-primary microsoft-save-btn d-flex fs-12 mb-3"  >
                                         Save
                                         <div role="status" class="spinner-border save-spinner save-spinner-microsoft"><span class="visually-hidden">Loading...</span></div>
                                          
                                          </button>
                              </div>  
                              
                                           
                            </div>
                        </div>
                        <div class="gjs-block-category ">
                          <div class="gjs-title general-tags-heading" >
                            <i class="gjs-caret-icon fa fa-caret-down"></i>
                            General Scripts
                          </div>
                          <div class="gjs-blocks-c general-block">
                              <div class="gjs-sm-property gjs-sm-stack gjs-sm-property__transition gjs-sm-property--full">
                                  <div class="gjs-sm-label gjs-four-color" data-sm-label="">
                                      <span class="gjs-sm-icon " title="">
                                        Header
                                      </span>
                                  </div>
                                  <div class="gjs-fields" data-sm-fields="">
                                    <textarea class="custom-seo-textarea custom-head-input-description page_header_script" placeholder="Paste your code here" name="headdescription"  maxlength="5000"></textarea>
                                </div>
                                 <p class="custom-seo-label m-0">Enter javascript or css code into html document header.</p>
                              </div>
                              <div class="gjs-sm-property gjs-sm-stack gjs-sm-property__transition gjs-sm-property--full">
                                  <div class="gjs-sm-label gjs-four-color" data-sm-label="">
                                      <span class="gjs-sm-icon " title="">
                                        Body
                                      </span>
                                  </div>
                                  <div class="gjs-fields" data-sm-fields="">
                                    <textarea class="custom-seo-textarea custom-body-input-description page_body_script" placeholder="Paste your code here" name="bodydescription"  maxlength="5000"></textarea>
                                </div>
                                 <p class="custom-seo-label m-0">Enter html, javascript or css right after the opening <body> tag.</p>
                              </div>
                              <div class="gjs-sm-property gjs-sm-stack gjs-sm-property__transition gjs-sm-property--full">
                                  <div class="gjs-sm-label gjs-four-color" data-sm-label="">
                                      <span class="gjs-sm-icon " title="">
                                        Footer
                                      </span>
                                  </div>
                                  <div class="gjs-fields" data-sm-fields="">
                                    <textarea class="custom-seo-textarea custom-footer-input-description page_footer_script" placeholder="Paste your code here" name="footerdescription"  maxlength="5000"></textarea>
                                </div>
                                 <p class="custom-seo-label m-0">Enter html, javascript or css right before the closing <body> tag. </p>
                                 <button type="button" class="btn btn-primary general-save-btn d-flex fs-12 mb-3"  >
                                 Save
                                 <div role="status" class="spinner-border save-spinner save-spinner-general"><span class="visually-hidden">Loading...</span></div>
                                  
                                  </button>
                                 
                              </div>
                          </div>
                        </div>
                     
                     
                    `;

                                    const panels = pn.getPanel('views-container')
                                    panels.set('appendContent', editMenuDiv).trigger('change:appendContent')
                                    editPanel = editMenuDiv

                                    // alert('cc')
                                }
                                //console.log('cc')


                                editPanel.style.display = 'block';

                                //  console.log('block')
                                let seotitle = pageDetail?.seo_setting?.seo_title;
                                let seokeyword = pageDetail?.seo_setting?.seo_keyword;
                                let seodescription = pageDetail?.seo_setting?.seo_description;
                                let seoslugg = pageDetail?.url;
                                let googleAnalyticsId1 = pageDetail?.script_setting?.google_analytics_id;

                                let googleVerificationTag = pageDetail?.script_setting?.google_verification_tag ? decodeURIComponent(window.atob(pageDetail?.script_setting?.google_verification_tag)) : '';

                                // let facebook_id = pageDetail?.script_setting?.facebook_id;
                                // let twitter_id = pageDetail?.script_setting?.twitter_id;
                                let ms_verification_tag = pageDetail?.script_setting?.ms_verification_tag ? decodeURIComponent(window.atob(pageDetail?.script_setting?.ms_verification_tag)) : '';
                                let ms_uet_tag_id = pageDetail?.script_setting?.ms_uet_tag_id;
                                let page_header_script = pageDetail?.script_setting?.page_header_script ? decodeURIComponent(window.atob(pageDetail?.script_setting?.page_header_script)) : '';
                                let page_body_script = pageDetail?.script_setting?.page_body_script ? decodeURIComponent(window.atob(pageDetail?.script_setting?.page_body_script)) : '';
                                let page_footer_script = pageDetail?.script_setting?.page_footer_script ? decodeURIComponent(window.atob(pageDetail?.script_setting?.page_footer_script)) : '';
                                let google_conversion_id = pageDetail?.script_setting?.google_conversion_id
                                let google_label_id = pageDetail?.script_setting?.google_conversion_label


                                //  Modified  seo
                                const seoBlock = document.querySelector(".seo-block");
                                seoBlock.classList.add('d-none');

                                const CarrotIcon = document.querySelector(".seo-tags-heading i ");

                                CarrotIcon.classList.add('fa-caret-right')


                                //google tags

                                const googleBlock = document.querySelector(".google-block");
                                googleBlock.classList.add('d-none');
                                const googleIcon = document.querySelector(".google-tags-heading i ");
                                googleIcon.classList.add('fa-caret-right')


                                // microsoft bock

                                const microsoftBlock = document.querySelector(".microsoft-block");
                                microsoftBlock.classList.add('d-none');
                                const microsoftIcon = document.querySelector(".microsoft-tags-heading i ");

                                microsoftIcon.classList.add('fa-caret-right')


                                // google id block

                                const googleIdBlock = document.querySelector(".googleId-block");
                                googleIdBlock.classList.add('d-none');
                                const googleIdIcon = document.querySelector(".googleId-tags-heading i ");

                                googleIdIcon.classList.add('fa-caret-right')


                                //   general block


                                const generalBlock = document.querySelector(".general-block");
                                generalBlock.classList.add('d-none');
                                const generalIcon = document.querySelector(".general-tags-heading i ");

                                generalIcon.classList.add('fa-caret-right')



                                console.log(CarrotIcon);
                                if (seotitle === undefined) {
                                    seotitle = '';
                                }
                                if (seokeyword === undefined) {
                                    seokeyword = '';
                                }
                                if (seodescription === undefined) {
                                    seodescription = '';
                                }
                                if (seoslugg === undefined) {
                                    seoslugg = '';
                                }
                                if (googleAnalyticsId1 === undefined) {
                                    googleAnalyticsId1 = '';
                                }

                                if (googleVerificationTag === undefined) {
                                    googleVerificationTag = '';
                                }


                                // if (facebook_id === undefined) {
                                //     facebook_id = '';
                                // }
                                // if (twitter_id === undefined) {
                                //     twitter_id = '';
                                // }
                                if (ms_verification_tag === undefined) {
                                    ms_verification_tag = '';
                                }
                                if (ms_uet_tag_id === undefined) {
                                    ms_uet_tag_id = '';
                                }
                                if (page_header_script === undefined) {
                                    page_header_script = '';
                                }
                                if (page_body_script === undefined) {
                                    page_body_script = '';
                                }
                                if (page_footer_script === undefined) {
                                    page_footer_script = '';
                                }
                                if (google_conversion_id === undefined) {
                                    google_conversion_id = '';
                                }
                                if (google_label_id === undefined) {
                                    google_label_id = '';
                                }


                                (function () {
                                    // alert(window.atob(ms_verification_tag))
                                    document.getElementsByClassName('custom-seo-input-title')[0].value = seotitle;
                                    document.getElementsByClassName('custom-seo-input-keyword')[0].value = seokeyword;
                                    document.getElementsByClassName('custom-seo-input-description')[0].value = seodescription;
                                    document.getElementsByClassName('custom-seo-input-slug')[0].value = seoslugg;
                                    document.getElementsByClassName('google-analyticsId')[0].value = googleAnalyticsId1;
                                    document.getElementsByClassName('google-verification')[0].value = googleVerificationTag;

                                    // document.getElementsByClassName('facebook_id')[0].value = facebook_id;
                                    // document.getElementsByClassName('twitter_id')[0].value = twitter_id;
                                    document.getElementsByClassName('ms_verification_tag ')[0].value = ms_verification_tag;
                                    document.getElementsByClassName('ms_uet_tag_id ')[0].value = ms_uet_tag_id;
                                    document.getElementsByClassName('page_header_script')[0].value = page_header_script;
                                    document.getElementsByClassName('page_body_script ')[0].value = page_body_script;
                                    document.getElementsByClassName('page_footer_script ')[0].value = page_footer_script;
                                    document.getElementsByClassName('google_conversion_id ')[0].value = google_conversion_id;
                                    document.getElementsByClassName('google_label_id ')[0].value = google_label_id;


                                })();


                                seoBtnTags = document.querySelector(".seo-tags-heading");
                                seoBtnTags.addEventListener('click', toggleSeoBlock);


                                googleBtnTags = document.querySelector(".google-tags-heading");
                                googleBtnTags.addEventListener('click', toogleGoogleBlock);

                                microsoftBtnTags = document.querySelector(".microsoft-tags-heading");
                                microsoftBtnTags.addEventListener('click', toogleMicroSoftBlock)



                                googleIdBtnTags = document.querySelector(".googleId-tags-heading");
                                googleIdBtnTags.addEventListener('click', toogleGoogleIdBlock)



                                generalBtnTags = document.querySelector(".general-tags-heading");
                                generalBtnTags.addEventListener('click', toogleGeneralBlock)


                                //Seo ScriptSetting start


                                var Save = document.querySelector('.savbtnnn');

                                var seoTitle = document.querySelector('.custom-seo-input-title');
                                var seoKeyword = document.querySelector('.custom-seo-input-keyword');
                                var seoDescription = document.querySelector('.custom-seo-input-description');
                                var seoSlug = document.querySelector('.custom-seo-input-slug');
                                var seoTitleValue = seoTitle.value;
                                var seoKeywordValue = seoKeyword.value;
                                var seoDescriptionValue = seoDescription.value;
                                var seoSlugValue = seoSlug.value;

                                if (seoDescriptionValue.length && seoTitleValue.length > 0) {
                                    Save.removeAttribute('disabled');
                                }

                                seoTitle.addEventListener('input', evt => {
                                    seoTitleValue = seoTitle.value
                                    if (seoSlugValue.length > 0) {
                                        Save.removeAttribute('disabled');
                                    }
                                    // if(seoDescriptionValue.length >0  && seoTitleValue.length > 0 &&  seoSlugValue.length > 0){
                                    //     Save.removeAttribute('disabled');
                                    // }else {
                                    //     Save.setAttribute("disabled", "disabled");
                                    // }
                                });
                                seoKeyword.addEventListener('input', evt => {
                                    seoKeywordValue = seoKeyword.value;
                                    if (seoSlugValue.length > 0) {
                                        Save.removeAttribute('disabled');
                                    }
                                })


                                seoDescription.addEventListener('input', evt => {
                                    seoDescriptionValue = seoDescription.value;
                                    if (seoSlugValue.length > 0) {
                                        Save.removeAttribute('disabled');
                                    }

                                    // if(seoDescriptionValue.length && seoTitleValue.length  && seoSlugValue.length > 0){
                                    //
                                    //     Save.removeAttribute('disabled');
                                    // }else {
                                    //     Save.setAttribute("disabled", "disabled");
                                    // }
                                })
                                seoSlug.addEventListener('change', evt => {
                                    let resullt = evt.target.value.replace(/\s+/g, '-').trim().toLowerCase()
                                    evt.target.value = resullt;
                                    // seoSlugValue=seoSlugValue.replace(/[^a-zA-Z0-9\s]/g, "").replace(/\s+/g, '-').trim().toLowerCase();
                                    // console.log(seoSlugValue);
                                    // seoSlugValue=seoSlug.value;
                                    seoSlugValue = resullt;

                                    if (seoSlugValue.length > 0) {

                                        Save.removeAttribute('disabled');
                                    } else {
                                        Save.setAttribute("disabled", "disabled");
                                    }
                                })


                                Save.addEventListener('click', async function (e) {
                                    if (isApiCallInProgress) {
                                        return; // Prevent multiple clicks while the API call is in progress.
                                    }

                                    isApiCallInProgress = true; // Set the flag to indicate an API call is in progress.

                                    let spinner = document.querySelector('.save-spinner');
                                    spinner.style.display = 'block';

                                    e.preventDefault();

                                    let formData = {
                                        page_id: editorpage,
                                        seo_title: seoTitleValue,
                                        seo_keyword: seoKeywordValue,
                                        seo_description: seoDescriptionValue,
                                        slug: seoSlugValue,
                                    };

                                    try {
                                        const {data} = await server.post(`page/update_seo_setting`,
                                            formData
                                        );

                                        console.log('API calling', data);
                                        setUpdateGrapeJs(!updateGrapeJs);

                                        if (data?.status) {
                                            toast(data?.message, 'success');
                                        }

                                        spinner.style.display = 'none';
                                    } catch (e) {
                                        spinner.style.display = 'none';
                                        console.log('cosakdljsald', e);

                                        if (e.response.data?.error === true) {
                                            if (e.response?.data?.errors.seo_title) {
                                                toast(e.response?.data?.errors.seo_title[0], 'error');
                                            } else if (e.response?.data?.errors.seo_description) {
                                                toast(e.response?.data?.errors.seo_description[0], 'error');
                                            } else if (e.response?.data?.errors.seo_keyword) {
                                                toast(e.response?.data?.errors.seo_keyword[0], 'error');
                                            } else if (e.response?.data?.errors.slug) {
                                                toast(e.response?.data?.errors.slug[0], 'error');
                                            }
                                            else
                                            {
                                                toast(e.response?.data?.errors_array[0], 'error');

                                            }
                                        }
                                    } finally {
                                        isApiCallInProgress = false; // Reset the flag to allow future API calls.
                                    }
                                });




                                //    SEO Script Setting end
                                //    Tracking Codes Setting start

                                var SavetrackingCode4 = document.querySelector('.tracking-code-save-btn');

                                // var SavetrackingCode = document.querySelector('.general-save-btn');

                                var googleAnalyticsId = document.querySelector('.google-analyticsId');
                                var googleAnalyticsIdValue = googleAnalyticsId.value;

                                var googleVerificationTagId = document.querySelector('.google-verification');
                                var googleVerificationTagValue = googleVerificationTagId.value;

                                // var facebookId = document.querySelector('.facebook_id');
                                // var facebookIdValue = facebookId.value;
                                // var twitterId = document.querySelector('.twitter_id');
                                // var twitterIdValue = twitterId.value;
                                var msVerificationTagId = document.querySelector('.ms_verification_tag');
                                var msVerificationTagIdValue = msVerificationTagId.value;
                                var msUetTagId = document.querySelector('.ms_uet_tag_id');
                                var msUetTagIdValue = msUetTagId.value;

                                var pageHeaderId = document.querySelector('.page_header_script');
                                var pageHeaderValue = pageHeaderId.value;

                                var pageBodyId = document.querySelector('.page_body_script');
                                var pageBodyValue = pageBodyId.value;

                                var pageFooterId = document.querySelector('.page_footer_script');
                                var pageFooterValue = pageFooterId.value;

                                var googleCoversationId = document.querySelector('.google_conversion_id');
                                var googleConversationValue = googleCoversationId.value;

                                var googleLabelId = document.querySelector('.google_label_id');
                                var googleLabelValue = googleLabelId.value;


                                googleAnalyticsId.addEventListener('input', function (e) {
                                    googleAnalyticsIdValue = googleAnalyticsId.value;
                                });
                                googleVerificationTagId.addEventListener('input', function (e) {
                                    googleVerificationTagValue = googleVerificationTagId.value;
                                });
                                // facebookId.addEventListener('input', function (e) {
                                //     facebookIdValue = this.value;
                                // });
                                // twitterId.addEventListener('input', function (e) {
                                //     twitterIdValue = this.value;
                                // });
                                msVerificationTagId.addEventListener('input', function (e) {
                                    msVerificationTagIdValue = this.value;
                                });
                                msUetTagId.addEventListener('input', function (e) {
                                    msUetTagIdValue = this.value;
                                });
                                pageHeaderId.addEventListener('input', function (e) {
                                    pageHeaderValue = this.value;
                                });
                                pageBodyId.addEventListener('input', function (e) {
                                    pageBodyValue = this.value;
                                });
                                pageFooterId.addEventListener('input', function (e) {
                                    pageFooterValue = this.value;
                                });
                                googleCoversationId.addEventListener('input', function (e) {
                                    googleConversationValue = this.value;
                                });

                                googleLabelId.addEventListener('input', function (e) {
                                    googleLabelValue = this.value;
                                });


                                SavetrackingCode4.addEventListener('click', async function (e) {

                                    let spinner = document.querySelector('.save-spinner-tracking-code');


                                    spinner.style.display = 'block';


                                    e.preventDefault();

                                    let formData = {
                                        page_id: editorpage,
                                        google_analytics_id: googleAnalyticsIdValue,
                                        // facebook_id: facebookIdValue,
                                        // twitter_id: twitterIdValue,
                                        google_verification_tag: window.btoa(encodeURIComponent(googleVerificationTagValue)),

                                        ms_verification_tag: window.btoa(encodeURIComponent(msVerificationTagIdValue)),
                                        ms_uet_tag_id: msUetTagIdValue,
                                        page_header_script: window.btoa(encodeURIComponent(pageHeaderValue)),
                                        page_body_script: window.btoa(encodeURIComponent(pageBodyValue)),
                                        page_footer_script: window.btoa(encodeURIComponent(pageFooterValue)),
                                        google_conversion_id: googleConversationValue,
                                        google_conversion_label: googleLabelValue


                                    }
                                    try {
                                        const {data} = await server.post(`page/update_script_setting`,
                                            formData
                                        );
                                        if (data?.status) {
                                            toast(data?.message, 'success')
                                        }
                                        //   console.log(data)
                                        // setProduct(data);
                                        // setLoading(false);
                                        // spinner.style.display='none';
                                        spinner.style.display = 'none';
                                    } catch (e) {
                                        spinner.style.display = 'none';
                                        // spinner.style.display='none';
                                        // if(e.response.data?.status===false){
                                        //     toast("Please fill all feilds",'error')
                                        // }
                                        //console.log(e.response);
                                        if (e?.response?.data?.error)
                                            toast(e?.response?.data?.errors_array, 'error');
                                    }
                                })


                                //    Tracking Codes Setting end


                                //microsoft_scripts

                                var SavetrackingCode3 = document.querySelector('.microsoft-save-btn');


                                var googleAnalyticsId = document.querySelector('.google-analyticsId');
                                var googleAnalyticsIdValue = googleAnalyticsId.value;

                                var googleVerificationTagId = document.querySelector('.google-verification');
                                var googleVerificationTagValue = googleVerificationTagId.value;


                                //  var facebookId = document.querySelector('.facebook_id');
                                //  var facebookIdValue = facebookId.value;
                                //  var twitterId = document.querySelector('.twitter_id');
                                //  var twitterIdValue = twitterId.value;
                                var msVerificationTagId = document.querySelector('.ms_verification_tag');
                                var msVerificationTagIdValue = msVerificationTagId.value;
                                var msUetTagId = document.querySelector('.ms_uet_tag_id');
                                var msUetTagIdValue = msUetTagId.value;

                                var pageHeaderId = document.querySelector('.page_header_script');
                                var pageHeaderValue = pageHeaderId.value;

                                var pageBodyId = document.querySelector('.page_body_script');
                                var pageBodyValue = pageBodyId.value;

                                var pageFooterId = document.querySelector('.page_footer_script');
                                var pageFooterValue = pageFooterId.value;

                                var googleCoversationId = document.querySelector('.google_conversion_id');
                                var googleConversationValue = googleCoversationId.value;

                                var googleLabelId = document.querySelector('.google_label_id');
                                var googleLabelValue = googleLabelId.value;


                                googleAnalyticsId.addEventListener('input', function (e) {
                                    googleAnalyticsIdValue = googleAnalyticsId.value;
                                });
                                googleVerificationTagId.addEventListener('input', function (e) {
                                    googleVerificationTagValue = googleVerificationTagId.value;
                                });

                                //  facebookId.addEventListener('input', function (e) {
                                //      facebookIdValue = this.value;
                                //  });
                                //  twitterId.addEventListener('input', function (e) {
                                //      twitterIdValue = this.value;
                                //  });
                                msVerificationTagId.addEventListener('input', function (e) {
                                    msVerificationTagIdValue = this.value;
                                });
                                msUetTagId.addEventListener('input', function (e) {
                                    msUetTagIdValue = this.value;
                                });
                                pageHeaderId.addEventListener('input', function (e) {
                                    pageHeaderValue = this.value;
                                });
                                pageBodyId.addEventListener('input', function (e) {
                                    pageBodyValue = this.value;
                                });
                                pageFooterId.addEventListener('input', function (e) {
                                    pageFooterValue = this.value;
                                });
                                googleCoversationId.addEventListener('input', function (e) {
                                    googleConversationValue = this.value;
                                });
                                googleLabelId.addEventListener('input', function (e) {
                                    googleLabelValue = this.value;
                                });


                                SavetrackingCode3.addEventListener('click', async function (e) {

                                    let spinner = document.querySelector('.save-spinner-microsoft');


                                    spinner.style.display = 'block';


                                    e.preventDefault();

                                    let formData = {
                                        page_id: editorpage,
                                        google_analytics_id: googleAnalyticsIdValue,
                                        //  facebook_id: facebookIdValue,
                                        //  twitter_id: twitterIdValue,
                                        google_verification_tag: window.btoa(encodeURIComponent(googleVerificationTagValue)),

                                        ms_verification_tag: window.btoa(encodeURIComponent(msVerificationTagIdValue)),
                                        ms_uet_tag_id: msUetTagIdValue,
                                        page_header_script: window.btoa(encodeURIComponent(pageHeaderValue)),
                                        page_body_script: window.btoa(encodeURIComponent(pageBodyValue)),
                                        page_footer_script: window.btoa(encodeURIComponent(pageFooterValue)),
                                        google_conversion_id: googleConversationValue,
                                        google_conversion_label: googleLabelValue


                                    }
                                    try {
                                        const {data} = await server.post(`page/update_script_setting`,
                                            formData
                                        );
                                        if (data?.status) {
                                            toast(data?.message, 'success')
                                        }
                                        //   console.log(data)
                                        // setProduct(data);
                                        // setLoading(false);
                                        // spinner.style.display='none';
                                        spinner.style.display = 'none';
                                    } catch (e) {
                                        spinner.style.display = 'none';
                                        // spinner.style.display='none';
                                        // if(e.response.data?.status===false){
                                        //     toast("Please fill all feilds",'error')
                                        // }
                                        //console.log(e.response);
                                        if (e?.response?.data?.error)
                                            toast(e?.response?.data?.errors_array, 'error');
                                    }
                                })


                                //    Microsoft Script end


                                //general_scripts

                                var SavetrackingCode2 = document.querySelector('.general-save-btn');

                                var googleAnalyticsId = document.querySelector('.google-analyticsId');
                                var googleAnalyticsIdValue = googleAnalyticsId.value;

                                var googleVerificationTagId = document.querySelector('.google-verification');
                                var googleVerificationTagValue = googleVerificationTagId.value;

                                //  var facebookId = document.querySelector('.facebook_id');
                                //  var facebookIdValue = facebookId.value;
                                //  var twitterId = document.querySelector('.twitter_id');
                                //  var twitterIdValue = twitterId.value;
                                var msVerificationTagId = document.querySelector('.ms_verification_tag');
                                var msVerificationTagIdValue = msVerificationTagId.value;
                                var msUetTagId = document.querySelector('.ms_uet_tag_id');
                                var msUetTagIdValue = msUetTagId.value;

                                var pageHeaderId = document.querySelector('.page_header_script');
                                var pageHeaderValue = pageHeaderId.value;

                                var pageBodyId = document.querySelector('.page_body_script');
                                var pageBodyValue = pageBodyId.value;

                                var pageFooterId = document.querySelector('.page_footer_script');
                                var pageFooterValue = pageFooterId.value;
                                var googleCoversationId = document.querySelector('.google_conversion_id');
                                var googleConversationValue = googleCoversationId.value;

                                var googleLabelId = document.querySelector('.google_label_id');
                                var googleLabelValue = googleLabelId.value;


                                googleAnalyticsId.addEventListener('input', function (e) {
                                    googleAnalyticsIdValue = googleAnalyticsId.value;
                                });
                                googleVerificationTagId.addEventListener('input', function (e) {
                                    googleVerificationTagValue = googleVerificationTagId.value;
                                });
                                //  facebookId.addEventListener('input', function (e) {
                                //      facebookIdValue = this.value;
                                //  });
                                //  twitterId.addEventListener('input', function (e) {
                                //      twitterIdValue = this.value;
                                //  });
                                msVerificationTagId.addEventListener('input', function (e) {
                                    msVerificationTagIdValue = this.value;
                                });
                                msUetTagId.addEventListener('input', function (e) {
                                    msUetTagIdValue = this.value;
                                });
                                pageHeaderId.addEventListener('input', function (e) {
                                    pageHeaderValue = this.value;
                                });
                                pageBodyId.addEventListener('input', function (e) {
                                    pageBodyValue = this.value;
                                });
                                pageFooterId.addEventListener('input', function (e) {
                                    pageFooterValue = this.value;
                                });
                                googleCoversationId.addEventListener('input', function (e) {
                                    googleConversationValue = this.value;
                                });

                                googleLabelId.addEventListener('input', function (e) {
                                    googleLabelValue = this.value;
                                });


                                SavetrackingCode2.addEventListener('click', async function (e) {

                                    let spinner = document.querySelector('.save-spinner-general');


                                    spinner.style.display = 'block';


                                    e.preventDefault();

                                    let formData = {
                                        page_id: editorpage,
                                        google_analytics_id: googleAnalyticsIdValue,
                                        //  facebook_id: facebookIdValue,
                                        //  twitter_id: twitterIdValue,
                                        google_verification_tag: window.btoa(encodeURIComponent(googleVerificationTagValue)),

                                        ms_verification_tag: window.btoa(encodeURIComponent(msVerificationTagIdValue)),
                                        ms_uet_tag_id: msUetTagIdValue,
                                        page_header_script: window.btoa(encodeURIComponent(pageHeaderValue)),
                                        page_body_script: window.btoa(encodeURIComponent(pageBodyValue)),
                                        page_footer_script: window.btoa(encodeURIComponent(pageFooterValue)),
                                        google_conversion_id: googleConversationValue,
                                        google_conversion_label: googleLabelValue


                                    }
                                    try {
                                        const {data} = await server.post(`page/update_script_setting`,
                                            formData
                                        );
                                        if (data?.status) {
                                            toast(data?.message, 'success')
                                        }
                                        //   console.log(data)
                                        // setProduct(data);
                                        // setLoading(false);
                                        // spinner.style.display='none';
                                        spinner.style.display = 'none';
                                    } catch (e) {
                                        spinner.style.display = 'none';
                                        // spinner.style.display='none';
                                        // if(e.response.data?.status===false){
                                        //     toast("Please fill all feilds",'error')
                                        // }
                                        //console.log(e.response);
                                        if (e?.response?.data?.error)
                                            toast(e?.response?.data?.errors_array, 'error');
                                    }
                                })


                                //    General Script end


                                //google script start

                                var SavetrackingCode5 = document.querySelector('.google-save-btn');

                                var googleAnalyticsId = document.querySelector('.google-analyticsId');
                                var googleAnalyticsIdValue = googleAnalyticsId.value;
                                var googleVerificationTagId = document.querySelector('.google-verification');
                                var googleVerificationTagValue = googleVerificationTagId.value;

                                //  var facebookId = document.querySelector('.facebook_id');
                                //  var facebookIdValue = facebookId.value;
                                //  var twitterId = document.querySelector('.twitter_id');
                                //  var twitterIdValue = twitterId.value;
                                var msVerificationTagId = document.querySelector('.ms_verification_tag');
                                var msVerificationTagIdValue = msVerificationTagId.value;
                                var msUetTagId = document.querySelector('.ms_uet_tag_id');
                                var msUetTagIdValue = msUetTagId.value;

                                var pageHeaderId = document.querySelector('.page_header_script');
                                var pageHeaderValue = pageHeaderId.value;

                                var pageBodyId = document.querySelector('.page_body_script');
                                var pageBodyValue = pageBodyId.value;

                                var pageFooterId = document.querySelector('.page_footer_script');
                                var pageFooterValue = pageFooterId.value;

                                var googleCoversationId = document.querySelector('.google_conversion_id');
                                var googleConversationValue = googleCoversationId.value;

                                var googleLabelId = document.querySelector('.google_label_id');
                                var googleLabelValue = googleLabelId.value;


                                googleAnalyticsId.addEventListener('input', function (e) {
                                    googleAnalyticsIdValue = googleAnalyticsId.value;
                                });
                                googleVerificationTagId.addEventListener('input', function (e) {
                                    googleVerificationTagValue = googleVerificationTagId.value;
                                });
                                //  facebookId.addEventListener('input', function (e) {
                                //      facebookIdValue = this.value;
                                //  });
                                //  twitterId.addEventListener('input', function (e) {
                                //      twitterIdValue = this.value;
                                //  });
                                msVerificationTagId.addEventListener('input', function (e) {
                                    msVerificationTagIdValue = this.value;
                                });
                                msUetTagId.addEventListener('input', function (e) {
                                    msUetTagIdValue = this.value;
                                });
                                pageHeaderId.addEventListener('input', function (e) {
                                    pageHeaderValue = this.value;
                                });
                                pageBodyId.addEventListener('input', function (e) {
                                    pageBodyValue = this.value;
                                });
                                pageFooterId.addEventListener('input', function (e) {
                                    pageFooterValue = this.value;
                                });
                                googleCoversationId.addEventListener('input', function (e) {
                                    googleConversationValue = this.value;
                                });
                                googleLabelId.addEventListener('input', function (e) {
                                    googleLabelValue = this.value;
                                });


                                SavetrackingCode5.addEventListener('click', async function (e) {

                                    let spinner = document.querySelector('.save-spinner-googleId');


                                    spinner.style.display = 'block';


                                    e.preventDefault();

                                    let formData = {
                                        page_id: editorpage,
                                        google_analytics_id: googleAnalyticsIdValue,
                                        //  facebook_id: facebookIdValue,
                                        //  twitter_id: twitterIdValue,
                                        google_verification_tag: window.btoa(encodeURIComponent(googleVerificationTagValue)),

                                        ms_verification_tag: window.btoa(encodeURIComponent(msVerificationTagIdValue)),
                                        ms_uet_tag_id: msUetTagIdValue,
                                        page_header_script: window.btoa(encodeURIComponent(pageHeaderValue)),
                                        page_body_script: window.btoa(encodeURIComponent(pageBodyValue)),
                                        page_footer_script: window.btoa(encodeURIComponent(pageFooterValue)),
                                        google_conversion_id: googleConversationValue,
                                        google_conversion_label: googleLabelValue


                                    }
                                    try {
                                        const {data} = await server.post(`page/update_script_setting`,
                                            formData
                                        );
                                        if (data?.status) {
                                            toast(data?.message, 'success')
                                        }
                                        //   console.log(data)
                                        // setProduct(data);
                                        // setLoading(false);
                                        // spinner.style.display='none';
                                        spinner.style.display = 'none';
                                    } catch (e) {
                                        spinner.style.display = 'none';
                                        // spinner.style.display='none';
                                        // if(e.response.data?.status===false){
                                        //     toast("Please fill all feilds",'error')
                                        // }
                                        //console.log(e.response);
                                        if (e?.response?.data?.error)
                                            toast(e?.response?.data?.errors_array, 'error');
                                    }
                                })


                                //    Google script end


                            },
                            stop: function (editor) {


                                if (editPanel != null) {
                                    editPanel.style.display = 'none'
                                }
                                if (seoBtnTags) {
                                    seoBtnTags.removeEventListener('click', toggleSeoBlock);
                                }

                                if (googleBtnTags) {
                                    googleBtnTags.removeEventListener('click', toogleGoogleBlock);
                                }

                                if (googleIdBtnTags) {
                                    googleIdBtnTags.removeEventListener('click', toogleGoogleIdBlock);
                                }
                                if (microsoftBtnTags) {
                                    microsoftBtnTags.removeEventListener('click', toogleMicroSoftBlock);
                                }
                                if (generalBtnTags) {
                                    generalBtnTags.removeEventListener('click', toogleGeneralBlock);
                                }
                            }
                        }
                        ,
                        attributes: {title: 'Add Seo Settings'},

                        togglable: 0,


                    },]);


                    function toggleSeoBlock() {
                        const seoBlock = editPanel.querySelector(".seo-block");
                        const CarrotIcon = seoBtnTags.querySelector("i");
        
                        if (CarrotIcon.classList.contains('fa-caret-right')) {
                            CarrotIcon.classList.remove('fa-caret-right');
                            CarrotIcon.classList.add('fa-caret-down');
                            seoBlock.classList.remove('d-none');
                        } else {
                            CarrotIcon.classList.remove('fa-caret-down');
                            CarrotIcon.classList.add('fa-caret-right');
                            seoBlock.classList.add('d-none');
                        }
                    }
        
        
                    function toogleGoogleBlock(e) {
                        const googleBlock = editPanel.querySelector(".google-block");
                        const CarrotIcon = googleBtnTags.querySelector("i");
        
                        if (CarrotIcon.classList.contains('fa-caret-right')) {
                            CarrotIcon.classList.remove('fa-caret-right');
                            CarrotIcon.classList.add('fa-caret-down');
                            googleBlock.classList.remove('d-none');
                        } else {
                            CarrotIcon.classList.remove('fa-caret-down');
                            CarrotIcon.classList.add('fa-caret-right');
                            googleBlock.classList.add('d-none');
                        }
                    }
        
        
                    function toogleMicroSoftBlock(e) {
                        const microsoftBlock = editPanel.querySelector(".microsoft-block");
                        const CarrotIcon = microsoftBtnTags.querySelector("i");
        
                        if (CarrotIcon.classList.contains('fa-caret-right')) {
                            CarrotIcon.classList.remove('fa-caret-right');
                            CarrotIcon.classList.add('fa-caret-down');
                            microsoftBlock.classList.remove('d-none');
                        } else {
                            CarrotIcon.classList.remove('fa-caret-down');
                            CarrotIcon.classList.add('fa-caret-right');
                            microsoftBlock.classList.add('d-none');
                        }
                    }
        
        
                    function toogleGoogleIdBlock(e) {
                        const googleIdBlock = editPanel.querySelector(".googleId-block");
                        const CarrotIcon = googleIdBtnTags.querySelector("i");
        
                        if (CarrotIcon.classList.contains('fa-caret-right')) {
                            CarrotIcon.classList.remove('fa-caret-right');
                            CarrotIcon.classList.add('fa-caret-down');
                            googleIdBlock.classList.remove('d-none');
                        } else {
                            CarrotIcon.classList.remove('fa-caret-down');
                            CarrotIcon.classList.add('fa-caret-right');
                            googleIdBlock.classList.add('d-none');
                        }
                    }
        
                    function toogleGeneralBlock(e) {
                        const generalBlock = editPanel.querySelector(".general-block");
                        const CarrotIcon = generalBtnTags.querySelector("i");
        
                        if (CarrotIcon.classList.contains('fa-caret-right')) {
                            CarrotIcon.classList.remove('fa-caret-right');
                            CarrotIcon.classList.add('fa-caret-down');
                            generalBlock.classList.remove('d-none');
                        } else {
                            CarrotIcon.classList.remove('fa-caret-down');
                            CarrotIcon.classList.add('fa-caret-right');
                            generalBlock.classList.add('d-none');
                        }
                    }
        
}
