import React, { useEffect, useState } from 'react';

function TimeAgo({ timestamp }) {
  const [timeAgo, setTimeAgo] = useState('');

  useEffect(() => {
    const currentTime = new Date();
    const previousTime = new Date(timestamp);
    const timeDifference = currentTime - previousTime;
    const secondsAgo = Math.floor(timeDifference / 1000);

    if (secondsAgo < 60) {
      setTimeAgo(`${secondsAgo} second${secondsAgo !== 1 ? 's' : ''} ago`);
    } else if (secondsAgo < 3600) {
      const minutesAgo = Math.floor(secondsAgo / 60);
      setTimeAgo(`${minutesAgo} minute${minutesAgo !== 1 ? 's' : ''} ago`);
    } else if (secondsAgo < 86400) {
      const hoursAgo = Math.floor(secondsAgo / 3600);
      setTimeAgo(`${hoursAgo} hour${hoursAgo !== 1 ? 's' : ''} ago`);
    } else if (secondsAgo < 2592000) { // 30 days in seconds
      const daysAgo = Math.floor(secondsAgo / 86400);
      setTimeAgo(`${daysAgo} day${daysAgo !== 1 ? 's' : ''} ago`);
    } else {
      // Handle cases for more than 30 days
      const monthsAgo = Math.floor(secondsAgo / 2592000); // 30 days in seconds

      if (monthsAgo >= 2) {
        setTimeAgo(`${monthsAgo} month${monthsAgo !== 1 ? 's' : ''} ago`);
      } else {
        // If less than 2 months, display months without remaining days
        setTimeAgo(`${monthsAgo} month${monthsAgo !== 1 ? 's' : ''} ago`);
      }
    }
  }, [timestamp]);

  return (
    <span>{timeAgo}</span>
  );
}

export default TimeAgo;
