import React,{useState,useEffect}  from "react";
import server from "../../../apis/server"
import Loader from "../../../components/common/loader/loader";
import toast from '../../../utils/toast' 
import { useNavigate ,useParams} from "react-router-dom";


export default function Detail() {
    const [isLoading, setIsLoading] = useState(false);
    const [packages, setpackages] = useState(null);
    const router = useNavigate()
    let { token } = useParams();



    useEffect(() => {
       if(token){
        verifyToken()
       }
    }, [])


    const verifyToken = async () => {

        try {
            setIsLoading(true)
            
        

            const {data} = await server.get(`account/verify/${token}`);
            if (data?.status) {
                
                // setpackages(data?.data)
                toast(data?.message, 'success')
                               router(`/login`)

                
            }
            else{
                toast(data?.message, 'error')
                router(`/signup`)


            }
            console.log(data)
            // setProduct(data);
            setIsLoading(false);

        } catch (e) {
            if (e.response?.data?.status === false) {
                console.log(e.response?.data.errors?.title[0])
                toast(e.response?.data?.message, 'error')
                router(`/signup`)

                // setError(e.response?.data.errors?.title[0])
                setIsLoading(false);
            } else {
                // setError(e.message)
            }


        }
    }


    return (
        <div>
            <div className="accounts-wrapper">
            {/* <ToastContainer/> */}

            {isLoading &&
       (


            <div className={'loaderPage'}>
                <Loader/>
            </div>
            
       )}



            </div>

        </div>
    )
}