import React, { useState, useEffect } from "react";
import { Card, CardBody, Spinner, CardTitle, CardSubtitle, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import server from '../../../apis/server'
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../common/loader/loader";
import card1img from '../../../images/tile0.png';
import card2img from '../../../images/tile1.png';
import card3img from '../../../images/tile2.png';
import card4img from '../../../images/tile3.png';
import Radio from "../../common/category/radio";




function List() {

    const router = useNavigate();
    let { state } = useLocation();

    const [title, setTitle] = useState("")
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [preLoader, setpreLoader] = useState(true)
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('All Templates');


    const [blankTemplate, setblankTemplate] = useState([
        {
            name: 'Blank Page',
            // subtitle:'Preset',
            src: card1img,
        }])


    const [template, setTemplate] = useState([

        //  {
        //      name:'Business Starter',
        //      subtitle:'Preset',
        //      src:card2img
        //  },
        //
        //  {
        //      name:'Business Starter',
        //      subtitle:'Preset',
        //      src:card3img
        //  },
        //
        //  {
        //      name:'Business Starter',
        //      subtitle:'Preset',
        //      src:card4img
        //  },
        //
        // //  {
        // //      name:'Business Starter',
        // //      subtitle:'Preset',
        // //      src:'/images/tile4.png'
        // //  },
        // // {
        // //      name:'Business Starter',
        // //      subtitle:'Preset',
        // //      src:'/images/tile5.png'
        // //  },
    ]);
    const [disable, setDisable] = useState(true);
    const [modal, setModal] = useState(false);
    const [isemptyModal, setIsemptyModal] = useState(true);
    const [slug, setSLug] = useState(true);

    const accountsString =   localStorage.getItem('accounts')
    const accounts =    accountsString ? JSON.parse(accountsString) : ''
    const {subscription_coupon,user_type}  =   accounts

    const toggle = () => {
        console.log('this is error state show ', errorMsg)
        setError(false);
        setModal(!modal);

    }
    const showModal = (val, slug) => {
        setIsemptyModal(val);
        setSLug(slug);
        toggle()

    }



    const characterLimit = event => {
        event.preventDefault();

        let result = event.target.value.replace(/\s+/g, ' ').trim();

        // if (event.target.value.includes(" ")) {
        //     event.target.value = event.target.value.replace(/\s/g, "");
        // }
        // let result=event.target.value;

        // setTitle(e.target.value)
        // let isValid = result.replace(/\s+/g, '');
        setTitle(result)
        const charCount = result.length;
        if (charCount >= 3 && charCount < 255) {

            setDisable(false);

        } else {
            console.log(charCount);
            setDisable(true);
        }
    }
    const createPage = async () => {

        setDisable(true)
        setIsLoading(true)

        setError('')
        let emptyFormData = {
            title,
            funnel_id: state?.id,
            grape_js_version: 1
        }
        let formData = {
            title,
            template_slug: slug,
            funnel_id: state?.id,
            grape_js_version: 1
        }
        try {
            const { data } = await server.post(`page/save`,
                isemptyModal ? emptyFormData : formData,
            );

            if (data?.status) {

                // isLoading(true)
                router(`/pages/editor/${data?.data?.page_id}`,
                    {
                        state: {
                            is_from_template: data?.data?.is_from_template

                        }
                    }
                )
            }
            console.log(data)
            // setProduct(data);
            // setLoading(false);
        } catch (e) {
            if (e.response?.data?.status === false) {
                console.log(e.response?.data.errors?.title[0])
                setError(e.response?.data.errors?.title[0])
                setDisable(false)
                setIsLoading(false)
            }
            else {
                setError(e.message)
                setDisable(false)
                setIsLoading(false)

            }
            //console.log(e.response);

        }
    }

    const getTemplateData = async () => {
        try {
           
            let coupon = subscription_coupon
           
            if( user_type == 'bpa'){
                coupon = 'bpa'
            }
            else if(coupon){
                coupon = subscription_coupon
            }
            if(coupon == null ){
                coupon = null
            }
           
            
            const { data } = await server.get(`templates/${coupon? coupon :null}`);
            if (data?.status) {
                setTemplate(data?.data);
                extractCategories(data?.data);
                setIsLoading(false);
            }
            setpreLoader(false);
        } catch (e) {
            if (e.response?.data?.status === false) {
                setError(e.response?.data.errors?.title[0]);
                setIsLoading(true);
            } else {
                setError(e.message);
            }
        }
    }

    const extractCategories = (templates) => {
        const categories = ['All Templates', ...new Set(templates?.map(template => template?.template_category))];
        // const categories = ['All Templates', 'Sports'];

        setCategories(categories);
    }



    const handleCategoryClick = (category) => {
        console.warn('categorey',category)
        setSelectedCategory(category);
    }


    const filteredTemplates = selectedCategory === 'All Templates' ? template : template.filter(t => t.template_category === selectedCategory);


    useEffect(() => {
        if (error) {
            let timerFunc = setTimeout(() => {
                setError((false))
            }, 3000);

            return () => clearTimeout(timerFunc);
        }
        getTemplateData()
    }, [error])








    return (
        <>

            {
                preLoader ?

                    <div className={'loaderPage'}>
                        <Loader />
                    </div>


                    :
                    <>
                       <div className="col-12">
                    <Radio
                        categories={categories}
                        selectedCategory={selectedCategory}
                        onCategoryChange={handleCategoryClick}
                    />
                </div>

                        <div className="row listTemplate py-4">
                            {blankTemplate.map((x, index) => {
                                return (
                                    <div className="col-3">
                                        <Card
                                            key={index}
                                            onClick={() => showModal(true)}
                                        >
                                            <span className={'cardImage'}>
                                                <img
                                                    alt={x.name}
                                                    src={x.src}
                                                    width="100%" height="100%" layout="responsive"
                                                    objectFit="cover"
                                                />
                                            </span>
                                            <CardBody className={'px-0'}>
                                                <h5>
                                                    {x.name}
                                                </h5>
                                                <p className="mb-2 text-muted">
                                                    {x.subtitle}
                                                </p>
                                            </CardBody>
                                        </Card>
                                    </div>)


                            })}

                            {
                                filteredTemplates?.map((x, index) => {
                                    return (
                                        <div className="col-3">
                                            <Card
                                                key={index}
                                                onClick={() => showModal(false, x.slug)}
                                            >
                                                <span className={'cardImage'}>
                                                    <img
                                                        alt={x.title}
                                                        src={x.thumbnail}
                                                        width="100%" height="100%" layout="responsive" objectFit="cover"
                                                    />
                                                </span>
                                                <CardBody className={'px-0'}>
                                                    <h5>
                                                        {x.title}
                                                    </h5>
                                                    {/* <p className="mb-2 text-muted">
                                                        Preset
                                                    </p> */}
                                                </CardBody>
                                            </Card>
                                        </div>
                                    )
                                })
                            }
                            <Modal autoFocus={false} isOpen={modal} toggle={toggle} className={'creatPageModal'}
                                centered backdrop={'false'}>
                                <ModalHeader toggle={toggle}>Set page name </ModalHeader>
                                <ModalBody>
                                    <p>Page name</p>
                                    <Input
                                        autoFocus
                                        className='' type={'text'} placeholder={'Enter page name '}
                                        onChange={characterLimit}
                                        onKeyPress={event => {
                                            if (event.key === 'Enter') {
                                                createPage()
                                            }
                                        }}
                                    />
                                    {error && (<p className={'errorMessage'}> {error} </p>)}
                                </ModalBody>
                                <ModalFooter>
                                    <Button className={'primary-btn-modal shadow-none'} onClick={createPage}
                                        disabled={disable ? true : false}>
                                        {isLoading ?
                                            <span className={'spinnerContainer'}> Creating  <Spinner>
                                                Loading...
                                            </Spinner></span> : 'Create'}</Button>{' '}
                                    <Button className={'secondary-btn-modal shadow-none'}
                                        onClick={toggle}>Cancel</Button>
                                </ModalFooter>
                            </Modal>

                        </div>
                    </>
            }

        </>


    );
}
export default List;