import server from "../../../../apis/server";
export function addRemoteStorage({
    editor, 
    setLoading, 
    setStatus, 
    setPageDetail, 
    setseoPgeTitle, 
    setseoPgeKeyword, 
    setseoPgeDescription, 
    setDraftLoading, 
    setHtml, 
    updatePage, 
    getPage, 
    editorpage
}) {
   
    editor.Storage.add('remote', {
        async load() {

          let  data = await server.get(`${getPage}`);
            setLoading(false)

            const divs = document.getElementsByClassName('form-group');
            // alert(JSON.stringify(divs))
            for (let x = 0; x < divs.length; x++) {
                const div = divs[x];
                const content = div.textContent.trim();
                // alert('cc')
                if (content == 'Handtekening' || content == 'Message') {
                    div.style.display = 'none';
                }
            }


            // setDraftData(data?.data?.data?.draft_body)

            // alert(data?.data?.data?.draft_body)
            setStatus(data?.data?.data.status)
            console.log('status', status);
            setPageDetail(data?.data?.data)
            setseoPgeTitle(data?.data?.data?.seo_setting?.seo_title);
            setseoPgeKeyword(data?.data?.data?.seo_setting?.seo_keyword);
            setseoPgeDescription(data?.data?.data?.seo_setting?.seo_description);
            return data
        },




        async store(data) {
            // Set loading to true when starting the operation
            setDraftLoading(true);

            try {
                const obj = {
                    page_id: editorpage,
                    draft_body: JSON.stringify(data),
                };



                const htmlObj = {
                    html: editor.getHtml(),
                    css: editor.getCss()

                }

                // console.warn('current html',htmlObj)
                setHtml(htmlObj)


                // Perform your asynchronous operation (e.g., making an API request)
                const response = await server.post(`${updatePage}`, obj);

                // Set loading back to false when the operation is complete
                setDraftLoading(false);

                return response;
            } catch (error) {
                // Handle errors if needed
                setDraftLoading(false);
                alert(error);
            }
        }
    });
}
 