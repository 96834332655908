import Header from "../../../../components/header/header";
import React from 'react';
import Sidebar from "../../../../components/sidebar/sidebar";
import DomainDetailList from "../../../../components/domain/detailTable"


export default function Domain() {
    return (
        <div>
            <main className={'mainContainer'}>
                <Sidebar/>
                <section className={'internalContainer'}>
                    <Header/>
                    <div className={'container  py-5'}>
                         <DomainDetailList />
                    </div>
                </section>
            </main>
        </div>
    )
}
