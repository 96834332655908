import Header from "../../../components/header/header";
import React from 'react';
import Sidebar from "../../../components/sidebar/sidebar";
import DomainList from "../../../components/domain/table"
import DomainWizard from "../../../components/domain/wizards"
import DomainMaualList from "../../../components/domain/addManual"


export default function Domain() {
    return (
        <div>
            <main className={'mainContainer'}>
                <Sidebar/>
                <section className={'internalContainer'}>
                    <Header page={'Domain Manager'}/>
                    <div className={'container  py-5'}>
                        <DomainList />
                    </div>
                </section>
            </main>
        </div>
    )
}
