import React ,{useState,useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
function Device({data}) {

    const [options, setChartOptions] = useState(
        {

            chart: {
                type: 'column',
                height: 230
            },
            title: {
                text: '',
                style: {
                    display: 'none'
                }
            },
            credits: {
                enabled: false
            },
            xAxis: {
                categories: ['18-24', '25-34', '35-44', '45-54', '55-64', '65+' ]
            },
            yAxis: {
                // min: 0,
                title: {
                    text: ''
                },
                stackLabels: {
                    enabled: true,

                }
            },

            tooltip: {
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: false
                    }
                }
            },
            series: [
                {
                    // name: 'en-us',
                    data: [0,0,0,0,0],
                }, {
                    // name: 'en-gb',
                    data: [0,0,0,0,0],
                }, {
                    // name: 'fr',
                    data: [0,0,0,0,0],
                }, {
                    // name: '(not set)',
                    data: [0,0,0,0,0],
                }
            ]
        }
    )

    useEffect(() => {

      
        const data2 = data?.device_x_axis_vals;
        const result = [];
        if(data2){
            Object?.keys(data2)
            .forEach(key => {
                result.push({name: key, data: data2[key]})
            })
        }
        
        console.log(result)
        
        
        
        setChartOptions({
            xAxis: {
                categories: data?.device_x_axis 
            },
            series: data2?.length != 0 ? result : [
                {
                    name: '',
                    data: data?.device_x_axis,
                }, 
            ]
        });
    }, [data])
    return (

        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>

    )
}
export default Device;