import SideBar from "../../../components/sidebar/sidebar";
import Header from "../../../components/header/header";
import React, {useState,useEffect} from "react";
import NavTabs from "../../../components/common/tabs/navTabs";
import {
    useParams
  } from "react-router-dom";
  import server from '../../../apis/server'
import Loader from "../../../components/common/loader/loader";


export default function Detail() {

    let { detail } = useParams();
    const [isLoading, setIsLoading] = useState(true)
    const [pageDetail, setPageDetail] = useState()
    const [updateTitle, setUpdateTitle] = useState(false)


    // {{base_url}}/page/edit/2
    //  alert(detail)
     useEffect(() => {
      if(detail){ 
        getPageDetail()
      }
    }, [detail,updateTitle])

    const getPageDetail = async () => {

        try {





             
            const {data} = await server.get(`/page/edit/${detail}`);
            if (data?.status) {
                console.log('detail is thereeeeeeee',data)
                setPageDetail(data?.data)
                setIsLoading(false);

            }
            // console.log(data)
            // setpreLoader(false);
            // setProduct(data);
            setIsLoading(false);

        } catch (e) {
            setLoading(false)
            if (e.response?.data?.status === false) {
                if (e.response?.data.errors_array?.[0]) {
                    toast(e.response?.data.errors_array?.[0], 'error')
                }
                else {
                    toast(e.response?.data?.message, 'error')

                }
                //  setError(e.response?.data.errors?.title[0])
            }

        }
    }
    const handleClick = val => {
         setUpdateTitle(!updateTitle)
      };


    const [activeTab, setActiveTab] = useState('1');
    return (
        <div>

           {isLoading &&
                (
                    <div className={'loaderPageEditor domainloader'}>

                        <Loader />
                    </div>

                )}


            <main className={'mainContainer'}>
                <SideBar/> 
                <section className={'internalContainer'}>
                    <Header page={'detail'} detail={detail} pageDetail={pageDetail} handleClick={handleClick}/>

                    <div className="container-fluid p-0">
                        <NavTabs detail ={detail} />
                    </div>

                </section>
            </main>
        </div>
    )
}