import React, { useState } from "react";
import Logo from "../../../images/logoinverse.png";
import IconCheckMark from "../../../images/icon-check-success.png";
import PricingModule from "../../../components/common/accounts/pricingModule"
import { Button, Input, Spinner } from "reactstrap";
import server from '../../../apis/server'
import toast from '../../../utils/toast'
import { useLocation } from "react-router-dom";


export default function Detail() {


    const accountsString = localStorage.getItem('accounts')
    const accounts = accountsString ? JSON.parse(accountsString) : ''
    console.log('accounts', accounts)
    const { user_id, subscription_status } = accounts


    const { state } = useLocation();

    const [coupon, setCoupon] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [disable, setDisable] = useState(true);
    const [validCoupon, setValidCoupon] = useState("")
    const [couponData, setCouponData] = useState("")

    const characterLimit = event => {
        event.preventDefault();

        let result = event.target.value.replace(/\s+/g, ' ').trim();

        setCoupon(result)
        const charCount = result.length;
        if (charCount >= 3 && charCount < 255) {

            setDisable(false);

        } else {
            console.log(charCount);

            setDisable(true);
        }
    }


    const validateCoupon = async () => {

        setDisable(true)
        setIsLoading(true)

        let formData = {
            coupon,
            user_id

        }
        try {
            const { data } = await server.post(`verifyCoupon`,
                formData,
            );

            if (data?.status) {
                toast(data?.message, 'success')
                setCouponData(data?.data)
                setValidCoupon(true)
                // isLoading(true)
                // router(`/pages/editor/${data?.data?.page_id}`)


            }

            console.log(data)
            // setProduct(data);
            setIsLoading(false);
        } catch (e) {
            setValidCoupon(false)
            setDisable(false)
            setIsLoading(false)
            if (e.response?.data?.status === false) {
                if (e.response?.data.errors_array?.[0]) {
                    toast(e.response?.data.errors_array?.[0], 'error')
                }
                else {
                    toast(e.response?.data?.message, 'error')
                }

                setIsLoading(false)
            }
            else {
                setIsLoading(false)

            }


        }
    }



    return (
        <div>
            <div className="billing-wrapper">
                <img src={Logo} alt={"LOGO"} />
                <div className={'container'}>
                    <div className={'billing-inner-wrapper '}>

                        <div className={'select-plan'}>
                            <h1 className={'text-center mb-5'}> Select your plan</h1>
                            {/*<p className={'text-center'}> Please select subscription plan to continue.</p>*/}


                            {subscription_status == null && (
                                <div className={'row justify-content-center mb-5'}>
                                    <div className={'col-md-4'}>
                                        <div className={'d-flex '}>
                                            <Input
                                                autoFocus
                                                className='' type={'text'} placeholder={'Enter your coupon here'}
                                                onChange={characterLimit}
                                                onKeyPress={event => {
                                                    if (event.key === 'Enter') {
                                                        validateCoupon
                                                    }
                                                }}
                                            />
                                            <Button className={'mx-3'}
                                                color="primary"

                                                disabled={
                                                    !coupon
                                                    // !values.confirm_pass ||
                                                }
                                                onClick={validateCoupon}

                                            >
                                                {isLoading ?
                                                    <span className={'spinnerContainer'}>
                                                        <Spinner>
                                                            Loading...
                                                        </Spinner></span> : 'Apply'}
                                            </Button>
                                        </div>



                                    </div>
                                </div>

                            )}

                            <PricingModule validCoupon={validCoupon} couponData={couponData} state={state} />

                        </div>
                        <div className={'payment-Successful   pb-5 d-none'}>
                            <img src={IconCheckMark} alt={'Check Payment'} />
                            <h1 className={'text-center'}> Your Payment Received Successfully </h1>
                            <p className={'text-center'}>Thanks for choosing Float Funnels, your  <br />
                                payment has been received successfully.</p>
                            <Button color={'primary'}> Go to Workspace </Button>
                        </div>

                    </div>




                </div>



            </div>

        </div>
    )
}