import React,{useState,useEffect} from "react";

function RightSide() {

    return (

        <>
            <h1>  </h1>
            {/*<img src={Bgrightimage} alt={'banner Image'} />*/}
        </>

    )
}

export default RightSide;