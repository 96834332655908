import axios from 'axios';

// we get accounts detail from storage


const auth = axios.create({
// .. where we make our configurations
    baseURL: process.env.REACT_APP_API_URL,
});


// Also editor/ configure interceptors && all the other cool stuff


export default auth;
   