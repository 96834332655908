import Header from "../../../components/header/header";
import React, {useEffect, useState} from 'react';
import Sidebar from "../../../components/sidebar/sidebar";
import InternalTitle from "../../../components/common/internalTittle/GenaricButton";
import Radio from '../../../components/common/category/radio';
import List from "../../../components/pages/templates/list";
import Loader from "../../../components/common/loader/loader";
import server from "../../../apis/server";



export default function Templates() {

    const [isLoading, setIsLoading] = useState(true)

    const [selectedOption, setSelectedOption] = useState("");
    const [error, setError] = useState("")
    const [template, setTemplate] = useState([]);

    return (
        <div>
            <main className={'mainContainer'}>
                <Sidebar/>
                        <section className={'internalContainer'}>
                            <Header page={`Templates`}/>
                            <div className={'allPages'}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-12 py-4 ">
                                                    <InternalTitle title={'Choose Template'}/>
                                                </div>
                                                {/* <div className="col-12">
                                                    <Radio/>
                                                </div> */}
                                                <div className="col-12">
                                                    <List/>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </section>


            </main>
        </div>
    )
}
